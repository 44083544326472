/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the VintagePrice interface.
 */
export function instanceOfVintagePrice(value) {
    var isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "vintage" in value;
    return isInstance;
}
export function VintagePriceFromJSON(json) {
    return VintagePriceFromJSONTyped(json, false);
}
export function VintagePriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': json['price'],
        'vintage': json['vintage'],
    };
}
export function VintagePriceToJSON(value) {
    return VintagePriceToJSONTyped(value, false);
}
export function VintagePriceToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': value.price,
        'vintage': value.vintage,
    };
}
