import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TableCellProps } from "@mui/material/TableCell/TableCell";
import { CurveAmount, CurveEditButtonBlock, CurveInput } from "../prices/CurveComponents";
import {
    BaseOptimiserScoreMarking,
    type ProjectTypeScore,
    type VintageScore
} from '@commodity-desk/commodity-desk-api-js';

/**
 * Editable table component for the inventory score marking screen
 *
 * @param initialRows     Initial table values
 * @param handleSave      Handle save action
 * @param handleEditing   Handle the change to and from editing mode
 * @param disableEditing  Disables ability to enter editing mode, however if the table is being edited - has no effect
 */
const InventoryScoreMarkingTable = (
    {
        initialRows,
        handleSave,
        handleEditing,
        disableEditing = false
    }: {
        initialRows: BaseOptimiserScoreMarking,
        handleSave: (rows: BaseOptimiserScoreMarking) => Promise<boolean>,
        handleEditing: (isEditing: boolean) => void,
        disableEditing?: boolean
    }) => {

    const [isEditing, setEditing] = useState<boolean>(false);
    const [isSaving, setSaving] = useState<boolean>(false);
    const [updatedRows, setUpdatedRows] = useState<BaseOptimiserScoreMarking>({productId: initialRows.productId, projectTypeScores: []});

    useEffect(() => {
        setUpdatedRows(deepCopy(initialRows));
    }, [initialRows]);

    useEffect(() => {
        handleEditing(isEditing);
    }, [isEditing]); // eslint-disable-line react-hooks/exhaustive-deps
    const getVintageYears = () => {
        const allVintageYears = initialRows.projectTypeScores.flatMap((pts: ProjectTypeScore) => pts.vintageScores.map((vs: VintageScore) => vs.vintageYear));
        return Array.from(new Set(allVintageYears)).sort().reverse();
    };

    const handleCellChange = (projectType: string, vintage: string | undefined, value: number) => {
        setUpdatedRows(prevState => {
            const nextState = deepCopy(prevState);
            if (vintage === undefined) {
                nextState.projectTypeScores.find(pts => pts.projectType === projectType)!.baseScore = value;
            } else {
                nextState.projectTypeScores
                    .find(pts => pts.projectType === projectType)!.vintageScores
                    .find(vs => vs.vintageYear === vintage)!.score = value;
            }
            return nextState;
        })
    };

    const handleEditClick = () => {
        setUpdatedRows(deepCopy(initialRows));
        setEditing(prevState => !prevState);
    };

    const handleSaveClick = () => {
        setSaving(true);
        handleSave(updatedRows).then(success => {
            setSaving(false);
            if (success) {
                setEditing(false);
            }
        });
    };

    const deepCopy = (inventoryScoreMarking: BaseOptimiserScoreMarking) => {
        const copyMarking = {productId: inventoryScoreMarking.productId, projectTypeScores: []} as BaseOptimiserScoreMarking;
        inventoryScoreMarking.projectTypeScores.forEach((projectTypeScore) => copyMarking.projectTypeScores.push(
            {
                projectType: projectTypeScore.projectType,
                baseScore: projectTypeScore.baseScore,
                vintageScores: projectTypeScore.vintageScores.map((vintageScore) => {
                    return {
                        vintageYear: vintageScore.vintageYear,
                        score: vintageScore.score
                    }
                })
            }
        ));
        return copyMarking;
    }

    return (
        <>
            <CurveEditButtonBlock
                isEditing={isEditing}
                handleEditClick={handleEditClick}
                isSaving={isSaving}
                handleSaveClick={handleSaveClick}
                disabled={disableEditing}
                style={{ marginRight: '-30px' }}
            />
            <Typography variant='h3' mt={5}>Project Type Score</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: '50px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <InventoryScoreMarkingTableCell>Project Type</InventoryScoreMarkingTableCell>
                            {initialRows.projectTypeScores.map((pts: ProjectTypeScore) => pts.projectType).map(header =>
                                <InventoryScoreMarkingTableCell key={"header-" + header} align='right'>{header}</InventoryScoreMarkingTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={"methodRow"}>
                            <InventoryScoreMarkingTableCell key={"scoreCell"}>Score</InventoryScoreMarkingTableCell>
                            {initialRows.projectTypeScores.map((projectTypeScore) => (
                                <InventoryScoreMarkingTableCell key={projectTypeScore.projectType} align='right'>
                                    {isEditing
                                        ? <CurveInput
                                            defaultValue={projectTypeScore.baseScore}
                                            handleChange={value => handleCellChange(projectTypeScore.projectType, undefined, value)}
                                            textFieldProps={{
                                                disabled: isSaving,
                                                InputProps: {
                                                    sx: {
                                                        width: '100px',
                                                        height: '32px',
                                                        mr: '-14px',
                                                    },
                                                },
                                            }}
                                        />
                                        : <CurveAmount value={projectTypeScore.baseScore} />
                                    }
                                </InventoryScoreMarkingTableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant='h3' mt={5}>Vintage Score</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: '50px'  }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <InventoryScoreMarkingTableCell>Vintage</InventoryScoreMarkingTableCell>
                            {initialRows.projectTypeScores.map((pts: ProjectTypeScore) => pts.projectType).map(header =>
                                <InventoryScoreMarkingTableCell key={"header-" + header} align='right'>{header}</InventoryScoreMarkingTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getVintageYears().map((vintageYear) =>
                            <TableRow key={vintageYear}>
                                <InventoryScoreMarkingTableCell key={vintageYear}>{vintageYear}</InventoryScoreMarkingTableCell>
                                {initialRows.projectTypeScores.map((projectTypeScore) => (
                                    <InventoryScoreMarkingTableCell key={vintageYear + "-" + projectTypeScore.projectType} align='right'>
                                        {isEditing
                                            ? <CurveInput
                                                defaultValue={projectTypeScore.vintageScores.find(vs => vs.vintageYear === vintageYear)?.score ?? 0}
                                                handleChange={value => handleCellChange(projectTypeScore.projectType, vintageYear, value)}
                                                textFieldProps={{
                                                    disabled: isSaving,
                                                    InputProps: {
                                                        sx: {
                                                            width: '100px',
                                                            height: '32px',
                                                            mr: '-14px',
                                                        },
                                                    },
                                                }}
                                            />
                                            : <CurveAmount value={projectTypeScore.vintageScores.find(vs => vs.vintageYear === vintageYear)?.score ?? 0} />
                                        }
                                    </InventoryScoreMarkingTableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

/**
 * Pre-formatted cell element for the editable inventory score marking table component
 *
 * @param height Height prop
 * @param sx SX prop
 * @param children Children prop
 * @param rest Other properties for the underlying <TableCell/> component
 */
const InventoryScoreMarkingTableCell = (
    {
        height = '45px',
        sx = { minWidth: '180px' },
        children,
        ...rest
    }: TableCellProps) => {
    return <TableCell height={height} sx={sx} {...rest}>{children}</TableCell>
};

export { InventoryScoreMarkingTable };
