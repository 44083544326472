import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

/**
 * The interface for the props for this component. The table data needs to be in the
 * folowing format
 *
 * {
 *     columns: [ 'col1', 'col2', 'col3' ]
 *     data: {
 *         [
 *             { rowHeader: row1, col1: 25, col2: 64, col3: 22 },
 *             { rowHeader: row2, col1: 84, col2: 78, col3: 23 }
 *         ]
 *     }
 * }
 *
 * for a table that would look like
 *  ---------------------------
 * |      | col1 | col2 | col3 |
 *  ---------------------------
 * | row1 |  25  |  64  |  22  |
 * | row2 |  84  |  78  |  23  |
 *  ---------------------------
 *
 */
interface AggregatorTableProps {
    data: { [key: string]: any }[];
    columns: string[];
}

const shouldRenderTable = (props: AggregatorTableProps): boolean => {
    return props.columns.length !== 0 && props.data.length !== 0;
};

const AggregatorTable = ({ data, columns }: AggregatorTableProps) => {
    return (
        <>
            {shouldRenderTable({ data, columns }) && (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* Empty cell for the top-left corner */}
                                <TableCell />
                                {columns.map((column) => (
                                    <TableCell key={column}>{column}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow key={row.get('rowHeader')}>
                                    <TableCell>{row.get('rowHeader')}</TableCell>
                                    {columns.map((column) => (
                                        <TableCell key={column}>{row.get(column)}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            )}
        </>
    );
};

export { AggregatorTable, type AggregatorTableProps };
