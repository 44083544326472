import {
    AppConfigContextType,
    CommodityType,
    CommodityFormFieldDefinition,
    FieldDefinition,
    FieldDefinitionKey,
    getGasOilFieldDefinitions
} from '@commodity-desk/common';

/**
 * Interface for a config related to a product
 *
 * @property {string} id The ID of the product
 * @property {string} displayCode The display code of the product
 */
interface ProductConfig {
    id: string;
    displayCode: string;
}

/**
 * Interface for config related to a Commodity
 */
interface CommodityConfigType {
    commodity: ProductConfig;
    lowCarbonCommodity: ProductConfig;
}

/**
 * Class to hold data and perform queries on Commodity config
 */
class CommodityConfig {

    readonly carbonProducts: ProductConfig[];
    readonly commodities: Map<CommodityType, CommodityConfigType>;
    readonly formFieldDefinitions: Map<CommodityType, CommodityFormFieldDefinition[]>;

    constructor(appConfigState: AppConfigContextType) {
        const rawCommodityConfig = appConfigState.getCommoditiesConfig();
        const commodities: Map<CommodityType, CommodityConfigType> = new Map();

        const getProductConfig = (config: any): ProductConfig => {
            return {
                id: config['ID'],
                displayCode: config['DISPLAY_CODE']
            };
        };

        Object.keys(rawCommodityConfig['COMMODITY_PRODUCTS']).forEach((entry) => {
            let commodityType: CommodityType;

            // The hardcoded values in the switch statement below correspond to the
            // values in the config file. TS doesn't have a Java style Enum.valueOf()
            // and this is the only location where we are using these hardcoded values
            // since we are assigning the Enum here. The rest of the code base should
            // use the Enum instead.
            switch (entry) {
                case 'GASOIL':
                    commodityType = CommodityType.GasOil;
                    break;
                default:
                    throw Error(`Unknown Commodity Type: ${entry}`)
            }

            const commodityConfig: CommodityConfigType = {
                commodity: getProductConfig(rawCommodityConfig['COMMODITY_PRODUCTS'][entry]['COMMODITY_PRODUCT']),
                lowCarbonCommodity: getProductConfig(rawCommodityConfig['COMMODITY_PRODUCTS'][entry]['LOW_CARBON_PRODUCT'])
            };

            commodities.set(commodityType, commodityConfig);
        });

        this.carbonProducts = rawCommodityConfig['CARBON_PRODUCTS'].map((cp: any) => getProductConfig(cp));
        this.commodities = commodities;
        this.formFieldDefinitions = new Map([
            [CommodityType.GasOil, getGasOilFieldDefinitions((appConfigState))]
        ]);
    }

    /**
     * Get all {@link ProductConfig} for the Commodities registered with the app
     */
    getCommodities(): ProductConfig[] {
        return Array.from(this.commodities.values()).map(i => i.commodity);
    }

    /**
     * Get all product ids for the Commodity products registered with the app
     */
    getCommodityProductIds(): string[] {
        return this.getCommodities().map(i => i.id);
    }

    /**
     * Get the {@link CommodityType} associated with commodity product id
     *
     * @param productId the ID of the Commodity product
     *
     * @return {CommodityType} the type of commodity for the product if found else undefined
     *
     * Note: We need to return undefined type here because the commodityType dropdown in the
     * form will pass in an empty string on start when there is no value selected by the user.
     * This will cause an error. We could get around this by having a value pre-selected but
     * this would change the UX which we decided against.
     */
    getCommodityTypeForProductId(productId: string): CommodityType | undefined {
        for (let [k, v] of Array.from(this.commodities.entries())) {
            if (v.commodity.id === productId) {
                return k;
            }
        }

        return undefined;
    }

    /**
     * Get all {@link CommodityFormFieldDefinition} for the commodity product matching with the id
     *
     * @param productId the ID of the Commodity product
     *
     * @return {CommodityFormFieldDefinition[]} array if found else undefined
     *
     * Note: We need to return undefined type here because the commodityType dropdown in the
     * form will pass in an empty string on start when there is no value selected by the user.
     * This will cause an error. We could get around this by having a value pre-selected but
     * this would change the UX which we decided against.
     */
    getCommodityFieldDefinitionsForProductId(productId: string): CommodityFormFieldDefinition[] | undefined {
        const type = this.getCommodityTypeForProductId(productId);
        return type ? this.formFieldDefinitions.get(type) : undefined
    }

    /**
     * Get all of the {@link FieldDefinition} for all commodities registered with the app
     */
    getAllFieldDefinitions(): FieldDefinition[] {
        return Array.from(this.formFieldDefinitions.values()).reduce((acc, value) => acc.concat(value), []);
    }

    /**
     * Get a Map of {@link FieldDefinitionKey} to their corresponding {@link FieldDefinition} for all
     * commodities registered with the app
     */
    getFieldDefinitionMap(): Map<FieldDefinitionKey, FieldDefinition> {
        const definitionMap = new Map<FieldDefinitionKey, FieldDefinition>();
        this.getAllFieldDefinitions()
            .forEach((definition) => {
                definitionMap.set(definition.key, definition);
            });
        return definitionMap;
    }
}

export { CommodityConfig }