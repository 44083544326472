var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import { OverviewCategory, RenderLocation, findFieldDefinitionInMap, formatDisplayValue, NO_DATA_INDICATOR } from '../component/fieldDefinitions/FieldDefinitions';
var ActionType;
(function (ActionType) {
    ActionType["Button"] = "Button";
    ActionType["Info"] = "Info";
    ActionType["LoadingBar"] = "LoadingBar";
})(ActionType || (ActionType = {}));
var TransactionOverview = function (_a) {
    var open = _a.open, onClose = _a.onClose, title = _a.title, uiElements = _a.uiElements, relatedProperties = _a.relatedProperties, fieldDefinitionMap = _a.fieldDefinitionMap, actions = _a.actions, productType = _a.productType;
    // separate the actions into info, loading, and button actions
    var infoActions = (actions === null || actions === void 0 ? void 0 : actions.filter(function (action) { return action.actionType === ActionType.Info; })) || [];
    var loadingActions = (actions === null || actions === void 0 ? void 0 : actions.filter(function (action) { return action.actionType === ActionType.LoadingBar; })) || [];
    var buttonActions = (actions === null || actions === void 0 ? void 0 : actions.filter(function (action) { return action.actionType === ActionType.Button; })) || [];
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: open, onClose: onClose, sx: {
                '.MuiPaper-root': { maxWidth: '870px' }
            } }, { children: [_jsxs(DialogContent, { children: [_jsx(Typography, __assign({ variant: "h2", mb: 3 }, { children: title })), loadingActions.length > 0 && (_jsx(Box, __assign({ mb: 2, textAlign: 'center' }, { children: _jsx(LinearProgress, {}) }))), infoActions.length > 0 && (_jsx(Box, __assign({ mb: 2, textAlign: 'center' }, { children: infoActions.map(function (action, index) { return (_jsx(Typography, __assign({ sx: { marginRight: '20px' } }, { children: action.label }), index)); }) }))), buttonActions.length > 0 && (_jsx(Box, __assign({ mb: 2, display: "flex", justifyContent: "flex-end", gap: 1.5 }, { children: buttonActions.map(function (action, index) { return (_jsx(Button, __assign({ onClick: action.action, color: "primary", variant: "outlined" }, { children: action.label }), index)); }) }))), _jsx(TransactionContents, { uiElements: uiElements, relatedProperties: relatedProperties, fieldDefinitionMap: fieldDefinitionMap, productType: productType })] }), _jsx(DialogActions, __assign({ sx: { p: 3, pt: 0 } }, { children: _jsx(Button, __assign({ onClick: onClose, color: "primary", variant: "outlined" }, { children: "Close" })) }))] })) }));
};
/**
 * Renders the transaction contents as inline or group based on the isGrouped prop
 * @param uiElements - the UI elements to render (usually correspond 1-1 to attributes, but some composite elements also exist)
 * @param relatedProperties - A dictionary of related properties that are not shown directly on the transaction overview, but these fields are needed for
 *                            some custom rendering functions that need access to these additional properties. For example, the Counterparty field may show
 *                            the client name, but also needs to show the client ID in a tooltip. In this case client ID is a related property.
 * @param fieldDefinitionMap - Provides properties used to render the contents of these uiElements/attributes
 * @param productType - used for selecting the Product-specific UI elements to display in the Product section of the dialog
 * @returns {JSX.Element} - the rendered component
 */
var TransactionContents = function (_a) {
    var uiElements = _a.uiElements, relatedProperties = _a.relatedProperties, fieldDefinitionMap = _a.fieldDefinitionMap, productType = _a.productType;
    var _b = useState(false), isMore = _b[0], setIsMore = _b[1];
    var groupedItems = getCategorisedProperties(uiElements, fieldDefinitionMap, productType);
    // As we have 2 panel layout, we need to split the groupedItems into 2 columns
    // When "More" call to action is clicked, second column will be shown
    var firstColumns = groupedItems === null || groupedItems === void 0 ? void 0 : groupedItems.slice(0, 2);
    var secondColumns = groupedItems === null || groupedItems === void 0 ? void 0 : groupedItems.slice(2, groupedItems.length);
    var showMoreOption = secondColumns.length > 0 && isMore;
    var data = {};
    for (var uiElementKey in uiElements) {
        var currentUiElement = uiElements[uiElementKey];
        data[uiElementKey] = currentUiElement.value;
    }
    relatedProperties === null || relatedProperties === void 0 ? void 0 : relatedProperties.forEach(function (value, key) {
        data[key] = value;
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: {
                    display: 'grid',
                    gridTemplateColumns: "repeat(".concat(showMoreOption ? 2 : 1, ", 1fr)"),
                    gap: 2,
                    justifyContent: 'space-between'
                } }, { children: [_jsx(Box, __assign({ sx: { display: 'grid', gap: 2 } }, { children: firstColumns === null || firstColumns === void 0 ? void 0 : firstColumns.map(function (group, index) {
                            var _a;
                            return (_jsxs(BoxStyled, __assign({ className: "border" }, { children: [_jsx(BoxStyled, __assign({ className: "padding header" }, { children: _jsx(Typography, __assign({ variant: "h3", sx: { margin: 0 } }, { children: group.label })) })), _jsx(BoxStyled, __assign({ className: "padding" }, { children: (_a = group.fields) === null || _a === void 0 ? void 0 : _a.map(function (field, fieldIndex) { return (_jsx(DialogField, { field: field, data: data }, fieldIndex)); }) }))] }), index));
                        }) })), showMoreOption && (_jsx(Box, __assign({ sx: { display: 'grid', gap: 2 } }, { children: secondColumns === null || secondColumns === void 0 ? void 0 : secondColumns.map(function (group, index) {
                            var _a;
                            return (_jsxs(BoxStyled, __assign({ className: "border" }, { children: [_jsx(BoxStyled, __assign({ className: "padding header" }, { children: _jsx(Typography, __assign({ variant: "h3", sx: { margin: 0 } }, { children: group.label })) })), _jsx(BoxStyled, __assign({ className: "padding" }, { children: (_a = group.fields) === null || _a === void 0 ? void 0 : _a.map(function (field, fieldIndex) { return (_jsx(DialogField, { field: field, data: data }, fieldIndex)); }) }))] }), index));
                        }) })))] })), secondColumns.length > 0 && (_jsx(Box, __assign({ mt: 2 }, { children: _jsx(Button, __assign({ sx: { padding: '0px' }, onClick: function () { return setIsMore(!isMore); } }, { children: isMore ? 'Less...' : 'More...' })) })))] }));
};
/**
 * Convert a map of UI Elements into a map keyed by OverviewCategory. Each value of this map contains a list
 * of TransactionOverviewUiElementProps, which contain all the properties needed to render UI Elements on the
 * Transaction Overview dialog.
 * @param uiElements The input UiElementMap to convert
 * @param fieldDefinitionMap The field definitions to use to apply label, category group, etc during conversion
 * @param productType The product type we are using here, if relevant
 * @returns an array of categories, each containing the category name as well as an array of fields that it contains
 */
var getCategorisedProperties = function (uiElements, fieldDefinitionMap, productType) {
    var _a, _b, _c;
    // uiElements is originally in a map structure keyed by field ID, containing key and value.
    // Convert this into an array of objects containing key, label, value, and overview category.
    var uiElementList = [];
    for (var uiElementKey in uiElements) {
        var currentUiElement = uiElements[uiElementKey];
        var fieldDefinition = findFieldDefinitionInMap(uiElementKey, fieldDefinitionMap);
        // Exclude any elements that do not match the current productType
        // This can happen if uiElements is constructed with unneeded fields, which can happen in some cases at the moment.
        // TODO: In the future we will not construct uiElements with unneeded fields. At that time this check can be removed.
        if (productType !== undefined && (fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.productTypes) !== undefined && !fieldDefinition.productTypes.includes(productType)) {
            continue;
        }
        // Return the result
        uiElementList.push(__assign(__assign({}, currentUiElement), { key: uiElementKey, 
            // Allow label to be overridden in uiElements if necessary, otherwise use displayName from field definitions.
            label: (_a = currentUiElement.label) !== null && _a !== void 0 ? _a : fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.displayName, 
            // Allow category to be overridden in uiElements if necessary, otherwise use category from field definitions.
            category: (_c = (_b = currentUiElement.category) !== null && _b !== void 0 ? _b : fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.overviewCategory) !== null && _c !== void 0 ? _c : OverviewCategory.Other, fieldDefinition: fieldDefinition }));
    }
    // go through all possible groups and store matching attributes in the result array
    var result = [];
    for (var category in OverviewCategory) {
        var fields = [];
        for (var _i = 0, uiElementList_1 = uiElementList; _i < uiElementList_1.length; _i++) {
            var uiElement = uiElementList_1[_i];
            if (uiElement.category === category) {
                fields.push(uiElement);
            }
        }
        result.push({ label: category, fields: fields });
    }
    return result === null || result === void 0 ? void 0 : result.filter(function (group) { return group.fields.length > 0; });
};
/**
 * Represents a single line of data on the transaction overview dialog, containing the label and value.
 * The `field` property contains the label and value for the field, and the `data` property contains the
 * other related elements from the data object, which are used in some custom rendering functions that need
 * access to these other fields.
 * @param field The {@link TransactionOverviewUiElementProps} containing the label and value for the field
 * @param data  An object containing the other related properties from the data object, used in some custom rendering functions.
 * @returns a JSX element representing the rendered dialog field.
 */
var DialogField = function (_a) {
    var field = _a.field, data = _a.data;
    return (_jsxs(Box, __assign({ sx: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: '1rem'
        } }, { children: [!field.hideLabel && _jsxs(Typography, { children: [field.label, ":"] }), _jsx(Box, __assign({ sx: { flex: '1', textAlign: 'right', overflow: 'auto' } }, { children: _jsx(TransactionValueLabel, { field: __assign({}, field), data: data }) }))] }), "".concat(field.label)));
};
var BoxStyled = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        minWidth: '400px',
        '&.padding': {
            padding: '10px 15px'
        },
        '&.border': {
            border: "1px solid ".concat(alpha(theme.palette.secondary.dark, 0.3)),
            borderRadius: '5px',
            overflow: 'hidden'
        },
        '&.header': {
            backgroundColor: alpha(theme.palette.secondary.dark, 0.2)
        }
    });
});
/**
 * Get UI Elements to be used with a transaction overview.
 * This function returns all product attribute based UI elements.
 * @param data The data object to be used to extract values for display
 * @param fieldDefinitions The full, un-filtered list of field definitions to reference when constructing the uiElements
 * @param productType The specific product type we are using
 * @returns
 */
var getProductAttributeUiElements = function (_a) {
    var data = _a.data, fieldDefinitions = _a.fieldDefinitions, productType = _a.productType;
    var uiElements = {};
    for (var _i = 0, fieldDefinitions_1 = fieldDefinitions; _i < fieldDefinitions_1.length; _i++) {
        var fieldDefinition = fieldDefinitions_1[_i];
        if (fieldDefinition.productTypes && fieldDefinition.productTypes.includes(productType)) {
            if (data.hasOwnProperty(fieldDefinition.key)) {
                uiElements[fieldDefinition.key] = {
                    value: data[fieldDefinition.key],
                    key: fieldDefinition.key
                };
            }
        }
    }
    return uiElements;
};
/**
 * Responsible for rendering the value of an element in the overview dialog
 * @param field: The TransactionOverviewUiElementProps, which must contain the raw `value` for the field, and may also optionally contain
 *              `fieldDefinition`, which will be used to format the value for display or render it using custom configured JSX logic.
 * @param data: The data object used for this overview dialog
 * @returns Rendered value
 */
var TransactionValueLabel = function (_a) {
    var _b;
    var field = _a.field, data = _a.data;
    if (field.value === undefined || field.value === null) {
        return _jsx(_Fragment, { children: NO_DATA_INDICATOR });
    }
    else if ((_b = field.fieldDefinition) === null || _b === void 0 ? void 0 : _b.renderJSX) {
        return field.fieldDefinition.renderJSX({ value: field.value, row: data, renderLocation: RenderLocation.OverviewDialog });
    }
    else if (field.fieldDefinition) {
        return _jsx(_Fragment, { children: formatDisplayValue(field.fieldDefinition, field.value) });
    }
    return _jsx(_Fragment, { children: field.value });
};
export { ActionType, TransactionContents, TransactionOverview, getProductAttributeUiElements };
