var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { CommodityDeskApi, Configuration } from '@commodity-desk/commodity-desk-api-js';
import { backendUrl } from '../state/AppConfig';
var CommodityDeskApiContext = createContext(undefined);
var CommodityDeskApiProvider = function (_a) {
    var user = _a.user, children = _a.children;
    var _b = useState(), commodityDeskApi = _b[0], setCommodityDeskApi = _b[1];
    useEffect(function () {
        var config = new Configuration({
            basePath: backendUrl,
            headers: {
                Authorization: "Bearer ".concat(user.access_token)
            }
        });
        setCommodityDeskApi(new CommodityDeskApi(config));
    }, [user]);
    return (_jsx(_Fragment, { children: commodityDeskApi && (_jsx(CommodityDeskApiContext.Provider, __assign({ value: { commodityDeskApi: commodityDeskApi } }, { children: commodityDeskApi ? _jsx(_Fragment, { children: children }) : null }))) }));
};
function useCommodityDeskApiContext() {
    var context = useContext(CommodityDeskApiContext);
    if (!context) {
        throw new Error('no provider for Commodity Desk API');
    }
    return context;
}
export { CommodityDeskApiProvider, useCommodityDeskApiContext };
