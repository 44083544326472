/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VintagePriceFromJSON, VintagePriceToJSON, } from './VintagePrice.js';
/**
 * Check if a given object implements the ProjectPrice interface.
 */
export function instanceOfProjectPrice(value) {
    var isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "vintagePrices" in value;
    return isInstance;
}
export function ProjectPriceFromJSON(json) {
    return ProjectPriceFromJSONTyped(json, false);
}
export function ProjectPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': json['price'],
        'projectId': json['projectId'],
        'vintagePrices': (json['vintagePrices'].map(VintagePriceFromJSON)),
    };
}
export function ProjectPriceToJSON(value) {
    return ProjectPriceToJSONTyped(value, false);
}
export function ProjectPriceToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': value.price,
        'projectId': value.projectId,
        'vintagePrices': (value.vintagePrices.map(VintagePriceToJSON)),
    };
}
