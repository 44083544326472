import { Button, Grid, LinearProgress } from '@mui/material';
import { AggregatorTable, AggregatorTableProps } from './AggregatorTable';
import { useState } from 'react';
import { 
    fetchByCriteria
} from '@commodity-desk/common';
import AggregatorInput from './AggregatorInput';
import {
    convertToAggregatorTableProps,
    processAggregatorInput,
    processData
} from './AggregatorProcessing';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

const Aggregator = () => {

    const { cortenApi } = useCortenApiState();
    const [productId, setProductId] = useState<string>('');
    const [rowHeaders, setRowHeaders] = useState<string[]>([]);
    const [columnHeaders, setColumnHeaders] = useState<string[]>([]);

    const [aggregatorTableProps, setAggregatorTableProps] = useState<AggregatorTableProps>({
        data: [],
        columns: []
    });
    const [loadingTable, setLoadingTable] = useState(false);

    // Basic validation for the text input fields. These will be changed in a later ticket.
    const areInputsValid = (): boolean => {
        if (!productId) {
            alert('Please select a product ID');
            return false;
        }

        if (rowHeaders.length === 0) {
            alert('Please select at least one row header');
            return false;
        }

        if (columnHeaders.length === 0) {
            alert('Please select at least one column header');
            return false;
        }

        return true;
    };

    const onApplyButtonPress = async () => {
        if (!areInputsValid()) {
            return;
        }

        setLoadingTable(true);

        let response = await fetchByCriteria(
            {
                productIds: [productId!],
                axes: [...rowHeaders, ...columnHeaders],
                includeBalances: true
            },
            cortenApi
        );

        let processedInputs = processAggregatorInput(rowHeaders, columnHeaders);

        let dataSet = processData(response, processedInputs);

        let aggregatorTableProps = convertToAggregatorTableProps(dataSet);

        setAggregatorTableProps(aggregatorTableProps);
        setLoadingTable(false);
    };

    return (
        <Grid container>
            <Grid item xs={12} marginTop={3}>
                <AggregatorInput
                    productId={productId}
                    setProductId={setProductId}
                    columnHeaders={columnHeaders}
                    setColumnHeaders={setColumnHeaders}
                    rowHeaders={rowHeaders}
                    setRowHeaders={setRowHeaders}
                />
            </Grid>
            <Grid item xs={12} marginTop={3}>
                <Button onClick={onApplyButtonPress}>Apply</Button>
            </Grid>
            <Grid item xs={12} marginTop={5}>
                {loadingTable ? (
                    <LinearProgress sx={{ width: '100%' }} />
                ) : (
                    <AggregatorTable
                        data={aggregatorTableProps.data}
                        columns={aggregatorTableProps.columns}
                    ></AggregatorTable>
                )}
            </Grid>
        </Grid>
    );
};

export { Aggregator };
