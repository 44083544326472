import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useInventoryManagementState } from './state/InventoryManagementState';
import { useProductState } from './state/ProductState';
import { 
    commentAttribute,
    productAttributes,
    sectionSpace,
    useAppConfigState 
} from '@commodity-desk/common';

const ProductAttributes = () => {
    const appConfigState = useAppConfigState();
    const { productData } = useInventoryManagementState();
    const { mblSelectedProduct } = useProductState();

    /**
     * Get Key name to display on Product Summary table based on attribute name
     * if matches configured registry ID key e.g. registry_id -> then use the configured display value e.g. "Registry ID"
     * else -> Replace "_" with " ", capitalise first word
     */
    const getAttributeDisplayName = (attributeId: String): String => {
        if (attributeId === productAttributes.registry.key) {
            return productAttributes.registry.display;
        } else {
            const formattedId = attributeId
                .replace(/_/g, ' ')                                                             // Replace _ with space
                .replace(/\w+/g, (match) => match.charAt(0).toUpperCase() + match.slice(1));    // Replace first letter with uppercase

            return formattedId;
        }
    };

    return (
        <>
            <Typography variant="h3">Product Summary</Typography>
            {productData?.attributes !== undefined && (
                <Box sx={{ marginBottom: sectionSpace }}>
                    {Object.keys(productData?.attributes).length === 0 ? (
                        <Typography>No attributes have been set</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ maxWidth: 450 }}>
                            <Table sx={{ maxWidth: 450 }} size='small' aria-label="product attributes table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(productData?.attributes).map(
                                        (key, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>{getAttributeDisplayName(key)}</TableCell>
                                                <TableCell>
                                                    {key === commentAttribute.key ? appConfigState.getProduct(mblSelectedProduct.id)?.displayComment : productData?.attributes[key]}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            )}
        </>
    );
};

export default ProductAttributes;
