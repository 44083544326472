import { Typography } from '@mui/material';
import { TradePageType, Trades } from '../../component/Trades'
import { ColumnSet } from '../../utility/ColumnSetSelectorUtil';

const TradeBlotter = () => {
    return (
        <>
            <Typography variant='h2'>Trade Blotter</Typography>
            <Trades
                pageType={TradePageType.TradeBlotter}
                columnDefinitions={[
                    {key: "transactionTimestamp", showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                    {key: "tradeDate"},
                    {key: "tradeId"},
                    {key: "transactionId"},
                    {key: "transactionType", showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                    {key: "counterParty", showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                    {key: "productId", showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom]},
                    {key: "registry"},
                    {key: "projectType", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                    {key: "projectId", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                    {key: "projectName", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                    {key: "vintage", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                    {key: "projectState", showInDefaultColumnSet: [ColumnSet.ACCU]},
                    {key: "country", showInDefaultColumnSet: [ColumnSet.VCU]},
                    {key: "accreditationCode", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "fuelSource", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "creationYear", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "generationYear", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "generationState", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "greenPowerAccredited", showInDefaultColumnSet: [ColumnSet.LGC]},
                    {key: "facility", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "segment", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "issueMonth", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "issueYear", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "countryOfOperation", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "certificateRegion"},
                    {key: "operatorName", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "miqMethaneIntensity", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "miqGrade", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "miqGradeStatus", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "miqAuditorName"},
                    {key: "eo100Grade", showInDefaultColumnSet: [ColumnSet.MiQC]},
                    {key: "quantity", showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                    {key: "currency"},
                    {key: "price"},
                    {key: "trader"},
                    {key: "salesPerson"},
                    {key: "salesCredits"},
                    {key: "brokerName"},
                    {key: "brokerage"},
                ]}
                pageFilters={{}}
            />
        </>
    );
};

export default TradeBlotter;
