import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { AmountFormat } from "@trovio-tech/trovio-core-api-jsx";
import { getDefaultLocaleConfig } from '../state/LocaleConfig';
/**
 * Wrapper JSX Component for the AmountFormat lib that deals with its
 * return type and ensures that the output is always a JSX element
 *
 * @param param0 @see AmountFormatWrapperProps
 * @returns @see JSX.Element
 */
var AmountFormatWrapper = function (_a) {
    var amount = _a.amount, minDecimalPos = _a.minDecimalPos, maxDecimalPos = _a.maxDecimalPos, _b = _a.fixedWidth, fixedWidth = _b === void 0 ? false : _b;
    var localeConfig = getDefaultLocaleConfig();
    var value = AmountFormat({
        amount: amount,
        minDecimalPos: minDecimalPos,
        maxDecimalPos: maxDecimalPos,
        groupSep: localeConfig.amountFormatGroupSeparator,
        decimalPoint: localeConfig.amountFormatDecimalPoint,
        fixedWidth: fixedWidth
    });
    if (typeof value === 'string') {
        // If the value returned is string then wrap in div to make it a JSX element
        return _jsx(_Fragment, { children: value });
    }
    else if (React.isValidElement(value)) {
        // If the value is a valid JSX element then return it as is
        return value;
    }
    console.warn("Unexpected type for AmountFormat: '".concat(value, "', with type: '").concat(typeof value, "'"));
    return _jsx(_Fragment, {});
};
export { AmountFormatWrapper };
