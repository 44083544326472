import dayjs from 'dayjs';
var DRAFTS_MODEL_VERSION = '0';
var draftsReviver = function (key, value) {
    if (key.endsWith('Date')) {
        return dayjs(value);
    }
    else {
        return value;
    }
};
export { DRAFTS_MODEL_VERSION, draftsReviver };
