
const LOGIN_CALLBACK_PATH = '/callback/login';
const LOGOUT_CALLBACK_PATH = '/callback/logout'
const ADMIN_GROUP_ID = "commodity_desk:admin";
const STORAGE_KEY_PREFIX = "admin:"

/**
 * Context URL for this app
 * @type {string}
 */
const homepage= "/app"

export {
    LOGIN_CALLBACK_PATH,
    LOGOUT_CALLBACK_PATH,
    ADMIN_GROUP_ID,
    STORAGE_KEY_PREFIX,
    homepage
};
