/**
 * Function that returns the default locale.
 * TODO - this function needs to return Locale config based on browsers
 * locale settings in a future iteration
 *
 * @returns @see LocaleConfig
 */
var getDefaultLocaleConfig = function () {
    return {
        amountFormatGroupSeparator: ',',
        amountFormatDecimalPoint: '.'
    };
};
export { getDefaultLocaleConfig };
