import { ProductType, carbonProjectAttributes } from '../state/AppConfig';
import { getDefaultLocaleConfig } from '../state/LocaleConfig';
import { AmountFormatAsString } from '@trovio-tech/trovio-core-api-jsx';
/**
 * A small utility class to separate Serial Number formatting logic.
 */
var SerialNumber = /** @class */ (function () {
    function SerialNumber(effectiveProductCode, serialNumberRangeStart, prefix, suffix, separator) {
        this.effectiveProductCode = effectiveProductCode;
        this.serialNumberRangeStart = serialNumberRangeStart;
        this.prefix = prefix;
        this.suffix = suffix;
        this.separator = separator;
    }
    SerialNumber.prototype.getRange = function (holdingIndex, holdingAmount) {
        var rangeStart = "".concat(this.serialNumberRangeStart + holdingIndex);
        var rangeEnd = "".concat(this.serialNumberRangeStart + holdingIndex + holdingAmount - 1);
        if (this.effectiveProductCode === ProductType.VCU) {
            // We assume that the prefix and suffix attributes include separators
            // Hence the following format string only has a separator between rangeStart and rangeEnd
            return "".concat(this.prefix).concat(rangeStart).concat(this.separator).concat(rangeEnd).concat(this.suffix);
        }
        else {
            var localeConfig = getDefaultLocaleConfig();
            var rangeStartFormatted = AmountFormatAsString({
                amount: rangeStart,
                maxDecimalPos: 0,
                minDecimalPos: 0,
                groupSep: localeConfig.amountFormatGroupSeparator,
                decimalPoint: localeConfig.amountFormatDecimalPoint
            });
            var rangeEndFormatted = AmountFormatAsString({
                amount: rangeEnd,
                maxDecimalPos: 0,
                minDecimalPos: 0,
                groupSep: localeConfig.amountFormatGroupSeparator,
                decimalPoint: localeConfig.amountFormatDecimalPoint
            });
            return "".concat(rangeStartFormatted, " ").concat(this.separator, " ").concat(rangeEndFormatted);
        }
    };
    ;
    SerialNumber.fromHolding = function (holding, appConfigState) {
        var effectiveProductCode = appConfigState.getProduct(holding.productId).displayCode;
        if (effectiveProductCode === ProductType.MiQC) {
            return null;
        }
        var serialNumberStart = holding.productItem.data.attributes[carbonProjectAttributes.projectSerialNumberStart.key];
        if (holding.holdingState === 'Unassigned') {
            return null;
        }
        if (effectiveProductCode === ProductType.ACCU || effectiveProductCode === ProductType.LGC) {
            // For ACCUs the serial_number_start is assumed to be a Number
            // Remove thousand separators before converting to Number
            return new SerialNumber(effectiveProductCode, Number(serialNumberStart.replaceAll('.', '').replaceAll(',', '')), '', '', '-');
        }
        else if (effectiveProductCode === ProductType.VCU) {
            var serialNumberPrefix = holding.productItem.data.attributes[carbonProjectAttributes.projectSerialNumberPrefix.key];
            var serialNumberSuffix = holding.productItem.data.attributes[carbonProjectAttributes.projectSerialNumberSuffix.key];
            return new SerialNumber(effectiveProductCode, Number(serialNumberStart), serialNumberPrefix, serialNumberSuffix, '-');
        }
        else {
            console.error('Received unexpected effective Product Code: ' + effectiveProductCode);
            return null;
        }
    };
    return SerialNumber;
}());
export { SerialNumber };
