import { FieldDataType, OverviewCategory } from './../FieldDefinitions';
import { ProductType, carbonProjectAttributes } from '../../../state/AppConfig';
import { getProjectBasedProductFieldDefinitions } from './ProjectBasedProductDefinitions';
var getVCUFieldDefinitions = function (appConfigState, includeSharedProjectBasedFields) {
    if (includeSharedProjectBasedFields === void 0) { includeSharedProjectBasedFields = true; }
    return (includeSharedProjectBasedFields ? getProjectBasedProductFieldDefinitions(appConfigState) : []).concat([
        {
            key: "country",
            displayName: "Country",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.VCU],
            attribute: carbonProjectAttributes.country,
            columnMinWidth: 130,
        },
    ]);
};
export { getVCUFieldDefinitions };
