import { Box, Checkbox, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import {
    DataGrid,
    getGridSingleSelectOperators,
    GridColDef,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridPaginationModel,
    GridSortModel,
    GridToolbar
} from '@mui/x-data-grid';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { 
    adjustForSort,
    AmountFormatWrapper,
    DATE_DISPLAY_FORMAT,
    MONTH_YEAR_DISPLAY_FORMAT,
    fetchHolding,
    getComparedFilterModel,
    getFilterKeyAndValue,
    getTableColumnSetStorageItem, 
    HoldingDetails, 
    HoldingDisplayDetails,
    inventoryAccount,
    lgcAttributes,
    miqcAttributes,
    ProductBase,
    ProductType,
    carbonProjectAttributes,
    renderFieldValue,
    SerialNumber,
    transactionAttributes,
    truncateHumanName,
    useAppConfigState,
    useLayoutState,
    useProductDataState,
    utcToLocalFormat,
    isBlankFilterItem,
} from '@commodity-desk/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
    dateRangeFilterOperators,
    extendedNumericFloatFilterOperators,
    extendedNumericFilterOperators,
    inputRangeFilterOperators,
    numericFilterOperators,
    singleMatchStringFilterOperator,
    stringFilterOperators
} from '../utility/DataGridFilterUtil';
import { withStyles } from '@mui/styles';
import {
    ColumnSet,
    productBasedColumnSets,
    getStoredColumnSet,
    getStoredColumnVisibilityModel,
    storeColumnVisibilityModel,
    TableColumnDefinition,
    useGridToolbarWithColumnSelector
} from '../utility/ColumnSetSelectorUtil';
import { STORAGE_KEY_PREFIX } from '../state/Variables';
import { 
    HoldingDataStateEnum,
    ListHoldingsRequest, 
    ListHoldingsStateEnum, 
    PagedListResponseHoldingData, 
    equalsExpr 
} from '@trovio-tech/trovio-core-api-js';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

enum HoldingPageType {
    PRODUCT_PROJECT_VINTAGE = 'PRODUCT_PROJECT_VINTAGE', // Used to display holdings in project details and vintage details pages
    CLIENT = 'CLIENT', // Used to display all holdings in Client Management Page.
    RISK = 'RISK', // Used to display all holdings that makes up a risk position
    INVENTORY_CERTIFICATES = 'INVENTORY_CERTIFICATES', // Used to display all holdings for a certificate based product e.g. LGC
    FORWARDS = 'FORWARDS' // Used to display forward trades
}

// We currently use fixed table height with a fixed number of items per page
const PAGE_SIZE = 20;
const INITIAL_PAGINATION_MODEL = { page: 0, pageSize: PAGE_SIZE };

const holdingsDisplayDetailsInit: HoldingDisplayDetails = {
    holdingId: '',
    accountId: '',
    amount: '',
    productCode: '',
    productBase: ProductBase.Project,
    projectId: '',
    projectName: '',
    projectType: '',
    projectCountry: '',
    projectState: '',
    projectUri: '',
    projectVintage: 0,
    certificateLgcAccreditationCode: '',
    certificateLgcFuelSource: '',
    certificateLgcGenerationYear: 0,
    certificateLgcCreationYear: 0,
    certificateLgcGenerationState: '',
    certificateLgcGreenPowerAccredited: '',
    certificateMiqcFacility: '',
    certificateMiqcSegment: '',
    certificateMiqcIssueMonth: '',
    certificateMiqcIssueYear: '',
    certificateMiqcCountryOfOperation: '',
    certificateMiqcCertificateRegion: '',
    certificateMiqcOperatorName: '',
    certificateMiqcMethaneIntensity: 0,
    certificateMiqcGrade: '',
    certificateMiqcGradeStatus: '',
    certificateMiqcAuditorName: '',
    certificateMiqcEo100Grade: '',
    serialNumbers: '',
    maxDecimalPos: 0,
    minDecimalPos: 0
}

interface HoldingPageFilters {
    accountId?: string;
    holdingState?: string;
    product?: string;
    projectType?: string;
    project?: string;
    vintage?: string;
    countryCode?: string;
    projectState?: string;
    accreditationCode?: string;
    fuelSource?: string;
    creationYear?: string;
    generationYear?: string;
    generationState?: string;
    greenPowerAccredited?: string;
    segment?: string;
    issueYear?: string;
    miqGrade?: string;
    facility?: string;
}

const Holdings = ({
    pageType,
    columnDefinitions,
    defaultOrdering,
    pageFilters,
    selectedHoldings,
    onHoldingSelectionUpdated,
    showDialogOnRowClick,
    refreshSignal
}: {
    pageType: HoldingPageType,
    columnDefinitions: TableColumnDefinition[],
    defaultOrdering?: string,
    pageFilters: HoldingPageFilters,
    selectedHoldings: any[],
    onHoldingSelectionUpdated?: any,
    showDialogOnRowClick?: boolean,
    refreshSignal?: number  // Used to force the holdings table to refresh its contents (e.g. when exiting dialogs)
}) => {

    const getInitialColumnVisibilityModel = (): GridColumnVisibilityModel => {
        if (pageType === HoldingPageType.CLIENT) {
            return getStoredColumnVisibilityModel(getStoredColumnSet(getTableNameForStorageKey), columnDefinitions, getTableNameForStorageKey);
        } else {
            const visibiltyModel = columnDefinitions.reduce(function (map, obj) {
                map[obj.key] = obj.showByDefault!;
                return map;
            }, {} as GridColumnVisibilityModel);
            return visibiltyModel;
        }
    }

    /**
     * Get the table name component to be used in the column visibility model and column set storage key.
     * @returns
     */
    const getTableNameForStorageKey = () => {
        let tableName: string = `Holdings-${pageType}`;
        return tableName;
    };

    const [holdings, setHoldings] = useState<any>([]);
    const abortController = useRef(new AbortController());
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const lastLoadedPage = useRef<number>(0);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
    const [totalAvailableRowCount, setTotalAvailableRowCount] = useState(0);
    const combinedHoldings = useRef<any[]>([]);
    const [initTableReady, setInitTableReady] = useState(false);
    const [ filterModel, setFilterModel ] = useState( {items: []} as GridFilterModel );
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [loading, setLoading] = useState(true);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [holdingDetails, setHoldingDetails] = useState<HoldingDisplayDetails>(holdingsDisplayDetailsInit)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(getInitialColumnVisibilityModel());
    const currentSelectedHoldingIds = useRef<Set<string>>(new Set())
    const [chosenColumnSet, setChosenColumnSet] = useState<ColumnSet>(getStoredColumnSet(getTableNameForStorageKey));
    
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { customTheme } = useLayoutState();
    const { cortenApi } = useCortenApiState();

    const handleColumnSetChange = (event: any) => {
        const columnSet = event.target.value;
        setChosenColumnSet(columnSet);
        // save column selection in local storage
        const storageItem = getTableColumnSetStorageItem(getTableNameForStorageKey(), STORAGE_KEY_PREFIX);
        storageItem.setItemValue(columnSet);
        // reset filters and sort
        setFilterModel({ items: [] } as GridFilterModel);
        setSortModel([]);
        // refresh table data
        refreshTable({
            filterItems: [], 
            sortItems: [], 
            columnSet: columnSet
        });
    };

    const {
        ExtendedToolbarWithColumnSelector
    } = useGridToolbarWithColumnSelector({
        customTheme,
        chosenColumnSet,
        handleColumnSetChange
    });

    useEffect(() => {
        refreshTable({filterItems: filterModel.items, sortItems:sortModel, columnSet: chosenColumnSet});
        setInitTableReady(true);
    }, [refreshSignal]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading && nextPage) {
            mapPageToNextCursor.current[paginationModel.page] = nextPage;
        }
    }, [paginationModel.page, loading, nextPage]);

    useEffect(() => {
        if (selectedHoldings.length === 0) {
            currentSelectedHoldingIds.current = new Set();
        }
    }, [selectedHoldings]);

    const handleFilterModelChange = async (newFilterModel: GridFilterModel) => {
        const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
        refreshTable({
            filterItems: comparedFilterModel.items,
            sortItems: sortModel,
            columnSet: chosenColumnSet
        });
        setFilterModel(comparedFilterModel);
    };

    const refreshTable = async ({
        filterItems, 
        sortItems, 
        columnSet
    }: {
        filterItems: GridFilterItem[], 
        sortItems?: GridSortModel, 
        columnSet?: ColumnSet
    }
    ) => {
        combinedHoldings.current = [];
        currentSelectedHoldingIds.current = new Set();
        // reset pagination on reload (from retirement or anything else), but do not reset filters
        lastLoadedPage.current = 0;
        mapPageToNextCursor.current = {};
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        // load data
        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchHoldings({
            filterItems: filterItems,
            sortItems: sortItems, 
            abortSignal: abortController.current.signal, 
            columnSet: columnSet
        }).then(r => {
            if (columnSet && pageType === HoldingPageType.CLIENT) {
                const customColumnVisibilityModel = getStoredColumnVisibilityModel(columnSet, columnDefinitions, getTableNameForStorageKey);
                setColumnVisibilityModel(customColumnVisibilityModel);
            }
        });
    };

    const loadMore = async (newPaginationModel: GridPaginationModel) => {
        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchHoldings({
            filterItems: filterModel.items,
            sortItems: sortModel, 
            nextPage: mapPageToNextCursor.current[newPaginationModel.page - 1], 
            abortSignal: abortController.current.signal,
            columnSet: chosenColumnSet
        });
    };

    const fetchHoldings = async ({
        filterItems, 
        sortItems,
        nextPage,
        abortSignal,
        columnSet
    }: {
        filterItems: GridFilterItem[], 
        sortItems: GridSortModel | undefined;
        nextPage?: string | undefined;
        abortSignal: AbortSignal;
        columnSet?: ColumnSet;
    }) => {

        try {

            let appliedPageFilters = [];
            let listHoldingsRequest: ListHoldingsRequest = {
                attributes: {},
                includeProductItemData: true,
                pageWithCount: true
            }

            //Adding fields to request object based on page level filters
            if (pageFilters.accountId) {
                listHoldingsRequest.accountId = pageFilters.accountId;
                appliedPageFilters.push('accountId');
            }
            if (pageFilters.product) {
                listHoldingsRequest.productId = [pageFilters.product!];
                appliedPageFilters.push('product');
            } else if (columnSet && productBasedColumnSets.includes(columnSet)) {
                listHoldingsRequest.productId = [appConfigState.getProducts().filter((data) => data.displayCode === columnSet).map(data => data.id)[0]];
            } else {
                // Filter for holdings belonging to products that are declared in our config
                listHoldingsRequest.productId = appConfigState.getProducts().map( product => product.id);
            }
            if (pageFilters.projectType) {
                listHoldingsRequest.attributes![carbonProjectAttributes.projectType.key] = equalsExpr(pageFilters.projectType);
                appliedPageFilters.push('projectType');
            }
            if (pageFilters.project) {
                listHoldingsRequest.attributes![carbonProjectAttributes.projectId.key] = equalsExpr(pageFilters.project);
                appliedPageFilters.push('projectId');
            }
            if (pageFilters.vintage) {
                listHoldingsRequest.attributes![carbonProjectAttributes.vintage.key] = equalsExpr(pageFilters.vintage);
                appliedPageFilters.push('vintage');
            }
            if (pageFilters.countryCode) {
                listHoldingsRequest.attributes![carbonProjectAttributes.country.key] = equalsExpr(pageFilters.countryCode);
                appliedPageFilters.push('country')
            }
            if (pageFilters.projectState) {
                listHoldingsRequest.attributes![carbonProjectAttributes.projectState.key] = equalsExpr(pageFilters.projectState);
                appliedPageFilters.push('projectState')
            }
            if (pageFilters.fuelSource) {
                listHoldingsRequest.attributes![lgcAttributes.fuelSource.key] = equalsExpr(pageFilters.fuelSource);
                appliedPageFilters.push('fuelSource');
            }
            if (pageFilters.generationYear) {
                listHoldingsRequest.attributes![lgcAttributes.generationYear.key] = equalsExpr(pageFilters.generationYear);
                appliedPageFilters.push('generationYear');
            }
            if (pageFilters.creationYear) {
                listHoldingsRequest.attributes![lgcAttributes.creationYear.key] = equalsExpr(pageFilters.creationYear);
                appliedPageFilters.push('creationYear');
            }
            if (pageFilters.generationState) {
                listHoldingsRequest.attributes![lgcAttributes.generationState.key] = equalsExpr(pageFilters.generationState);
                appliedPageFilters.push('generationState');
            }
            if (pageFilters.greenPowerAccredited) {
                listHoldingsRequest.attributes![lgcAttributes.greenPowerAccredited.key] = equalsExpr(pageFilters.greenPowerAccredited);
                appliedPageFilters.push('greenPowerAccredited');
            }
            if (pageFilters.accreditationCode) {
                listHoldingsRequest.attributes![lgcAttributes.accreditationCode.key] = equalsExpr(pageFilters.accreditationCode);
                appliedPageFilters.push('accreditationCode');
            }
            if (pageFilters.segment) {
                listHoldingsRequest.attributes![miqcAttributes.segment.key] = equalsExpr(pageFilters.segment);
                appliedPageFilters.push('segment');
            }
            if (pageFilters.issueYear) {
                listHoldingsRequest.attributes![miqcAttributes.issueYear.key] = equalsExpr(pageFilters.issueYear);
                appliedPageFilters.push('issueYear');
            }
            if (pageFilters.miqGrade) {
                listHoldingsRequest.attributes![miqcAttributes.miqGrade.key] = equalsExpr(pageFilters.miqGrade);
                appliedPageFilters.push('miqGrade');
            }
            if (pageFilters.facility) {
                listHoldingsRequest.attributes![miqcAttributes.facility.key] = equalsExpr(pageFilters.facility);
                appliedPageFilters.push('facility');
            }

            let modifiedFilterItems = adjustForSort(
                {
                    filterItems: filterItems,
                    sortItems: sortItems ?? {} as GridSortModel,
                    pageFilters: appliedPageFilters
                }
            );
            modifiedFilterItems.filter(item => !isBlankFilterItem(item))
                .forEach((item: GridFilterItem) => {
                    /**
                     * Adding request fields based on the filters selected.
                     * accountId, state and productId are handled explicitly as they have there own fields in the request object
                     * whereas the other fiter items are included in the attributes map.
                     */
                    if (item.field === 'accountId') {
                        listHoldingsRequest.accountId = item.value;
                    } else if (item.field === 'state') {
                        listHoldingsRequest.state = [item.value as ListHoldingsStateEnum];
                    } else if (item.field === 'productId') {
                        listHoldingsRequest.productId = item.value;
                    } else {
                        const filterData = getFilterKeyAndValue(item, appConfigState);
                        listHoldingsRequest.attributes![filterData.key] = filterData.value
                    }
                });

            if (pageFilters.holdingState) {
                listHoldingsRequest.state = [pageFilters.holdingState as ListHoldingsStateEnum];
            } else if (!listHoldingsRequest.state) {
                listHoldingsRequest.state = [ListHoldingsStateEnum.Unspent, ListHoldingsStateEnum.Escrowed]
            }

            if ((sortItems !== undefined && sortItems.length > 0) || defaultOrdering) {
                listHoldingsRequest.sort = buildSortItems(sortItems, defaultOrdering);
            }

            if (nextPage !== undefined) {
                listHoldingsRequest.pageFrom = nextPage;
            }
            const holdingsData: PagedListResponseHoldingData = await cortenApi.account.listHoldings(listHoldingsRequest, { ...(abortSignal && { signal: abortSignal }) });
            if (!holdingsData) {
                return; // The request was aborted
            }
            setLoading(false);
            setNextPage(holdingsData.nextPage);
            setTotalAvailableRowCount(holdingsData.count!);

            combinedHoldings.current = [...combinedHoldings.current, ...holdingsData.list];

            // set state for final data to display in holdings table
            setHoldings(combinedHoldings.current.slice(lastLoadedPage.current * PAGE_SIZE, (lastLoadedPage.current + 1) * PAGE_SIZE));

        } catch (error) {
            console.error('Some Promise All requests failed', error);
        }
    };
    
    const buildSortItems = (items: GridSortModel | undefined, defaultSort?: string | undefined): string[] => {
        const sortItems: string[] = []

        if (items) {
            items
                .filter((item) => item.sort !== undefined && item.sort !== null)
                .forEach((item) => {
                    let directionChar = item.sort === 'asc' ? '+' : '-';
                    const productItemAttributeLabel = 'productItemAttribute';
                    switch (item.field) {
                        case 'holdingId':
                            sortItems.push(`${directionChar}id`)
                            break;
                        case 'amount':
                            sortItems.push(`${directionChar}amount`)
                            break;
                        case 'projectType':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.projectType.key}`);
                            break;
                        case 'projectId':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.projectId.key}`);
                            break;
                        case 'projectName':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.projectName.key}`);
                            break;
                        case 'projectState':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.projectState.key}`);
                            break;
                        case 'country':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.country.key}`);
                            break;
                        case 'accreditationCode':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.accreditationCode.key}`)
                            break;
                        case 'fuelSource':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.fuelSource.key}`)
                            break;
                        case 'generationYear':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.generationYear.key}`)
                            break;
                        case 'creationYear':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.creationYear.key}`)
                            break;
                        case 'generationState':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.generationState.key}`)
                            break;
                        case 'greenPowerAccredited':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${lgcAttributes.greenPowerAccredited.key}`)
                            break;
                        case 'facility':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.facility.key}`)
                            break;
                        case 'segment':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.segment.key}`)
                            break;
                        case 'issueMonth':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.issueMonth.key}`)
                            break;
                        case 'issueYear':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.issueYear.key}`)
                            break;
                        case 'countryOfOperation':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.countryOfOperation.key}`)
                            break;
                        case 'certificateRegion':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.certificateRegion.key}`)
                            break;
                        case 'operatorName':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.operatorName.key}`)
                            break;
                        case 'miqMethaneIntensity':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.miqMethaneIntensity.key}`)
                            break;
                        case 'miqGrade':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.miqGrade.key}`)
                            break;
                        case 'miqGradeStatus':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.miqGradeStatus.key}`)
                            break;
                        case 'miqAuditorName':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.miqAuditorName.key}`)
                            break;
                        case 'eo100Grade':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${miqcAttributes.eo100Grade.key}`)
                            break;
                        case 'vintage':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${carbonProjectAttributes.vintage.key}`)
                            break;
                        case 'valueDate':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.valueDate.key}`)
                            break;
                        case 'tradeDate':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.tradeDate.key}`);
                            break;
                        case 'currency':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.currency.key}`)
                            break;
                        case 'price':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.price.key}`)
                            break;
                        //Following are only used in the forward trades table. Hence, they will always be productItemAttributes.
                        case 'trader':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.trader.key}`)
                            break;
                        case 'salesPerson':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.salesPerson.key}`)
                            break;
                        case 'brokerName':
                            sortItems.push(`${directionChar}${productItemAttributeLabel}.${transactionAttributes.brokerName.key}`)
                            break;
                    }
                })
        }

        // If a default sort is specified and we are not sorting by any custom columns, then use default sort
        if (defaultSort !== undefined && sortItems.length === 0) {
            sortItems.push(defaultSort);
        }

        return sortItems
    }

    const handleCheckboxChangeEvent = (event: ChangeEvent<HTMLInputElement>, holdingId: string) => {
        if (event.target.checked) {
            currentSelectedHoldingIds.current.add(holdingId);
        } else {
            currentSelectedHoldingIds.current.delete(holdingId);
        }
        onHoldingSelectionUpdated(combinedHoldings.current.filter((h: any) => currentSelectedHoldingIds.current.has(h.holdingId)));
    };
    // on open of holdings details dialog
    const handleOpenHoldingDetails = async (holdingId: string, serialNumRange: string) => {
        // start modal loader
        setLoadingModal(true)
        // open modal
        setIsDetailsOpen(true)

        const data = await fetchHolding(holdingId, cortenApi);
        // get product code from productDisplayData Map
        const product = data.productId ?  appConfigState.getProduct(data.productId)?.displayCode : ''
        // get client name from account ID
        let clientName = appConfigState.getDisplayNameForAddress(data.accountId);
        const productItemAttributes = data.productItem!.data.attributes!;
        const productData = data.product!.data;
        let displayData: HoldingDisplayDetails = {
            holdingId: pageType === HoldingPageType.FORWARDS ? '' : data.holdingId,
            forwardId: pageType === HoldingPageType.FORWARDS ? data.holdingId : '',
            valueDate: pageType === HoldingPageType.FORWARDS ? utcToLocalFormat(productItemAttributes[transactionAttributes.valueDate.key]!.toString(), DATE_DISPLAY_FORMAT) : undefined,
            accountId: clientName!,
            amount: data.amount.toString(),
            productCode: product!,
            productBase: appConfigState.getProduct(data.productId!)!.productBase,
            projectId: (productItemAttributes[carbonProjectAttributes.projectId.key] ?? "").toString() ,
            projectName: (productItemAttributes[carbonProjectAttributes.projectName.key] ?? "").toString(),
            projectType: (productItemAttributes[carbonProjectAttributes.projectType.key] ?? "").toString(),
            projectCountry: (productItemAttributes[carbonProjectAttributes.country.key] ?? "").toString(),
            projectState: (productItemAttributes[carbonProjectAttributes.projectState.key] ?? "").toString(),
            projectUri: (productItemAttributes[carbonProjectAttributes.projectUri.key] ?? "").toString(),
            projectVintage: Number(productItemAttributes[carbonProjectAttributes.vintage.key] ?? ""),
            certificateLgcAccreditationCode: (productItemAttributes[lgcAttributes.accreditationCode.key] ?? "").toString(),
            certificateLgcFuelSource: (productItemAttributes[lgcAttributes.fuelSource.key] ?? "").toString(),
            certificateLgcGenerationYear: Number(productItemAttributes[lgcAttributes.generationYear.key] ?? ""),
            certificateLgcCreationYear: Number(productItemAttributes[lgcAttributes.creationYear.key] ?? ""),
            certificateLgcGenerationState: (productItemAttributes[lgcAttributes.generationState.key] ?? "").toString(),
            certificateLgcGreenPowerAccredited: productItemAttributes[lgcAttributes.greenPowerAccredited.key]?.toString(),
            certificateMiqcFacility: (productItemAttributes[miqcAttributes.facility.key] ?? "").toString(),
            certificateMiqcSegment: (productItemAttributes[miqcAttributes.segment.key] ?? "").toString(),
            certificateMiqcIssueMonth: (productItemAttributes[miqcAttributes.issueMonth.key] ?? "").toString(),
            certificateMiqcIssueYear: (productItemAttributes[miqcAttributes.issueYear.key] ?? "").toString(),
            certificateMiqcCountryOfOperation: (productItemAttributes[miqcAttributes.countryOfOperation.key] ?? "").toString(),
            certificateMiqcCertificateRegion: (productItemAttributes[miqcAttributes.certificateRegion.key] ?? "").toString(),
            certificateMiqcOperatorName: (productItemAttributes[miqcAttributes.operatorName.key] ?? "").toString(),
            certificateMiqcMethaneIntensity: Number(productItemAttributes[miqcAttributes.miqMethaneIntensity.key] ?? ""),
            certificateMiqcGrade: (productItemAttributes[miqcAttributes.miqGrade.key] ?? "").toString(),
            certificateMiqcGradeStatus: (productItemAttributes[miqcAttributes.miqGradeStatus.key] ?? "").toString(),
            certificateMiqcAuditorName: (productItemAttributes[miqcAttributes.miqAuditorName.key] ?? "").toString(),
            certificateMiqcEo100Grade: (productItemAttributes[miqcAttributes.eo100Grade.key] ?? "").toString(),
              // TODO: Trade timestamp is currently not saved with time information. When it is, remove the date format from the display below.
            tradeTimestamp: pageType === HoldingPageType.FORWARDS ? utcToLocalFormat(productItemAttributes[transactionAttributes.tradeDate.key]!.toString(), DATE_DISPLAY_FORMAT): '',
            tradeId: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.tradeId.key]??'').toString():'',
            tradeCurrency: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.currency.key]??'').toString():'',
            tradePrice: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.price.key]??'').toString(): '',
            traderName: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.trader.key]??'').toString(): '',
            salesPerson: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.salesPerson.key]??'').toString(): '',
            salesCredits: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.salesCredits.key]??'').toString(): '',
            brokerName: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.brokerName.key]??'').toString(): '',
            brokerFee: pageType === HoldingPageType.FORWARDS ? (productItemAttributes[transactionAttributes.brokerage.key]??'').toString(): '',
            serialNumbers: serialNumRange,
            maxDecimalPos: productData.maxDecimalPos,
            minDecimalPos: productData.minDecimalPos
        }

        // set data to state
        setHoldingDetails(displayData)
        // stop loader
        setLoadingModal(false)
    }

    // on close of holding details modal
    const handleCloseDetails = () => {
        setIsDetailsOpen(false)
    }

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        if (loading) {
            return; // Since we use block based pagination we cannot skip ahead to future pages, we need to wait for the current page to load
        }
        if (newPaginationModel.page > lastLoadedPage.current) {
            lastLoadedPage.current = newPaginationModel.page;
            loadMore(newPaginationModel);
        } else {
            setHoldings(combinedHoldings.current.slice(newPaginationModel.page * PAGE_SIZE, (newPaginationModel.page + 1) * PAGE_SIZE));
        }
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    const WideTooltip = withStyles({ tooltip: { maxWidth: 'none' } })(Tooltip);
    const filterAndOrderColumnsByDefinition = (columns: GridColDef<any>[]): GridColDef<any>[] => {
        let selectedColumns: GridColDef<any>[] = [];
        for (let columnDefinition of columnDefinitions) {
            let matchingColumn = columns.find(column => column.field === columnDefinition.key);
            if (matchingColumn !== undefined) {
                selectedColumns.push(matchingColumn);
            }
        }
        return selectedColumns;
    }

    const handleColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
        if (pageType === HoldingPageType.CLIENT) {
            storeColumnVisibilityModel(chosenColumnSet, newModel, getTableNameForStorageKey);
        }
        setColumnVisibilityModel(newModel);
    }

    const handleRowClick = (row: any) => {
        if (showDialogOnRowClick) {
            handleOpenHoldingDetails(row.holdingId, row.serialNumRange);
        }
    };

    return (
        <>
            {!initTableReady ? (
                <LinearProgress />
            ) : (
                <>
                    <Box style={{ width: '100%', height: 'auto', overflow: "auto"}}>
                        <DataGrid
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            }}
                            slots={{ toolbar: pageType === HoldingPageType.CLIENT ? ExtendedToolbarWithColumnSelector: GridToolbar }}
                            rows={holdings}
                            getRowId={row => row.holdingId}
                            disableRowSelectionOnClick
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => {
                                handleColumnVisibilityModelChange(newModel);
                            }}
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => {
                                handleFilterModelChange(newFilterModel);
                            }}
                            // Sort props
                            sortingMode='server'
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                setSortModel(newSortModel);
                                refreshTable({
                                    filterItems: filterModel.items, 
                                    sortItems: newSortModel,
                                    columnSet: chosenColumnSet
                                });
                            }}
                            pageSizeOptions={[PAGE_SIZE]}
                            rowCount={totalAvailableRowCount}
                            paginationMode="server"
                            onPaginationModelChange={handlePaginationModelChange}
                            onRowClick={params => handleRowClick(params.row)}
                            paginationModel={paginationModel}
                            loading={loading}
                            // TODO: When the server supports exports (server side) then configure the export button to use that instead of exporting the current page only
                            columns={filterAndOrderColumnsByDefinition([
                                {
                                    field: 'selectbox',
                                    headerName: '',
                                    filterable: false,
                                    sortable: false,
                                    disableExport: true,
                                    renderCell: (params: any) => {
                                        return <Checkbox
                                            checked={selectedHoldings!.includes(params.row)}
                                            onClick={(event) => {
                                                event.stopPropagation(); // stop the event propagating up to the row click listener
                                            }}
                                            onChange={(event) => {
                                                handleCheckboxChangeEvent(event, params.row.holdingId);
                                            }}
                                            // Disable any rows that have a different product ID from the first selected one so that the selected holdings are all from one product.
                                            // Also, disable any rows that have a different state than the state of first selected state, so all selected rows are of same state.
                                            // Also disable, if the state is not Unspent or escrowed,
                                            // or if holdings are opened from inventory management and holding is not held by inventory
                                            disabled={
                                                (selectedHoldings.length > 0 && (selectedHoldings[0].productId !== params.row.productId || selectedHoldings[0].state !== params.row.state)) ||
                                                ![HoldingDataStateEnum.Unspent, HoldingDataStateEnum.Escrowed].includes(params.row.state) ||
                                                (pageType === HoldingPageType.PRODUCT_PROJECT_VINTAGE && params.row.accountId !== inventoryAccount.id)
                                            }
                                        />
                                    }
                                },
                                {
                                    field: 'tradeDate',
                                    headerName: 'Trade Date',
                                    minWidth: 180,
                                    flex: 1,
                                    type: 'date',
                                    filterOperators: dateRangeFilterOperators({showTimeStamp:false}),
                                    valueGetter: (params) => {
                                        const value = (params.row.productItem.data.attributes[transactionAttributes.tradeDate.key]??"").toString();
                                        return value && new Date(value)
                                    },
                                    valueFormatter: ({ value }) => value && utcToLocalFormat(value, DATE_DISPLAY_FORMAT)
                                },
                                {
                                    field: 'tradeId',
                                    headerName: 'Trade ID',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.tradeId.key] : undefined,
                                    minWidth: 150,
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'valueDate',
                                    headerName: 'Value Date',
                                    filterable: false,
                                    minWidth: 145,
                                    flex: 1,
                                    type: 'date',
                                    valueGetter: (params) => {
                                        const value = (params.row.productItem.data.attributes[transactionAttributes.valueDate.key]??"").toString();
                                        return value && new Date(value)
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value && utcToLocalFormat(new Date(value).toDateString(), DATE_DISPLAY_FORMAT))
                                },
                                {
                                    field: 'holdingId',
                                    headerName: 'ID',
                                    filterable: false,
                                    minWidth: 100,
                                    flex: 1
                                },
                                {
                                    field: 'forwardId',
                                    headerName: 'Forward ID',
                                    valueGetter: (params: any) => {
                                        return pageType===HoldingPageType.FORWARDS ? params.row.holdingId: '';
                                    },
                                    filterable: false,
                                    minWidth: 145,
                                    flex: 1,
                                    sortable: false,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'amount',
                                    headerName: pageType === HoldingPageType.INVENTORY_CERTIFICATES ? 'Quantity' : 'Amount',
                                    type: 'number',
                                    filterable: false,
                                    minWidth: pageType === HoldingPageType.INVENTORY_CERTIFICATES ? 130 : 120,
                                    flex: 1,
                                    renderCell: (params) => {
                                        return <AmountFormatWrapper
                                        amount={params.row.amount as number}
                                        minDecimalPos={productsData.get(params.row.productItem.data.productId)?.minDecimalPos!}
                                        maxDecimalPos={productsData.get(params.row.productItem.data.productId)?.maxDecimalPos!} />;
                                    }
                                },
                                {
                                    field: 'currency',
                                    headerName: 'Currency',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.currency.key] : undefined,
                                    minWidth: 130,
                                    flex: 1,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    valueOptions: [
                                        { value: 'AUD', label: 'AUD' },
                                        { value: 'USD', label: 'USD' }
                                    ]
                                },
                                {
                                    field: 'price',
                                    headerName: 'Price',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.price.key] : undefined,
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'number',
                                    filterOperators: [
                                        ...extendedNumericFloatFilterOperators,
                                        ...inputRangeFilterOperators({type:'Decimal'})
                                    ],
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'productId',
                                    headerName: 'Product',
                                    type: 'singleSelect',
                                    minWidth: 120,
                                    flex: 1,
                                    sortable: false, // TODO: Re-enable when available
                                    filterable: !pageFilters.product && !productBasedColumnSets.includes(chosenColumnSet),
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) => operator.value !== 'not'
                                    ).map((operator) => {
                                        return {
                                            ...operator,
                                            getValueAsString: (value) => {
                                                let valueArray: string[] = [];
                                                if (!Array.isArray(value)) {
                                                    valueArray = [value];
                                                } else {
                                                    valueArray = value;
                                                }
                                                return appConfigState.getProducts().filter((data) => valueArray.includes(data.id))
                                                    .map(data => data.displayCode).join(", ");
                                            },
                                        }
                                    }),
                                    valueOptions: appConfigState.getProducts().map((data) => ({
                                        value: data.id,
                                        label: data.displayCode
                                    }))
                                },
                                {
                                    field: 'projectType',
                                    headerName: 'Project Type',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.projectType.key],
                                    filterOperators: stringFilterOperators,
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !(pageFilters.projectType !== undefined && pageFilters.projectType !== ''),
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'projectId',
                                    headerName: 'Project',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.projectId.key],
                                    filterOperators: stringFilterOperators,
                                    minWidth: 150,
                                    flex: 1,
                                    filterable: !(pageType === HoldingPageType.RISK && pageFilters.project !== undefined && pageFilters.project !== ''),
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'projectName',
                                    headerName: 'Project Name',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.projectName.key],
                                    filterOperators: stringFilterOperators,
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !(pageFilters.project !== undefined && pageFilters.project !== ''),
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'vintage',
                                    headerName: 'Vintage',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.vintage.key],
                                    filterOperators: numericFilterOperators,
                                    filterable: !(pageType === HoldingPageType.RISK && pageFilters.vintage !== undefined && pageFilters.vintage !== ''),
                                    valueFormatter: ({ value }) => renderFieldValue(value),
                                    minWidth: 120,
                                    flex: 1
                                },
                                {
                                    field: 'country',
                                    headerName: 'Country',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.country.key],
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: !pageFilters.countryCode,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'projectState',
                                    headerName: 'Project State',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[carbonProjectAttributes.projectState.key],
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: !pageFilters.projectState,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'state',
                                    headerName: 'State',
                                    type: 'singleSelect',
                                    filterOperators: singleMatchStringFilterOperator,
                                    sortable: false, // TODO: Re-enable when available
                                    flex: 1,
                                    minWidth: 150,
                                    valueOptions: [
                                        // Values in this list should be kept in sync with core10 HoldingState.kt
                                        { value: 'Unassigned', label: 'Unassigned' },
                                        { value: 'Unspent', label: 'Unspent' },
                                        { value: 'Escrowed', label: 'Locked' },
                                    ],
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'fuelSource',
                                    headerName: 'Fuel Source',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.fuelSource.key],
                                    flex: 1,
                                    minWidth: 150,
                                    filterOperators: stringFilterOperators,
                                    filterable: !pageFilters.fuelSource,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'accreditationCode',
                                    headerName: 'Accreditation Code',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.accreditationCode.key],
                                    minWidth: 160,
                                    flex: 1,
                                    filterable: !pageFilters.accreditationCode,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'creationYear',
                                    headerName: 'Creation Year',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.creationYear.key],
                                    flex: 1,
                                    minWidth: 160,
                                    filterable: !pageFilters.creationYear,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({})
                                    ],
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'generationYear',
                                    headerName: 'Generation Year',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.generationYear.key],
                                    flex: 1,
                                    minWidth: 180,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({})
                                    ],
                                    filterable: !pageFilters.generationYear,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'generationState',
                                    headerName: 'Generation State',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.generationState.key],
                                    flex: 1,
                                    minWidth: 180,
                                    filterOperators: stringFilterOperators,
                                    filterable: !pageFilters.generationState,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'greenPowerAccredited',
                                    headerName: 'GreenPower Accredited',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[lgcAttributes.greenPowerAccredited.key],
                                    flex: 1,
                                    minWidth: 230,
                                    filterable: !pageFilters.greenPowerAccredited,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    renderCell: ({value}) => {
                                        switch (value) {
                                            case true:
                                                return <FontAwesomeIcon icon={faCheck}/>;
                                            case false:
                                                return <FontAwesomeIcon icon={faXmark}/>;
                                            default:
                                                return '-';
                                        }
                                    },
                                    valueOptions: [
                                        { value: 'true', label: 'true' },
                                        { value: 'false', label: 'false' }
                                    ],
                                    valueFormatter: ({ value }) => value !== undefined ? `${value}` : '-'
                                },
                                {
                                    field: 'facility',
                                    headerName: 'Facility',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.facility.key],
                                    flex: 1,
                                    minWidth: 120,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'segment',
                                    headerName: 'Segment',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.segment.key],
                                    flex: 1,
                                    minWidth: 130,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'issueMonth',
                                    headerName: 'Issue Month',
                                    flex: 1,
                                    minWidth: 150,
                                    type: 'date',
                                    filterOperators: dateRangeFilterOperators({showTimeStamp:false, wholeMonths: true}),
                                    valueGetter: (params) => {
                                        const value = (params.row.productItem.data.attributes[miqcAttributes.issueMonth.key]??"").toString();
                                        return value && new Date(value)
                                    },
                                    valueFormatter: ({ value }) => value && utcToLocalFormat(new Date(value).toDateString(), MONTH_YEAR_DISPLAY_FORMAT)
                                },
                                {
                                    field: 'issueYear',
                                    headerName: 'Issue Year',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.issueYear.key],
                                    flex: 1,
                                    minWidth: 150,
                                    filterOperators: numericFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value),
                                },
                                {
                                    field: 'countryOfOperation',
                                    headerName: 'Country of Operation',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.countryOfOperation.key],
                                    flex: 1,
                                    minWidth: 210,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'certificateRegion',
                                    headerName: 'Certificate Region',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.certificateRegion.key],
                                    flex: 1,
                                    minWidth: 190,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'operatorName',
                                    headerName: 'Operator Name',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.operatorName.key],
                                    flex: 1,
                                    minWidth: 180,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'miqMethaneIntensity',
                                    headerName: 'MiQ Methane Intensity',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.miqMethaneIntensity.key],
                                    type: 'number',
                                    flex: 1,
                                    minWidth: 220,
                                    filterOperators: [
                                        ...extendedNumericFloatFilterOperators,
                                        ...inputRangeFilterOperators({type:'Decimal'})
                                    ],
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'miqGrade',
                                    headerName: 'MiQ Grade',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.miqGrade.key],
                                    flex: 1,
                                    minWidth: 140,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'miqGradeStatus',
                                    headerName: 'MiQ Grade Status',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.miqGradeStatus.key],
                                    flex: 1,
                                    minWidth: 190,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'miqAuditorName',
                                    headerName: 'MiQ Auditor Name',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.miqAuditorName.key],
                                    flex: 1,
                                    minWidth: 190,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'eo100Grade',
                                    headerName: 'EO100 Grade',
                                    valueGetter: (params: any) => params.row.productItem.data.attributes[miqcAttributes.eo100Grade.key],
                                    flex: 1,
                                    minWidth: 160,
                                    filterOperators: stringFilterOperators,
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'serialNumRange',
                                    headerName: 'Serial Numbers',
                                    valueGetter: (params: any) => SerialNumber.fromHolding(params.row, appConfigState)?.getRange(params.row.index ?? 0, Number(params.row.amount)),
                                    filterable: false,
                                    sortable: false,
                                    minWidth: 150,
                                    flex: 5,
                                    renderCell: (params: any) => (
                                        <WideTooltip arrow title={
                                            <div onClick={() => navigator.clipboard.writeText(params.value)}
                                                 style={{ whiteSpace: 'pre-line' }}>
                                                {params.value}
                                            </div>
                                        }>
                                            <span>{renderFieldValue(params.value)}</span>
                                        </WideTooltip>
                                    ),
                                    valueFormatter: ({ value }) => `${value}`
                                },
                                {
                                    field: 'accountId',
                                    headerName: 'Held By',
                                    type: 'singleSelect',
                                    filterOperators: singleMatchStringFilterOperator,
                                    sortable: false,
                                    minWidth: 200,
                                    flex: 2,
                                    valueOptions: [
                                        {
                                            value: inventoryAccount.id,
                                            label: inventoryAccount.display
                                        }
                                    ].concat(appConfigState.getClients().map((client) => (
                                        {
                                            value: client.id,
                                            label: client.display
                                        }
                                    ))),
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'trader',
                                    headerName: 'Trader',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.trader.key] : undefined,
                                    minWidth: 150,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>-</Typography>
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'salesPerson',
                                    headerName: 'Sales Person',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.salesPerson.key] : undefined,
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>-</Typography>
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'salesCredits',
                                    headerName: 'Sales Credits',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.salesCredits.key] : undefined,
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value ? (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />) : '-'}</>
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'brokerName',
                                    headerName: 'Broker Name',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.brokerName.key] : undefined,
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>-</Typography>
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'brokerage',
                                    headerName: 'Brokerage',
                                    valueGetter: (params) =>pageType === HoldingPageType.FORWARDS ? params.row.productItem.data.attributes[transactionAttributes.brokerage.key] : undefined,
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value ? (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />) : '-'}</>
                                    },
                                    valueFormatter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'info',
                                    headerName: 'Info',
                                    filterable: false,
                                    sortable: false,
                                    disableExport: true,
                                    renderCell: (params: any) => {
                                        return <IconButton color="primary" size='small' onClick={(event: any) => {
                                            event.stopPropagation();
                                            handleOpenHoldingDetails(params.row.holdingId, params.row.serialNumRange);
                                        }}><FontAwesomeIcon icon={faInfoCircle}/></IconButton>;
                                    }
                                },
                            ])}
                            density="compact"
                            disableDensitySelector
                            aria-label="holdings table"
                        />
                    </Box>

                    <HoldingDetails open={isDetailsOpen} onClose={handleCloseDetails} loading={loadingModal} data={holdingDetails} appConfigState={appConfigState}/>
                </>
            )}
        </>
    );
};

export { Holdings, HoldingPageType };
