var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
var backendUrl = ''; // Do not change this. Change the value below when testing.
if (process.env["REACT_APP_DEV_MODE"] === "true") {
    // When testing locally, use the integ1 back end
    // This can be changed to any development env or alternatively 'http://localhost:8080' if running a local backend.
    backendUrl = 'https://carbon-desk-integ1.dev.trovio.io';
    // To use this back end, but apply the app config for another environment, see instructions below.
}
// To overwrite the app config used for local front end development, follow following instructions:
// To run the admin-app with a custom config, run following in mbl-frontend directory.
//`yq -o=json '.FRONTEND' "../config/env-{env}.yaml" > admin-app/public/dev-app-config-override.json`
//`npm run start:admin_with_config`
//To run the client-app with a custom config, run following in mbl-frontend directory.
//`yq -o=json '.FRONTEND' "../config/env-{env}.yaml" > client-app/public/dev-app-config-override.json`
//`npm run start:client_with_config`
var settingsUrl = "".concat(backendUrl, "/frontend-app-config"); // Do not change this
var ProductBase;
(function (ProductBase) {
    ProductBase["Project"] = "Project";
    ProductBase["Certificate"] = "Certificate";
})(ProductBase || (ProductBase = {}));
var ProductType;
(function (ProductType) {
    ProductType["ACCU"] = "ACCU";
    ProductType["VCU"] = "VCU";
    ProductType["LGC"] = "LGC";
    ProductType["MiQC"] = "MiQC";
})(ProductType || (ProductType = {}));
var AttributeGroup;
(function (AttributeGroup) {
    AttributeGroup["TRADE"] = "TRADE";
    AttributeGroup["PROJECT"] = "PROJECT";
    AttributeGroup["SHARED"] = "SHARED";
})(AttributeGroup || (AttributeGroup = {}));
var fetchAppConfig = function (homepage) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (process.env["REACT_APP_DEV_MODE"] === "true" && process.env["REACT_APP_CUSTOM_APP_CONFIG"] === "true") {
            settingsUrl = "http://localhost:3000/".concat(homepage, "dev-app-config-override.json");
        }
        return [2 /*return*/, fetch(settingsUrl, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })];
    });
}); };
var AppConfigStateContext = createContext(null);
// These variables are set later when AppConfigProvider loads.
// They are kept as global static variables for ease of access in helper functions including in .js libs imported from core10.
var cortenApiUrl = '';
var cortenApiKey = '';
var cortenWebsocketUrl = '';
var products = [];
var clients = [];
var markets = [];
var themes = [];
var inventoryAccount;
var marketControllerAccount;
// A placeholder attribute, used to keep a non-undefined state while attributes are being loaded from the config
var loadingAttribute = {
    key: '',
    display: '',
    group: AttributeGroup.SHARED
};
var transactionAttributes = {
    tradeId: loadingAttribute,
    tradeDate: loadingAttribute,
    trader: loadingAttribute,
    valueDate: loadingAttribute,
    transactionTypeInventory: loadingAttribute,
    transactionTypeClient: loadingAttribute,
    currency: loadingAttribute,
    price: loadingAttribute,
    salesPerson: loadingAttribute,
    salesCredits: loadingAttribute,
    brokerName: loadingAttribute,
    brokerage: loadingAttribute,
    retirementReason: loadingAttribute,
};
var productAttributes = {
    registry: loadingAttribute,
    registryCountry: loadingAttribute,
};
var carbonProjectAttributes = {
    projectType: loadingAttribute,
    projectId: loadingAttribute,
    projectName: loadingAttribute,
    vintage: loadingAttribute,
    projectState: loadingAttribute,
    country: loadingAttribute,
    projectUri: loadingAttribute,
    projectSerialNumberStart: loadingAttribute,
    projectSerialNumberPrefix: loadingAttribute,
    projectSerialNumberSuffix: loadingAttribute,
};
var lgcAttributes = {
    accreditationCode: loadingAttribute,
    fuelSource: loadingAttribute,
    creationYear: loadingAttribute,
    generationYear: loadingAttribute,
    generationState: loadingAttribute,
    greenPowerAccredited: loadingAttribute,
};
var miqcAttributes = {
    facility: loadingAttribute,
    segment: loadingAttribute,
    issueMonth: loadingAttribute,
    issueYear: loadingAttribute,
    countryOfOperation: loadingAttribute,
    certificateRegion: loadingAttribute,
    operatorName: loadingAttribute,
    miqMethaneIntensity: loadingAttribute,
    miqGrade: loadingAttribute,
    miqGradeStatus: loadingAttribute,
    miqAuditorName: loadingAttribute,
    eo100Grade: loadingAttribute,
};
var emissionAttributes = {
    name: loadingAttribute,
    intensity: loadingAttribute,
    document: loadingAttribute
};
var inventoryScoreAttribute;
var commentAttribute;
var AppConfigProvider = function (_a) {
    var children = _a.children, appConfig = _a.appConfig;
    var _b = useState(undefined), settings = _b[0], setSettings = _b[1];
    var clientToAddressMap = useRef({});
    var addressToClientMap = useRef({});
    useEffect(function () {
        cortenApiUrl = appConfig.GENERAL.CORTEN_URL;
        cortenApiKey = appConfig.GENERAL.CORTEN_API_KEY;
        cortenWebsocketUrl = appConfig.GENERAL.CORTEN_WEBSOCKET_URL;
        products = appConfig.PRODUCTS.INVENTORY_PRODUCTS.map(function (product) {
            return {
                id: product['ID'],
                displayName: product['DISPLAY_NAME'],
                displayCode: product['DISPLAY_CODE'],
                displayComment: product['DISPLAY_COMMENT'],
                queryCode: product['QUERY_CODE'],
                productBase: ProductBase[product['PRODUCT_BASE']],
                currency: product['CURRENCY'],
            };
        });
        clients = appConfig.GENERAL.ACCOUNT.CLIENTS.map(function (client) {
            return {
                id: client['ID'],
                display: client['DISPLAY']
            };
        });
        markets = appConfig.GENERAL.MARKETS.map(function (market) {
            return {
                name: market['NAME'],
                enabled: market['ENABLED']
            };
        });
        themes = Object.entries(appConfig.GENERAL.THEMES).map(function (_a) {
            var _b;
            var name = _a[0], config = _a[1];
            return ({
                name: name,
                displayName: config['DISPLAY_NAME'],
                default: (_b = config['DEFAULT'] === true) !== null && _b !== void 0 ? _b : false,
            });
        });
        inventoryAccount = getAccount('INVENTORY_ISSUER');
        marketControllerAccount = getAccount('MARKET_CONTROLLER');
        var attributeConfig = appConfig.ATTRIBUTES;
        var transactionGroup = 'TRADE';
        var productItemGroup = 'PROJECT';
        var sharedGroup = 'SHARED';
        transactionAttributes = {
            tradeId: getAttributeInternal(attributeConfig, transactionGroup, 'ID'),
            tradeDate: getAttributeInternal(attributeConfig, transactionGroup, 'TIMESTAMP'),
            trader: getAttributeInternal(attributeConfig, transactionGroup, 'TRADER_NAME'),
            valueDate: getAttributeInternal(attributeConfig, transactionGroup, 'VALUE_DATE'),
            transactionTypeInventory: getAttributeInternal(attributeConfig, sharedGroup, 'TRANSACTION_TYPE_INVENTORY'),
            transactionTypeClient: getAttributeInternal(attributeConfig, sharedGroup, 'TRANSACTION_TYPE_CLIENT'),
            currency: getAttributeInternal(attributeConfig, transactionGroup, 'CURRENCY'),
            price: getAttributeInternal(attributeConfig, transactionGroup, 'PRICE'),
            salesPerson: getAttributeInternal(attributeConfig, transactionGroup, 'SALES_PERSON'),
            salesCredits: getAttributeInternal(attributeConfig, transactionGroup, 'SALES_CREDITS'),
            brokerName: getAttributeInternal(attributeConfig, transactionGroup, 'BROKER_NAME'),
            brokerage: getAttributeInternal(attributeConfig, transactionGroup, 'BROKERAGE_FEE'),
            retirementReason: getAttributeInternal(attributeConfig, 'RETIREMENT', 'REASON'),
        };
        productAttributes = {
            registry: getAttributeInternal(attributeConfig, 'REGISTRY', 'ID'),
            registryCountry: getAttributeInternal(attributeConfig, 'REGISTRY', 'COUNTRY'),
        };
        carbonProjectAttributes = {
            projectType: getAttributeInternal(attributeConfig, productItemGroup, 'TYPE'),
            projectId: getAttributeInternal(attributeConfig, productItemGroup, 'ID'),
            projectName: getAttributeInternal(attributeConfig, productItemGroup, 'NAME'),
            vintage: getAttributeInternal(attributeConfig, productItemGroup, 'VINTAGE'),
            projectState: getAttributeInternal(attributeConfig, productItemGroup, 'STATE'),
            country: getAttributeInternal(attributeConfig, productItemGroup, 'COUNTRY_CODE'),
            projectUri: getAttributeInternal(attributeConfig, productItemGroup, 'URI'),
            projectSerialNumberStart: getAttributeInternal(attributeConfig, productItemGroup, 'SERIAL_NUMBER_START'),
            projectSerialNumberPrefix: getAttributeInternal(attributeConfig, productItemGroup, 'SERIAL_NUMBER_PREFIX'),
            projectSerialNumberSuffix: getAttributeInternal(attributeConfig, productItemGroup, 'SERIAL_NUMBER_SUFFIX'),
        };
        lgcAttributes = {
            accreditationCode: getAttributeInternal(attributeConfig, productItemGroup, 'ACCREDITATION_CODE'),
            fuelSource: getAttributeInternal(attributeConfig, productItemGroup, 'FUEL_SOURCE'),
            creationYear: getAttributeInternal(attributeConfig, productItemGroup, 'CREATION_YEAR'),
            generationYear: getAttributeInternal(attributeConfig, productItemGroup, 'GENERATION_YEAR'),
            generationState: getAttributeInternal(attributeConfig, productItemGroup, 'GENERATION_STATE'),
            greenPowerAccredited: getAttributeInternal(attributeConfig, productItemGroup, 'GREENPOWER_ACCREDITED'),
        };
        miqcAttributes = {
            facility: getAttributeInternal(attributeConfig, productItemGroup, 'FACILITY'),
            segment: getAttributeInternal(attributeConfig, productItemGroup, 'SEGMENT'),
            issueMonth: getAttributeInternal(attributeConfig, productItemGroup, 'ISSUE_MONTH'),
            issueYear: getAttributeInternal(attributeConfig, productItemGroup, 'ISSUE_YEAR'),
            countryOfOperation: getAttributeInternal(attributeConfig, productItemGroup, 'COUNTRY_OF_OPERATION'),
            certificateRegion: getAttributeInternal(attributeConfig, productItemGroup, 'CERTIFICATE_REGION'),
            operatorName: getAttributeInternal(attributeConfig, productItemGroup, 'OPERATOR_NAME'),
            miqMethaneIntensity: getAttributeInternal(attributeConfig, productItemGroup, 'MIQ_METHANE_INTENSITY'),
            miqGrade: getAttributeInternal(attributeConfig, productItemGroup, 'MIQ_GRADE'),
            miqGradeStatus: getAttributeInternal(attributeConfig, productItemGroup, 'MIQ_GRADE_STATUS'),
            miqAuditorName: getAttributeInternal(attributeConfig, productItemGroup, 'MIQ_AUDITOR_NAME'),
            eo100Grade: getAttributeInternal(attributeConfig, productItemGroup, 'EO100_GRADE'),
        };
        // other attributes
        inventoryScoreAttribute = getAttributeInternal(attributeConfig, productItemGroup, 'INVENTORY_SCORE');
        commentAttribute = getAttributeInternal(attributeConfig, sharedGroup, 'COMMENT');
        setSettings(appConfig);
        clientToAddressMap.current = new Map(appConfig['GENERAL']['ACCOUNT']['CLIENTS'].map(function (client) {
            return ["".concat(client.DISPLAY), client.ID];
        }));
        addressToClientMap.current = new Map(appConfig['GENERAL']['ACCOUNT']['CLIENTS'].map(function (client) {
            return ["".concat(client.ID), client.DISPLAY];
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    var settingsLoaded = function () {
        return settings !== undefined;
    };
    var getAccount = function (accountName) {
        var acc = appConfig['GENERAL']['ACCOUNT'][accountName];
        return {
            id: acc['ID'],
            display: acc['DISPLAY']
        };
    };
    var getClients = function () {
        return clients;
    };
    var getDisplayNameForAddress = function (address) {
        if (address === undefined) {
            return undefined;
        }
        else if (address === getAccount('INVENTORY_ISSUER').id) {
            return getAccount('INVENTORY_ISSUER').display;
        }
        else {
            return addressToClientMap.current.get(address.toLowerCase());
        }
    };
    var getClientForAddress = function (address) {
        if (address === undefined) {
            return undefined;
        }
        return addressToClientMap.current.get(address.toLowerCase());
    };
    var getAddressForClient = function (clientName) {
        if (clientName === undefined) {
            return undefined;
        }
        return clientToAddressMap.current.get(clientName);
    };
    var getMarkets = function () {
        return markets;
    };
    var getThemes = function () {
        return themes;
    };
    var getProducts = function (excludeCertificateBasedProduct, excludeProducts) {
        if (excludeCertificateBasedProduct === true) {
            return products.filter(function (p) { return p.productBase !== ProductBase.Certificate; });
        }
        if (excludeProducts !== undefined && excludeProducts.length > 0) {
            return products.filter(function (p) { return !excludeProducts.includes(p.displayCode); });
        }
        return products;
    };
    var getProduct = function (productId) {
        return products.find(function (p) { return p.id === productId; });
    };
    // Only to be called for 'Project' based products like ACCU and VCU
    var getProjectAttributes = function () {
        return [
            carbonProjectAttributes.projectId.key,
            carbonProjectAttributes.projectName.key,
            carbonProjectAttributes.projectType.key,
            carbonProjectAttributes.country.key,
            carbonProjectAttributes.projectState.key,
            carbonProjectAttributes.projectUri.key
        ];
    };
    var getAttributeInternal = function (attributeConfig, group, attribute) {
        var attr = attributeConfig[group][attribute];
        return {
            key: attr['KEY'],
            display: attr['DISPLAY'],
            group: group
        };
    };
    /**
     * Function to retrieve Commodity related {@link Attribute} from the config
     *
     * @param group The group heading in the config under which to retrieve the attribute
     * @param attribute the key of the attribute definition within the config under the
     * above group heading
     *
     * @return {Attribute} the attribute definition
     */
    var getCommodityAttribute = function (group, attribute) {
        return getAttributeInternal(settings['COMMODITY_ATTRIBUTES'], group, attribute);
    };
    var enableAggregator = function () {
        return settings['GENERAL']['ENABLE_AGGREGATOR'];
    };
    var commoditiesFeatureEnabled = function () {
        return settings['GENERAL']['COMMODITIES_FEATURE_ENABLED'];
    };
    var getCommoditiesConfig = function () {
        return settings['PRODUCTS']['COMMODITIES'];
    };
    return (_jsx(AppConfigStateContext.Provider, __assign({ value: {
            getClients: getClients,
            getClientForAddress: getClientForAddress,
            getDisplayNameForAddress: getDisplayNameForAddress,
            getAddressForClient: getAddressForClient,
            getMarkets: getMarkets,
            getThemes: getThemes,
            getProducts: getProducts,
            getProduct: getProduct,
            getProjectAttributes: getProjectAttributes,
            getCommodityAttribute: getCommodityAttribute,
            enableAggregator: enableAggregator,
            commoditiesFeatureEnabled: commoditiesFeatureEnabled,
            getCommoditiesConfig: getCommoditiesConfig
        } }, { children: settingsLoaded() && children })));
};
function useAppConfigState() {
    var context = useContext(AppConfigStateContext);
    if (!context) {
        throw new Error('no provider for AppConfigState');
    }
    return context;
}
export { backendUrl, fetchAppConfig, ProductBase, ProductType, AttributeGroup, AppConfigProvider, useAppConfigState, 
// Exported variables that are only active after the provider has loaded:
cortenApiUrl, cortenApiKey, cortenWebsocketUrl, products, clients, markets, themes, inventoryAccount, marketControllerAccount, transactionAttributes, productAttributes, carbonProjectAttributes, lgcAttributes, miqcAttributes, emissionAttributes, inventoryScoreAttribute, commentAttribute, };
