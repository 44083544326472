import { useEffect, useRef } from 'react';
import { useNotification } from '../NotificationProvider';
var transactionTimeoutMillis = 16000;
// simple custom hook to help us monitor results of transactions we perform
var useTransactionMonitor = function (onSuccess, onError, onTimeout) {
    var notification = useNotification().notification;
    var transactionId = useRef(null);
    var transactionTimeout = useRef();
    useEffect(function () {
        var txId = transactionId.current;
        if (!txId) {
            return;
        }
        else if (notification.hasOwnProperty('mintTransactionResponse')) {
            if (notification['mintTransactionResponse'].txId === txId) {
                unsubscribe();
                onSuccess(notification['mintTransactionResponse']);
            }
        }
        else if (notification.hasOwnProperty('mintTransactionFailed')) {
            if (notification['mintTransactionFailed'].txId === txId) {
                unsubscribe();
                onError(notification['mintTransactionFailed']);
            }
        }
    }, [notification]); // eslint-disable-line react-hooks/exhaustive-deps
    var subscribe = function (txId) {
        unsubscribe(); // silently reset, if already subscribed
        transactionId.current = txId;
        transactionTimeout.current = setTimeout(function () {
            unsubscribe();
            onTimeout();
        }, transactionTimeoutMillis);
    };
    var unsubscribe = function () {
        clearTimeout(transactionTimeout.current);
        transactionId.current = null;
    };
    return { subscribe: subscribe, unsubscribe: unsubscribe };
};
export { useTransactionMonitor };
