/**
 * This file is the app UI entry point
 */
import { Box } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { 
    CommodityDeskApiProvider,    
    cortenApiKey,
    cortenApiUrl,
    CustomTheme, 
    LayoutProvider, 
    LoginRedirectHandler,
    LogoutHandler,
    MainView,
    NotFound,
    ProductDataProvider,
    useAppConfigState,
    useAppNavigate,
    useLayoutState
} from '@commodity-desk/common';
import { ApiProvider as CortenApiProvider, useAccessKey, useAccessKeyDispatch, useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { APP_ROUTES } from '../utility/AppRoutes';
import Navigation from './Navigation';
import { homepage, LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH } from '../state/Variables';

const dashboardRoutes = APP_ROUTES.dashboard;

const AppContainer = ({
    theme,
    setTheme,
    themeAnchorEl,
    setThemeAnchorEl
}: {
    theme: CustomTheme;
    setTheme: any;
    themeAnchorEl: any;
    setThemeAnchorEl: any;
}) => {
    const appConfigState = useAppConfigState();
    const { user, isTokenExpired, userManager, setUser, setLoading, signOut, setAccountId, signIn, loading, enabled } = useAuth();
    const navigate = useNavigate();
    const appNavigate = useAppNavigate({homepage, navigate});
    const keyDispatch = useAccessKeyDispatch();
    const { currentKey, keys } = useAccessKey();

    return (
        <>
            <Routes>
                {/* Public Routes */}
                <Route
                    path="/"
                    element={<AuthRedirect redirect={dashboardRoutes.inventoryManagement.path} />}
                />
                <Route path={LOGOUT_CALLBACK_PATH} element={
                    <LogoutHandler
                        homePage={homepage}
                        appNavigate={appNavigate}
                        userManager={userManager}
                        setUser={setUser}
                        setAccountId={setAccountId}
                        keyDispatch={keyDispatch}
                    />
                } />
                <Route path={LOGIN_CALLBACK_PATH} element={
                    <LoginRedirectHandler
                        homepage={homepage}
                        navigate={navigate}
                        userManager={userManager}
                        setUser={setUser}
                        setLoading={setLoading}
                        signOut={signOut} />
                } />
                <Route path="*" element={<NotFound />} />

                {/* Protected App Routes, Only accessible when user is authenticated */}
                <Route
                    path={homepage}
                    element={
                        <RequireAuth>
                            <DashboardWrapper>
                                <Box sx={{ flexGrow: 1 }}>
                                    <LayoutProvider theme={theme} user={user} isTokenExpired={isTokenExpired}>
                                        <Navigation
                                            theme={theme}
                                            setTheme={setTheme}
                                            themeAnchorEl={themeAnchorEl}
                                            setThemeAnchorEl={setThemeAnchorEl}
                                        />
                                        <CortenApiProvider user={user} cortenApiUrl={cortenApiUrl} cortenApiKey={cortenApiKey}>
                                            <CortenApiConsumer
                                                user={user}
                                                signIn={signIn}
                                                signOut={signOut}
                                                loading={loading}
                                                isTokenExpired={isTokenExpired}
                                                enabled={enabled}
                                                currentKey={currentKey}
                                                keys={keys}
                                            />
                                        </CortenApiProvider>
                                    </LayoutProvider>
                                </Box>
                            </DashboardWrapper>
                        </RequireAuth>
                    }
                >
                    {/* Inventory Management */}
                    <Route path={dashboardRoutes.inventoryManagement.path} element={<Outlet />}>
                        <Route
                            index
                            path={`${dashboardRoutes.inventoryManagement.path}/*`}
                            element={dashboardRoutes.inventoryManagement.component}
                        ></Route>
                    </Route>
                    {/* Project Details Route */}
                    <Route
                        index
                        path={dashboardRoutes.inventoryProjectDetails.path}
                        element={dashboardRoutes.inventoryProjectDetails.component}
                    />
                    {/* Vintage Details Route  */}
                    <Route
                        path={dashboardRoutes.inventoryVintageDetails.path}
                        element={dashboardRoutes.inventoryVintageDetails.component}
                    />
                    {/* Certificate Details Route */}
                    <Route
                        path={dashboardRoutes.certificateDetails.path}
                        element={dashboardRoutes.certificateDetails.component}
                    />
                    {/* Physical Trade Route */}
                    <Route
                        path={dashboardRoutes.physicalTrade.path}
                        element={dashboardRoutes.physicalTrade.component}
                    />
                    {/* Forward Trade Route */}
                    <Route
                        path={dashboardRoutes.forwardTrade.path}
                        element={dashboardRoutes.forwardTrade.component}
                    />
                    {/* Retirement Trade Route */}
                    <Route
                        path={dashboardRoutes.retirementTrade.path}
                        element={dashboardRoutes.retirementTrade.component}
                    />
                    {/* Risk Route Route */}
                    <Route
                        path={dashboardRoutes.risk.path}
                        element={dashboardRoutes.risk.component}
                    />
                    {/* Risk Holdings / Forwards Route */}
                    <Route
                        path={dashboardRoutes.riskPosition.path}
                        element={dashboardRoutes.riskPosition.component}
                    />
                    {/* Trade Blotter Route */}
                    <Route
                        path={dashboardRoutes.tradeBlotter.path}
                        element={dashboardRoutes.tradeBlotter.component}
                    />
                    {/* Client Management Route */}
                    <Route
                        path={dashboardRoutes.clientManagement.path}
                        element={dashboardRoutes.clientManagement.component}
                    />
                    {/* Curve Route */}
                    <Route
                        path={dashboardRoutes.curve.path}
                        element={dashboardRoutes.curve.component}
                    />
                    {/* Commodities Route */}
                    <Route
                        path={`${dashboardRoutes.commodities.path}/*`}
                        element={
                            <IsEnabled logic={appConfigState.commoditiesFeatureEnabled()}>
                                <>{dashboardRoutes.commodities.component}</>
                            </IsEnabled>
                        }
                    />
                    {/* Aggregator Route */}
                    <Route
                        path={dashboardRoutes.aggregator.path}
                        element={
                            <IsEnabled logic={appConfigState.enableAggregator()}>
                                <>{dashboardRoutes.aggregator.component}</>
                            </IsEnabled>
                        }
                    />
                    {/* Inventory Score Marking Route */}
                    <Route
                        path={dashboardRoutes.inventoryScore.path}
                        element={dashboardRoutes.inventoryScore.component}
                    />
                    {/* Catch other Routes */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};

export default AppContainer;

// Wrapper component to check if user is authenticated
const RequireAuth = ({ children }: PropsWithChildren) => {
    const { user, isTokenExpired } = useAuth();
    if (user && !isTokenExpired(user)) {
        return <>{children}</>;
    }
    return null;
};

// Wrapper component to redirect (Authenticated user) to any page based on redirect props passed
const AuthRedirect = ({ redirect }: { redirect: string }) => {
    const { user, isTokenExpired } = useAuth();
    if (user && !isTokenExpired(user)) {
        return <Navigate to={redirect} />;
    }
    return <></>;
};

// Component to Enabled/Disblaed any Routes based on config
const IsEnabled = ({ children, logic }: { logic: boolean } & PropsWithChildren) => {
    if (!logic) {
        return <Navigate to="*" />;
    }
    return <>{children}</>;
};

// Dashboard Root Wrapper , which redirect user to inventory page if user is authenticated
const DashboardWrapper = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    useEffect(() => {
        if (pathname === homepage) {
            navigate(dashboardRoutes.inventoryManagement.path);
        }
    }, [navigate, pathname]);

    return <>{children}</>;
};

/**
 * Component that will enable us to get useCortenApiState() and pass it into ProductDataProvider
 * @returns 
 */
const CortenApiConsumer = ({
    user, signIn, signOut, loading, enabled, isTokenExpired, currentKey, keys
}: {
    user: any;
    signIn: any;
    signOut: any;
    loading: any;
    enabled: any;
    isTokenExpired: any
    currentKey: any;
    keys: any;
}) => {
    const appConfigState = useAppConfigState();
    const { cortenApi } = useCortenApiState();
    return (
        <CommodityDeskApiProvider user={user}>
            <ProductDataProvider appConfigState={appConfigState} cortenApi={cortenApi}>
                <LayoutStateConsumer
                    user={user}
                    signIn={signIn}
                    signOut={signOut}
                    loading={loading}
                    isTokenExpired={isTokenExpired}
                    enabled={enabled}
                    currentKey={currentKey}
                    keys={keys}
                />
            </ProductDataProvider>
        </CommodityDeskApiProvider>
    );
};

/**
 * Component that will enable us to get useLayoutState() and pass it into MainView
 * @param param0 
 * @returns 
 */
const LayoutStateConsumer = ({
    user, signIn, signOut, loading, enabled, isTokenExpired, currentKey, keys
}: {
    user:any;
    signIn: any;
    signOut: any;
    loading: any;
    enabled: any;
    isTokenExpired: any
    currentKey: any;
    keys: any;
}) => {
    const layoutState = useLayoutState();

    return (
        <MainView
            user={user}
            signIn={signIn}
            signOut={signOut}
            loading={loading}
            isTokenExpired={isTokenExpired}
            enabled={enabled}
            currentKey={currentKey}
            keys={keys}
            layoutState={useLayoutState()}
            theme={layoutState.customTheme.theme}
        >
            <Outlet />
        </MainView>
    );
};
