var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useRef, useState } from 'react';
import { inventoryAccount, carbonProjectAttributes, lgcAttributes, miqcAttributes, useAppConfigState } from './AppConfig';
import { fetchBalances, fetchByCriteria } from '../utility/Fetch';
import { equalsExpr } from '@trovio-tech/trovio-core-api-js';
var Options = /** @class */ (function () {
    function Options(values, disabled) {
        if (values === void 0) { values = []; }
        if (disabled === void 0) { disabled = false; }
        var _this = this;
        this.getTotalBalance = function () {
            return _this.values.every(function (option) { return option.balance != null; })
                ? _this.values.reduce(function (sum, option) { return sum + +option.balance; }, 0)
                : null;
        };
        this.values = values;
        this.disabled = disabled;
    }
    return Options;
}());
var Option = /** @class */ (function () {
    function Option(id, label, balance, disabled) {
        if (balance === void 0) { balance = null; }
        if (disabled === void 0) { disabled = null; }
        this.id = id;
        this.label = label;
        this.balance = balance;
        this.disabled = disabled;
    }
    Option.isDisabled = function (option, disableOnZeroBalance) {
        var _a;
        if (disableOnZeroBalance === void 0) { disableOnZeroBalance = true; }
        return (_a = option.disabled) !== null && _a !== void 0 ? _a : (disableOnZeroBalance && option.balance != null && option.balance <= 0);
    };
    return Option;
}());
var ProductItemFilter = /** @class */ (function () {
    function ProductItemFilter(key, loadOptions, setOptionsState) {
        var _this = this;
        this.subscribers = [];
        this.currentOptions = new Options();
        /**
         * Subscribe to changes in the specified filters.
         *
         * @param filters product item filters to subscribe to
         */
        this.subscribeTo = function (filters) {
            filters.forEach(function (filter) { return filter.subscribers.push(_this); });
        };
        /**
         * This function will notify all subscribers (i.e. dependants) of this filter, prompting
         * them to reload their options values and associated balances from Corten. For any field
         * that gets reset as a result - its subscribers are updated as well.
         *
         * @param form the current state of the product item filters form
         * @param resetCallback the function to be called if the selected option is no longer available
         */
        this.onChange = function (form, resetCallback) { return __awaiter(_this, void 0, void 0, function () {
            var subscribersToNotify, touchedSubscribers, promises, moreSubscribers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setOptionsState(function (prevState) { return new Options(prevState.values, true); });
                        subscribersToNotify = new Set(this.subscribers);
                        touchedSubscribers = new Set();
                        _a.label = 1;
                    case 1:
                        if (!(subscribersToNotify.size > 0)) return [3 /*break*/, 3];
                        promises = Array.from(subscribersToNotify).map(function (sub) {
                            subscribersToNotify.delete(sub);
                            touchedSubscribers.add(sub);
                            return sub.reloadOptions(form, resetCallback);
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        moreSubscribers = _a.sent();
                        moreSubscribers.flat(1).forEach(function (sub) { return subscribersToNotify.add(sub); });
                        return [3 /*break*/, 1];
                    case 3:
                        // set state for all changed fields simultaneously
                        touchedSubscribers.forEach(function (sub) { return sub.setOptionsState(function (p) { return sub.currentOptions; }); });
                        this.setOptionsState(function (prevState) { return new Options(prevState.values, false); });
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Get total available balance for this filter, if available.
         * - filter disabled: undefined
         * - an option is selected: its balance
         * - else: sum of all options' balances
         *
         * @param form the current state of the product item filters form
         * @returns balance the total available balance based on this filter's state
         */
        this.getAvailableBalance = function (form) {
            var _a, _b;
            return _this.currentOptions.disabled
                ? undefined
                : (_b = (_a = _this.getSelectedOption(form)) === null || _a === void 0 ? void 0 : _a.balance) !== null && _b !== void 0 ? _b : _this.currentOptions.getTotalBalance();
        };
        /**
         * Re-fetch options for this filter using {@link loadOptions} callback and the current form state.
         * If the currently selected option is no longer available, use {@link resetField} callback to
         * clear the filter.
         *
         * @param form the current state of the product item filters form
         * @param resetCallback the function to be called if the selected option is no longer available
         * @returns subscribers list of subscribers to notify if this filter was reset as a result of
         *          option update, ar else an empty list
         */
        this.reloadOptions = function (form, resetCallback) { return __awaiter(_this, void 0, void 0, function () {
            var _a, currentOption;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // disable the field while we load the new options
                        this.setOptionsState(function (prevState) { return new Options(prevState === null || prevState === void 0 ? void 0 : prevState.values, true); });
                        // we let the caller update the option state so that all updated fields are re-enabled together
                        _a = this;
                        return [4 /*yield*/, this.loadOptions(form).then(function (result) { return new Options(result); })];
                    case 1:
                        // we let the caller update the option state so that all updated fields are re-enabled together
                        _a.currentOptions = _b.sent();
                        currentOption = this.getSelectedOption(form);
                        // when the selected option is no longer available - reset the field
                        if (!currentOption && form[this.key]) {
                            resetCallback(this.key);
                            form[this.key] = undefined;
                            return [2 /*return*/, this.subscribers];
                        }
                        return [2 /*return*/, []];
                }
            });
        }); };
        /**
         * Use form state to find the currently selected {@link Option} object from the list
         * of {@link currentOptions}.
         *
         * @param form the current state of the product item filters form
         * @returns option currently selected {@link Option} object, or undefined
         */
        this.getSelectedOption = function (form) {
            return _this.currentOptions.values.find(function (opt) { return opt.id === form[_this.key] && !Option.isDisabled(opt); });
        };
        this.key = key;
        this.loadOptions = loadOptions;
        this.setOptionsState = setOptionsState;
    }
    return ProductItemFilter;
}());
/**
 * Custom hook providing dynamic option values for product item filter fields: Product,
 * Project Type, Project and Vintage, as well as the total available balance based on current
 * filter values.
 *
 * Plug the state into your form and call {@link onFilterChange} whenever any values from
 * {@link ProductItemForm} are updated.
 *
 * Use {@link resetProductFilters} to reset the option values and force reload.
 */
var useProductItemFilters = function (_a) {
    var cortenApi = _a.cortenApi, excludeCertificateBasedProduct = _a.excludeCertificateBasedProduct, excludeProducts = _a.excludeProducts, _b = _a.useLockedBalances, useLockedBalances = _b === void 0 ? false : _b;
    var _c = useState(new Options([])), productOptions = _c[0], setProductOptions = _c[1];
    var _d = useState(new Options([])), projectTypeOptions = _d[0], setProjectTypeOptions = _d[1];
    var _e = useState(new Options([])), projectOptions = _e[0], setProjectOptions = _e[1];
    var _f = useState(new Options([])), vintageOptions = _f[0], setVintageOptions = _f[1];
    var _g = useState(new Options([])), projectStateOptions = _g[0], setStateOptions = _g[1];
    var _h = useState(new Options([])), countryOptions = _h[0], setCountryOptions = _h[1];
    // LGC:
    var _j = useState(new Options([])), fuelSourceOptions = _j[0], setFuelSourceOptions = _j[1];
    var _k = useState(new Options([])), generationYearOptions = _k[0], setGenerationYearOptions = _k[1];
    var _l = useState(new Options([])), creationYearOptions = _l[0], setCreationYearOptions = _l[1];
    var _m = useState(new Options([])), generationStateOptions = _m[0], setGenerationStateOptions = _m[1];
    var _o = useState(new Options([])), greenPowerAccreditedOptions = _o[0], setGreenPowerAccreditedOptions = _o[1];
    // MiQC:
    var _p = useState(new Options([])), segmentOptions = _p[0], setSegmentOptions = _p[1];
    var _q = useState(new Options([])), issueYearOptions = _q[0], setIssueYearOptions = _q[1];
    var _r = useState(new Options([])), miqGradeOptions = _r[0], setMiqGradeOptions = _r[1];
    var _s = useState(0), availableBalance = _s[0], setAvailableBalance = _s[1];
    var allFiltersRef = useRef(new Map());
    var formFilterRef = useRef();
    var appConfigState = useAppConfigState();
    useEffect(function () {
        var root = buildFilter(); // root form filter
        var product = buildFilter('product', setProductOptions, fetchProductsFromBalances);
        var projectType = buildFilter('projectType', setProjectTypeOptions, fetchProjectTypes);
        var project = buildFilter('project', setProjectOptions, fetchProjects);
        var vintage = buildFilter('vintage', setVintageOptions, fetchVintages);
        var projectState = buildFilter('projectState', setStateOptions, fetchStates);
        var country = buildFilter('country', setCountryOptions, fetchCountries);
        // LGC:
        var fuelSource = buildFilter('fuelSource', setFuelSourceOptions, fetchFuelSources);
        var generationYear = buildFilter('generationYear', setGenerationYearOptions, fetchGenerationYears);
        var creationYear = buildFilter('creationYear', setCreationYearOptions, fetchCreationYears);
        var generationState = buildFilter('generationState', setGenerationStateOptions, fetchGenerationState);
        var greenPowerAccredited = buildFilter('greenPowerAccredited', setGreenPowerAccreditedOptions, fetchGreenPowerAccredited);
        // MiQC:
        var segment = buildFilter('segment', setSegmentOptions, fetchSegments);
        var issueYear = buildFilter('issueYear', setIssueYearOptions, fetchIssueYears);
        var miqGrade = buildFilter('miqGrade', setMiqGradeOptions, fetchMiqGrades);
        product.subscribeTo([root]);
        projectType.subscribeTo([root, product, project, vintage, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        project.subscribeTo([root, product, projectType, vintage, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        vintage.subscribeTo([root, product, projectType, project, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        projectState.subscribeTo([root, product, projectType, project, vintage, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        country.subscribeTo([root, product, projectType, project, vintage, projectState, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        // LGC:
        fuelSource.subscribeTo([root, product, projectType, project, vintage, projectState, country, generationYear, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        creationYear.subscribeTo([root, product, projectType, project, vintage, projectState, country, fuelSource, generationState, greenPowerAccredited, generationYear, segment, issueYear, miqGrade]);
        generationYear.subscribeTo([root, product, projectType, project, vintage, projectState, country, fuelSource, generationState, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        generationState.subscribeTo([root, product, projectType, project, vintage, projectState, country, fuelSource, generationYear, greenPowerAccredited, creationYear, segment, issueYear, miqGrade]);
        greenPowerAccredited.subscribeTo([root, product, projectType, project, vintage, projectState, country, fuelSource, generationYear, generationState, creationYear, segment, issueYear, miqGrade]);
        // MiQC:
        segment.subscribeTo([root, product, issueYear, miqGrade, projectType, project, vintage, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear]);
        issueYear.subscribeTo([root, product, segment, miqGrade, projectType, project, vintage, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear]);
        miqGrade.subscribeTo([root, product, segment, issueYear, projectType, project, vintage, projectState, country, fuelSource, generationYear, generationState, greenPowerAccredited, creationYear]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    var resetProductFilters = function (resetCallback, initialValues, initialOptions) {
        if (initialValues === void 0) { initialValues = {}; }
        if (initialOptions === void 0) { initialOptions = undefined; }
        // set placeholder options while we load the current values
        if (initialOptions) {
            setProductOptions(initialOptions.productOptions);
            setProjectTypeOptions(initialOptions.projectTypeOptions);
            setProjectOptions(initialOptions.projectOptions);
            setVintageOptions(initialOptions.vintageOptions);
            setStateOptions(initialOptions.projectStateOptions);
            setCountryOptions(initialOptions.countryOptions);
            // LGC:
            setFuelSourceOptions(initialOptions.fuelSourceOptions);
            setCreationYearOptions(initialOptions.creationYearOptions);
            setGenerationYearOptions(initialOptions.generationYearOptions);
            setGenerationStateOptions(initialOptions.generationStateOptions);
            setGreenPowerAccreditedOptions(initialOptions.greenPowerAccreditedOptions);
            // MiQC:
            setSegmentOptions(initialOptions.segmentOptions);
            setIssueYearOptions(initialOptions.issueYearOptions);
            setMiqGradeOptions(initialOptions.miqGradeOptions);
        }
        // trigger option and balances load
        onFilterChange(resetCallback, initialValues);
    };
    // update options and available balance based on the selected filters
    var onFilterChange = function (resetCallback, form, fieldName) {
        if (fieldName === void 0) { fieldName = undefined; }
        setAvailableBalance(null);
        var fieldFilter = fieldName ? allFiltersRef.current.get(fieldName) : undefined;
        var changedFilter = fieldFilter !== null && fieldFilter !== void 0 ? fieldFilter : formFilterRef.current;
        changedFilter.onChange(form, resetCallback).then(function () {
            // set the smallest filter balance as available
            var balances = Array.from(allFiltersRef.current.values())
                .map(function (filter) { return filter.getAvailableBalance(form); })
                .filter(Boolean);
            setAvailableBalance(balances.length > 0 ? Math.min.apply(Math, balances) : 0);
        });
    };
    var fetchProjectTypes = function (form) { return fetchAttributeOptions(form, carbonProjectAttributes.projectType.key); };
    var fetchProjects = function (form) { return fetchAttributeOptions(form, carbonProjectAttributes.projectId.key, carbonProjectAttributes.projectName.key); };
    var fetchVintages = function (form) { return fetchAttributeOptions(form, carbonProjectAttributes.vintage.key); };
    var fetchStates = function (form) { return fetchAttributeOptions(form, carbonProjectAttributes.projectState.key); };
    var fetchCountries = function (form) { return fetchAttributeOptions(form, carbonProjectAttributes.country.key); };
    // LGC:
    var fetchFuelSources = function (form) { return fetchAttributeOptions(form, lgcAttributes.fuelSource.key); };
    var fetchCreationYears = function (form) { return fetchAttributeOptions(form, lgcAttributes.creationYear.key); };
    var fetchGenerationYears = function (form) { return fetchAttributeOptions(form, lgcAttributes.generationYear.key); };
    var fetchGenerationState = function (form) { return fetchAttributeOptions(form, lgcAttributes.generationState.key); };
    var fetchGreenPowerAccredited = function (form) { return fetchAttributeOptions(form, lgcAttributes.greenPowerAccredited.key); };
    // MiQC:
    var fetchSegments = function (form) { return fetchAttributeOptions(form, miqcAttributes.segment.key); };
    var fetchIssueYears = function (form) { return fetchAttributeOptions(form, miqcAttributes.issueYear.key); };
    var fetchMiqGrades = function (form) { return fetchAttributeOptions(form, miqcAttributes.miqGrade.key); };
    var fetchProductsFromBalances = function (form) { return __awaiter(void 0, void 0, void 0, function () {
        var allProductsBalances, criteria;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allProductsBalances = [];
                    if (!form.account) return [3 /*break*/, 2];
                    criteria = {
                        sumProductItems: true,
                        accountId: form.account
                    };
                    return [4 /*yield*/, fetchBalances({
                            criteria: criteria,
                            allProductIds: appConfigState.getProducts(excludeCertificateBasedProduct, excludeProducts).map(function (data) { return data.id; }),
                            api: cortenApi,
                        })];
                case 1:
                    allProductsBalances = _a.sent();
                    // add products with zero balance as they are not included into the API response
                    appConfigState.getProducts(excludeCertificateBasedProduct, excludeProducts).map(function (product) { return product.id; })
                        .filter(function (id) { return !allProductsBalances.find(function (balance) { return balance['productId'] === id; }); })
                        .forEach(function (id) { return allProductsBalances.push({ issuerAmount: 0, assignedAmount: 0, productId: id, unassignedAmount: 0, escrowAmount: 0 }); });
                    _a.label = 2;
                case 2: return [2 /*return*/, appConfigState.getProducts(excludeCertificateBasedProduct, excludeProducts)
                        .map(function (product) {
                        var balances = allProductsBalances.find(function (balance) { return balance['productId'] === product.id; });
                        var balance = balances ? resolveAvailableBalance(balances, form) : null;
                        return new Option(product.id, product.displayCode, balance);
                    })];
            }
        });
    }); };
    // fetch options and balance via attribute api
    var fetchAttributeOptions = function (form, idAttribute, labelAttribute) {
        if (labelAttribute === void 0) { labelAttribute = idAttribute; }
        return __awaiter(void 0, void 0, void 0, function () {
            var criteria, issuerOptionsPromise, balanceSource, balancesPromise, _a, issuerOptions, balances;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        // we cannot fetch valid option if no product is selected
                        if (!form.product)
                            return [2 /*return*/, []];
                        criteria = {
                            productIds: [form.product],
                            axes: Array.from(new Set([idAttribute, labelAttribute])),
                            attributes: buildAttributeFilters(form, idAttribute),
                            includeBalances: true,
                        };
                        issuerOptionsPromise = fetchByCriteria(__assign(__assign({}, criteria), { accountId: inventoryAccount.id }), cortenApi);
                        balanceSource = undefined;
                        if (form.account === inventoryAccount.id) {
                            balanceSource = issuerOptionsPromise;
                        }
                        else if (form.account) {
                            balanceSource = fetchByCriteria(__assign(__assign({}, criteria), { accountId: form.account }), cortenApi);
                        }
                        balancesPromise = (_b = balanceSource === null || balanceSource === void 0 ? void 0 : balanceSource.then(function (data) {
                            var balanceMap = new Map();
                            for (var _i = 0, _a = data.list; _i < _a.length; _i++) {
                                var item = _a[_i];
                                var id = String(item['attributes'][idAttribute]);
                                var balance = resolveAvailableBalance(item['balances'], form);
                                balanceMap.set(id, balance);
                            }
                            return balanceMap;
                        })) !== null && _b !== void 0 ? _b : Promise.resolve(undefined);
                        return [4 /*yield*/, Promise.all([issuerOptionsPromise, balancesPromise])];
                    case 1:
                        _a = _c.sent(), issuerOptions = _a[0], balances = _a[1];
                        return [2 /*return*/, issuerOptions.list.map(function (item) {
                                var _a;
                                var id = String(item['attributes'][idAttribute]);
                                var label = String(item['attributes'][labelAttribute]);
                                var balance = balances ? (_a = balances.get(id)) !== null && _a !== void 0 ? _a : 0 : null;
                                return new Option(id, label, balance);
                            })];
                }
            });
        });
    };
    var buildAttributeFilters = function (form, idAttribute) {
        var filters = [];
        if (form.projectType && idAttribute !== carbonProjectAttributes.projectType.key) {
            filters.push({ code: carbonProjectAttributes.projectType.key, value: equalsExpr(form.projectType) });
        }
        if (form.project && idAttribute !== carbonProjectAttributes.projectId.key) {
            filters.push({ code: carbonProjectAttributes.projectId.key, value: equalsExpr(form.project) });
        }
        if (form.vintage && idAttribute !== carbonProjectAttributes.vintage.key) {
            filters.push({ code: carbonProjectAttributes.vintage.key, value: equalsExpr(form.vintage) });
        }
        if (form.projectState && idAttribute !== carbonProjectAttributes.projectState.key) {
            filters.push({ code: carbonProjectAttributes.projectState.key, value: equalsExpr(form.projectState) });
        }
        if (form.country && idAttribute !== carbonProjectAttributes.country.key) {
            filters.push({ code: carbonProjectAttributes.country.key, value: equalsExpr(form.country) });
        }
        if (form.fuelSource && idAttribute !== lgcAttributes.fuelSource.key) {
            filters.push({ code: lgcAttributes.fuelSource.key, value: equalsExpr(form.fuelSource) });
        }
        if (form.creationYear && idAttribute !== lgcAttributes.creationYear.key) {
            filters.push({ code: lgcAttributes.creationYear.key, value: equalsExpr(form.creationYear) });
        }
        if (form.generationYear && idAttribute !== lgcAttributes.generationYear.key) {
            filters.push({ code: lgcAttributes.generationYear.key, value: equalsExpr(form.generationYear) });
        }
        if (form.generationState && idAttribute !== lgcAttributes.generationState.key) {
            filters.push({ code: lgcAttributes.generationState.key, value: equalsExpr(form.generationState) });
        }
        if (form.greenPowerAccredited && idAttribute !== lgcAttributes.greenPowerAccredited.key) {
            filters.push({ code: lgcAttributes.greenPowerAccredited.key, value: equalsExpr(form.greenPowerAccredited) });
        }
        if (form.segment && idAttribute !== miqcAttributes.segment.key) {
            filters.push({ code: miqcAttributes.segment.key, value: equalsExpr(form.segment) });
        }
        if (form.issueYear && idAttribute !== miqcAttributes.issueYear.key) {
            filters.push({ code: miqcAttributes.issueYear.key, value: equalsExpr(form.issueYear) });
        }
        if (form.miqGrade && idAttribute !== miqcAttributes.miqGrade.key) {
            filters.push({ code: miqcAttributes.miqGrade.key, value: equalsExpr(form.miqGrade) });
        }
        return filters;
    };
    var resolveAvailableBalance = function (balances, form) {
        if (useLockedBalances) {
            return balances['escrowAmount'];
        }
        else if (form.account === inventoryAccount.id) {
            return balances['issuerAmount'];
        }
        else {
            return form.account ? balances['assignedAmount'] : null;
        }
    };
    var buildFilter = function (key, setOptionsState, loadOptions) {
        if (setOptionsState === void 0) { setOptionsState = function () { }; }
        if (loadOptions === void 0) { loadOptions = function () { return Promise.resolve([]); }; }
        var filter = new ProductItemFilter(key, loadOptions, setOptionsState);
        if (key) {
            allFiltersRef.current.set(key, filter);
        }
        else {
            formFilterRef.current = filter;
        }
        return filter;
    };
    return {
        productOptions: productOptions,
        projectTypeOptions: projectTypeOptions,
        projectOptions: projectOptions,
        vintageOptions: vintageOptions,
        projectStateOptions: projectStateOptions,
        countryOptions: countryOptions,
        // LGC:
        fuelSourceOptions: fuelSourceOptions,
        creationYearOptions: creationYearOptions,
        generationYearOptions: generationYearOptions,
        generationStateOptions: generationStateOptions,
        greenPowerAccreditedOptions: greenPowerAccreditedOptions,
        // MiQC:
        segmentOptions: segmentOptions,
        issueYearOptions: issueYearOptions,
        miqGradeOptions: miqGradeOptions,
        // other
        availableBalance: availableBalance,
        onFilterChange: onFilterChange,
        resetProductFilters: resetProductFilters
    };
};
export { useProductItemFilters, Option, Options, };
