/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectTypeScoreFromJSON, ProjectTypeScoreToJSON, } from './ProjectTypeScore.js';
/**
 * Check if a given object implements the BaseOptimiserScoreMarking interface.
 */
export function instanceOfBaseOptimiserScoreMarking(value) {
    var isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "projectTypeScores" in value;
    return isInstance;
}
export function BaseOptimiserScoreMarkingFromJSON(json) {
    return BaseOptimiserScoreMarkingFromJSONTyped(json, false);
}
export function BaseOptimiserScoreMarkingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productId': json['productId'],
        'projectTypeScores': (json['projectTypeScores'].map(ProjectTypeScoreFromJSON)),
    };
}
export function BaseOptimiserScoreMarkingToJSON(value) {
    return BaseOptimiserScoreMarkingToJSONTyped(value, false);
}
export function BaseOptimiserScoreMarkingToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productId': value.productId,
        'projectTypeScores': (value.projectTypeScores.map(ProjectTypeScoreToJSON)),
    };
}
