import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { 
    AppConfigProvider, 
    fetchAppConfig,
    transformConfig
} from '@commodity-desk/common';
import { LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH } from './state/Variables';

(async function fetchConfigData() {
    try {
        let appConfigResponse = await fetchAppConfig("");
        if (appConfigResponse.ok) {
            let appConfigJson = await appConfigResponse.json();

            let cortenAppConfigResponse = await fetch(`${appConfigJson.GENERAL.CORTEN_URL}/api/config`, {
                method: 'GET',
                headers: {
                    'X-Trovio-DemoAuthorization': appConfigJson.GENERAL.CORTEN_API_KEY
                }
            });
            if (cortenAppConfigResponse.ok) {
                let cortenAppConfigJson = await cortenAppConfigResponse.json();

                // Temporary hack to allow MBL app to be compatible with the new app config schema
                // whilst also being compatible with the old schema. Once Corten is updated we will
                // remove this hack and change the config parsing in AuthProvider.js in MBL-370
                if (cortenAppConfigJson.hasOwnProperty('authConfig')) {
                    console.log('New corten app config scheme detected')
                    cortenAppConfigJson = cortenAppConfigJson.authConfig[0];
                }

                ReactDOM.render(
                    <React.StrictMode>
                        <BrowserRouter>
                            <AppConfigProvider appConfig={appConfigJson}>
                                <App cortenAppConfig={transformConfig(cortenAppConfigJson, LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH)} />
                            </AppConfigProvider>
                        </BrowserRouter>
                    </React.StrictMode>,
                    document.getElementById('root')
                );
            } else {
                throw new Error(`${cortenAppConfigResponse.status}, ${cortenAppConfigResponse.statusText}`);
            }

        } else {
            throw new Error(`${appConfigResponse.status}, ${appConfigResponse.statusText}`);
        }
    } catch (error) {
        console.log('resp not ok');

        console.log('something went wrong', error);
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
