import { FieldDataType, OverviewCategory } from './../FieldDefinitions';
import { ProductType, lgcAttributes } from '../../../state/AppConfig';
var getLGCFieldDefinitions = function (appConfigState) {
    return [
        {
            key: "accreditationCode",
            displayName: "Accreditation Code",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.accreditationCode,
            columnMinWidth: 160,
        },
        {
            key: "fuelSource",
            displayName: "Fuel Source",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.fuelSource,
            columnMinWidth: 150,
        },
        {
            key: "creationYear",
            displayName: "Creation Year",
            dataType: FieldDataType.Integer,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.creationYear,
            columnMinWidth: 120,
        },
        {
            key: "generationYear",
            displayName: "Generation Year",
            dataType: FieldDataType.Integer,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.generationYear,
            columnMinWidth: 150,
        },
        {
            key: "generationState",
            displayName: "Generation State",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.generationState,
            columnMinWidth: 130,
        },
        {
            key: "greenPowerAccredited",
            displayName: "GreenPower Accredited",
            dataType: FieldDataType.Boolean,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.LGC],
            attribute: lgcAttributes.greenPowerAccredited,
            columnMinWidth: 150,
        },
    ];
};
export { getLGCFieldDefinitions };
