/* eslint-disable no-mixed-operators */
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { 
    useAuth, 
    useCortenApiState , 
    submitUnsignedTransaction
} from '@trovio-tech/trovio-core-api-jsx';
import { useForm, useWatch } from 'react-hook-form';
import { 
    AlertDialogContent, 
    AlertType,
    AmountFormatWrapper, 
    ControlledTextField,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getOrderedProjectsAndAmounts,
    getProductAttributeUiElements,
    getTradeOptimiserOptions,
    inventoryAccount,
    marketControllerAccount,
    Option, 
    Options, 
    ProductType,
    renderDialogField,
    renderEntry,
    renderOrderedProjectVintageAmounts,
    ResettableFormFields,
    TradeOptimiser,
    TradeOptimiserAmountType,
    transactionAttributes,
    TransactionOverview,
    TransactionType,
    useAppConfigState,
    useCommodityDeskApiContext,
    useProductDataState,
    useProductItemFilters,
    useTransactionMonitor
} from '@commodity-desk/common';
import { CreateEscrowRequest, ProductItemsAmount } from '@trovio-tech/trovio-core-api-js';

interface LockToMarketForm extends ResettableFormFields {
    quantity: number,
    market: string,
    tradeOptimiser: TradeOptimiser
}

const defaultValues: LockToMarketForm = {
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    projectState: '',
    country: '',
    // LGC
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenPowerAccredited: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
    // other
    quantity: 0,
    market: '',
    tradeOptimiser: TradeOptimiser.DEFAULT
};


const HoldingLockToMarket = ({
    lockToMarketDialogActive,
    preSelectedProductId,
    preSelectedProjectId,
    preSelectedProjectType,
    preSelectedVintage,
    onLockToMarketDialogClosed
}: {
    lockToMarketDialogActive: boolean;
    preSelectedProductId?: string;
    preSelectedProjectId?: string;
    preSelectedProjectType?: string;
    preSelectedVintage?: string;
    onLockToMarketDialogClosed: any;
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid },
        setValue
    } = useForm<LockToMarketForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['product', 'market'], control });

    // Used to detect form changes that require us to reset the Trade Optimiser field
    const formWatch = useWatch({ control });
    const productWatch = useWatch({
        name: 'product',
        control: control
    });
    const { cortenApi, cortenAuthApi } = useCortenApiState();

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenPowerAccreditedOptions,
        segmentOptions,
        issueYearOptions,
        miqGradeOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({cortenApi: cortenApi, excludeProducts: []});

    const appConfigState = useAppConfigState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);
    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const user = useAuth();
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [balanceError, setBalanceError] = useState<string>();
    const [marketOptions, setMarketOptions] = useState<Option[]>([]);
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const { productsData } = useProductDataState();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const productItemIds = useRef<Promise<string[]>>();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    useEffect(() => {
        if (lockToMarketDialogActive) {
            setMarketOptions(appConfigState.getMarkets().map((market) => (
                new Option(market.name, market.name, null, !market.enabled)
            )));
            let preSelectedData = getValues();
            if (preSelectedProductId !== undefined) {
                preSelectedData.product = preSelectedProductId;
            } else {
                preSelectedData.product = "";
            }
            if (preSelectedProjectId !== undefined) {
                preSelectedData.project = preSelectedProjectId;
            } else {
                preSelectedData.project = "";
            }
            if (preSelectedProjectType !== undefined) {
                preSelectedData.projectType = preSelectedProjectType;
            } else {
                preSelectedData.projectType = "";
            }
            if (preSelectedVintage !== undefined) {
                preSelectedData.vintage = preSelectedVintage;
            } else {
                preSelectedData.vintage = "";
            }
            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, {...preSelectedData, account: inventoryAccount.id});
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [lockToMarketDialogActive]); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product) {
            error = 'Please select a Product';
        }
        if (!form.market) {
            error = 'Please select a Market';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
        },
        (tx) => {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when locking holdings to market.');
            setTransactionErrorCode(tx.errorCode);
        },
        () => {
            setIsTransactionInProgress(false);
            setTransactionWarnMessage('The transaction timed out. Please check back later to confirm whether the transaction was processed.');
        }
    );

    const handleLockToMarketDialogClose = () => {
        unsubscribe();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        // update state in the parent view
        onLockToMarketDialogClosed();
    }

    const performLockToMarket = async () => {
        setIsTransactionInProgress(true);
        setTransactionInReview(false);
        let requestFrom: ProductItemsAmount = {
            type: "ProductItemsAmount",
            productItemIds: await productItemIds.current!,
            amount: getValues().quantity
        }
        
        const request: CreateEscrowRequest = {
            type: 'CreateEscrowRequest',
            fromAccountId: inventoryAccount.id,
            controllerAccountId: marketControllerAccount.id,
            from: requestFrom,
            nonce: new Date().getTime(),
            attributes: { [`${transactionAttributes.transactionTypeInventory.key}`]: TransactionType.LockToMarket as any }
        };
        const result = await submitUnsignedTransaction(request, user, cortenAuthApi);
        if (result === undefined) {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage("Could not complete the transaction - the request was rejected")

        } else {
            pendingTransaction.current = result.txId;
            subscribe(result.txId);
        }
    };

    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(resetField, {...getValues(), account: inventoryAccount.id}, field);
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        getOrderedProjectsAndAmounts(
            {
                ...getValues(),
                product: selectedProductId
            },
            inventoryAccount.id,
            setProjectAmounts,
            productItemIds,
            appConfigState,
            cortenApi,
            commodityDeskApi,
            handleError
        );
    };

    const handleError = () => {
        setTransactionInReview(false);
        setTransactionErrorMessage("Could not connect to CortenX. Please re-try.");
    };

    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };

    // Used to reset the Trade Optimiser field when product is changed in the form
    useEffect(() => {
        if ([ProductType.LGC, ProductType.MiQC].includes(appConfigState.getProduct(getValues('product') ?? '')?.displayCode as ProductType)) {
            setValue('tradeOptimiser', TradeOptimiser.DEFAULT)
        }
    }, [productWatch]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectedProduct = appConfigState.getProduct(getValues('product') ?? '');
    const selectedProductId = getValues().product!;
    
    if (transactionSuccess) {
        return (
            <TransactionOverview
                open={transactionSuccess}
                onClose={handleLockToMarketDialogClose}
                title='Transaction Submitted successfully'
                uiElements={{
                    'transactionId': { value: pendingTransaction.current! },
                    'market': { value: getValues().market },
                    'productId': { value: getValues().product },
                    ...getProductAttributeUiElements({
                        data: {
                            ...getValues(),
                            // projectName is not part of the form object, so we need to add it here for it to show on the overview
                            'projectName': projectOptions?.values.find(opt => opt.id === getValues().project)?.label
                        },
                        fieldDefinitions: fieldDefinitions,
                        productType: appConfigState.getProduct(selectedProductId)?.displayCode as ProductType
                    }),
                    'quantity': { value: getValues().quantity.toString() }
                }}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={appConfigState.getProduct(selectedProductId)?.displayCode as ProductType}
            />
        );
    }
     

    return (
        <Dialog
            open={lockToMarketDialogActive}
            onClose={handleLockToMarketDialogClose}
            fullWidth
            maxWidth="sm"
        >

            {!transactionInReview && !transactionSuccess && !isTransactionInProgress && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Lock to Market
                        </Typography>

                        {renderEntry('Market:', (
                            <ControlledTextField name='market' label='Market'
                                options={new Options(marketOptions, false)}
                                rules={{ required: 'Market is required' }}
                                control={control} errors={errors}
                                reset={resetField}
                            />
                        ), true, true)}
                        {renderEntry('Product:', (
                            <ControlledTextField name='product' label='Product'
                                options={productOptions}
                                customOnChange={() => onOptionSelect('product')}
                                rules={{ required: 'Product is required' }}
                                control={control} errors={errors} reset={resetField}
                            />
                        ), true, true)}

                        {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Project Type:', (
                            <ControlledTextField name='projectType' label='Project Type'
                                options={projectTypeOptions}
                                customOnChange={() => onOptionSelect('projectType')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                            />
                        ), true, true)}

                        {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Project:', (
                            <ControlledTextField name='project' label='Project'
                                options={projectOptions}
                                customOnChange={() => onOptionSelect('project')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                            />
                        ), true, true)}
                        {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Vintage:', (
                            <ControlledTextField name='vintage' label='Vintage'
                                options={vintageOptions}
                                customOnChange={() => onOptionSelect('vintage')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                            />
                        ), true, true)}
                        {selectedProduct?.displayCode === ProductType.ACCU && renderEntry('State:', (
                            <ControlledTextField name='projectState' label='State'
                                options={projectStateOptions}
                                customOnChange={() => onOptionSelect('projectState')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                            />
                        ), true, true)}
                        {selectedProduct?.displayCode === ProductType.VCU && renderEntry('Country:', (
                            <ControlledTextField name='country' label='Country'
                                options={countryOptions}
                                customOnChange={() => onOptionSelect('country')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                            />
                        ), true, true)}

                        {selectedProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Fuel Source:', (
                                <ControlledTextField name='fuelSource' label='Fuel Source'
                                    options={fuelSourceOptions}
                                    customOnChange={() => onOptionSelect('fuelSource')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                            ), true, true)}
                        {selectedProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Creation Year:', (
                                <ControlledTextField name='creationYear' label='Creation Year'
                                    options={creationYearOptions}
                                    customOnChange={() => onOptionSelect('creationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                            ), true, true)}
                        {selectedProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Generation Year:', (
                                <ControlledTextField name='generationYear' label='Generation Year'
                                    options={generationYearOptions}
                                    customOnChange={() => onOptionSelect('generationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                            ), true, true)}
                        {selectedProduct?.displayCode === ProductType.LGC &&
                            renderEntry('Generation State:', (
                                <ControlledTextField name='generationState' label='Generation State'
                                    options={generationStateOptions}
                                    customOnChange={() => onOptionSelect('generationState')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                            ), true, true)}
                        {selectedProduct?.displayCode === ProductType.LGC &&
                            renderEntry('GreenPower Accredited:', (
                                <ControlledTextField name='greenPowerAccredited' label='GreenPower Accredited'
                                    options={greenPowerAccreditedOptions}
                                    customOnChange={() => onOptionSelect('greenPowerAccredited')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                            ), true, true)}

                        {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('Segment:', (
                            <ControlledTextField name='segment' label='Segment'
                                options={segmentOptions}
                                customOnChange={() => onOptionSelect('segment')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                        ), true, true)}

                        {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('Issue Year:', (
                            <ControlledTextField name='issueYear' label='Issue Year'
                                options={issueYearOptions}
                                customOnChange={() => onOptionSelect('issueYear')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                        ), true, true)}

                        {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('MiQ Grade:', (
                            <ControlledTextField name='miqGrade' label='MiQ Grade'
                                options={miqGradeOptions}
                                customOnChange={() => onOptionSelect('miqGrade')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                />
                        ), true, true)}

                        {renderEntry('Quantity:', (
                            <>
                                <ControlledTextField name='quantity' label='Quantity' integer
                                    rules={{
                                        required: 'Quantity is required',
                                        pattern: {
                                            value: /^\d*$/,
                                            message: 'Quantity must be a whole number',
                                        },
                                        min: {
                                            value: 1,
                                            message: 'Quantity must be greater than 0',
                                        },
                                        max: {
                                            value: availableBalance,
                                            message: 'Quantity exceeds available balance',
                                        },
                                    }}
                                    control={control} errors={errors}
                                />
                                {!balanceError && availableBalance == null
                                    ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                    : (<Typography variant='caption'
                                        color={balanceError ? 'error' : 'textSecondary'}
                                        mt={1} mb={1}
                                        sx={{ float: 'right' }}>
                                        {balanceError
                                            ? <div>{balanceError}</div>
                                            : <div>Available Balance: <AmountFormatWrapper
                                                amount={availableBalance}
                                                minDecimalPos={productsData.get(selectedProductId)?.minDecimalPos!}
                                                maxDecimalPos={productsData.get(selectedProductId)?.maxDecimalPos!}
                                            /></div>}
                                    </Typography>)
                                }
                            </>
                        ), true, true)}
                        {(renderEntry('Trade Optimiser:', (
                            <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                                options={getTradeOptimiserOptions(selectedProduct?.displayCode as ProductType)}
                                rules={{ required: 'Trade optimiser is required' }}
                                control={control} errors={errors} reset={resetField} />
                        ), true, true))}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleLockToMarketDialogClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => openReviewDialog()}
                            color="primary"
                            variant="outlined"
                            disabled={
                                !isValid || !availableBalance
                            }
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {(transactionInReview || isTransactionInProgress) && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>Review Details Below</Typography>
                        {renderDialogField('Market', getValues().market)}
                        {getValues().product && renderDialogField('Product', appConfigState.getProduct(selectedProductId)?.displayCode)}
                        {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}
                        {getValues().project && renderDialogField(
                            'Project',
                            projectOptions?.values.find(opt => opt.id === getValues().project)?.label)
                        }
                        {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                        {getValues().projectState && renderDialogField('State', getValues().projectState)}
                        {getValues().country && renderDialogField('Country', getValues().country)}
                        {getValues().segment && renderDialogField('Segment', getValues().segment)}
                        {getValues().issueYear && renderDialogField('Issue Year', getValues().issueYear)}
                        {getValues().miqGrade && renderDialogField('MiQ Grade', getValues().miqGrade)}
                        {renderDialogField('Quantity', getValues().quantity, {
                            minDecimalPos: productsData.get(selectedProductId)?.minDecimalPos!,
                            maxDecimalPos: productsData.get(selectedProductId)?.maxDecimalPos!,
                        })}
                        {getValues().tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(selectedProductId)!, TradeOptimiserAmountType.Price)}
                        {getValues().tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(selectedProductId)!, TradeOptimiserAmountType.Score)}
                        {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                        <Button
                            onClick={performLockToMarket}
                            color='primary'
                            variant='outlined'
                            disabled={
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) &&
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity)
                            }
                        >
                            {isTransactionInProgress ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '2px',
                                }}
                                >
                                    <CircularProgress size={20} />
                                </div>
                            ) : ('Confirm')}
                        </Button>
                    </DialogActions>
                </>
            )}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onLockToMarketDialogClosed}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onLockToMarketDialogClosed}
                />
            )}


        </Dialog>
    );

};

export {HoldingLockToMarket}
