import { Box, Button, CircularProgress, Typography } from '@mui/material';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Path, useWatch } from 'react-hook-form';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useRef, useState } from 'react';

/**
 * Component that allows uploading of any files. It will
 * - render a button for uploading
 * - attach the uploaded file to a form
 * - render a button for removing an uploaded file
 *
 * @param T A generic type for this component. It must be a subtype of Record<string, any> which is used by
 * react hook form.
 * @param name The name of the field to which the document upload component will be associated with
 * @param label The name to display for this document upload button
 * @param acceptedFileTypes {string} Filter for the file types that will be accepted for upload
 * @param uploadFunction A function that will be called by this component to perform the upload. The function
 * must return the result. Any exception thrown by the function will be handled as an upload error
 * @param control {Control} the form control associated with the field
 * @param setValue {UseFormSetValue} the setValue function from the form, used for attaching the uploaded
 *  file with the form for submission.
 * @param onUploadStatusChanged {(boolean) => void} An optional function that will be called whenever there is
 *  a change in the upload status
 * @param disabled optional boolean to indicate whether the button should be disabled
 */
const DocumentUpload = <T extends Record<string, any>>({
    name,
    label,
    acceptedFileTypes,
    uploadFunction,
    control,
    setValue,
    onUploadStatusChanged,
    disabled
}: {
    name: Path<T>;
    label: string;
    acceptedFileTypes: string;
    uploadFunction: (event: React.ChangeEvent<HTMLInputElement>) => Promise<any>;
    control: Control<T>;
    setValue: UseFormSetValue<T>;
    onUploadStatusChanged?: (isUploading: boolean) => void;
    disabled?: boolean
}) => {

    const documentWatch = useWatch({control: control, name: name});
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    // opens the file browser to select document
    const openFileBrowser = () => {
        // clear upload error
        setUploadError(false);
        // Trigger a click event on the hidden file input when the button is clicked
        fileInputRef.current?.click();
    };

    const removeFile = () => {
        // remove document from state
        setValue(name, '' as any);
    };

    return (
        <Box sx={{marginTop: 2}}>
            {documentWatch ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            marginBottom: 2
                        }}
                    >
                        <CloudDoneIcon />
                        <Typography sx={{ marginLeft: 2 }}>
                            File Uploaded
                        </Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        onClick={removeFile}
                        startIcon={<ClearIcon />}
                        disabled={disabled}
                    >
                        Remove {label}
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        variant="outlined"
                        onClick={openFileBrowser}
                        startIcon={<CloudUploadIcon />}
                        disabled={disabled}
                    >
                        {isUploading ? <CircularProgress size={24} /> : `Upload ${label}`}
                        <input
                            hidden
                            accept={acceptedFileTypes}
                            type="file"
                            onChange={(e) => {
                                setIsUploading(true);
                                if (onUploadStatusChanged) {
                                    onUploadStatusChanged(true);
                                }
                                uploadFunction(e).then((data: any) => {
                                    setValue(name, data);
                                }).catch((e) => {
                                    // failed to upload the document
                                    console.error(e);
                                    // display error
                                    setUploadError(true);
                                }).finally(() => {
                                    setIsUploading(false);
                                    if (onUploadStatusChanged) {
                                        onUploadStatusChanged(false);
                                    }
                                })
                            }}
                            ref={fileInputRef}
                        />
                    </Button>

                    {uploadError && (
                        <Typography sx={{ color: 'red', marginTop: 1 }}>
                            Error uploading document
                        </Typography>
                    )}
                </>
            )}
        </Box>
    )
}

export default DocumentUpload;