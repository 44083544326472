import { FieldDataType, OverviewCategory } from './../FieldDefinitions';
import { ProductType, miqcAttributes } from '../../../state/AppConfig';
var getMiQCFieldDefinitions = function (appConfigState) {
    return [
        {
            key: "facility",
            displayName: "Facility",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.facility,
            columnMinWidth: 120,
        },
        {
            key: "segment",
            displayName: "Segment",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.segment,
            columnMinWidth: 130,
        },
        {
            key: "issueMonth",
            displayName: "Issue Month",
            dataType: FieldDataType.DateYearMonth,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.issueMonth,
            columnMinWidth: 150,
        },
        {
            key: "issueYear",
            displayName: "Issue Year",
            dataType: FieldDataType.Integer,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.issueYear,
            columnMinWidth: 150,
        },
        {
            key: "countryOfOperation",
            displayName: "Country of Operation",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.countryOfOperation,
            columnMinWidth: 210,
        },
        {
            key: "certificateRegion",
            displayName: "Certificate Region",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.certificateRegion,
            columnMinWidth: 190,
        },
        {
            key: "operatorName",
            displayName: "Operator Name",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.operatorName,
            columnMinWidth: 180,
        },
        {
            key: "miqMethaneIntensity",
            displayName: "MiQ Methane Intensity",
            dataType: FieldDataType.Decimal,
            unitAmount: 0.00001,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.miqMethaneIntensity,
            columnMinWidth: 220,
        },
        {
            key: "miqGrade",
            displayName: "MiQ Grade",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.miqGrade,
            columnMinWidth: 140,
        },
        {
            key: "miqGradeStatus",
            displayName: "MiQ Grade Status",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.miqGradeStatus,
            columnMinWidth: 190,
        },
        {
            key: "miqAuditorName",
            displayName: "MiQ Auditor Name",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.miqAuditorName,
            columnMinWidth: 190,
        },
        {
            key: "eo100Grade",
            displayName: "EO100 Grade",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.MiQC],
            attribute: miqcAttributes.eo100Grade,
            columnMinWidth: 160,
        },
    ];
};
export { getMiQCFieldDefinitions };
