/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the TagProjectResult interface.
 */
export function instanceOfTagProjectResult(value) {
    var isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "success" in value;
    return isInstance;
}
export function TagProjectResultFromJSON(json) {
    return TagProjectResultFromJSONTyped(json, false);
}
export function TagProjectResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'productId': json['productId'],
        'success': json['success'],
    };
}
export function TagProjectResultToJSON(value) {
    return TagProjectResultToJSONTyped(value, false);
}
export function TagProjectResultToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorMessage': value.errorMessage,
        'productId': value.productId,
        'success': value.success,
    };
}
