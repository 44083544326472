var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, Typography } from '@mui/material';
import { FieldDataType, OverviewCategory, NO_DATA_INDICATOR } from './FieldDefinitions';
import { renderTruncatedHumanName, renderCurrencyAmount, renderTruncatedIdField } from '../utility/CommonJsxRenderFunctions';
import { transactionAttributes } from '../../state/AppConfig';
import { TransactionType } from '../../state/Variables';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
/**
 * Retrive a list of FieldDefinition objects containing all properties for transaction based fields.
 * @param appConfigState  The application config state, used to retrieve attribute info as well as the configured list of Clients, for filter options
 * @param productsData    The current product information, including number of decimal places to render product amounts in the quantity field.
 * @param filterOperators The set of filter operators that are available. Used to set filtering logic when rendering data in a table.
 * @returns
 */
var getTransactionFieldDefinitions = function (appConfigState, productsData, filterOperators) {
    return [
        // Below are fields that are base properties of the transaction
        {
            key: "transactionId",
            displayName: "Transaction ID",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction,
            sortable: false,
            columnMinWidth: 150,
            renderJSX: renderTruncatedIdField,
            dataGridDefinitions: __assign({}, (filterOperators ? {
                customFilterOperators: filterOperators.transactionIdFilterOperators()
            } : {}))
        },
        {
            key: "transactionTimestamp",
            displayName: "Transaction Timestamp",
            dataType: FieldDataType.Timestamp,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.tradeDate,
            columnMinWidth: 220,
        },
        {
            key: "counterParty",
            displayName: "Counterparty",
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Transaction,
            fixedOptions: appConfigState.getClients().map(function (client) { return ({
                value: client.id,
                label: client.display
            }); }),
            sortable: false,
            columnMinWidth: 160,
            renderJSX: function (params) {
                var _a;
                return (_jsx(Tooltip, __assign({ title: params.row.counterPartyToolTipText, arrow: true }, { children: _jsx(Typography, { children: (_a = params.value) !== null && _a !== void 0 ? _a : NO_DATA_INDICATOR }) })));
            },
            dataGridDefinitions: __assign({}, (filterOperators ? {
                customFilterOperators: filterOperators.singleSelectFilterOperators().filter(function (operator) { return operator.value !== 'not'; }).map(function (operator) {
                    return __assign(__assign({}, operator), { getValueAsString: function (value) {
                            var valueArray = [];
                            if (!Array.isArray(value)) {
                                valueArray = [value];
                            }
                            else {
                                valueArray = value;
                            }
                            return appConfigState.getClients().filter(function (data) { return valueArray.includes(data.id); })
                                .map(function (data) { return data.display; }).join(", ");
                        } });
                }),
            } : {}))
        },
        {
            key: "quantity",
            displayName: "Quantity",
            dataType: FieldDataType.Integer,
            overviewCategory: OverviewCategory.Product,
            columnMinWidth: 160,
            renderJSX: function (params) {
                var _a, _b;
                return _jsx(AmountFormatWrapper, { amount: params.value, minDecimalPos: (_a = productsData.get(params.row.productId)) === null || _a === void 0 ? void 0 : _a.minDecimalPos, maxDecimalPos: (_b = productsData.get(params.row.productId)) === null || _b === void 0 ? void 0 : _b.maxDecimalPos });
            },
            dataGridDefinitions: __assign({}, (filterOperators ? {
                customFilterOperators: __spreadArray(__spreadArray([], filterOperators.extendedNumericFilterOperators.filter(function (operator) { return operator.value !== '!='; }), true), filterOperators.inputRangeFilterOperators({ type: 'Integer' }), true)
            } : {}))
        },
        {
            key: "lockId",
            displayName: "Lock ID",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction
        },
        {
            key: "tradeDirection",
            displayName: "Trade Direction",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction
        },
        {
            key: "market",
            displayName: "Market",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction
        },
        {
            key: "fromAccountId",
            displayName: "From Account",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction
        },
        // Below are fields that are also saved as attributes on transactions, if specified when submitting the transaction
        {
            key: "tradeId",
            displayName: "Trade ID",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.tradeId,
            sortable: false,
            includeInGroupBy: false,
            columnMinWidth: 150,
            renderJSX: renderTruncatedIdField
        },
        {
            key: "tradeDate",
            displayName: "Trade Date",
            dataType: FieldDataType.Date,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.tradeDate,
            columnMinWidth: 180,
        },
        {
            key: "trader",
            displayName: "Trader",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.trader,
            columnMinWidth: 150,
            renderJSX: renderTruncatedHumanName
        },
        {
            key: "valueDate",
            displayName: "Value Date",
            dataType: FieldDataType.Date,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.valueDate,
            columnMinWidth: 145,
        },
        {
            key: "transactionTypeInventory",
            sharedColumnId: "transactionType",
            displayName: "Transaction Type",
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.transactionTypeInventory,
            fixedOptions: [
                { value: TransactionType.PhysicalBuy, label: TransactionType.PhysicalBuy },
                { value: TransactionType.PhysicalSell, label: TransactionType.PhysicalSell },
                { value: TransactionType.ForwardSell, label: TransactionType.ForwardSell },
                { value: TransactionType.ForwardSellDeliver, label: TransactionType.ForwardSellDeliver },
                { value: TransactionType.ClientRetirement, label: TransactionType.ClientRetirement },
                { value: TransactionType.InventoryRetirement, label: TransactionType.InventoryRetirement },
            ],
            sortable: false,
            columnMinWidth: 150,
        },
        {
            key: "transactionTypeClient",
            sharedColumnId: "transactionType",
            displayName: "Transaction Type",
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Transaction,
            attribute: transactionAttributes.transactionTypeClient,
            fixedOptions: [
                { value: TransactionType.PhysicalBuy, label: TransactionType.PhysicalBuy },
                { value: TransactionType.PhysicalSell, label: TransactionType.PhysicalSell },
                { value: TransactionType.ForwardSell, label: TransactionType.ForwardSell },
                { value: TransactionType.ForwardSellDeliver, label: TransactionType.ForwardSellDeliver },
                { value: TransactionType.ClientRetirement, label: TransactionType.ClientRetirement },
                { value: TransactionType.InventoryRetirement, label: TransactionType.InventoryRetirement },
            ],
            sortable: false,
            includeInGroupBy: false,
            columnMinWidth: 150,
        },
        {
            key: "currency",
            displayName: "Currency",
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Other,
            fixedOptions: [
                { value: 'AUD', label: 'AUD' },
                { value: 'USD', label: 'USD' }
            ],
            attribute: transactionAttributes.currency,
            columnMinWidth: 130,
            dataGridDefinitions: __assign({}, (filterOperators ? {
                customFilterOperators: filterOperators.singleSelectFilterOperators().filter(function (operator) {
                    return operator.value !== 'not' && operator.value !== 'isAnyOf';
                })
            } : {}))
        },
        {
            key: "price",
            displayName: "Price",
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Other,
            unitAmount: 0.01,
            attribute: transactionAttributes.price,
            columnMinWidth: 120,
            renderJSX: renderCurrencyAmount
        },
        {
            key: "salesPerson",
            displayName: "Sales Person",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.salesPerson,
            columnMinWidth: 160,
            renderJSX: renderTruncatedHumanName
        },
        {
            key: "salesCredits",
            displayName: "Sales Credits",
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.salesCredits,
            sortable: false,
            filterable: false,
            includeInGroupBy: false,
            columnMinWidth: 140,
            renderJSX: renderCurrencyAmount
        },
        {
            key: "brokerName",
            displayName: "Broker Name",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.brokerName,
            columnMinWidth: 160,
            renderJSX: renderTruncatedHumanName
        },
        {
            key: "brokerage",
            displayName: "Brokerage",
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.brokerage,
            sortable: false,
            filterable: false,
            includeInGroupBy: false,
            columnMinWidth: 140,
            renderJSX: renderCurrencyAmount
        },
        {
            key: "reason",
            displayName: "Retirement Reason",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Other,
            attribute: transactionAttributes.retirementReason,
            includeInGroupBy: false
        }
    ];
};
export { getTransactionFieldDefinitions };
