import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogState, useCommoditiesDispatch, useCommoditiesState } from './state/CommoditiesState';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    AlertDialogContent,
    AlertType,
    CommodityFormSchema,
    ControlledTextField,
    DATE_DISPLAY_FORMAT,
    DocumentLink,
    FieldDataType,
    Option,
    Options,
    OverviewCategory,
    renderDialogField,
    TransactionOverview,
    UiElementMap
} from '@commodity-desk/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DocumentUpload from './DocumentUpload';
import 'dayjs/locale/en-au';

const CommodityForm = () => {
    const {
        submitCommoditiesForm,
        dialogState,
        performIssueCommodity,
        requesting,
        uploadFile,
        commodityConfig
    } = useCommoditiesState();
    const { setDialogState, resetTable } = useCommoditiesDispatch();
    const [ isUploading, setIsUploading ]= useState<boolean>(false)
    const getDefaultValues = () => {
        // Prepare default values variable
        const defaultValues: Record<string, any> = {};

        // Get all possible fields in this form
        let attrs = commodityConfig.getAllFieldDefinitions();

        // Place all the fields into the default values record and return it
        attrs.forEach((attr) => {
            defaultValues[attr.key] = ''
        })

        // Also add the productId field to defaults
        defaultValues['productId'] = ''

        return defaultValues;
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
        getValues,
        setValue,
        trigger,
        clearErrors
    } = useForm<CommodityFormSchema>({ defaultValues: getDefaultValues() });

    const productWatch = useWatch({control: control, name: 'productId'});

    // close form
    const handleCommoditiesFormClose = () => {
        // refresh table data
        if (dialogState === DialogState.SUCCESS || dialogState === DialogState.ERROR) {
            // Start from the first page again
            resetTable();
        }

        // clear fields
        reset(getDefaultValues());
        setDialogState(DialogState.HIDDEN);
    };

    useEffect(() => {
        // If the product field changes then reset all the fields
        reset(getDefaultValues());

        // Set the new value of the productId
        setValue('productId', productWatch);

        // Trigger validation on the productId field which force the form to update
        trigger('productId')
    }, [productWatch]);

    // Special setup required to ensure that the Verifier field is required whenever GHC Verification field is set to true
    const [isVerifierRequired, setIsVerifierRequired] = useState(false);
    const ghcVerificationWatch = useWatch({ name: 'ghcVerification', control });

    useEffect(() => {
        // the field is required when GHC Verification field on is true
        let required = ghcVerificationWatch === 'true';
        // clear error messages, in case we are transitioning from "required" to "not required"
        if (!required) clearErrors('verifier');
        setIsVerifierRequired(required);
    }, [ghcVerificationWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const getProductSpecificFormInputs = () => {
        // Get all the input field attributes specific to the product chosen
        return commodityConfig.getCommodityFieldDefinitionsForProductId(productWatch)
            // Then map the attributes to specifc form input field depending on its type
            ?.map((attr) => {
                switch (attr.dataType) {
                    case FieldDataType.Date:
                        return (
                            <Box key={attr.key} sx={{ marginTop: 2 }}>
                                <Controller
                                    name={attr.key}
                                    control={control}
                                    rules={attr.rules}
                                    render={({ field, fieldState }) =>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                                            <DatePicker
                                                label={attr.displayName}
                                                format={DATE_DISPLAY_FORMAT}
                                                onChange={(date: any) => field.onChange(date)}
                                                value={field.value}
                                                inputRef={field.ref}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true, size: 'small',
                                                        required: true,
                                                        error: !!fieldState?.error,
                                                        helperText: fieldState?.error?.message,
                                                    },
                                                }} />
                                        </LocalizationProvider>
                                    }
                                />
                            </Box>
                        )
                    case FieldDataType.Document:
                        return (
                            <Fragment key={attr.key}>
                                <DocumentUpload<CommodityFormSchema>
                                    name={attr.key}
                                    label={attr.displayName}
                                    acceptedFileTypes="*/*"
                                    uploadFunction={uploadFile}
                                    control={control}
                                    setValue={setValue}
                                    onUploadStatusChanged={(isUploading) => setIsUploading(isUploading)}
                                    disabled={isUploading}
                                />
                            </Fragment>
                        );
                    case FieldDataType.Boolean:
                    case FieldDataType.Integer:
                    case FieldDataType.Decimal:
                    case FieldDataType.String:
                    case FieldDataType.FixedOptions:
                        // Special block to deal with boolean or dropdown fields
                        let options: Options | undefined = undefined;
                        if (attr.dataType === FieldDataType.Boolean) {
                            options = new Options([
                                new Option('true', 'true'),
                                new Option('false', 'false')
                            ]);
                        } else {
                            options = attr.fixedOptions
                                ?.map(o => new Option(o.value, o.label))
                                ?.reduce((
                                        acc, option) => {
                                        acc.values.push(option);
                                        return acc;
                                    },
                                    new Options()
                                );
                        }

                        // Special block to ensure that Verifier field is set to required whenever
                        // GHC Verification field is true
                        let rules: any | undefined = undefined;
                        if (attr.key === 'verifier') {
                            rules = {
                                required: isVerifierRequired && `Verifier is required`
                            };
                        } else {
                            rules = attr.rules;
                        }

                        return (
                            <Box key={attr.key} sx={{ marginTop: 2 }}>
                                <ControlledTextField
                                    name={attr.key}
                                    label={attr.displayName}
                                    rules={rules}
                                    options={options}
                                    type={[FieldDataType.Decimal, FieldDataType.Integer].includes(attr.dataType) ? 'number' : null}
                                    control={control}
                                    errors={errors}
                                    disabled={attr.disabled}
                                    customOnChange={() => {
                                        if (attr.onChange) {
                                            attr.onChange(getValues(), setValue);
                                        }
                                        // Trigger validation for the field on change so that
                                        // error messages display
                                        trigger(attr.key);
                                    }}
                                />
                            </Box>
                        );
                    default:
                        throw Error(`Unhandled FieldDataType: ${attr.dataType}`)
                }
        })
    }

    const renderProductSpecificDialogFields = () => {
        const formData = getValues();
        return commodityConfig.getCommodityFieldDefinitionsForProductId(productWatch)?.map((attr) => {
            if (attr.dataType === FieldDataType.Date) {
                // For date type attributes we need to format them to string
                return <Fragment key={attr.key}>
                    { renderDialogField(attr.displayName, dayjs(formData[attr.key]).format(DATE_DISPLAY_FORMAT)) }
                </Fragment>
            } else if (attr.dataType === FieldDataType.Document && formData[attr.key]) {
                // For file attachments we render a special element with a link to the uploaded file
                return (
                    <Box
                        key={attr.key}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '1rem'
                        }}
                    >
                        <Typography>{attr.displayName}</Typography>
                        <DocumentLink url={formData[attr.key].url} />
                    </Box>
                );
            } else {
                // For other types we use the renderDialogField from the utils
                return <Fragment key={attr.key}>
                    { renderDialogField(attr.displayName, formData[attr.key]) }
                </Fragment>
            }
        })
    }

    const getProductSpecificDialogUiElements = (): UiElementMap => {
        // Prepare object for populating with fields
        const obj: any = {};

        commodityConfig.getCommodityFieldDefinitionsForProductId(productWatch)?.map(attr => {
            if (attr.dataType === FieldDataType.Date) {
                // For date attributes we format them to string
                obj[`${attr.displayName}`] = {
                    label: attr.displayName,
                    value: dayjs(getValues()[attr.key]).format(DATE_DISPLAY_FORMAT),
                    category: attr.overviewCategory
                };
            } else if (attr.dataType === FieldDataType.Document && getValues()[attr.key]) {
                // For file attachments we render them as a special element a link to the uploaded file
                obj[`${attr.displayName}`] = {
                    label: attr.displayName,
                    value: <DocumentLink url={getValues()[attr.key].url} />,
                    category: attr.overviewCategory
                }
            } else {
                // For other types we pass them in as they are
                obj[`${attr.displayName}`] = {
                    label: attr.displayName,
                    value: getValues()[attr.key],
                    category: attr.overviewCategory
                };
            }
        });
        return obj;
    }

    if (dialogState === DialogState.SUCCESS) {
        return (
            <TransactionOverview
                open={dialogState === DialogState.SUCCESS}
                onClose={handleCommoditiesFormClose}
                title="Transaction Submitted Successfully"
                uiElements={{
                    commodityType: {
                        value: commodityConfig.getCommodityTypeForProductId(getValues().productId),
                        label: 'Commodity Type',
                        category: OverviewCategory.Commodity
                    },
                    ...getProductSpecificDialogUiElements()
                }}
                fieldDefinitionMap={commodityConfig.getFieldDefinitionMap()}
                productType={undefined}
            />
        );
    }

    return (
        <>
            <Dialog
                open={dialogState !== DialogState.HIDDEN}
                onClose={handleCommoditiesFormClose}
                fullWidth
                maxWidth="sm"
            >
                {/* Main form */}
                {dialogState === DialogState.FORM && (
                    <form onSubmit={handleSubmit(submitCommoditiesForm)}>
                        <DialogContent>
                            <Typography variant="h2">Create New Commodity</Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                                <ControlledTextField
                                    name="productId"
                                    label="Commodity Type"
                                    options={new Options(commodityConfig.getCommodities().map((p) => new Option(p.id, p.displayCode)))}
                                    control={control}
                                    errors={errors}
                                    rules={{ required: 'Product is required' }}
                                />

                                {getProductSpecificFormInputs()}
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" onClick={handleCommoditiesFormClose}>
                                Cancel
                            </Button>
                            <Button variant="outlined" type="submit" disabled={!isValid || isUploading}>
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                )}

                {/* Review dialog */}
                {dialogState === DialogState.REVIEW && (
                    <>
                        <DialogContent>
                            <Typography variant="h2">Review Details Below</Typography>

                            {renderDialogField('Commodity Type', commodityConfig.getCommodityTypeForProductId(getValues().productId))}

                            {renderProductSpecificDialogFields()}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => setDialogState(DialogState.FORM)}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Button onClick={() => performIssueCommodity(
                                getValues(),
                                commodityConfig.getCommodityFieldDefinitionsForProductId(productWatch)!
                            )} variant="outlined">
                                {requesting ? <CircularProgress size={24} /> : 'Confirm'}
                            </Button>
                        </DialogActions>
                    </>
                )}

                {/* Error Dialog */}
                {dialogState === DialogState.ERROR && (
                    <AlertDialogContent
                        alertType={AlertType.Error}
                        alertMessage="An error occurred while issuing commodity"
                        errorCode={null}
                        handleDialogClose={handleCommoditiesFormClose}
                    />
                )}
            </Dialog>
        </>
    );
};

export default CommodityForm;
