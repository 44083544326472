var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, Typography } from '@mui/material';
import { FieldDataType, OverviewCategory, RenderLocation, NO_DATA_INDICATOR } from './../FieldDefinitions';
import { ProductType, carbonProjectAttributes } from '../../../state/AppConfig';
import { truncateProjectName } from '../../../utility/ProjectUtils';
/** Get all field definitions for project based products, such as ACCU and VCU */
var getProjectBasedProductFieldDefinitions = function (appConfigState) {
    return [
        {
            key: "projectType",
            displayName: "Project Type",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.ACCU, ProductType.VCU],
            attribute: carbonProjectAttributes.projectType,
            columnMinWidth: 200,
        },
        {
            key: "projectId",
            displayName: "Project ID",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.ACCU, ProductType.VCU],
            attribute: carbonProjectAttributes.projectId,
            columnMinWidth: 150,
        },
        {
            key: "projectName",
            displayName: "Project Name",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.ACCU, ProductType.VCU],
            attribute: carbonProjectAttributes.projectName,
            columnMinWidth: 200,
            renderJSX: function (params) {
                if (params.value === undefined) {
                    return _jsx(_Fragment, { children: NO_DATA_INDICATOR });
                }
                else if (params.renderLocation === RenderLocation.OverviewDialog) {
                    return (_jsx(Typography, { children: params.value }));
                }
                else {
                    return (_jsx(Tooltip, __assign({ title: params.value, arrow: true }, { children: _jsx(Typography, { children: truncateProjectName(params.value) }) })));
                }
            }
        },
        {
            key: "vintage",
            displayName: "Vintage",
            dataType: FieldDataType.Integer,
            overviewCategory: OverviewCategory.Product,
            productTypes: [ProductType.ACCU, ProductType.VCU],
            attribute: carbonProjectAttributes.vintage,
            columnMinWidth: 120,
        },
    ];
};
export { getProjectBasedProductFieldDefinitions };
