import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { 
    AmountFormatWrapper,
    DATE_DISPLAY_FORMAT,
    ControlledTextField, 
    Currency, 
    FormDrafts,
    getTradeOptimiserOptions,
    inventoryAccount,
    Option,
    Options,
    ProductBase, 
    ProductItemFilterOptions,
    ProductItemForm,
    ProductType,  
    ResettableFormFields,
    sectionSpace,
    StorageItem,
    TradeDirection,
    TradeOptimiser,
    useAppConfigState,
    useFormDrafts,
    useProductDataState,
    useProductItemFilters,
    useRequired,
    useLayoutState
} from '@commodity-desk/common';
import dayjs, { Dayjs } from 'dayjs';
import { PhysicalTradeSubmitDialog } from './PhysicalTradeSubmitDialog';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface PhysicalTradeDraft {
    form: PhysicalTradeForm,
    filterOptions?: ProductItemFilterOptions,
    version: string,
}

interface PhysicalTradeForm {
    tradeId?: string,
    tradeDate: Dayjs,
    trader: string,
    counterparty: string,
    tradeDirection: TradeDirection,
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    projectState: string,
    country: string,
    // LGC
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenPowerAccredited: string,
    // MiQC
    segment: string,
    issueYear: string,
    miqGrade: string,
    // other
    quantity: number,
    valueDateOption: string,
    customValueDate: Dayjs,
    currency: Currency,
    price: string,
    salesPerson: string,
    salesCredits: string,
    broker: string,
    brokerageFee: string,
    tradeOptimiser: TradeOptimiser,
}

// When updating this form, you must update the equivalent form in TradeOptimiserUtil
const defaultValues: PhysicalTradeForm = {
    tradeId: '',
    tradeDate: dayjs(),
    tradeDirection: TradeDirection.SELL,
    trader: '',
    counterparty: '',
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    projectState: '',
    country: '',
    // LGC
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenPowerAccredited: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
    // other
    quantity: 0,
    valueDateOption: '',
    customValueDate: dayjs(),
    currency: Currency.AUD,
    price: '',
    salesPerson: '',
    salesCredits: '',
    broker: '',
    brokerageFee: '',
    tradeOptimiser: TradeOptimiser.DEFAULT,
};

const valueDateOptions: Option[] = [1, 2, 3, 4, 5, 6, 7].map(
    (num) => new Option(`${num}`, `T + ${num} day${num === 1 ? '' : 's'}`)
).concat([new Option('Custom', 'Custom')]);

const PhysicalTrade = () => {
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { customTheme } = useLayoutState();
    const { cortenApi } = useCortenApiState();

    const {
        handleSubmit,
        control,
        getValues,
        resetField,
        reset,
        trigger,
        clearErrors,
        formState: { errors, isValid },
        setValue
    } = useForm<PhysicalTradeForm>({ mode: 'onChange', defaultValues: defaultValues });
    const balanceErrorWatch = useWatch({
        name: ['tradeDirection', 'counterparty', 'product'],
        control,
    });

    // Used to detect form changes that require us to reset the Trade Optimiser field
    const formWatch = useWatch({ control });
    const productWatch = useWatch({
        name: 'product',
        control: control
    })

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenPowerAccreditedOptions,
        segmentOptions,
        issueYearOptions,
        miqGradeOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters,
    } = useProductItemFilters({cortenApi: cortenApi});

    const skipDraftUpdates = useRef(1); // set to "1" to skip update upon the load of default values
    const {
        draftsMetadata,
        initialiseDrafts,
        selectDraft,
        updateDraft,
        deleteDraft,
        getCurrentDraftId,
    } = useFormDrafts<PhysicalTradeDraft>(
        StorageItem.PHYSICAL_TRADE_FORM.key,
        { form: defaultValues },
        '6',
        () => { skipDraftUpdates.current++ }
    );

    const isSalesCreditsRequired = useRequired<PhysicalTradeForm>('salesCredits', 'salesPerson', control, clearErrors);
    const isSalesPersonRequired = useRequired<PhysicalTradeForm>('salesPerson', 'salesCredits', control, clearErrors);
    const isBrokerageFeeRequired = useRequired<PhysicalTradeForm>('brokerageFee', 'broker', control, clearErrors);
    const isBrokerRequired = useRequired<PhysicalTradeForm>('broker', 'brokerageFee', control, clearErrors);

    const [balanceError, setBalanceError] = useState<string>();
    const [isSubmitting, setSubmitting] = useState(false);

    const onSubmitOpen = (data: PhysicalTradeForm) => {
        setSubmitting(true);
    };

    const onSubmitClose = (resetForm: boolean) => {
        setSubmitting(false);
        let currentDraftId = draftsMetadata!.currentDraftId;
        let draft = resetForm
            ? deleteDraft(currentDraftId, true)
            : selectDraft(currentDraftId);
        setFormFromDraft(draft);
    };

    // on mount - load form values from storage
    useEffect(() => {
        setFormFromDraft(initialiseDrafts());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    // on form update - update stored values
    useEffect(() => {
        if (skipDraftUpdates.current > 0) {
            // ignore changes that are a result of switching drafts
            // to preserve the correct "modified date"
            skipDraftUpdates.current--;
        } else {
            updateDraft((data) => {
                data.form = formWatch as PhysicalTradeForm;
                // We store dynamic options so that, when switching to a draft, we can show the previously
                // selected values immediately rather than waiting for a load to complete.
                // Current options will still be loaded in the background.
                data.filterOptions = {
                    productOptions: productOptions,
                    projectTypeOptions: projectTypeOptions,
                    projectOptions: projectOptions,
                    vintageOptions: vintageOptions,
                    projectStateOptions: projectStateOptions,
                    countryOptions: countryOptions,
                    fuelSourceOptions: fuelSourceOptions,
                    creationYearOptions: creationYearOptions,
                    generationYearOptions: generationYearOptions,
                    generationStateOptions: generationStateOptions,
                    greenPowerAccreditedOptions: greenPowerAccreditedOptions,
                    segmentOptions: segmentOptions,
                    issueYearOptions: issueYearOptions,
                    miqGradeOptions: miqGradeOptions,
                };
            });
        }
    }, [formWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (form.tradeDirection === TradeDirection.BUY && !form.counterparty) {
            error = 'Please select a Counterparty';
        } else if (!form.product) {
            error = 'Please select a Product';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const getClientOptions = () => {
        let values = appConfigState.getClients().map(client => (new Option(client.id, client.display)));
        return new Options(values);
    };

    const getCurrencyOptions = () => {
        let values = [
            new Option(Currency.AUD, Currency.AUD),
            new Option(Currency.USD, Currency.USD),
        ];
        return new Options(values);
    };

    const onOptionSelect = (field: keyof ProductItemForm) => {
        const accountIdToUse = getFromAccountId(getValues());
        onFilterChange(buildResetCallback(), {...getValues(), account: accountIdToUse}, field);
    };

    const handleDraftChange = (event: any, value: string) => {
        let draftId = value === 'ADD' ? null : value;
        setFormFromDraft(selectDraft(draftId));
    };

    const handleDraftDelete = (value: string) => setFormFromDraft(deleteDraft(value));

    const setFormFromDraft = (draft: PhysicalTradeDraft) => {
        const accountIdToUse = getFromAccountId(draft.form);
        reset(draft.form, { keepDefaultValues: true });
        resetProductFilters(buildResetCallback(), {...draft.form, account:accountIdToUse}, draft.filterOptions);
    };

    const getFromAccountId = (form: PhysicalTradeForm) : string => {
        let accountIdToUse = '';
        if (form.tradeDirection === TradeDirection.SELL) {
            accountIdToUse = inventoryAccount.id;
        } else {
            accountIdToUse = form.counterparty;
        }
        return accountIdToUse
    }

    const onFormClear = () => {
        reset();
        resetProductFilters(buildResetCallback(), getValues());
    };

    const buildResetCallback = () => {
        let draftId = getCurrentDraftId();
        return (fieldName: keyof ResettableFormFields) => {
            // if the user hasn't switched drafts - reset form field
            if (draftId === getCurrentDraftId()) {
                resetField(fieldName);
                return;
            }
            // otherwise update the target draft
            updateDraft(data => data.form[fieldName] = defaultValues[fieldName] as any, draftId);
        };
    };

    // Used to reset the Trade Optimiser field when product is changed in the form
    useEffect(() => {
        if ([ProductType.LGC, ProductType.MiQC].includes(appConfigState.getProduct(currentForm.product)?.displayCode as ProductType)) {
            setValue('tradeOptimiser', TradeOptimiser.DEFAULT)
        }
    }, [productWatch]) // eslint-disable-line react-hooks/exhaustive-deps

    const currentForm = getValues();
    const currentProduct = appConfigState.getProduct(currentForm.product);

    return (
        <form onSubmit={handleSubmit(onSubmitOpen)}>
            <Typography align={'center'} variant='h2'>Physical Trade Ticket</Typography>
            <Box display='flex' justifyContent={'center'} sx={{ mt: -2, mb: 2.5 }}>
                <FormDrafts draftsMetadata={draftsMetadata} onChange={handleDraftChange} onDelete={handleDraftDelete} customTheme={customTheme}/>
            </Box>
            <Container maxWidth='sm'>
                <Grid container spacing={2} mt={-1}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Trade:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller name='tradeDate'
                                        control={control}
                                        rules={{
                                            required: 'Valid trade date is required',
                                            validate: value => value?.isValid() || 'Valid trade date is required',
                                        }}
                                        render={({ field, fieldState }) =>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                                                <DatePicker label='Trade Date'
                                                            format={DATE_DISPLAY_FORMAT}
                                                            onChange={(date: any) => field.onChange(date)}
                                                            value={field.value}
                                                            inputRef={field.ref}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true, size: 'small',
                                                                    required: true,
                                                                    error: !!fieldState?.error,
                                                                    helperText: fieldState?.error?.message,
                                                                },
                                                            }} />
                                            </LocalizationProvider>
                                        }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display='inline-flex'>
                            <Typography margin={1}>Direction: </Typography>
                            <Controller name='tradeDirection'
                                        control={control}
                                        render={({ field }) =>
                                            <ToggleButtonGroup {...field}
                                                               onChange={(event, value) => {
                                                                   if (value == null) return;
                                                                   field.onChange(value);
                                                                   onOptionSelect('account');
                                                               }}
                                                               disabled={availableBalance == null}
                                                               size='small' color='primary'
                                                               sx={{ height: '2.321rem' }}
                                                               exclusive fullWidth>
                                                <ToggleButton key={TradeDirection.SELL}
                                                              value={TradeDirection.SELL}>SELL</ToggleButton>
                                                <ToggleButton key={TradeDirection.BUY}
                                                              value={TradeDirection.BUY}>BUY</ToggleButton>
                                            </ToggleButtonGroup>} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='trader' label='Trader'
                                                 rules={{ required: 'Trader is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='counterparty' label='Counterparty'
                                                 options={getClientOptions()}
                                                 customOnChange={() => onOptionSelect('account')}
                                                 rules={{ required: 'Counterparty is required' }}
                                                 disabled={availableBalance == null}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledTextField name='tradeId' label='Trade ID'
                                                 rules={{
                                                    pattern: {
                                                        // eslint-disable-next-line no-useless-escape
                                                        value: /^[A-Za-z0-9\-_.~();\/?:@&=$#]+$/,
                                                        message: 'Trade ID can only contain alphanumeric characters and - _ . ~ ( ) ; / ? : @ & = $ #',
                                                    }
                                                 }}
                                                 control={control} errors={errors}/>
                        </Grid>
                    </Grid>

                    <Grid item height={sectionSpace}></Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Product:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledTextField name='product' label='Product'
                                options={productOptions}
                                customOnChange={() => onOptionSelect('product')}
                                rules={{ required: 'Product is required' }}
                                control={control} errors={errors} reset={resetField}
                            />
                        </Grid>
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField name='projectType' label='Project Type'
                                options={projectTypeOptions}
                                customOnChange={() => onOptionSelect('projectType')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField name='project' label='Project'
                                options={projectOptions}
                                customOnChange={() => onOptionSelect('project')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField name='vintage' label='Vintage'
                                options={vintageOptions}
                                customOnChange={() => onOptionSelect('vintage')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.ACCU && <Grid item xs={12}>
                            <ControlledTextField name='projectState' label='State'
                                options={projectStateOptions}
                                customOnChange={() => onOptionSelect('projectState')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos} />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.VCU && <Grid item xs={12}>
                            <ControlledTextField name='country' label='Country'
                                options={countryOptions}
                                customOnChange={() => onOptionSelect('country')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}/>
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField name='fuelSource' label='Fuel Source'
                                options={fuelSourceOptions}
                                customOnChange={() => onOptionSelect('fuelSource')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField name='creationYear' label='Creation Year'
                                options={creationYearOptions}
                                customOnChange={() => onOptionSelect('creationYear')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField name='generationYear' label='Generation Year'
                                options={generationYearOptions}
                                customOnChange={() => onOptionSelect('generationYear')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField name='generationState' label='Generation State'
                                options={generationStateOptions}
                                customOnChange={() => onOptionSelect('generationState')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField name='greenPowerAccredited' label='GreenPower Accredited'
                                options={greenPowerAccreditedOptions}
                                customOnChange={() => onOptionSelect('greenPowerAccredited')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField name='segment' label='Segment'
                                options={segmentOptions}
                                customOnChange={() => onOptionSelect('segment')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField name='issueYear' label='Issue Year'
                                options={issueYearOptions}
                                customOnChange={() => onOptionSelect('issueYear')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField name='miqGrade' label='MiQ Grade'
                                options={miqGradeOptions}
                                customOnChange={() => onOptionSelect('miqGrade')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        <Grid item xs={12} mb={-4.5}>
                            <ControlledTextField name='quantity' label='Quantity' integer
                                rules={{
                                    required: 'Quantity is required',
                                    pattern: {
                                        value: /^\d*$/,
                                        message: 'Quantity must be a whole number',
                                    },
                                    min: {
                                        value: 1,
                                        message: 'Quantity must be greater than 0',
                                    },
                                    max: {
                                        value: availableBalance,
                                        message: 'Quantity exceeds available balance',
                                    },
                                }}
                                control={control} errors={errors} />
                            {!balanceError && availableBalance == null
                                ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                : (<Typography variant='caption'
                                    color={balanceError ? 'error' : 'textSecondary'}
                                    mt={1} mb={1}
                                    sx={{ float: 'right' }}>
                                    {balanceError
                                        ? <div>{balanceError}</div>
                                        : <div>Available Balance: <AmountFormatWrapper
                                            amount={availableBalance}
                                            minDecimalPos={productsData.get(getValues().product)?.minDecimalPos!}
                                            maxDecimalPos={productsData.get(getValues().product)?.maxDecimalPos!}
                                        /></div>}
                                </Typography>)}
                        </Grid>
                    </Grid>

                    <Grid item height={sectionSpace}></Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Attributes:</Typography>
                        </Grid>

                        <Grid item xs={12} sm={currentForm.valueDateOption === 'Custom' ? 6 : 12}>
                            <ControlledTextField name='valueDateOption' label='Value Date'
                                                 options={new Options(valueDateOptions)}
                                                 rules={{ required: 'Value Date is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        { currentForm.valueDateOption === 'Custom' && (
                            <Grid item xs={12} sm={6}>
                                <Controller name='customValueDate'
                                            control={control}
                                            rules={{
                                                required: 'Valid value date is required',
                                                validate: value => value?.isValid() || 'Valid value date is required',
                                            }}
                                            render={({ field, fieldState }) =>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                                                    <DatePicker label='Value Date'
                                                                format={DATE_DISPLAY_FORMAT}
                                                                onChange={(date: any) => field.onChange(date)}
                                                                value={field.value}
                                                                inputRef={field.ref}
                                                                slotProps={{
                                                                    textField: {
                                                                        fullWidth: true, size: 'small',
                                                                        required: true,
                                                                        error: !!fieldState?.error,
                                                                        helperText: fieldState?.error?.message,
                                                                    },
                                                                }} />
                                                </LocalizationProvider>
                                            }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='currency' label='Currency'
                                                 options={getCurrencyOptions()}
                                                 rules={{ required: 'Currency is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='price' label='Price' type='number'
                                                 rules={{
                                                     required: 'Price is required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Price must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='salesPerson' label='Sales Person'
                                                 rules={{ required: isSalesPersonRequired && 'Sales person is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='salesCredits' label='Sales Credits' type='number'
                                                 rules={{
                                                     required: isSalesCreditsRequired && 'Sales credits are required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Sales Credits must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='broker' label='Broker'
                                                 rules={{ required: isBrokerRequired && 'Broker is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='brokerageFee' label='Brokerage Fee' type='number'
                                                 rules={{
                                                     required: isBrokerageFeeRequired && 'Brokerage fee is required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Brokerage Fee must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} />
                        </Grid>
                    </Grid>

                    <Grid item height={sectionSpace}></Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                                                 options={getTradeOptimiserOptions(appConfigState.getProduct(currentForm.product)?.displayCode as ProductType)}
                                                 rules={{ required: 'Trade optimiser is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} mt={2} spacing={2}>
                        <Grid item xs={6}>
                            <Button variant='outlined' fullWidth onClick={onFormClear}>Clear</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button disabled={!isValid || !availableBalance}
                                    type='submit'
                                    variant='outlined'
                                    fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <PhysicalTradeSubmitDialog open={isSubmitting}
                                       onClose={onSubmitClose}
                                       form={getValues()}
                                       projectName={projectOptions?.values.find(opt => opt.id === getValues().project)?.label} />
        </form>
    );
};

export { PhysicalTrade, type PhysicalTradeForm };
