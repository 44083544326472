import { Fragment, useEffect, useState } from "react";
import { CurveAmount, CurveEditButtonBlock, CurveInput } from "./CurveComponents";
import { Box, Typography } from "@mui/material";

/**
 * Editable individual price component for the curve marking screen
 *
 * @param label Price label
 * @param currency Currency label
 * @param defaultValue Initial price value
 * @param handleSave Handle save action
 * @param handleEditing Handle the change to and from editing mode
 * @param disableEditing Disables ability to enter editing mode, however if the table is being edited - has no effect
 */
const CurvePrice = (
    {
        label,
        currency,
        defaultValue,
        handleSave,
        handleEditing,
        disableEditing,
    }: {
        label: string,
        currency: string | undefined,
        defaultValue: number,
        handleEditing: (isEditing: boolean) => void
        disableEditing: boolean,
        handleSave: (value: number) => Promise<boolean>
    }) => {
    const [isEditing, setEditing] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [price, setPrice] = useState(0);

    useEffect(() => {
        setPrice(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        handleEditing(isEditing);
    }, [isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleEditClick = () => {
        setPrice(defaultValue);
        setEditing(prevState => !prevState);
    };

    const handleSaveClick = () => {
        setSaving(true);
        handleSave(price).then(success => {
            if (success) {
                setEditing(false);
            }
            setSaving(false);
        });
    };

    return (
        <Fragment>
            {isEditing
                // NB: On edit cancel, we must update defaultValue to reset PriceInput's state.
                // Currently, this is done through toggling isEditing which causes a re-render.
                ? <CurveInput
                    defaultValue={defaultValue}
                    handleChange={setPrice}
                    textFieldProps={{
                        label: label,
                        size: 'small',
                        disabled: isSaving,
                        sx: { width: '200px' },
                    }}
                />
                : <Box width='200px'
                       height='2.321rem'
                       display='inline-flex'
                       justifyContent='space-between'
                       alignItems='center'
                       sx={{ verticalAlign: 'top' }}
                >
                    <Typography align='left'>{label}: </Typography>
                    <Typography align='right'><CurveAmount value={price}/> {currency ? currency : '-'}</Typography>
                </Box>

            }
            <CurveEditButtonBlock
                isEditing={isEditing}
                handleEditClick={handleEditClick}
                isSaving={isSaving}
                handleSaveClick={handleSaveClick}
                disabled={disableEditing}
                style={{ marginLeft: '1rem' }}
            />
        </Fragment>
    );
};

export default CurvePrice;
