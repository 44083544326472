import dayjs from 'dayjs';
var TIMESTAMP_DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm:ss';
var DATE_DISPLAY_FORMAT = 'YYYY-MM-DD';
var MONTH_YEAR_DISPLAY_FORMAT = 'MMM-YYYY';
/**
 * format UTC time to local time using dayjs library
 *
 * @param UTCTimestamp
 * @returns formatted local date time string
 */
var utcToLocalFormat = function (UTCTimestamp, format) {
    if (format === void 0) { format = TIMESTAMP_DISPLAY_FORMAT; }
    return dayjs(UTCTimestamp).format(format);
};
var currentDateExportFormat = function () {
    var currentDate = new Date();
    return utcToLocalFormat(currentDate.toUTCString())
        .replace(/[/:]/g, '')
        .replace(/[/-]/g, '')
        .replace(/[ ]/g, 'T')
        .split('+')[0];
};
var isValidDate = function (dateString) {
    if (!dateString) {
        return false;
    }
    var date = new Date(dateString);
    return !isNaN(date.getTime());
};
export { utcToLocalFormat, currentDateExportFormat, DATE_DISPLAY_FORMAT, TIMESTAMP_DISPLAY_FORMAT, MONTH_YEAR_DISPLAY_FORMAT, isValidDate };
