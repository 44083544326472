var StorageType;
(function (StorageType) {
    StorageType[StorageType["SESSION_STORAGE"] = 0] = "SESSION_STORAGE";
    StorageType[StorageType["LOCAL_STORAGE"] = 1] = "LOCAL_STORAGE";
})(StorageType || (StorageType = {}));
/**
 * A class that's keeping track of the list of storage items that should not be kept around when a user is signed out
 *
 * Exposes {@link LOCAL_STORAGE_KEYS} and {@link SESSION_STORAGE_KEYS}, each being an extensive set of items being
 * stored in the corresponding storage
 */
var StorageItem = /** @class */ (function () {
    function StorageItem(key, type) {
        this.key = key;
        this.type = type;
        var storage = type === StorageType.SESSION_STORAGE
            ? StorageItem.SESSION_STORAGE_KEYS
            : StorageItem.LOCAL_STORAGE_KEYS;
        storage.push(key);
    }
    StorageItem.initItems = function (storageKeyPrefix) {
        StorageItem.PHYSICAL_TRADE_FORM = new StorageItem("".concat(storageKeyPrefix, "PhysicalTradeTicketDataKey"), StorageType.LOCAL_STORAGE);
        StorageItem.FORWARD_TRADE_FORM = new StorageItem("".concat(storageKeyPrefix, "ForwardTradeTicketDataKey"), StorageType.LOCAL_STORAGE);
        StorageItem.RETIREMENT_FORM = new StorageItem("".concat(storageKeyPrefix, "RetirementTicketDataKey"), StorageType.LOCAL_STORAGE);
    };
    StorageItem.removeStorageItems = function () {
        StorageItem.LOCAL_STORAGE_KEYS.forEach(function (key) { return localStorage.removeItem(key); });
        StorageItem.SESSION_STORAGE_KEYS.forEach(function (key) { return sessionStorage.removeItem(key); });
    };
    StorageItem.prototype.getStorage = function () {
        switch (this.type) {
            case StorageType.SESSION_STORAGE:
                return sessionStorage;
            case StorageType.LOCAL_STORAGE:
                return localStorage;
        }
    };
    StorageItem.prototype.getItemValue = function () {
        return this.getStorage().getItem(this.key);
    };
    StorageItem.prototype.setItemValue = function (item) {
        this.getStorage().setItem(this.key, item);
    };
    StorageItem.LOCAL_STORAGE_KEYS = [];
    StorageItem.SESSION_STORAGE_KEYS = [];
    return StorageItem;
}());
// In following two functions, storage items are added here rather than adding as static items in StorageItem since these items will be dynamically determined
// by various components that will use table column selector functionality, based on table name and selected column set.
/**
 * Returns the storage item to store the currently selected column set for a given table
 * @param tableName
 * @param storageKeyPrefix App specifix prefix to distinguish storage items of different apps
 * @returns
 */
var getTableColumnSetStorageItem = function (tableName, storageKeyPrefix) {
    return new StorageItem("".concat(storageKeyPrefix).concat(tableName, "ColumnSet"), StorageType.LOCAL_STORAGE);
};
/**
 * Returns the storage item to store the currently selected column visibility mode for a given table and column set
 * @param tableName
 * @param columnSet
 * @param storageKeyPrefix App specifix prefix to distinguish storage items of different apps
 * @returns
 */
var getTableColumnVisibilityStorageItem = function (tableName, columnSet, storageKeyPrefix) {
    return new StorageItem("".concat(storageKeyPrefix).concat(tableName).concat(columnSet, "ColumnVisibility"), StorageType.LOCAL_STORAGE);
};
export { StorageItem, getTableColumnSetStorageItem, getTableColumnVisibilityStorageItem };
