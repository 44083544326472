var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
var AlertType;
(function (AlertType) {
    AlertType["Error"] = "Error";
    AlertType["Warning"] = "Warning";
})(AlertType || (AlertType = {}));
var AlertDialogContent = function (_a) {
    var alertType = _a.alertType, alertMessage = _a.alertMessage, errorCode = _a.errorCode, handleDialogClose = _a.handleDialogClose;
    return (_jsxs(_Fragment, { children: [_jsxs(DialogContent, { children: [_jsxs(Typography, __assign({ variant: 'h2' }, { children: [" ", alertType === AlertType.Error ? "Error" : alertType === AlertType.Warning ? "Warning" : ""] })), _jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsx(Typography, __assign({ sx: { marginRight: '4px' } }, { children: alertMessage })), alertType === AlertType.Error && errorCode
                                && (_jsxs(Typography, { children: ["Error code: ", errorCode] })), alertType === AlertType.Error && !errorCode
                                && (_jsx(Typography, { children: "Please try again later." }))] }))] }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleDialogClose, color: 'primary', variant: 'outlined' }, { children: "Close" })) })] }));
};
export { AlertType, AlertDialogContent };
