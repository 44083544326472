import Big from 'big.js';
import { FieldDataType, OverviewCategory, renderDocumentLink } from '../FieldDefinitions';
import { CommodityType, getCommodityAmountFieldDefinitionKey } from './CommodityFieldDefinitions';
var calculateTotalGhcEmissions = function (emissionFactor, volume) {
    return emissionFactor && volume ? Big(emissionFactor).times(Big(volume)).toString() : '';
};
var getGasOilFieldDefinitions = function (appConfigState) {
    var getGasOilAttribute = function (attributeName) {
        return appConfigState.getCommodityAttribute('GAS_OIL', attributeName);
    };
    return [
        {
            key: 'fuelType',
            displayName: getGasOilAttribute('FUEL_TYPE').display,
            attribute: getGasOilAttribute('FUEL_TYPE'),
            fixedOptions: [
                { label: 'HSMGO', value: 'HSMGO' },
                { label: 'VLSFO', value: 'VLSFO' },
                { label: 'MGO', value: 'MGO' },
                { label: 'Diesel', value: 'Diesel' },
                { label: 'Motor Gasoline', value: 'Motor Gasoline' },
                { label: 'Jet Gasoline', value: 'Jet Gasoline' }
            ],
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Commodity,
            onChange: function (formData, setValue) {
                var ipccClassification = '';
                var emissionFactor = 0;
                switch (formData['fuelType']) {
                    case 'HSMGO':
                        ipccClassification = 'Gas / Diesel Oil';
                        emissionFactor = 3.197;
                        break;
                    case 'VLSFO':
                        ipccClassification = 'Residential Fuel Oil';
                        emissionFactor = 3.197;
                        break;
                    case 'MGO':
                        ipccClassification = 'Gas / Diesel Oil';
                        emissionFactor = 3.197;
                        break;
                    case 'Diesel':
                        ipccClassification = 'Gas / Diesel Oil';
                        emissionFactor = 3.197;
                        break;
                    case 'Motor Gasoline':
                        ipccClassification = 'Motor Fuel Oil';
                        emissionFactor = 3.081;
                        break;
                    case 'Jet Gasoline':
                        ipccClassification = 'Jet Gasoline';
                        emissionFactor = 3.112;
                        break;
                }
                setValue('ipccClassification', ipccClassification);
                setValue('emissionFactor', emissionFactor);
                var totalGhcEmissions = calculateTotalGhcEmissions(emissionFactor, formData[getCommodityAmountFieldDefinitionKey(CommodityType.GasOil)]);
                setValue('totalGhcEmissions', totalGhcEmissions);
            },
            rules: {
                required: "".concat(getGasOilAttribute('FUEL_TYPE').display, " is required")
            }
        },
        {
            key: 'standard',
            displayName: getGasOilAttribute('STANDARD').display,
            attribute: getGasOilAttribute('STANDARD'),
            fixedOptions: [
                { label: 'IPCC', value: 'IPCC' },
                { label: 'EU ETA', value: 'EU ETA' },
                { label: 'IMO', value: 'IMO' },
                { label: 'EPA', value: 'EPA' }
            ],
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Commodity
        },
        {
            key: 'ipccClassification',
            displayName: getGasOilAttribute('IPCC_CLASSIFICATION').display,
            attribute: getGasOilAttribute('IPCC_CLASSIFICATION'),
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Commodity,
            disabled: true
        },
        {
            key: 'emissionFactor',
            displayName: getGasOilAttribute('EMISSION_FACTOR').display,
            attribute: getGasOilAttribute('EMISSION_FACTOR'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            disabled: true
        },
        {
            key: 'date',
            displayName: getGasOilAttribute('DATE').display,
            attribute: getGasOilAttribute('DATE'),
            dataType: FieldDataType.Date,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: 'Valid date is required',
                validate: function (value) { return (value === null || value === void 0 ? void 0 : value.isValid()) || 'Valid date is required'; },
            }
        },
        {
            key: getCommodityAmountFieldDefinitionKey(CommodityType.GasOil),
            displayName: 'Volume',
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: 'Volume is required',
                validate: function (value) { return value > 0 || 'Volume must be greater than zero'; }
            },
            onChange: function (formData, setValue) {
                var totalGhcEmissions = calculateTotalGhcEmissions(formData['emissionFactor'], formData[getCommodityAmountFieldDefinitionKey(CommodityType.GasOil)]);
                setValue('totalGhcEmissions', totalGhcEmissions);
            }
        },
        {
            key: 'ghcVerification',
            displayName: getGasOilAttribute('GHC_VERIFICATION').display,
            attribute: getGasOilAttribute('GHC_VERIFICATION'),
            dataType: FieldDataType.Boolean,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: "".concat(getGasOilAttribute('GHC_VERIFICATION').display, " is required")
            }
        },
        {
            key: 'verifier',
            displayName: getGasOilAttribute('VERIFIER').display,
            attribute: getGasOilAttribute('VERIFIER'),
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Commodity
        },
        {
            key: 'countryOfOrigin',
            displayName: getGasOilAttribute('COUNTRY_OF_ORIGIN').display,
            attribute: getGasOilAttribute('COUNTRY_OF_ORIGIN'),
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: "".concat(getGasOilAttribute('COUNTRY_OF_ORIGIN').display, " is required")
            }
        },
        {
            key: 'waterContent',
            displayName: getGasOilAttribute('WATER_CONTENT').display,
            attribute: getGasOilAttribute('WATER_CONTENT'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: "".concat(getGasOilAttribute('WATER_CONTENT').display, " is required"),
                validate: function (value) { return value > 0 || "".concat(getGasOilAttribute('WATER_CONTENT').display, " must be greater than zero"); }
            }
        },
        {
            key: 'totalGhcEmissions',
            displayName: getGasOilAttribute('TOTAL_GHC_EMISSIONS').display,
            attribute: getGasOilAttribute('TOTAL_GHC_EMISSIONS'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            disabled: true
        },
        {
            key: 'sulphurContent',
            displayName: getGasOilAttribute('SULPHUR_CONTENT').display,
            attribute: getGasOilAttribute('SULPHUR_CONTENT'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                required: "".concat(getGasOilAttribute('SULPHUR_CONTENT').display, " is required"),
                validate: function (value) { return value > 0 || "".concat(getGasOilAttribute('SULPHUR_CONTENT').display, " must be greater than zero"); }
            }
        },
        {
            key: 'viscosity',
            displayName: getGasOilAttribute('VISCOSITY').display,
            attribute: getGasOilAttribute('VISCOSITY'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                validate: function (value) { return (value > 0 || !value) || "".concat(getGasOilAttribute('VISCOSITY').display, " must be greater than zero"); }
            }
        },
        {
            key: 'coqDensity',
            displayName: getGasOilAttribute('COQ_DENSITY').display,
            attribute: getGasOilAttribute('COQ_DENSITY'),
            dataType: FieldDataType.Decimal,
            overviewCategory: OverviewCategory.Commodity,
            rules: {
                validate: function (value) { return (value > 0 || !value) || "".concat(getGasOilAttribute('COQ_DENSITY').display, " must be greater than zero"); }
            }
        },
        {
            key: 'coqFile',
            displayName: getGasOilAttribute('COQ_FILE').display,
            attribute: getGasOilAttribute('COQ_FILE'),
            dataType: FieldDataType.Document,
            overviewCategory: OverviewCategory.Commodity,
            renderJSX: renderDocumentLink
        },
        {
            key: 'bunkerDeliveryNote',
            displayName: getGasOilAttribute('BUNKER_DELIVERY_NOTE').display,
            attribute: getGasOilAttribute('BUNKER_DELIVERY_NOTE'),
            dataType: FieldDataType.Document,
            overviewCategory: OverviewCategory.Commodity,
            renderJSX: renderDocumentLink
        }
    ];
};
export { getGasOilFieldDefinitions };
