/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MethodScoreFromJSON, MethodScoreToJSON, } from './MethodScore.js';
/**
 * Check if a given object implements the MethodOptimiserScoreMarking interface.
 */
export function instanceOfMethodOptimiserScoreMarking(value) {
    var isInstance = true;
    isInstance = isInstance && "methodScores" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "projectType" in value;
    return isInstance;
}
export function MethodOptimiserScoreMarkingFromJSON(json) {
    return MethodOptimiserScoreMarkingFromJSONTyped(json, false);
}
export function MethodOptimiserScoreMarkingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'methodScores': (json['methodScores'].map(MethodScoreFromJSON)),
        'productId': json['productId'],
        'projectType': json['projectType'],
    };
}
export function MethodOptimiserScoreMarkingToJSON(value) {
    return MethodOptimiserScoreMarkingToJSONTyped(value, false);
}
export function MethodOptimiserScoreMarkingToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'methodScores': (value.methodScores.map(MethodScoreToJSON)),
        'productId': value.productId,
        'projectType': value.projectType,
    };
}
