/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ResolvedOptimiserMethodScoreFromJSON, ResolvedOptimiserMethodScoreToJSON, } from './ResolvedOptimiserMethodScore.js';
/**
 * Check if a given object implements the ResolvedOptimiserScores interface.
 */
export function instanceOfResolvedOptimiserScores(value) {
    var isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "rows" in value;
    isInstance = isInstance && "vintageYears" in value;
    return isInstance;
}
export function ResolvedOptimiserScoresFromJSON(json) {
    return ResolvedOptimiserScoresFromJSONTyped(json, false);
}
export function ResolvedOptimiserScoresFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'endOfDay': !exists(json, 'endOfDay') ? undefined : (new Date(json['endOfDay'])),
        'productId': json['productId'],
        'rows': (json['rows'].map(ResolvedOptimiserMethodScoreFromJSON)),
        'vintageYears': json['vintageYears'],
    };
}
export function ResolvedOptimiserScoresToJSON(value) {
    return ResolvedOptimiserScoresToJSONTyped(value, false);
}
export function ResolvedOptimiserScoresToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'endOfDay': value.endOfDay === undefined ? undefined : (value.endOfDay.toISOString().substring(0, 10)),
        'productId': value.productId,
        'rows': (value.rows.map(ResolvedOptimiserMethodScoreToJSON)),
        'vintageYears': value.vintageYears,
    };
}
