import {
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridFilterItem,
    GridFilterOperator,
} from '@mui/x-data-grid';
import { TransactionIdAnyOfInput, TransactionIdInput } from '../component/TransactionIdInput';
import { DatePickerFilter } from '../component/date-picker/DatePickerFilter';
import IntegerInput from '../component/IntegerInput';
import { InputRangeFilter, InputRangeType, InputRangeView } from '../component/input-range-picker/InputRangeFilter';
import { DataGridFilterOperators, utcToLocalFormat } from '@commodity-desk/common';


// For a 'numeric' type column in MUI datagrid, where we are also making use of the extendedMuiFilterToCoreFilter
// function, allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const extendedNumericFilterOperators = getGridNumericOperators()
    .filter((operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty')
    .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? operator.value === 'isAnyOf' ? operator.InputComponent: IntegerInput : undefined
    }));


const dateRangeFilterOperators = ({showTimeStamp=false, wholeMonths=false}): GridFilterOperator<any, Date, any>[] => {
    return [
        {
            label: 'range',
            value: 'range',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return String(params.value) >= String(filterItem.value);
                };
            },
            InputComponent: DatePickerFilter,
            InputComponentProps: {
                showTimeStamp: showTimeStamp,
                wholeMonths: wholeMonths
            },
            getValueAsString: (value) => {
                const values = value?.split('..');
                const startDate = utcToLocalFormat(values[0]);
                const endDate = utcToLocalFormat(values[1]);
                if (startDate && endDate) {
                    return `${startDate}..${endDate}`;
                }
                return `${startDate}`;
            },
        }
    ];
};

/**
 * Utility function that returns a list of supported custom filters for Transaction ID field
 * @returns a list of supported operators for the Trasnaction ID field
 */
const getTransactionIdFilterOperators = () => {
    const transactionIdEqualsFilter = getGridStringOperators().filter((operator) =>
        operator.value === 'equals'
    ).map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? TransactionIdInput : undefined
    }))

    const transactionIdIsAnyFilter = getGridStringOperators().filter((operator) =>
        operator.value === 'isAnyOf'
    ).map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? TransactionIdAnyOfInput : undefined
    }))

    return [...transactionIdEqualsFilter, ...transactionIdIsAnyFilter]
};

/**
 * Input Range Filter Operators
 * @param {type} type - type of input range filter
 * @param {view} view - view of input range filter
 * @returns input range filter UI with start and end value
 */
const inputRangeFilterOperators = ({
    type,
    view
}: {
    type?: InputRangeType;
    view?:InputRangeView
}): GridFilterOperator<any, Date, any>[] => {
    return [
        {
            label: 'range',
            value: 'range',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return String(params.value) >= String(filterItem.value);
                };
            },
            InputComponent: InputRangeFilter,
            InputComponentProps: {
                type,
                view
            }
        }
    ];
};

// For a 'string' type column in MUI datagrid allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const stringFilterOperators = getGridStringOperators().filter(
    (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
);

// For a 'single-select' type column in MUI datagrid only allow the 'is' operator for filtering.
const singleMatchStringFilterOperator = getGridSingleSelectOperators().filter(
    (operator) => operator.value === 'is'
);

// For a 'numeric' type column in MUI datagrid allow all filtering operations except '>' and '<=', 'isEmpty' and 'isNotEmpty'.
const numericFilterOperators = getGridNumericOperators().filter(
    (operator) =>
        operator.value !== '>' &&
        operator.value !== '<=' &&
        operator.value !== 'isEmpty' &&
        operator.value !== 'isNotEmpty'
);

// For a 'numeric float' type column in MUI datagrid
// Allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const extendedNumericFloatFilterOperators = getGridNumericOperators()
    .filter((operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty')

// For a 'date' or 'datetime' type column in MUI datagrid allow all filtering operations except 'isEmpty', 'isNotEmpty' and 'not'.
const dateFilterOperators = (showTime?: boolean): GridFilterOperator<any, Date, any>[] => {
    return getGridDateOperators(showTime).filter(
        (operator) =>
            operator.value !== 'isEmpty' &&
            operator.value !== 'isNotEmpty' &&
            operator.value !== 'not'
    );
};

/**
 * Contains all possible filter operators used in DataGrid tables. We pass this in when constructing field definitions for the purposes
 * of rendering DataGrid tables. This will be removed when DataGrid is removed. This is defined in admin/client and not in the common
 * module for the same reason that all of the other DataGrid functions and types here have not been moved into the common module yet -
 * due to the issue where defining DataGrid types and functions outside of the main DataGrid component causes an error.
 */
const filterOperators = {
    dateRangeFilterOperators: dateRangeFilterOperators,
    extendedNumericFilterOperators: extendedNumericFilterOperators,
    extendedNumericFloatFilterOperators: extendedNumericFloatFilterOperators,
    inputRangeFilterOperators: inputRangeFilterOperators,
    stringFilterOperators: stringFilterOperators,
    singleSelectFilterOperators: getGridSingleSelectOperators,
    transactionIdFilterOperators: getTransactionIdFilterOperators
} as DataGridFilterOperators;

export {
    extendedNumericFilterOperators,
    dateRangeFilterOperators,
    getTransactionIdFilterOperators,
    inputRangeFilterOperators,
    stringFilterOperators,
    singleMatchStringFilterOperator,
    numericFilterOperators,
    extendedNumericFloatFilterOperators,
    dateFilterOperators,
    filterOperators
};
