import { AttributeCriteria, extendedMuiFilterToCoreFilter } from '@commodity-desk/common';
import { ColumnFilter } from '@trovio-ui-libs/ui';

/**
 * dataTableFilterToCoreFilter - Convert DataTable filter to Core filter
 * TODO: Create a generic filter handler which support core10 filters
 * Currently utilizing the extendedMuiFilterToCoreFilter to manage and match the filter object
 */
const dataTableFilterToCoreFilter = (filters: ColumnFilter[]) => {
    return filters.map((filter) => ({
        code: filter.queryKey || filter.id,
        value: extendedMuiFilterToCoreFilter({
            item: {
                operator: filter.operator,
                value: filter.value
            } as any
        })
    })) as AttributeCriteria[];
};

export { dataTableFilterToCoreFilter };
