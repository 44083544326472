import { jsx as _jsx } from "react/jsx-runtime";
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { FieldDataType, OverviewCategory } from './FieldDefinitions';
/**
 * Get a custom field definition based on a product amount
 * @param key               The FieldDefinitionKey to use
 * @param displayName       The display label
 * @param columnMinWidth    The minimum width to use if showing this data in a table
 * @param productsData      The set of product data that is active for this application
 * @param selectedProductId The currently selected product ID. Optional. If set, dataType will correctly reflect the decimal places configured for the product.
 *                          If not set, will default to 'Decimal'
 * @returns
 */
var getCustomProductAmountFieldDefinition = function (_a) {
    var _b, _c;
    var key = _a.key, displayName = _a.displayName, columnMinWidth = _a.columnMinWidth, productsData = _a.productsData, selectedProductId = _a.selectedProductId;
    return {
        key: key,
        displayName: displayName,
        dataType: selectedProductId === undefined || ((_c = (_b = productsData.get(selectedProductId)) === null || _b === void 0 ? void 0 : _b.maxDecimalPos) !== null && _c !== void 0 ? _c : 1) > 0 ? FieldDataType.Decimal : FieldDataType.Integer,
        overviewCategory: OverviewCategory.Other,
        filterable: false,
        columnMinWidth: columnMinWidth,
        renderJSX: function (params) {
            var _a, _b;
            return _jsx(AmountFormatWrapper, { amount: params.value, minDecimalPos: (_a = productsData.get(params.row.productId)) === null || _a === void 0 ? void 0 : _a.minDecimalPos, maxDecimalPos: (_b = productsData.get(params.row.productId)) === null || _b === void 0 ? void 0 : _b.maxDecimalPos });
        },
    };
};
export { getCustomProductAmountFieldDefinition };
