import { homepage } from '../state/Variables';
import { Aggregator } from '../view/aggregator/Aggregator';
import CertificateDetails from '../view/inventoryManagement/CertificateDetails';
import ClientManagement from '../view/clientManagement/ClientManagement';
import ContainCommodities from '../view/commodities/ContainCommodities';
import ContainInventoryManagement from '../view/inventoryManagement/ContainInventoryManagement';
import ContainProjectDetails from '../view/inventoryManagement/ContainProjectDetails';
import ContainProjectVintageDetails from '../view/inventoryManagement/ContainProjectVintageDetails';
import { PhysicalTrade } from '../view/trade/physical/PhysicalTrade';
import { ForwardTrade } from '../view/trade/forward/ForwardTrade';
import { RetirementTrade } from '../view/trade/retirement/RetirementTrade';
import TradeBlotter from '../view/tradeBlotter/TradeBlotter';
import TradingRiskPosition from '../view/tradingRisk/TradingRiskPosition';
import TradingRisk from '../view/tradingRisk/TradingRisk';
import Curve from "../view/prices/Curve";
import InventoryScores from "../view/inventoryScore/InventoryScores";

export const APP_ROUTES = {
    homepage: {
        label: 'Home',
        path: '/',
        component: <></>
    },
    dashboard: {
        inventoryManagement: {
            label: 'Inventory',
            path: `${homepage}/inventory-management`,
            component: <ContainInventoryManagement />
        },
        inventoryProjectDetails: {
            label: 'Project Details',
            path: `${homepage}/inventory-management/product/:productID/project/:projectID/*`,
            component: <ContainProjectDetails />
        },
        inventoryVintageDetails: {
            label: 'Vintage Details',
            path: `${homepage}/inventory-management/product/:productID/project/:projectID/vintages/:year`,
            component: <ContainProjectVintageDetails />
        },
        certificateDetails: {
            label: 'Certificate Details',
            path: `${homepage}/inventory-management/product/:productID/certificate/:baseAttribute/*`,
            component: <CertificateDetails />
        },
        physicalTrade: {
            label: 'Physical Trade',
            path: `${homepage}/physical-trade`,
            component: <PhysicalTrade />
        },
        forwardTrade: {
            label: 'Forward Trade',
            path: `${homepage}/forward-trade`,
            component: <ForwardTrade />
        },
        retirementTrade: {
            label: 'Retirement',
            path: `${homepage}/retirement`,
            component: <RetirementTrade />
        },
        risk: {
            label: 'Risk',
            path: `${homepage}/risk`,
            component: <TradingRisk />
        },
        riskPosition: {
            label: 'Risk Position',
            path: `${homepage}/risk/position/*`,
            component: <TradingRiskPosition />
        },
        tradeBlotter: {
            label: 'Trade Blotter',
            path: `${homepage}/trade-blotter`,
            component: <TradeBlotter />
        },
        clientManagement: {
            label: 'Clients',
            path: `${homepage}/client-management`,
            component: <ClientManagement />
        },
        curve: {
            label: 'Curve',
            path: `${homepage}/curve`,
            component: <Curve />
        },
        inventoryScore: {
            label: 'Inventory Scores',
            path: `${homepage}/inventory-scores`,
            component: <InventoryScores />
        },
        commodities: {
            label: 'Commodities',
            path: `${homepage}/commodities`,
            component: <ContainCommodities />
        },
        aggregator: {
            label: 'Aggregator',
            path: `${homepage}/aggregator`,
            component: <Aggregator />
        }
    }
};
