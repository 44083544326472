var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { truncateHumanName, truncateLongId } from '../../utility/ProjectUtils';
import { RenderLocation, NO_DATA_INDICATOR } from '../fieldDefinitions/FieldDefinitions';
/**
 * Used to render a 2-decimal place currency amount
 * @param params The input parameters
 * @returns JSX
 */
var renderCurrencyAmount = function (params) {
    return _jsx(_Fragment, { children: params.value ? (_jsx(AmountFormatWrapper, { amount: params.value, minDecimalPos: 2, maxDecimalPos: 2 })) : (_jsx(_Fragment, { children: NO_DATA_INDICATOR })) });
};
var copyElement = function (value) { return (_jsx(Tooltip, __assign({ title: "Copy to clipboard" }, { children: _jsx(IconButton, __assign({ size: "small", sx: {
            color: 'primary.main'
        }, onClick: function () { return navigator.clipboard.writeText((value === null || value === void 0 ? void 0 : value.toString()) || ''); } }, { children: _jsx(FontAwesomeIcon, { icon: faCopy }) })) }))); };
/**
 * Renders the provided ID field in truncated form, along with a copy button next to the field (when shown on the
 * overview dialog), which will copy the value when clicked. Shows a tooltip to display the full un-truncated value.
 * @param params The cell parameters
 * @returns JSX
 */
var renderTruncatedIdField = function (params) {
    if (params.value === undefined) {
        return _jsx(_Fragment, { children: NO_DATA_INDICATOR });
    }
    else if (params.renderLocation === RenderLocation.OverviewDialog) {
        return (_jsx(Tooltip, __assign({ title: params.value.toString(), arrow: true, placement: "top" }, { children: _jsxs(Typography, { children: [truncateLongId(params.value.toString()), copyElement(params.value)] }) })));
    }
    else {
        return (_jsx(Tooltip, __assign({ title: params.value, arrow: true }, { children: _jsx(Typography, { children: truncateLongId(params.value) }) })));
    }
};
/**
 * Used to render a copy button next to the field in the overview dialog, which will copy the value when clicked
 * @param params The cell parameters
 * @returns JSX
 */
var renderWithCopyButton = function (params) {
    if (params.value === undefined) {
        return _jsx(_Fragment, { children: NO_DATA_INDICATOR });
    }
    else if (params.renderLocation === RenderLocation.OverviewDialog) {
        return (_jsxs(_Fragment, { children: [params.value.toString(), copyElement(params.value)] }));
    }
    else {
        return _jsx(_Fragment, { children: params.value });
    }
};
/**
 * Used to render a truncated human name in a cell of a table. The name is typically first name plus last name,
 * although any format of name can be used here as this is a user entered field. For the overview dialog, we
 * continue to render the full name, which will be wrapped appropriately in the dialog itself.
 * @param params The cell parameters
 * @returns JSX
 */
var renderTruncatedHumanName = function (params) {
    if (params.value === undefined) {
        return _jsx(_Fragment, { children: NO_DATA_INDICATOR });
    }
    else if (params.renderLocation === RenderLocation.OverviewDialog) {
        return _jsx(_Fragment, { children: params.value });
    }
    else {
        return (_jsx(Tooltip, __assign({ title: params.value, arrow: true }, { children: _jsx(Typography, __assign({ sx: { minWidth: '150px', maxWidth: '180px' } }, { children: truncateHumanName(params.value) })) })));
    }
};
export { renderWithCopyButton, renderCurrencyAmount, renderTruncatedHumanName, renderTruncatedIdField };
