import { AppConfigContextType, carbonProjectAttributes } from '@commodity-desk/common';

/**
 * Aggregator Attribute data structure
 * @property {string} displayName The name to display an attribute as in the UI
 * @property {string} cortenName The actual corten attribute name
 */
interface AggregatorAttribute {
    displayName: string;
    cortenName: string;
}

/**
 * Function to get an array of @see AggregatorAttribute to use in our app
 * @param {AppConfigContextType} appConfigState the state from which to derive the project attributes
 * @returns an array of @see AggregatorAttribute
 */
const getAttributes = (appConfigState: AppConfigContextType): AggregatorAttribute[] => {
    return [
        {
            displayName: 'Project Type',
            cortenName: carbonProjectAttributes.projectType.key
        },
        {
            displayName: 'Project Vintage',
            cortenName: carbonProjectAttributes.vintage.key
        },
        {
            displayName: 'Project Name',
            cortenName: carbonProjectAttributes.projectName.key
        },
        {
            displayName: 'Country Code',
            cortenName: carbonProjectAttributes.country.key
        },
        {
            displayName: 'Serial Number Start',
            cortenName: carbonProjectAttributes.projectSerialNumberStart.key
        }
    ]
}

export { type AggregatorAttribute, getAttributes}