import {
    DialogContent,
    Drawer,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useUnderlyingDispatch, useUnderlyingState } from './state/UnderlyingState';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import UnderlyingHoldingDialog from './UnderlyingHoldingDialog';

const UnderlyingDrawer = () => {
    const { isUnderlyingDrawerOpen, selectedDecarbItem, underlyingAssets, loadingUnderlying, getProductDisplayCode } =
        useUnderlyingState();
    const { setIsUnderlyingDrawerOpen, handleOpenHoldingDetails } = useUnderlyingDispatch();
    const theme = useTheme();

    return (
        <>
            <Drawer
                anchor="right"
                open={isUnderlyingDrawerOpen}
                onClose={() => setIsUnderlyingDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        width: '75%',
                        maxWidth: '1000px'
                    }
                }}
            >
                <DialogContent>
                    <Typography variant="h2">
                        Decarbonised Emission Asset {selectedDecarbItem?.id}
                    </Typography>
                    {loadingUnderlying ? (
                        <LinearProgress />
                    ) : (
                        <>
                            {underlyingAssets?.length > 0 ? (
                                <>
                                    <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                        Underlying
                                    </Typography>

                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>State </TableCell>
                                                        <TableCell>Product</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell align="center">Info</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {underlyingAssets.map((item, index) => (
                                                        <TableRow hover key={index}>
                                                            <TableCell>{item.holdingId}</TableCell>
                                                            <TableCell>{item.state}</TableCell>
                                                            <TableCell>
                                                                {getProductDisplayCode(item)}
                                                            </TableCell>
                                                            <TableCell>{item.amount}</TableCell>

                                                            <TableCell align="center">
                                                                <IconButton
                                                                    aria-label="info"
                                                                    sx={{
                                                                        color: theme.palette.primary
                                                                            .main
                                                                    }}
                                                                    onClick={() =>
                                                                        handleOpenHoldingDetails(item)
                                                                    }
                                                                >
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    {/* Dialog to show holding info of underlying baked product item */}
                                    <UnderlyingHoldingDialog />
                                </>
                            ) : (
                                <Typography>No decarbonised emissions to show</Typography>
                            )}
                        </>
                    )}
                </DialogContent>
            </Drawer>
        </>
    );
};

export default UnderlyingDrawer;
