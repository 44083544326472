/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the CurveMarking interface.
 */
export function instanceOfCurveMarking(value) {
    var isInstance = true;
    isInstance = isInstance && "methodologyBases" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productPrice" in value;
    isInstance = isInstance && "projectVintageBases" in value;
    isInstance = isInstance && "vintageMethodologyBases" in value;
    return isInstance;
}
export function CurveMarkingFromJSON(json) {
    return CurveMarkingFromJSONTyped(json, false);
}
export function CurveMarkingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'methodologyBases': json['methodologyBases'],
        'productId': json['productId'],
        'productPrice': json['productPrice'],
        'projectVintageBases': json['projectVintageBases'],
        'vintageMethodologyBases': json['vintageMethodologyBases'],
    };
}
export function CurveMarkingToJSON(value) {
    return CurveMarkingToJSONTyped(value, false);
}
export function CurveMarkingToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': value.currency,
        'methodologyBases': value.methodologyBases,
        'productId': value.productId,
        'productPrice': value.productPrice,
        'projectVintageBases': value.projectVintageBases,
        'vintageMethodologyBases': value.vintageMethodologyBases,
    };
}
