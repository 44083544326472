import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useNavigate } from "react-router-dom";
var getDestination = function (url, homepage) {
    if (url.startsWith("/") && url !== homepage) {
        return homepage + url;
    }
    else {
        return url;
    }
};
var useAppNavigate = function (_a) {
    var homepage = _a.homepage, navigate = _a.navigate;
    return function (url) { return url && navigate(getDestination(url, homepage)); };
};
function AppNavigate(_a) {
    var to = _a.to, homepage = _a.homepage;
    return _jsx(Navigate, { to: to && getDestination(to, homepage) });
}
export { useAppNavigate, AppNavigate };
