var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { extendedMuiFilterToCoreFilter } from '../../utility/ProjectUtils';
import { FieldDataType, getFieldDefinitions, defaultValueGetter, formatDisplayValue, renderJSXValue } from './FieldDefinitions';
/**
 * Get the full list of all possible column definitions. Based on the full list of field definitions, after mapping these to column
 * definitions and resolving cases where multiple fields map to the same column.
 * @param appConfigState   The application config state, used to retrieve application settings such as attribute keys
 * @param productsData     The product data that is currently in use, containing the number of decimal places to render for each product
 * @param filterOperators  A set of filter operators that can be used by columns of data in a table
 * @returns An array of column definitions in DataGrid compatible format
 */
var getDataGridColumnDefinitions = function (appConfigState, productsData, filterOperators) {
    return getFieldDefinitions({ appConfigState: appConfigState, productsData: productsData, filterOperators: filterOperators }).map(function (fieldDefinition) { return fieldDefinitionToDataGridColumnDefinition(fieldDefinition, filterOperators); }).filter(
    // Filter out duplicate column definitions, which can result from multiple field definitions mapping to the same column (same sharedColumnId)
    function (value, index, self) { return index === self.findIndex(function (t) { return t.field === value.field; }); });
};
/**
 * Maps a {@link FieldDefinition} to a column definition in DataGrid format, used to add a column to a DataGrid table
 */
function fieldDefinitionToDataGridColumnDefinition(fieldDefinition, filterOperators) {
    var _a, _b, _c, _d, _e;
    return {
        field: (_a = fieldDefinition.sharedColumnId) !== null && _a !== void 0 ? _a : fieldDefinition.key,
        headerName: fieldDefinition.displayName,
        minWidth: fieldDefinition.columnMinWidth,
        flex: 1,
        type: fieldTypeToDataGridType(fieldDefinition.dataType),
        sortable: (_b = fieldDefinition.sortable) !== null && _b !== void 0 ? _b : true,
        filterable: (_c = fieldDefinition.filterable) !== null && _c !== void 0 ? _c : true,
        filterOperators: (_e = (_d = fieldDefinition.dataGridDefinitions) === null || _d === void 0 ? void 0 : _d.customFilterOperators) !== null && _e !== void 0 ? _e : fieldFilterOperators(fieldDefinition.dataType, filterOperators),
        valueOptions: fieldValueOptions(fieldDefinition),
        valueGetter: function (_a) {
            var value = _a.value;
            return defaultValueGetter(fieldDefinition.dataType, value);
        },
        valueFormatter: function (_a) {
            var value = _a.value;
            return formatDisplayValue(fieldDefinition, value);
        },
        renderCell: renderJSXValue(fieldDefinition)
    };
}
/**
 * Determines the filter operators that should apply when adding a column to a DataGrid table, given the type of the field (column)
 * @param fieldType        The type of the field
 * @param filterOperators  The set of all possible filter operators that can apply
 * @returns An array of filter operators
 */
var fieldFilterOperators = function (fieldType, filterOperators) {
    if (fieldType === FieldDataType.FixedOptions) {
        return filterOperators.singleSelectFilterOperators();
    }
    else if (fieldType === FieldDataType.Integer) {
        return __spreadArray(__spreadArray([], filterOperators.extendedNumericFilterOperators, true), filterOperators.inputRangeFilterOperators({ type: 'Integer' }), true);
    }
    else if (fieldType === FieldDataType.Decimal) {
        return __spreadArray(__spreadArray([], filterOperators.extendedNumericFloatFilterOperators, true), filterOperators.inputRangeFilterOperators({ type: 'Decimal' }), true);
    }
    else if (fieldType === FieldDataType.Timestamp) {
        return filterOperators.dateRangeFilterOperators({ showTimeStamp: true });
    }
    else if ([FieldDataType.Date, FieldDataType.DateYearMonth].includes(fieldType)) {
        return filterOperators.dateRangeFilterOperators({ showTimeStamp: false });
    }
    else if (fieldType === FieldDataType.Boolean) {
        return filterOperators.singleSelectFilterOperators().filter(function (operator) { return operator.value !== 'not' && operator.value !== 'isAnyOf'; });
    }
    return filterOperators.stringFilterOperators;
};
/**
 * Determines the value options that should apply to a column of data in a DataGrid table, if any. Value options provide the full possible
 * set of values that can be encountered in the data for that column. Used to show a drop down select box with specific options
 * when filtering, as opposed to an open-ended text input box
 * @param fieldDefinition The field definition to retrieve value options for
 * @returns A list of value options, containing value and label
 */
var fieldValueOptions = function (fieldDefinition) {
    if (fieldDefinition.fixedOptions) {
        return fieldDefinition.fixedOptions.map(function (fixedOption) { return fixedOption; });
    }
    else if (fieldDefinition.dataType === FieldDataType.Boolean) {
        return [
            { value: 'true', label: 'true' },
            { value: 'false', label: 'false' }
        ];
    }
    return undefined;
};
/**
 * Determines the data type for a field, in DataGrid format
 * Will return undefined for string types, which is the default used in DataGrid
 * @param fieldType The field type to map to table data type
 * @returns A string describing the table data type
 */
var fieldTypeToDataGridType = function (fieldType) {
    if ([FieldDataType.FixedOptions, FieldDataType.Boolean].includes(fieldType)) {
        return 'singleSelect';
    }
    else if ([FieldDataType.Integer, FieldDataType.Decimal].includes(fieldType)) {
        return 'number';
    }
    else if (fieldType === FieldDataType.Timestamp) {
        return 'dateTime';
    }
    else if ([FieldDataType.Date, FieldDataType.DateYearMonth].includes(fieldType)) {
        return 'date';
    }
    return undefined;
};
/**
 * A convenience function to get the filter value to use in a core10 query, given a GridFilterItem and the field definition
 * @param item A GridFilterItem, obtained by filtering on a column in DataGrid
 * @param filterField The {@link FieldDefinition} corresponding to the column that is being filtered on
 * @returns a string, or a string[] containing the filter strings that can be added to a core10 query
 */
var getCoreFilterValue = function (item, filterField) {
    return extendedMuiFilterToCoreFilter({
        item: item,
        isDate: [FieldDataType.Date, FieldDataType.DateYearMonth, FieldDataType.Timestamp].includes(filterField.dataType),
        unitAmount: filterField.dataType === FieldDataType.Integer ? 1 : filterField.unitAmount
    });
};
export { fieldDefinitionToDataGridColumnDefinition, getDataGridColumnDefinitions, getCoreFilterValue };
