// eslint-disable-next-line import/no-anonymous-default-export
export default {
    entries<K, V>(map: Map<K, V> | undefined): [K, V][] {
        return map ? Array.from(map.entries()) : [];
    },

    values<V>(map: Map<any, V> | undefined): V[] {
        return map ? Array.from(map.values()) : [];
    },

    keys<K>(map: Map<K, any> | undefined): K[] {
        return map ? Array.from(map.keys()) : [];
    },

    innerKeys<K>(map: Map<any, Map<K, any>> | undefined): K[] {
        let innerKeys = new Set<K>();
        this.values(map).flatMap(this.keys).forEach(key => innerKeys.add(key));
        return Array.from(innerKeys);
    },

    deepCopy<K1, K2, V>(rows: Map<K1, Map<K2, V>>) {
        const copy = new Map<K1, Map<K2, V>>();
        this.entries(rows).forEach(([key, value]) => copy.set(key, new Map(value)));
        return copy;
    },
};
