import Commodities from './Commodities';
import { DecarboniseProvider } from './state/DecarboniseState';
import { CommoditiesProvider } from './state/CommoditiesState';
import { UnderlyingProvider } from './state/UnderlyingState';

const ContainCommodities = () => {
    return (
        <CommoditiesProvider>
            <DecarboniseProvider>
                <UnderlyingProvider>
                    <Commodities />
                </UnderlyingProvider>
            </DecarboniseProvider>
        </CommoditiesProvider>
    );
};

export default ContainCommodities;
