import Big from 'big.js';
/**
 * Function to derive the unitAmount for a product
 * @param productData {ProductData} the data for the product
 *
 * @return {number} The unit amount for the product
 */
var getProductUnitAmount = function (productData) {
    // Return the unitAmount calculated as 10 ^ (-1 * maxDecimalPos)
    return Big(10)
        .pow(-1 * productData.maxDecimalPos)
        .toNumber();
};
export { getProductUnitAmount };
