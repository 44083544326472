var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
/**
 * Responsible for displaying a dialog with tabs
 *
 * @param title : title of the dialog
 * @param isLoading : boolean to indicate whether the dialog is loading
 * @param open : boolean to indicate whether the dialog is open
 * @param onClose : callback function to close the dialog
 * @param tabs : array of tabs to display, based on array tab will be created dynamically
 * @returns Dialog with tabs
 */
var TransactionOverviewWithTab = function (_a) {
    var title = _a.title, isLoading = _a.isLoading, open = _a.open, onClose = _a.onClose, tabs = _a.tabs, customTheme = _a.customTheme;
    var hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };
    var _b = useState(0), tabValue = _b[0], setTabValue = _b[1];
    var onTabChange = function (event, value) {
        setTabValue(value);
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: onClose, fullWidth: true, maxWidth: "md", scroll: "paper" }, { children: [_jsxs(DialogContent, __assign({ style: { minHeight: '50vh' } }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: title })), _jsx(Box, __assign({ sx: { borderBottom: 1, borderColor: 'divider', marginBottom: 1 } }, { children: _jsx(Tabs, __assign({ value: tabValue, onChange: onTabChange }, { children: tabs === null || tabs === void 0 ? void 0 : tabs.map(function (tab, index) { return (_jsx(Tab, { label: tab.label, value: index, sx: __assign(__assign({}, hoverTabTextColor), { transition: customTheme.customProps.navHoverTransition, '&:hover': {
                                        backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                    } }) }, index)); }) })) })), isLoading ? (_jsx(LinearProgress, {})) : (_jsx(_Fragment, { children: tabs === null || tabs === void 0 ? void 0 : tabs.map(function (tab, index) {
                            if (tabValue === index) {
                                return _jsx(Box, { children: tab.contents }, index);
                            }
                            return null;
                        }) }))] })), _jsx(DialogActions, __assign({ sx: { p: 3, pt: 0 } }, { children: _jsx(Button, __assign({ onClick: onClose, color: "primary", variant: "outlined" }, { children: "Close" })) }))] })));
};
export default TransactionOverviewWithTab;
