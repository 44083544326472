import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { TableCellProps } from "@mui/material/TableCell/TableCell";
import { AmountFormatWrapper } from '@commodity-desk/common';
import { ResolvedOptimiserScores } from "@commodity-desk/commodity-desk-api-js";

/**
 * Table component for computed inventory scores
 * @param rows  Table data
 */
const ComputedInventoryScoreTable = ({ rows }: { rows: ResolvedOptimiserScores }) => {

    let allScores = rows.rows.flatMap(r => r.vintageScores.map(v => v.resolvedScore));
    let minValue = Math.min(...allScores);
    let maxValue = Math.max(...allScores);
    let colourScale = new ColourScale(minValue, maxValue, ["#d93d3d", "#fcf751", "#47b536"], 1);
    if (minValue === 0 && maxValue === 0) {
        // When no scores are defined, use a generic grey background colour for all cells
        colourScale = new ColourScale(0, 1, ["#aaaaaa", "#ffffff"], 1);
    }

    return (
        <>
            <Typography variant='h3' mt={5}>Current Scores</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: '50px' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <ComputedScoreTableCell></ComputedScoreTableCell>
                            <ComputedScoreTableCell align='right'>Vintage</ComputedScoreTableCell>
                            {rows.vintageYears.map(vintage =>
                                <ComputedScoreTableCell key={"header-" + vintage} align='right'>{vintage}</ComputedScoreTableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <ComputedScoreTableCell>Project Type</ComputedScoreTableCell>
                            {rows.vintageYears.map(vintage =>
                                <ComputedScoreTableCell></ComputedScoreTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.rows.map(row =>
                            <TableRow key={row.projectType + "-" + row.method}>
                                <ComputedScoreTableCell key={row.projectType + "-" + row.method + "-1"}>{row.projectType}</ComputedScoreTableCell>
                                <ComputedScoreTableCell key={row.projectType + "-" + row.method + "-2"}></ComputedScoreTableCell>
                                {row.vintageScores.map((vintageScore) => (
                                    <ComputedScoreTableCell
                                        key={row.projectType + "-" + row.method + "-" + vintageScore.vintageYear}
                                        align='right'
                                        sx={{
                                            color: '#222',
                                            backgroundColor: colourScale.getColour(vintageScore.resolvedScore).toHexString()
                                        }}
                                    >
                                        <ComputedAmount value={vintageScore.resolvedScore} />
                                    </ComputedScoreTableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

/**
 * Pre-formatted cell element for the computed score table component
 *
 * @param height Height prop
 * @param sx SX prop
 * @param children Children prop
 * @param rest Other properties for the underlying <TableCell/> component
 */
const ComputedScoreTableCell = (
    {
        height = '45px',
        sx = { width: '80px' },
        children,
        ...rest
    }: TableCellProps) => {
    return <TableCell height={height} sx={sx} {...rest}>{children}</TableCell>
};

const ComputedAmount = ({ value }: { value: number }) => {
    return <AmountFormatWrapper amount={value} minDecimalPos={2} maxDecimalPos={2}/>
};

class Colour {
    public r: number;
    public g: number;
    public b: number;
    public a: number;

    constructor(r: number, g: number, b: number, a: number = 1) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    toHexString() {
        const r = Math.floor(this.r * this.a);
        const g = Math.floor(this.g * this.a);
        const b = Math.floor(this.b * this.a);
        return `#${this.toHex(r)}${this.toHex(g)}${this.toHex(b)}`;
    }

    toHex(c: number) {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }
}


function hexToColour(hex: string, alpha: number) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return new Colour(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), alpha);
    } else {
        throw new Error(`${hex} is not a valid hex colour.`);
    }
}

class ColourScale {
    private min: number;
    private max: number;
    private alpha: number;
    private stops: Colour[];

    constructor(min: number, max: number, stops: string[], alpha: number = 1) {
        this.min = min;
        this.max = max;
        this.alpha = alpha;
        this.stops = stops.map((stop) => hexToColour(stop, alpha));
    }

    getColour(value: number) {
        const numStops = this.stops.length;
        if (value < this.min) return this.stops[0];
        if (value > this.max) return this.stops[numStops - 1];

        const range = this.max - this.min;
        let weight = (value - this.min) / range;
        const stopIdx = Math.max(Math.ceil(weight * (numStops - 1)), 1);

        const minColour = this.stops[stopIdx - 1];
        const maxColour = this.stops[stopIdx];

        weight = weight * (numStops - 1) - (stopIdx - 1);

        const r = Math.floor(weight * maxColour.r + (1 - weight) * minColour.r);
        const g = Math.floor(weight * maxColour.g + (1 - weight) * minColour.g);
        const b = Math.floor(weight * maxColour.b + (1 - weight) * minColour.b);

        return new Colour(r, g, b, this.alpha);
    }
}

export { ComputedInventoryScoreTable };
