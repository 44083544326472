import { Alert, Box, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import {
    fetchComputedInventoryScores,
    fetchInventoryScoreMarking,
    saveInventoryScoreMarking,
    useAppConfigState, useCommodityDeskApiContext
} from '@commodity-desk/common';
import { InventoryScoreMarkingTable } from "./InventoryScoreMarkingTable";
import { ComputedInventoryScoreTable } from "./ComputedInventoryScoreTable";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { InventoryScoreTagger } from "./InventoryScoreTagger";
import {
    BaseOptimiserScoreMarking,
    ResolvedOptimiserScores,
    ResponseError
} from '@commodity-desk/commodity-desk-api-js';

/**
 * Top-level application component, allowing the user to modify marked inventory scores
 */
const InventoryScores = () => {
    const config = useAppConfigState();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const [inventoryScores, setInventoryScores] = useState<BaseOptimiserScoreMarking>();
    const [computedScores, setComputedScores] = useState<ResolvedOptimiserScores>();
    const [isEditing, setEditing] = useState(false);
    const [productId, setProductId] = useState(config.getProducts(true)[0].id);
    const inventoryScoresAbortController = useRef(new AbortController());
    const computedScoresAbortController = useRef(new AbortController());

    useEffect(() => {
        setInventoryScores(undefined);
        setComputedScores(undefined);
        loadInventoryScores(productId);
    }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadComputedScores(productId);
    }, [inventoryScores]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadInventoryScores = (productId: string): void => {
        setEditing(false);
        inventoryScoresAbortController.current.abort();
        inventoryScoresAbortController.current = new AbortController();
        fetchInventoryScoreMarking({
            productId: productId,
            endOfDay: undefined,
            abortSignal: inventoryScoresAbortController.current.signal,
            api: commodityDeskApi
        }).then(response => {
            if (response) {
                setInventoryScores(response);
            }
        });
    };

    const loadComputedScores = (productId: string): void => {
        setEditing(false);
        computedScoresAbortController.current.abort();
        computedScoresAbortController.current = new AbortController();
        fetchComputedInventoryScores({
            productId: productId,
            endOfDay: undefined,
            abortSignal: computedScoresAbortController.current.signal,
            api: commodityDeskApi
        }).then(response => {
            if (response) {
                setComputedScores(response);
            }
        })
    };

    const saveScores = async (inventoryScoreMarking: any, endOfDay: boolean = false): Promise<boolean> => {
        const eodType = endOfDay ? 'EOD' : 'CURRENT';
        try {
            await saveInventoryScoreMarking({
                marking: inventoryScoreMarking,
                endOfDay: endOfDay,
                api: commodityDeskApi
            });
        } catch (e) {
            if (e instanceof ResponseError) {
                toast(<Alert severity='error'>{`Failed to save ${eodType} Inventory Score Marking - ${await e.response.text()}`}</Alert>);
            } else {
                console.error(`Failed to save ${eodType} Inventory Score Marking - ${e}`)
                toast(<Alert severity='error'>{`Failed to save ${eodType} Inventory Score Marking`}</Alert>);
            }
            return false;
        }

        toast(<Alert severity='success'>{eodType} Inventory Score Marking saved successfully</Alert>);
        // reset state to force a render, updating "initial" values for all subcomponents
        setInventoryScores({ ...inventoryScoreMarking });
        return true;
    };

    return (
        <>
            <Box sx={{ maxWidth: 'lg', margin: '0 0 auto' }}>
                <Typography variant='h2'>Inventory Score Marking</Typography>
                <Typography variant='h3' mt={2} mb={4}>Configure Score Grid</Typography>
                <TextField
                    label='Product'
                    value={productId}
                    onChange={event => setProductId(event.target.value)}
                    select size='small' sx={{ marginRight: '1rem', width: 200 }}
                >
                    {config.getProducts(true).map((product) =>
                        <MenuItem key={product.id} value={product.id}>{product.displayCode}</MenuItem>
                    )}
                </TextField>
                {inventoryScores
                    ? <Fragment>
                        <InventoryScoreTagger productId={productId} />
                        <InventoryScoreMarkingTable
                            initialRows={inventoryScores}
                            handleSave={rows => saveScores(rows)}
                            handleEditing={setEditing}
                            disableEditing={isEditing}
                        />
                        {computedScores
                            ? <Fragment>
                                <ComputedInventoryScoreTable
                                    rows={computedScores}
                                />

                            </Fragment>
                            : <LinearProgress sx={{ mt: 4 }} />
                        }
                    </Fragment>
                    : <LinearProgress sx={{ mt: 4 }} />
                }
            </Box>
        </>
    )
};

export default InventoryScores;