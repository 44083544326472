/**
 * The various types of commodities supported by the app
 */
var CommodityType;
(function (CommodityType) {
    CommodityType["GasOil"] = "GasOil";
})(CommodityType || (CommodityType = {}));
/**
 * Get the FieldDefinitionKey corresponding to the Amount field for the given {@link CommodityType}
 * @param type {CommodityType} the commodity type to retrieve the key for
 *
 * @return {FieldDefinitionKey} the key
 */
var getCommodityAmountFieldDefinitionKey = function (type) {
    switch (type) {
        case CommodityType.GasOil:
            return 'gasoilAmount';
    }
};
export { getCommodityAmountFieldDefinitionKey, CommodityType };
