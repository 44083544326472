// truncate URI
var truncateUri = function (uri) {
    if (!uri)
        return null;
    if (uri.length > 39) {
        return "".concat(uri.slice(0, 40), "...");
    }
    return uri;
};
export { truncateUri };
