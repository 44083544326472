var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from '@mui/material';
var renderEntry = function (label, props, useDialog, controlledTextField) {
    if (controlledTextField === void 0) { controlledTextField = false; }
    if (useDialog) {
        return (_jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' } }, { children: [!controlledTextField && (_jsx(Typography, { children: label })), _jsx(Box, __assign({ sx: { width: '20em', textAlign: 'left', flex: '1' } }, { children: props }))] })));
    }
    else {
        return (_jsx(Grid, __assign({ item: true, xs: 12, sx: { width: '100%' } }, { children: props })));
    }
};
export { renderEntry };
