/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ResolvedOptimiserVintageScore interface.
 */
export function instanceOfResolvedOptimiserVintageScore(value) {
    var isInstance = true;
    isInstance = isInstance && "resolvedScore" in value;
    isInstance = isInstance && "vintageYear" in value;
    return isInstance;
}
export function ResolvedOptimiserVintageScoreFromJSON(json) {
    return ResolvedOptimiserVintageScoreFromJSONTyped(json, false);
}
export function ResolvedOptimiserVintageScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resolvedScore': json['resolvedScore'],
        'vintageYear': json['vintageYear'],
    };
}
export function ResolvedOptimiserVintageScoreToJSON(value) {
    return ResolvedOptimiserVintageScoreToJSONTyped(value, false);
}
export function ResolvedOptimiserVintageScoreToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resolvedScore': value.resolvedScore,
        'vintageYear': value.vintageYear,
    };
}
