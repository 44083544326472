var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Tab, Tabs, Typography } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
var FormDrafts = function (_a) {
    var draftsMetadata = _a.draftsMetadata, onChange = _a.onChange, onDelete = _a.onDelete, customTheme = _a.customTheme;
    var renderDraftTab = function (id, name) {
        var labelElement = _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, { children: name }), _jsx(IconButton, __assign({ color: 'primary', size: 'small', tabIndex: -1, component: 'span' // nested buttons are not allowed, so use a different component
                    , onMouseDown: function (event) {
                        // stop the ripple animation from propagating to the tab itself
                        event.stopPropagation();
                    }, onClick: function (event) {
                        // stop clicks from propagating to the tab itself
                        event.stopPropagation();
                        onDelete(id);
                    }, sx: {
                        width: 20,
                        height: 20,
                        marginLeft: 1.5,
                        marginTop: -0.25,
                        marginRight: -0.5,
                        '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
                    } }, { children: _jsx(Clear, { color: id === (draftsMetadata === null || draftsMetadata === void 0 ? void 0 : draftsMetadata.currentDraftId) ? 'primary' : 'secondary', sx: { fontSize: 20 } }) }))] }));
        return (_jsx(Tab, { label: labelElement, value: id, sx: {
                color: customTheme.theme.palette.secondary.main,
                transition: customTheme.customProps.navHoverTransition,
                '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
            } }, id));
    };
    var renderAddDraftTab = function () {
        var id = 'ADD';
        return (_jsx(Tab, { label: _jsx(Add, {}), value: id, sx: {
                width: 48,
                minWidth: 48,
                color: customTheme.theme.palette.primary.main,
                transition: customTheme.customProps.navHoverTransition,
                '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
            } }, id));
    };
    return (_jsxs(Tabs, __assign({ value: draftsMetadata === null || draftsMetadata === void 0 ? void 0 : draftsMetadata.currentDraftId, onChange: onChange, variant: 'scrollable', scrollButtons: true, sx: { width: '729px' } }, { children: [renderAddDraftTab(), draftsMetadata === null || draftsMetadata === void 0 ? void 0 : draftsMetadata.drafts.map(function (_a) {
                var id = _a.id, name = _a.name;
                return renderDraftTab(id, name);
            })] })));
};
export { FormDrafts };
