/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VintageScoreFromJSON, VintageScoreToJSON, } from './VintageScore.js';
/**
 * Check if a given object implements the ProjectTypeScore interface.
 */
export function instanceOfProjectTypeScore(value) {
    var isInstance = true;
    isInstance = isInstance && "baseScore" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "vintageScores" in value;
    return isInstance;
}
export function ProjectTypeScoreFromJSON(json) {
    return ProjectTypeScoreFromJSONTyped(json, false);
}
export function ProjectTypeScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'baseScore': json['baseScore'],
        'projectType': json['projectType'],
        'vintageScores': (json['vintageScores'].map(VintageScoreFromJSON)),
    };
}
export function ProjectTypeScoreToJSON(value) {
    return ProjectTypeScoreToJSONTyped(value, false);
}
export function ProjectTypeScoreToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'baseScore': value.baseScore,
        'projectType': value.projectType,
        'vintageScores': (value.vintageScores.map(VintageScoreToJSON)),
    };
}
