import {
    DialogContent,
    Drawer,
    Paper,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { HoldingData } from '@trovio-tech/trovio-core-api-js';
import { useCommoditiesState } from './state/CommoditiesState';
import { formatDisplayValue } from '@commodity-desk/common';

const CommoditiesTableDrawer = ({
    isOpen,
    onClose,
    holdingData
}: {
    isOpen: boolean;
    onClose: () => void;
    holdingData?: HoldingData;
}) => {

    const { commodityConfig } = useCommoditiesState();

    const getMappedAttributes = (): {[key: string]: any} => {
        if (!holdingData) {
            // Exit early if holdingData is not defined
            return {};
        }

        const definitions = commodityConfig.getCommodityFieldDefinitionsForProductId(holdingData!.productId!)!;
        const holdingAttributes = holdingData!.productItem!.data.attributes!;

        const entries = definitions
            .filter(df => df.attribute)
            .map((df) => {
                const value = holdingAttributes[df.attribute!.key];
                let displayValue: any;

                if (value && df.renderJSX && Object.hasOwn(value, 'url')) {
                    displayValue = df.renderJSX({value: (value as any).url})
                } else {
                    displayValue = formatDisplayValue(df, value);
                }

                return [df.displayName, displayValue];
            })

        return Object.fromEntries(entries);
    }

    return (<>
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '75%',
                    maxWidth: '1000px'
                }
            }}
        >
            <DialogContent>
                <Typography variant="h2">
                    Commodity
                </Typography>
                    <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                        Details
                    </Typography>

                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Property</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow hover key="id">
                                    <TableCell>ID</TableCell>
                                    <TableCell>{holdingData?.holdingId}</TableCell>
                                </TableRow>
                                <TableRow hover key="product">
                                    <TableCell>Product</TableCell>
                                    <TableCell>{commodityConfig.getCommodityTypeForProductId(holdingData?.productId!)}</TableCell>
                                </TableRow>
                                <TableRow hover key="amount">
                                    <TableCell>Amount</TableCell>
                                    <TableCell>{holdingData?.amount}</TableCell>
                                </TableRow>

                                {Object.entries(getMappedAttributes()).map(([k, v]) => (
                                    <TableRow hover key={k}>
                                        <TableCell>{k}</TableCell>
                                        <TableCell>{v}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </DialogContent>
        </Drawer>
    </>)

}

export default CommoditiesTableDrawer;