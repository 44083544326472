import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { 
    AttributeCriteria,
    CurrentState, 
    fetchByCriteria,
    fetchProductAttributesWithId,
    fetchProductItemsWithId,
    productAttributes,
    carbonProjectAttributes,
    ProjectData, 
    SumProjectBalances, 
    useAppConfigState,
} from '@commodity-desk/common';
import { projectDisplayDetails } from '../../../utility/ConfigureProjectDetails';
import {  useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface SelectedIDs {
    productID: string;
    projectID: string;
    vintage: string;
}

interface InitState {
    state: string;
}

interface ProjectDetailsStateType {
    projectBalances: SumProjectBalances | undefined;
    vintages: number[];
    handleProjectDetailsView: () => void;
    fetchProductItems: () => void;
    fetchRegistryId: () => void;
    selectedIds: MutableRefObject<SelectedIDs>;
    initState: { state: string };
    productItemData: any;
    selectedProjectDetails: ProjectData | undefined;
    registryId: string;
    fetchProjectBalanceAndDetails: (attributes: AttributeCriteria[]) => void;
    loading: boolean;
    isPathError: boolean;
}

interface ProjectDetailsDispatchType {
    setIsPathError: Dispatch<SetStateAction<boolean>>;
}

const ProjectDetailsStateContext = createContext<ProjectDetailsStateType | null>(null);
const ProjectDetailsDispatchContext = createContext<ProjectDetailsDispatchType | null>(null);

const ProjectDetailsProvider = ({ children }: { children?: ReactNode }) => {
    const appConfigState = useAppConfigState();
    const { cortenApi } = useCortenApiState();
    const [vintages, setVintages] = useState<number[]>([]);
    const [projectBalances, setProjectBalances] = useState<SumProjectBalances | undefined>(
        undefined
    );
    const [initState, setInitState] = useState<InitState>({ state: CurrentState.LOADING });
    const [selectedProjectDetails, setSelectedProjectDetails] = useState<ProjectData | undefined>(
        undefined
    );
    const [registryId, setRegistryId] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [isPathError, setIsPathError] = useState(false);

    const productItemData = useRef<any>([]);
    const selectedIds = useRef<SelectedIDs>({ productID: '', projectID: '', vintage: '' });

    // runs only when we have the productItemData response
    useEffect(() => {
        if (initState.state === CurrentState.READY && selectedIds.current.vintage === '') {
            // run other functions that depend on productItemData
            init();
        }
    }, [initState]); // eslint-disable-line react-hooks/exhaustive-deps

    // called from project details View
    const handleProjectDetailsView = () => {
        fetchRegistryId();
        fetchProductItems();
    };

    /**
     * run all functions that depend on productItemData response
     */
    const init = async () => {
        // fetch sum holdings for selected project, set project details display data
        let attributes = [{ code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID }];
        await fetchProjectBalanceAndDetails(attributes);

        // find unique vintages for a project
        await findUniqueVintages();

        // stop page loader
        setLoading(false)

    };

    // fetch registry ID for the product
    const fetchRegistryId = async () => {
        let productData = await fetchProductAttributesWithId(selectedIds.current.productID, cortenApi);

        if (productData && Object.keys(productData).length > 0) {
            setRegistryId(productData.data.attributes![productAttributes.registry.key].toString());
        } else {
            setRegistryId('');
        }
    };

    // fetch all batches of product Items for product ID and combine
    const fetchProductItems = async () => {
        setInitState({ state: CurrentState.LOADING });

        let combinedProductItems = await fetchProductItemsWithId(cortenApi, selectedIds.current.productID);

        // set all batches of response data to a useRef
        productItemData.current = combinedProductItems;

        if (productItemData.current.length !== 0) {
            setInitState({ state: CurrentState.READY });
        }
    };

    // fetch balances by project and populate details UI display data
    const fetchProjectBalanceAndDetails = async (attributes: AttributeCriteria[]) => {
        const criteria = {
            productIds: [selectedIds.current.productID],
            includeBalances: true,
            axes: appConfigState.getProjectAttributes(),
            attributes: attributes
        };

        let projectData = await fetchByCriteria(criteria, cortenApi);

        if (projectData && projectData.list.length > 0) {
            // set balances
            setProjectBalances(projectData.list[0].balances);

            // set project details display data
            const displayFormat = projectDisplayDetails(projectData.list[0], appConfigState);
            setSelectedProjectDetails(displayFormat);
        } else {
            setProjectBalances(undefined);
            setSelectedProjectDetails(undefined);
        }
    };

    // find unique vintages for a project
    const findUniqueVintages = async () => {

        const projectAttributes = [carbonProjectAttributes.vintage.key]
        const attributes = [
            { code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID }
        ]
        const criteria = {
            productIds: [selectedIds.current.productID],
            axes: projectAttributes,
            attributes: attributes
        }

        let projectData = await fetchByCriteria(criteria, cortenApi);

        if (projectData && projectData.list.length > 0) {
            let allVintages: number[] = [];
            projectData.list.forEach((item: any) => {
                allVintages.push(item.attributes[carbonProjectAttributes.vintage.key]);
            });
            setVintages(allVintages)
        } else {
            setVintages([])
        }
    };

    return (
        <ProjectDetailsStateContext.Provider
            value={{
                projectBalances: projectBalances,
                vintages: vintages,
                handleProjectDetailsView: handleProjectDetailsView,
                selectedIds: selectedIds,
                fetchProductItems: fetchProductItems,
                initState: initState,
                productItemData: productItemData,
                selectedProjectDetails: selectedProjectDetails,
                registryId: registryId,
                fetchRegistryId: fetchRegistryId,
                fetchProjectBalanceAndDetails: fetchProjectBalanceAndDetails,
                loading: loading,
                isPathError: isPathError
            }}
        >
            <ProjectDetailsDispatchContext.Provider value={{setIsPathError: setIsPathError}}>
                {children}
            </ProjectDetailsDispatchContext.Provider>
        </ProjectDetailsStateContext.Provider>
    );
};

function useProjectDetailsState() {
    const context = useContext(ProjectDetailsStateContext);
    if (!context) {
        throw new Error('no provider for useProjectDetailsState');
    }
    return context;
}

function useProjectDetailsDispatch() {
    const context = useContext(ProjectDetailsDispatchContext);
    if (!context) {
        throw new Error('no provider for useProjectDetailsDispatch');
    }
    return context;
}

export { ProjectDetailsProvider, useProjectDetailsState, useProjectDetailsDispatch };
