var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { transactionAttributes, carbonProjectAttributes, lgcAttributes, miqcAttributes } from '../state/AppConfig';
import { FieldDataType } from '../component/fieldDefinitions/FieldDefinitions';
import { muiToCoreFilter } from '@trovio-tech/trovio-core-api-jsx';
// A special value that indicates that we want to filter for non null values 
// of a boolean attribute. The value is arbitrarily chosen to be something
// that is unlikely to be a value in a filter
var BOOLEAN_NOT_NULL = '+-#BooleanNotNull+-#';
/**
 * Function to return all unique values of a property from a given set of ProjectProductItemData
 *
 * @param arr Array of ProjectProductItemData
 * @param property the property to find unique values of
 * @returns An array of unique values of the property
 */
var getUniqueValues = function (arr, property) {
    var uniqueValues = new Set();
    arr.forEach(function (obj) {
        uniqueValues.add(obj[property]);
    });
    return Array.from(uniqueValues);
};
var truncateLongId = function (id) {
    if (id.length > 10) {
        return "".concat(id.slice(0, 12), "...");
    }
    return id;
};
var truncateHumanName = function (name) {
    if (name.length > 20) {
        return "".concat(name.slice(0, 21), "...");
    }
    return name;
};
var truncateProjectName = function (name) {
    if (name === undefined)
        return name;
    if (name.length > 25) {
        return "".concat(name.slice(0, 26), "...");
    }
    return name;
};
// Utility functions to test whether the input value is valid integer
var isValidInteger = function (inputValue) {
    // To accept only numbers and ensure that it cannot start with 0
    return /^[1-9]\d*$/.test(inputValue);
};
/**
 * Return a list of product item IDs retired from the transaction details response.
 * We do this by comparing spent holdings and issued holdings, and see which product item has spent holding amount
 * greater than issued holding amount.
 * @param data  Transaction details response
 * @returns list of product item IDs
 */
var getRetiredProductItemIds = function (data) {
    var issuedHoldings = data.issuedHoldings, spentHoldings = data.spentHoldings;
    var productItemAmounts = {};
    // Iterate over spentHoldings and add their amounts to productItemAmounts
    for (var _i = 0, spentHoldings_1 = spentHoldings; _i < spentHoldings_1.length; _i++) {
        var spentHolding = spentHoldings_1[_i];
        var productItemId = spentHolding.productItemId, amount = spentHolding.amount;
        var numAmount = parseInt(amount);
        if (productItemAmounts[productItemId]) {
            productItemAmounts[productItemId] += numAmount;
        }
        else {
            productItemAmounts[productItemId] = numAmount;
        }
    }
    // Iterate over issuedHoldings and subtract their amounts from productItemAmounts
    for (var _a = 0, issuedHoldings_1 = issuedHoldings; _a < issuedHoldings_1.length; _a++) {
        var issuedHolding = issuedHoldings_1[_a];
        var productItemId = issuedHolding.productItemId, amount = issuedHolding.amount;
        var numAmount = parseInt(amount);
        if (productItemAmounts[productItemId]) {
            productItemAmounts[productItemId] -= numAmount;
        }
        else {
            productItemAmounts[productItemId] = -numAmount;
        }
    }
    // Filter out productItemIds with non-zero resulting amounts
    var nonZeroProductItemIds = [];
    for (var productItemId in productItemAmounts) {
        if (productItemAmounts[productItemId] !== 0) {
            nonZeroProductItemIds.push(productItemId);
        }
    }
    return nonZeroProductItemIds;
};
/**
 * Function to convert MUI Datagrid filter values to corten API filter values
 * This function uses muiToCoreFilter provided by trovio-core-api-jsx lib to generate
 * filter strings whereas commodity-desk specific filtering logic is handled here.
 * Currently, commodity-desk considers `*` and `+-#BooleanNotNull+-#`
 * as special placeholder values that represents scenarios where we need to filter all rows
 * where the selected field has any value.
 *
 * @param {GridFilterItem} item the item to inspect and derive corten equivalent
 * filter values for
 * @param {number} unitAmount used for numeric operators, it denotes the smallest
 * increment in the unit that the item is refering to. This is used to get around
 * the range limitation in corten API. Currently, it only supports 'less than' and
 * 'greater than or equal to' comparison operators. The unitAmount is then added
 * to the boundary condition for cases where we want to use 'less than or equal to'
 * and the 'greater than' operators.
 * @param {boolean} isDate indicates whether the item should be treated as a Date
 * @param {boolean} containsTime only valid if isDate is set to true. Indicates
 * whether the date value has time component
 *
 * @returns {string} returns a string that is the filter value notation supported
 * in corten
 */
var extendedMuiFilterToCoreFilter = function (_a) {
    var item = _a.item, unitAmount = _a.unitAmount, isDate = _a.isDate, containsTime = _a.containsTime;
    var filterValue = item.value;
    if ((item.operator === 'equals' && filterValue === BOOLEAN_NOT_NULL) || filterValue === '*') {
        // Filter for attributes that has any value other than undefined
        // Eg: When filtering for entries where greenPowerAccredited is either true or false and value is not undefined
        // (mainly when sorting with greenPowerAccredited, so sorted results will only include true/false entries),
        // this function should be called with filterValue === BOOLEAN_NOT_NULL and with equals operator.
        // When filtering for entries where any string or date field (eg: project state, trade date) has any values and not undefined 
        // (mainly used when sorting by the field to remove entries where value for sort field is missing),
        // this function should be called with filterValue === * and with equals operator.
        filterValue = '*';
    }
    else {
        filterValue = muiToCoreFilter({
            item: item,
            unitAmount: unitAmount,
            isDate: isDate,
            containsTime: containsTime
        });
    }
    return filterValue;
};
/**
 * Determine whether the given GridFilterItem is blank.
 * We generally want to skip the filter item when this is the case
 * @param item The GridFilterItem to check whether it is blank
 * @returns a boolean indicating whether it is blank
 */
var isBlankFilterItem = function (item) {
    return (item.value == null || // undefined, null
        (item.value.hasOwnProperty('length') && item.value.length === 0) || // '', []
        (item.value.constructor === Object && Object.keys(item.value).length === 0) // {}
    );
};
/*
* Enum class for determining the type of query key
* @enum {string}
*/
var FilterKeyType;
(function (FilterKeyType) {
    /**
     * @member {string}
     * The key is a standalone item and will be used as is
     */
    FilterKeyType["Standalone"] = "Standalone";
    /**
     * @member {string}
     * The key is an attribute will be wrapped in attribute[]
     */
    FilterKeyType["Attribute"] = "Attribute";
    /**
     * @member {string}
     * The key is a transaction attribute and will be wrapped with transactionAttribute[]
     */
    FilterKeyType["TransactionAttribute"] = "TransactionAttribute";
    /**
     * @member {string}
     * The key is a product item attribute and will be wrapped with productItemAttribute[]
     */
    FilterKeyType["ProductItemAttribute"] = "ProductItemAttribute";
})(FilterKeyType || (FilterKeyType = {}));
/**
 * Returns the corten attribute key and formatted filter string for the provided {GridFilterItem}
 * @param item GridFilterItem for a corten attribute filter
 * @param appConfigState application config state
 * @returns an object containing the correnponsing attribute key and filter string to use
 */
var getFilterKeyAndValue = function (item, appConfigState) {
    switch (item.field) {
        case 'projectType':
            return { key: carbonProjectAttributes.projectType.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'tradeDate':
            return { key: transactionAttributes.tradeDate.key, value: extendedMuiFilterToCoreFilter({ item: item, isDate: true }) };
        case 'tradeId':
            return { key: transactionAttributes.tradeId.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'tradeType':
        case 'transactionType':
        case 'transactionTypeInventory':
            return { key: transactionAttributes.transactionTypeInventory.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'transactionTypeClient':
            return { key: transactionAttributes.transactionTypeClient.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'projectType':
            return { key: carbonProjectAttributes.projectType.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'projectId':
            return { key: carbonProjectAttributes.projectId.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'projectName':
            return { key: carbonProjectAttributes.projectName.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'vintage':
            return { key: carbonProjectAttributes.vintage.key, value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 }) };
        case 'country':
            return { key: carbonProjectAttributes.country.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'projectState':
            return { key: carbonProjectAttributes.projectState.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'accreditationCode':
            return { key: lgcAttributes.accreditationCode.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'fuelSource':
            return { key: lgcAttributes.fuelSource.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'generationYear':
            return { key: lgcAttributes.generationYear.key, value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 }) };
        case 'creationYear':
            return { key: lgcAttributes.creationYear.key, value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 }) };
        case 'generationState':
            return { key: lgcAttributes.generationState.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'greenPowerAccredited':
            return { key: lgcAttributes.greenPowerAccredited.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'facility':
            return { key: miqcAttributes.facility.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'segment':
            return { key: miqcAttributes.segment.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'issueMonth':
            return { key: miqcAttributes.issueMonth.key, value: extendedMuiFilterToCoreFilter({ item: item, isDate: true }) };
        case 'issueYear':
            return { key: miqcAttributes.issueYear.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'countryOfOperation':
            return { key: miqcAttributes.countryOfOperation.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'certificateRegion':
            return { key: miqcAttributes.certificateRegion.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'operatorName':
            return { key: miqcAttributes.operatorName.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'miqMethaneIntensity':
            return { key: miqcAttributes.miqMethaneIntensity.key, value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 0.00001 }) };
        case 'miqGrade':
            return { key: miqcAttributes.miqGrade.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'miqGradeStatus':
            return { key: miqcAttributes.miqGradeStatus.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'miqAuditorName':
            return { key: miqcAttributes.miqAuditorName.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'eo100Grade':
            return { key: miqcAttributes.eo100Grade.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'currency':
            return { key: transactionAttributes.currency.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'price':
            return { key: transactionAttributes.price.key, value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 0.01 }) };
        case 'valueDate':
            return { key: transactionAttributes.valueDate.key, value: extendedMuiFilterToCoreFilter({ item: item, isDate: true }) };
        case 'trader':
            return { key: transactionAttributes.trader.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'salesPerson':
            return { key: transactionAttributes.salesPerson.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        case 'brokerName':
            return { key: transactionAttributes.brokerName.key, value: extendedMuiFilterToCoreFilter({ item: item }) };
        default:
            throw ("Unexpected filter key");
    }
};
/**
 * Function to construct the sorting component of the /history API request.
 *
 * @param {GridSortItem[]} items a list of items based on which to construct the
 * sorting URI component
 * @param appConfigState application config state
 * @param isProductItemApiQuery A boolean indicating whether this function is being used for a product item API query.
 *                              When set, some of the keywords used to reference attributes change, as the API uses
 *                              slightly different naming.
 *
 * @returns {string} a string that will be the sorting component of the request URI
 * @deprecated Use getSortItemQueryStrings instead
 */
var getFormattedSortItems = function (_a) {
    var items = _a.items, defaultSort = _a.defaultSort, appConfigState = _a.appConfigState, isProductItemApiQuery = _a.isProductItemApiQuery;
    var sortItems = [];
    var transactionSortApplied = false;
    items.filter(function (item) { return item.sort != null; })
        .forEach(function (item) {
        var directionChar = item.sort === 'asc' ? '+' : '-';
        var productItemAttributeLabel = 'productItemAttribute';
        var transactionAttributeLabel = 'transactionAttribute';
        if (isProductItemApiQuery) {
            // For the product item API, which queries product items, all transaction / trade level attributes
            // apply to the product item itself, alongside the usual product level attributes. The API requires
            // the use of the productItemAttribute label to sort on any attribute.
            transactionAttributeLabel = 'productItemAttribute';
        }
        switch (item.field) {
            case 'transactionTimestamp':
                sortItems.push("".concat(directionChar, "transaction"));
                transactionSortApplied = true;
                break;
            case 'tradeDate':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.tradeDate.key));
                break;
            case 'transactionTypeInventory':
            case 'transactionType':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.transactionTypeInventory.key));
                break;
            case 'transactionTypeClient':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.transactionTypeClient.key));
                break;
            case 'projectType':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectType.key));
                break;
            case 'projectId':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectId.key));
                break;
            case 'projectName':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectName.key));
                break;
            case 'vintage':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.vintage.key));
                break;
            case 'projectState':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectState.key));
                break;
            case 'country':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.country.key));
                break;
            case 'accreditationCode':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.accreditationCode.key));
                break;
            case 'fuelSource':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.fuelSource.key));
                break;
            case 'generationYear':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.generationYear.key));
                break;
            case 'creationYear':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.creationYear.key));
                break;
            case 'generationState':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.generationState.key));
                break;
            case 'greenPowerAccredited':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(lgcAttributes.greenPowerAccredited.key));
                break;
            case 'facility':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.facility.key));
                break;
            case 'segment':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.segment.key));
                break;
            case 'issueMonth':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.issueMonth.key));
                break;
            case 'issueYear':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.issueYear.key));
                break;
            case 'countryOfOperation':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.countryOfOperation.key));
                break;
            case 'certificateRegion':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.certificateRegion.key));
                break;
            case 'operatorName':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.operatorName.key));
                break;
            case 'miqMethaneIntensity':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.miqMethaneIntensity.key));
                break;
            case 'miqGrade':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.miqGrade.key));
                break;
            case 'miqGradeStatus':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.miqGradeStatus.key));
                break;
            case 'miqAuditorName':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.miqAuditorName.key));
                break;
            case 'eo100Grade':
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(miqcAttributes.eo100Grade.key));
                break;
            case 'quantity':
                if (isProductItemApiQuery) {
                    sortItems.push("".concat(directionChar, "unassignedAmount"));
                }
                else {
                    sortItems.push("".concat(directionChar, "amount"));
                }
                break;
            case 'currency':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.currency.key));
                break;
            case 'price':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.price.key));
                break;
            case 'valueDate':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.valueDate.key));
                break;
            case 'trader':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.trader.key));
                break;
            case 'salesPerson':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.salesPerson.key));
                break;
            case 'brokerName':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.brokerName.key));
                break;
        }
    });
    // If a default sort is specified and we are not sorting by any custom columns, then use default sort
    if (defaultSort !== undefined && sortItems.length === 0) {
        sortItems.push(defaultSort);
    }
    if (!transactionSortApplied && !isProductItemApiQuery) {
        // We want to apply a default sort of reverse chronological order if
        // - no sorting is being applied
        // - sorting on fields other than timestamp is applied (so that items
        //   with the same value are then further sorted)
        // This is only done for the history API and not for the product item API
        sortItems.push('-transaction');
    }
    return sortItems;
};
/**
 * Function to construct the sorting component of the /history API request.
 *
 * @param {GridSortItem[]} items a list of items based on which to construct the sorting URI component
 * @param defaultSort The default sort method to use, if no custom columns are to be used in sorting
 * @param appConfigState application config state
 * @param isProductItemApiQuery A boolean indicating whether this function is being used for a product item API query.
 *                              When set, some of the keywords used to reference attributes change, as the API uses
 *                              slightly different naming.
 * @param {Map<String, FieldDefinition>} fieldDefinitionMap a map of field definitions,
 *                                                          used to determine the type of the field and the operator to apply
 *
 * @returns {string} a string that will be the sorting component of the request URI
 */
var getSortItemQueryStrings = function (_a) {
    var items = _a.items, defaultSort = _a.defaultSort, appConfigState = _a.appConfigState, isProductItemApiQuery = _a.isProductItemApiQuery, fieldDefinitionMap = _a.fieldDefinitionMap;
    var sortItems = [];
    var transactionSortApplied = false;
    items.filter(function (item) { return item.sort != null; })
        .forEach(function (item) {
        var directionChar = item.sort === 'asc' ? '+' : '-';
        var productItemAttributeLabel = 'productItemAttribute';
        var transactionAttributeLabel = 'transactionAttribute';
        if (isProductItemApiQuery) {
            // For the product item API, which queries product items, all transaction / trade level attributes
            // apply to the product item itself, alongside the usual product level attributes. The API requires
            // the use of the productItemAttribute label to sort on any attribute.
            transactionAttributeLabel = 'productItemAttribute';
        }
        switch (item.field) {
            case 'transactionTimestamp':
                sortItems.push("".concat(directionChar, "transaction"));
                transactionSortApplied = true;
                break;
            case 'quantity':
                if (isProductItemApiQuery) {
                    sortItems.push("".concat(directionChar, "unassignedAmount"));
                }
                else {
                    sortItems.push("".concat(directionChar, "amount"));
                }
                break;
            case 'projectState':
                // TODO: Field is named differently in Holdings.tsx - reconcile this and delete this entry afterwards
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectState.key));
                break;
            case 'transactionType':
                sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(transactionAttributes.transactionTypeInventory.key));
                break;
            default:
                var fieldDefinition = fieldDefinitionMap.get(item.field);
                if (fieldDefinition === undefined) {
                    throw new Error("Could not find field definition matching sort item ".concat(item.field));
                }
                if (fieldDefinition.attribute === undefined) {
                    throw new Error("No attribute was specified for sortable field ".concat(item.field));
                }
                if (fieldDefinition.attribute.group === 'TRADE') {
                    sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else if (fieldDefinition.attribute.group === 'PROJECT') {
                    sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else if (fieldDefinition.attribute.group === 'SHARED') {
                    sortItems.push("".concat(directionChar).concat(transactionAttributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else {
                    throw new Error("Unknown attribute group ".concat(fieldDefinition.attribute.group, " in getSortItemQueryStrings"));
                }
                break;
        }
    });
    // If a default sort is specified and we are not sorting by any custom columns, then use default sort
    if (defaultSort !== undefined && sortItems.length === 0) {
        sortItems.push(defaultSort);
    }
    if (!transactionSortApplied && !isProductItemApiQuery) {
        // We want to apply a default sort of reverse chronological order if
        // - no sorting is being applied
        // - sorting on fields other than timestamp is applied (so that items
        //   with the same value are then further sorted)
        // This is only done for the history API and not for the product item API
        sortItems.push('-transaction');
    }
    return sortItems;
};
/**
     * Function to adjust filters for certain sort fields. Since these fields
     * are optional, a lot of transactions will be missing values for these
     * fields. Corten places all of the empty fields in one end of the result
     * set when sorting. This makes viewing the results difficult .e.g searching
     * for the highest price. To remedy this problem, we add an additional
     * negation filter for these fields which has the effect of removing all
     * entries that have null values for these fields.
     *
     * @param {GridFilterItem[]} filterItems a list of filter items currently
     *  applied by the user
     * @param {GridSortModel} sortItems a list of sort items applied by the user
     * @param {string[]} pageFilters a list page filters that currently apply
     *
     * @returns {GridFilterItem[]} a list of filter items containing any additional
     *  filter items applied to adjust for sorting on optional fields
     * @deprecated Use adjustFiltersForSort instead
     */
var adjustForSort = function (_a) {
    var filterItems = _a.filterItems, sortItems = _a.sortItems, pageFilters = _a.pageFilters;
    var modifiedFilterItems = __spreadArray([], filterItems, true);
    // Apply negation filters to remove nulls
    // However, if any table filters or page filters currently apply, we must skip applying
    // negation filters on these columns, as they aren't needed in this situation, since
    // we already have a filter on the column (which already would ignore nulls).
    sortItems
        // Filter out undefined values
        .filter(function (item) { return item.sort != null; })
        // Skip applying negation filters on any columns that are currently used in table filters
        .filter(function (item) { return !filterItems.filter(function (fi) { return fi.value; }).some(function (fi) { return fi.field === item.field; }); })
        // Also skip applying negation filters if any page filters apply on the same columns
        .filter(function (item) { return !pageFilters.some(function (pf) { return pf === item.field; }); })
        .forEach(function (item) {
        switch (item.field) {
            case 'projectType':
            case 'projectId':
            case 'projectName':
            case 'country':
            case 'projectState':
            case 'accreditationCode':
            case 'fuelSource':
            case 'generationState':
            case 'currency':
            case 'trader':
            case 'brokerName':
            case 'salesPerson':
                modifiedFilterItems.push({ field: item.field, value: '*', operator: 'equals' });
                break;
            case 'price':
            case 'vintage':
            case 'generationYear':
            case 'creationYear':
                modifiedFilterItems.push({ field: item.field, value: 0, operator: '>' });
                break;
            case 'tradeDate':
            case 'valueDate':
                modifiedFilterItems.push({ field: item.field, value: '*', operator: 'range' });
                break;
            case 'greenPowerAccredited':
                modifiedFilterItems.push({ field: item.field, value: BOOLEAN_NOT_NULL, operator: 'equals' });
                break;
        }
    });
    return modifiedFilterItems;
};
/**
     * Function to adjust filters for certain sort fields. Since these fields
     * are optional, a lot of transactions will be missing values for these
     * fields. Corten places all of the empty fields in one end of the result
     * set when sorting. This makes viewing the results difficult .e.g searching
     * for the highest price. To remedy this problem, we add an additional
     * negation filter for these fields which has the effect of removing all
     * entries that have null values for these fields.
     *
     * @param {GridFilterItem[]} filterItems a list of filter items currently
     *  applied by the user
     * @param {GridSortModel} sortItems a list of sort items applied by the user
     * @param {string[]} pageFilters a list page filters that currently apply
     * @param {Map<String, FieldDefinition>} fieldDefinitionMap a map of field definitions,
     * used to determine the type of the field and the operator to apply
     *
     * @returns {GridFilterItem[]} a list of filter items containing any additional
     *  filter items applied to adjust for sorting on optional fields
     */
var adjustFiltersForSort = function (_a) {
    var filterItems = _a.filterItems, sortItems = _a.sortItems, pageFilters = _a.pageFilters, fieldDefinitionMap = _a.fieldDefinitionMap;
    var modifiedFilterItems = __spreadArray([], filterItems, true);
    // Apply negation filters to remove nulls
    // However, if any table filters or page filters currently apply, we must skip applying
    // negation filters on these columns, as they aren't needed in this situation, since
    // we already have a filter on the column (which already would ignore nulls).
    sortItems
        // Filter out undefined values
        .filter(function (item) { return item.sort != null; })
        // Skip applying negation filters on any columns that are currently used in table filters
        .filter(function (item) { return !filterItems.filter(function (fi) { return fi.value; }).some(function (fi) { return fi.field === item.field; }); })
        // Also skip applying negation filters if any page filters apply on the same columns
        .filter(function (item) { return !pageFilters.some(function (pf) { return pf === item.field; }); })
        .forEach(function (item) {
        switch (item.field) {
            // TODO: Remove this after renaming field in Holdings.tsx (and any other place that uses this naming)
            case 'projectState': // state is called 'projectState' in Holdings component since it already contains a separate state field
                modifiedFilterItems.push({ field: item.field, value: '*', operator: 'equals' });
                break;
            default:
                var fieldDefinition = fieldDefinitionMap.get(item.field);
                if (fieldDefinition === undefined) {
                    throw new Error("Could not find field definition matching filter item ".concat(item.field));
                }
                switch (fieldDefinition.dataType) {
                    case FieldDataType.String:
                    case FieldDataType.FixedOptions:
                    case FieldDataType.Document:
                    case FieldDataType.Integer:
                    case FieldDataType.Decimal:
                    case FieldDataType.Date:
                    case FieldDataType.DateYearMonth:
                    case FieldDataType.Timestamp:
                    case FieldDataType.Boolean:
                        modifiedFilterItems.push({ field: item.field, value: '*', operator: 'equals' });
                        break;
                    default:
                        throw new Error("Data type ".concat(fieldDefinition.dataType, " was not specified in adjustFiltersForSort"));
                }
        }
    });
    return modifiedFilterItems;
};
/**
 * Function to returns a new filter model that is compared to the previous filter model.
 *
 * If the field of the new filter model is different from the previous filter model,
 * then the value of the new filter model is set to undefined.
 * @param prevFilterModel The previous filter model to compare.
 * @param newFilterModel The new filter model to compare.
 * @returns The compared filter model with  value = undefined if the field is different.
 */
var getComparedFilterModel = function (prevFilterModel, newFilterModel) {
    var _a, _b;
    var prevFilterField = (_a = prevFilterModel === null || prevFilterModel === void 0 ? void 0 : prevFilterModel.items[0]) === null || _a === void 0 ? void 0 : _a.field;
    var newFilterField = ((_b = newFilterModel === null || newFilterModel === void 0 ? void 0 : newFilterModel.items[0]) === null || _b === void 0 ? void 0 : _b.field) || undefined;
    var filterData = __assign({}, newFilterModel);
    if (prevFilterField && prevFilterField !== newFilterField) {
        if (newFilterField) {
            filterData.items[0].value = undefined;
        }
    }
    return filterData;
};
/**
 * If a single non-array element is received, return it in an array with 1 element. If array is received, return it without any change.
 * @param rawItem Single element or an array of items
 * @returns
 */
var ensureArray = function (value) { return Array.isArray(value) ? value : [value]; };
export { getUniqueValues, truncateLongId, getRetiredProductItemIds, truncateHumanName, truncateProjectName, extendedMuiFilterToCoreFilter, getFilterKeyAndValue, getFormattedSortItems, getComparedFilterModel, getSortItemQueryStrings, isValidInteger, BOOLEAN_NOT_NULL, adjustForSort, adjustFiltersForSort, FilterKeyType, isBlankFilterItem, ensureArray };
