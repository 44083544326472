import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { StorageItem } from '../utility/StorageUtils';
import { COGNITO_GROUPS_KEY } from '../state/Variables';
/**
 * This is a extension for AuthProvider. Any auth related logic in addition to what is included in core10 Authprovider,
 * such as MBL specific auth event callbacks should be included here.
 * @returns ReactNode : children wrapped within this component
 */
function AuthExtension(_a) {
    var children = _a.children, cognitoGroupId = _a.cognitoGroupId, userManager = _a.userManager, user = _a.user, signOut = _a.signOut;
    useEffect(function () {
        if (userManager) {
            userManager.events.addUserUnloaded(function () {
                removeStorageItems();
            });
            userManager.events.addUserSignedOut(function () {
                removeStorageItems();
            });
        }
    }, [userManager]);
    useEffect(function () {
        if (user) {
            if (!user.profile[COGNITO_GROUPS_KEY] ||
                !user.profile[COGNITO_GROUPS_KEY].includes(cognitoGroupId)) {
                console.log('Unexpected User, Signing Out!');
                signOut();
            }
        }
    }, [user]);
    var removeStorageItems = function () {
        StorageItem.LOCAL_STORAGE_KEYS.forEach(function (key) { return localStorage.removeItem(key); });
        StorageItem.SESSION_STORAGE_KEYS.forEach(function (key) { return sessionStorage.removeItem(key); });
    };
    return _jsx(_Fragment, { children: children });
}
export default AuthExtension;
