var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAuth } from '@trovio-tech/trovio-core-api-jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
function LoginRedirectHandler(_a) {
    var homepage = _a.homepage, navigate = _a.navigate, userManager = _a.userManager, setUser = _a.setUser, setLoading = _a.setLoading, signOut = _a.signOut;
    var _b = useState(false), isErrorDialogOpen = _b[0], setIsErrorDialogOpen = _b[1];
    var closeErrorDialog = function () {
        setIsErrorDialogOpen(false);
    };
    var handleOkClick = function () {
        signOut();
        closeErrorDialog();
    };
    useEffect(function () {
        if (userManager) {
            userManager
                .signinRedirectCallback()
                .then(function (loadedUser) {
                setUser(loadedUser);
                // Redirect the user to the homepage once login is successful
                navigate(homepage);
            })
                .catch(function (err) {
                console.error(err);
                setLoading(false);
                // We encountered an error during retrieving user info. We need them to sign in again
                setIsErrorDialogOpen(true);
            });
        }
    }, [userManager]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs(Dialog, __assign({ open: isErrorDialogOpen, onClose: closeErrorDialog }, { children: [_jsx(DialogTitle, { children: "Login Error" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: "Something went wrong your login. Please login again." }) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleOkClick, color: "primary" }, { children: "OK" })) })] })));
}
export default LoginRedirectHandler;
