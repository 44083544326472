import { DataTable, DataTableColumnProps } from '@trovio-ui-libs/ui';
import { LinearProgress, Typography } from '@mui/material';
import { HoldingData } from '@trovio-tech/trovio-core-api-js';
import { useState } from 'react';
import CommoditiesTableDrawer from './CommoditiesTableDrawer';
import { useCommoditiesState } from './state/CommoditiesState';

// Arbitrary column width value that ensures that all columns are equally spaced
const COLUMN_WIDTH = 200;

const CommoditiesTable = () => {

    const {
        commodityConfig,
        commodityItems,
        loadingTable,
        getNextPageToken,
        totalCount,
        pagination,
        onPaginationChange
    } = useCommoditiesState();

    const [selectedRow, setSelectedRow] = useState<HoldingData | undefined>(undefined);

    const columns: DataTableColumnProps<HoldingData>[] = [
        {
            key: 'holdingId',
            header: 'ID',
            width: COLUMN_WIDTH
        },
        {
            key: 'product',
            header: 'Product',
            cell: ({table, row}) => commodityConfig.getCommodityTypeForProductId(row?.productId!)!,
            width: COLUMN_WIDTH
        },
        {
            key: 'amount',
            header: 'Amount',
            width: COLUMN_WIDTH
        }
    ]

    return (
        <>
            <Typography variant="h3">Commodities</Typography>

            {loadingTable && <LinearProgress/>}

            <DataTable
                loading={loadingTable}
                columns={columns}
                data={commodityItems}
                onRowClick={(row) => {
                    setSelectedRow(row);
                }}

                // Pagination Props
                paginationMode="server"
                paginationNextPage={getNextPageToken(pagination.pageIndex)}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                paginationPageSizes={[10, 25, 50, 100]}
                totalCount={totalCount}

                // Miscellaneous
                enableInfoColumn
            />

            <CommoditiesTableDrawer
                isOpen={selectedRow !== undefined}
                onClose={() => {
                    setSelectedRow(undefined);
                }}
                holdingData={selectedRow}
            />
        </>
    );
}

export default CommoditiesTable;