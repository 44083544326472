import { LinearProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { HoldingsSummary } from "./HoldingsSummary";
import { useLocation } from "react-router-dom";
import { 
    BalanceData,
    fetchByCriteria,
    inventoryAccount,
    lgcAttributes,
    miqcAttributes,
    ProductBase,
    ProductDisplayData,
    ProductType,
    useAppConfigState,
    useProductDataState
} from '@commodity-desk/common';
import { HoldingPageType, Holdings } from "../../component/Holdings";
import ProductItemAttributesTable from "./ProductItemAttributesTable";
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

/**
 * Attributes that are included in the group-by logic when looking up the balance data for display in the summary area of this page.
 * Contains the base attribute key along with several other fields of interest. All of these fields are displayed in the summary area.
 * Balances are not shown for individual entries in the result, instead we show only a single summed balance value. Therefore the group-by
 * logic is only used to determine the values that exist for the attributes in the selection below, so that we can show them in the summary area.
 */
const groupByAttributes = {
    [ProductType.LGC]: [lgcAttributes.accreditationCode, lgcAttributes.fuelSource, lgcAttributes.generationState],
    [ProductType.MiQC]: [miqcAttributes.facility, miqcAttributes.segment, miqcAttributes.countryOfOperation, miqcAttributes.certificateRegion, miqcAttributes.operatorName],
}

/**
 * The base attribute key that applies to each type of product that can be used with this component. The value of this attribute is provided
 * in the input URI. This component shows all data that exists for the input value of the base attribute.
 */
const baseAttributes = {
    [ProductType.LGC]: lgcAttributes.accreditationCode,
    [ProductType.MiQC]: miqcAttributes.facility,
}

/**
 * Used to display details of a specific certificate, for certificate based products (like LGC and MiQC)
 * Shows a summary table containing the total balance as well as a set of selected attributes that applies to the selected certificate.
 * This page is currently only shown when clicking on a link in the header of the L3 table on the risk page.
 */
const CertificateDetails = () => {
    const appConfigState = useAppConfigState();

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [product, setProduct] = useState<ProductDisplayData | undefined>(undefined);
    const [balances, setBalances] = useState<BalanceData | undefined>(undefined);
    const [certificateAttributes, setCertificateAttributes] = useState<any>(undefined);
    const { productsData } = useProductDataState();
    const { cortenApi } = useCortenApiState();

    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productId = splitLocation[4];
    // The base attribute value for the certificate (E.g. for LGC, base attribute would be accreditation code)
    const baseAttributeValue = decodeURI(splitLocation[6]);

    const fetchBalance = (chosenProduct: ProductDisplayData) => {
        const criteria = {
            productIds: [productId],
            includeBalances: true,
            axes: groupByAttributes[chosenProduct.displayCode as keyof typeof groupByAttributes].map(attribute => {
                return attribute.key;
            }),
            attributes: [{
                code: baseAttributes[chosenProduct.displayCode as keyof typeof baseAttributes].key,
                value: baseAttributeValue
            }]
        };

        return fetchByCriteria(criteria, cortenApi);
    }

    useEffect(() => {
        // We currently validate that this is a Certificate based product by looking up the product info here, given that we
        // cannot pass this info in as a paramter, because this page is only referenced directly by URL at the moment.
        const foundProduct = appConfigState.getProducts().find(p => p.id === productId);
        if (foundProduct && foundProduct.productBase === ProductBase.Certificate) {
            setProduct(foundProduct);
            fetchBalance(foundProduct).then((balanceData) => {
                if (balanceData.list.length > 0) {
                    setCertificateAttributes(balanceData.list[0].attributes);
                    setBalances(balanceData.list[0].balances);
                    if (balanceData.list.length > 1) {
                        console.error(
                            `Found more than row of balance data for ${foundProduct.displayCode} when grouping on ` +
                            `${groupByAttributes[foundProduct.displayCode as keyof typeof groupByAttributes]}, which was unexpected. ` +
                            `This means that the Core10 product data may be incorrect, and the summary balances and details may be wrong.`
                        );
                    }
                } else {
                    setErrorMessage('No certificates could be found');
                }
            })
        } else {
            setErrorMessage('Error - Product ID not recognised');
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Typography variant="h2">Certificate Details</Typography>
            {
                errorMessage ?
                    <Typography>{errorMessage}</Typography> :
                    <>
                        {!balances ?
                            <LinearProgress /> : (
                                <>
                                    <HoldingsSummary
                                        issuerAmount={balances?.issuerAmount}
                                        assignedAmount={balances?.assignedAmount}
                                        unassignedAmount={balances?.unassignedAmount}
                                        escrowAmount={balances?.escrowAmount}
                                        title="Certificate Holding Summary"
                                        minDecimalPos={productsData.get(productId)?.minDecimalPos!}
                                        maxDecimalPos={productsData.get(productId)?.maxDecimalPos!}
                                    />

                                    <ProductItemAttributesTable
                                        title="Certificate Summary"
                                        bodyProps={
                                            groupByAttributes[product!.displayCode as keyof typeof groupByAttributes].map(attribute => {
                                                return {
                                                    heading: attribute.display,
                                                    value: certificateAttributes[attribute.key]
                                                }
                                            })
                                        }
                                    />

                                    <Typography variant="h3">Certificates</Typography>
                                    <Holdings
                                        pageType={HoldingPageType.INVENTORY_CERTIFICATES}
                                        columnDefinitions={
                                            product?.displayCode === ProductType.LGC ? [
                                                {key: 'accreditationCode', showByDefault: false},
                                                {key: 'fuelSource', showByDefault: false},
                                                {key: 'creationYear', showByDefault: true},
                                                {key: 'generationYear', showByDefault: true},
                                                {key: 'generationState', showByDefault: false},
                                                {key: 'serialNumRange', showByDefault: true},
                                                {key: 'amount', showByDefault: true},
                                                {key: 'state', showByDefault: true},
                                                {key: 'greenPowerAccredited', showByDefault: true},
                                            ] : product?.displayCode === ProductType.MiQC ? [
                                                {key: 'facility', showByDefault: false},
                                                {key: 'segment', showByDefault: false},
                                                {key: 'issueMonth', showByDefault: true},
                                                {key: 'issueYear', showByDefault: true},
                                                {key: 'countryOfOperation', showByDefault: false},
                                                {key: 'certificateRegion', showByDefault: false},
                                                {key: 'operatorName', showByDefault: false},
                                                {key: 'miqMethaneIntensity', showByDefault: true},
                                                {key: 'miqGrade', showByDefault: true},
                                                {key: 'miqGradeStatus', showByDefault: true},
                                                {key: 'miqAuditorName', showByDefault: true},
                                                {key: 'eo100Grade', showByDefault: true}
                                            ] : []
                                        }
                                        pageFilters={{
                                            accountId: inventoryAccount.id,
                                            product: productId,
                                            accreditationCode: product?.displayCode === ProductType.LGC ? certificateAttributes[baseAttributes[ProductType.LGC].key] : undefined,
                                            facility: product?.displayCode === ProductType.MiQC ? certificateAttributes[baseAttributes[ProductType.MiQC].key] : undefined
                                        }}
                                        selectedHoldings={[]}
                                    />
                                </>
                            )}
                    </>
            }
        </>
    )
}

export default CertificateDetails