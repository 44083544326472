import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import dayjs from 'dayjs';
import { 
    BaseAttributeOption, 
    Column, 
    ColumnType, 
    DateRange, 
    DeltaLadderOptionCondition, 
    DeltaLevel, 
    DeltaLevelSummary,
    DetailsViewUrlParameters, 
    DisplayCode, 
    ExpandedRows, 
    ProductInfo, 
    RangeUnits, 
    RiskAttributeBreakdownOption, 
    RowDefinition,
    Row, 
    RowType, 
    UrlFragment, 
    DATE_DISPLAY_FORMAT, 
    TOTAL_COLUMN_LABEL, 
    UNCLASSIFIED_COLUMN_KEY 
} from './DeltaLadderTypes';
import { DeltaLadderBody, downloadCsv } from './DeltaLadderBody';
import { ProductState } from './DeltaLadderProductState';

// TODO: Remove references below
import { AttributeCriteria, fetchBalances, fetchByCriteria, ProductCriteria } from '@commodity-desk/common';
import { TrovioCoreApi } from '@trovio-tech/trovio-core-api-js';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

/**
 * Get the date range between two months, indicated by month numbers, relative to the current day
 * @param monthNumber           The month number that ends the date range
 * @param previousMonthNumber   The month number that starts the date range
 * @returns                     a DateRange containing start and end date, and other range info
 */
const getMonthDateRange = (previousMonthNumber: number | undefined, monthNumber: number): DateRange => {
    // We add one day to ensure that new trades exist in the chosen range (rather than in the following range for 1 day)
    const start = dayjs().add(previousMonthNumber ?? 0, 'month').add(1, 'days').startOf('day');
    const end = dayjs().add(monthNumber, 'month').add(1, 'days').startOf('day');
    return new DateRange(previousMonthNumber ?? 0, monthNumber, RangeUnits.MONTH, start, end);
}

/**
 * Fetch forward positions for specific date ranges
 * @param criteria       The product criteria or filters that apply to the current view
 * @param rows           The set of rows to fetch data for, containing the date ranges that apply
 * @param dateAttribute  The value date attribute key, taken from the app config
 * @returns              The forward data response
 */
const fetchForwardData = (api: TrovioCoreApi, criteria: ProductCriteria, rows: Row[], dateAttribute: string): Promise<any>[] => {
    let forwardData = [];
    let originalAttributes = criteria.attributes!;
    for (const row of rows.filter(r => r.rowType === RowType.FORWARD)) {
        let dateRangeCondition = [{code: dateAttribute, value: row.getQueryRangeString()} as AttributeCriteria];
        criteria.attributes = dateRangeCondition.concat(originalAttributes);
        forwardData.push(fetchByCriteria(criteria, api));
    }
    return forwardData;
}

/**
 * Determine whether a set of option conditions (from the input parameters to DeltaLadder) match the 
 * current state of the table. Used to conditionally render some options / functions.
 * 
 * For example, when setting l2AttributeBreakdownOptions or l3BaseAttributes when constructing DeltaLadder, we
 * can specify conditions, like
 * {
 *     displayName: "STATE",
 *     attribute: carbonProjectAttributes.projectState.key,
 *     conditions: [{l1AttributeValue: [ProductType.ACCU]}],
 *     urlParamName: 'projectState'
 * },
 * This condition is {l1AttributeValue: [ProductType.ACCU]}, which means that this entry for the l2AttributeBreakdownOptions
 * is only used when the L1 attribute value selected is equal to ProductType.ACCU. This only happens when ACCU is selected 
 * in the L1 table. Essentially this means we only display the STATE attribute in the L2 attribute dropdown menu when the ACCU
 * product is selected. The same conditions can apply to l3BaseAttributes that we set, allowing us to specify different L3 base
 * attributes for different products.
 */
const optionConditionsMatch = (productCode: string, conditions?: DeltaLadderOptionCondition[]): boolean => {
    if (conditions === undefined) {
        return true;
    }
    for (const condition of conditions) {
        if (condition.l1AttributeValue && condition.l1AttributeValue.includes(productCode)) {
            return true;
        }
    }
    return false;
}

/**
 * The primary component used when rendering a Delta Ladder
 * 
 * @param rowDefinitions                    The definitions for each row we would like to display in the table. Can contain entries for 
 *                                          rows for physical reserves, net delta, as well as any number of forward projections for 
 *                                          customised date ranges
 * @param projectionAttribute               The attribute to use when projecting data across rows. Must be a timestamp type attribute.
 * @param unclassifiedAttributeFilterValue  The Corten filter value that is used to query for unclassified data (usually !*)
 * @param products                          The products to show in the L1 table
 * @param l2AttributeBreakdownOptions       The available options in the attribute selection box in the L2 table. Can contain conditions
 *                                          to only show some entries for certain products
 * @param l3BaseAttributes                  The base attributes to use when displaying the L3 table. Usually only one attribute is specified 
 *                                          per product. Can contain conditions to vary the base attirbute used per product.
 * @param detailsViewUrlParameters          Contains parameters used when constructing the URL that points to the details view page (external)
 * @param projectDetailsUrl                 The URL used to point to the project details page (external)
 * @param numberDecimalPointCharacter       The character to use as a decimal point when rendering numbers (locale specific)
 * @param numberGroupSeparatorCharacter     The character to use as a thousands separator when rendering numbers (locale specific)
 * @param navHoverBackgroundColor           The background to use when hovering over a nav element in a table header (theme specific)
 * @param nestedTableBackgroundColors       The background colours to use when expanding a row by clicking (theme specific)
 * @returns 
 */
const DeltaLadder = ({
    rowDefinitions,
    projectionAttribute,
    unclassifiedAttributeFilterValue,
    products,
    l2AttributeBreakdownOptions,
    l3BaseAttributes,
    detailsViewUrlParameters,
    projectDetailsUrl,
    numberDecimalPointCharacter,
    numberGroupSeparatorCharacter,
    navHoverBackgroundColor,
    nestedTableBackgroundColors
}: {
    rowDefinitions: RowDefinition[],
    projectionAttribute: string,
    unclassifiedAttributeFilterValue: string,
    products: ProductInfo[],
    l2AttributeBreakdownOptions: RiskAttributeBreakdownOption[],
    l3BaseAttributes: BaseAttributeOption[],
    detailsViewUrlParameters: DetailsViewUrlParameters,
    projectDetailsUrl: string,
    numberDecimalPointCharacter: string,
    numberGroupSeparatorCharacter: string,
    navHoverBackgroundColor: string,
    nestedTableBackgroundColors: string[]
}) => {
    // Primary summary refers to the first table on the page, used with L1 and L2 delta levels
    const [primarySummary, setPrimarySummary] = useState<DeltaLevelSummary>();
    // Secondary summary refers to the second table on the page, used with the L3 delta level
    const [secondarySummary, setSecondarySummary] = useState<DeltaLevelSummary>();
    const [loadingL2View, setLoadingL2View] = useState<boolean>(false);

    const productStateRegistry = useRef<Map<string, ProductState>>(new Map());
    const selectedProductState = useRef<ProductState>();
    const { cortenApi } = useCortenApiState();

    const rows = getRowFromDefinitions(rowDefinitions);

    useEffect(() => {
        loadL1Table();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Populates L1 Delta Ladder table, whenever the page is first loaded, or when we are
     * on the L2 table and click the X next to the product button to go back up to L1
     */
    const loadL1Table = () => {
        let updatedRegistry = new Map<string, ProductState>();
        let columns = new Array<Column>();

        const physicalPromise: Promise<any> = fetchBalances({
            criteria: {sumProductItems: true},
            allProductIds: products.map((product) => product.id),
            api: cortenApi
        })
        const forwardPromises = getForwardL1Data(rows);

        Promise.all([physicalPromise].concat(forwardPromises)).then((promiseResponse) => {
            const physicalBalances = promiseResponse[0];
            const forwardBalances = promiseResponse.slice(1);
            for (const product of products) {
                const physicalBalance =
                    physicalBalances.find((b: any) => b.productId === product.id)?.issuerAmount ?? 0 +
                    physicalBalances.find((b: any) => b.productId === product.id)?.escrowAmount ?? 0;

                const forwardBalance = forwardBalances.map(u => u.list.find((b: any) => b.balances.productId === product.id)?.balances?.unassignedAmount ?? 0);
                // default selected attribute to whatever was set as isDefault for the input l2AttributeBreakdownOptions
                const attributeFromRegistry = productStateRegistry.current.get(product.code)?.selectedL2Attribute;
                const defaultAttribute = l2AttributeBreakdownOptions.find(option => optionConditionsMatch(product.code, option.conditions))?.attribute;
                if (defaultAttribute === undefined) {
                    throw new Error(`Default attribute was not defined for product ${product.id}`);
                }
                let selectedAttribute = attributeFromRegistry ?? defaultAttribute;
                let productState = new ProductState({
                    product: product,
                    selectedL2Attribute: selectedAttribute,
                    projectionAttribute: projectionAttribute,
                    unclassifiedAttributeFilterValue: unclassifiedAttributeFilterValue,
                    l3BaseAttributes: l3BaseAttributes,
                    fetchForwardData: fetchForwardData,
                    optionConditionsMatch: optionConditionsMatch,
                    cortenApi: cortenApi
                });

                columns.push(Column.fromBalances(product.code, ColumnType.STANDARD, rows, forwardBalance, physicalBalance, productState.product.code));
                updatedRegistry.set(product.code, productState);
            }
            // update selected product state instance
            let selectedProduct = selectedProductState.current?.product.code
                ? updatedRegistry.get(selectedProductState.current.product.code)
                : undefined;
            selectedProductState.current = selectedProduct;
            productStateRegistry.current = updatedRegistry;
            // if the previously selected product is no longer available, hide secondary summary
            if (!selectedProduct) setSecondarySummary(undefined);
            setPrimarySummary(new DeltaLevelSummary({rows: rows, columns: columns, level: DeltaLevel.L1, key: "ROOT", orderAlphabetically: false}));
        });
    };

    /**
     * Updates the L1 Delta Ladder table after any row containing forward trades is expanded
     * @param baseRow               The key of the base row that is being expanded
     * @param existingExpandedRows  The set of existing set of rows that we are adding expanded rows to
     * @param newExpandedRows       The set of expanded rows that we are expanding with
     */
    const updateL1TableWithExpandedRows = (baseRow: Row, existingExpandedRows: Map<String, ExpandedRows>, newExpandedRows: Row[], expansionDepth: number) => {
        let forwardPromises = getForwardL1Data(newExpandedRows);
        let columns = new Array<Column>();
        Promise.all(forwardPromises).then((forwardBalances) => {
            for (const product of products) {
                const forwardBalance = forwardBalances.map(u => u.list.find((b: any) => b.balances.productId === product.id)?.balances?.unassignedAmount ?? 0);
                columns.push(Column.fromBalances(product.code, ColumnType.STANDARD, newExpandedRows, forwardBalance));
            }
            const updatedExpandedRows = new ExpandedRows(baseRow, newExpandedRows, columns, expansionDepth);
            existingExpandedRows.set(baseRow.label, updatedExpandedRows);
            baseRow.isExpanded = true;
            setPrimarySummary(primarySummary!.clone());
        });
    }

    /**
     * Get L1 (project summary) forward data for the provided date ranges
     * @param rows  Get L1 data for these rows.
     * @returns     An array of promises
     */
    const getForwardL1Data = (rows: Row[]) => {
        const forwardPromises = fetchForwardData(
            cortenApi,   
            {
                productIds: products.map(p => p.id),
                includeBalances: true,
                axes: [],
                isUnassigned: true,
                attributes: []
            },
            rows,
            projectionAttribute
        );
        return forwardPromises;
    }

    /**
     * Populates L2 Delta Ladder table, whenever a Product is selected in L1, or whenever we are
     * already on the L2 table and we change the breakdown attribute from the drop-down select box
     * @param productCode       The code for the selected product
     * @param attributeSummary  The attribute summary data that currently applies
     */
    const loadL2Table = (productCode: string, attributeSummary: DeltaLevelSummary | undefined) => {
        let productState = productStateRegistry.current.get(productCode)!;
        setLoadingL2View(true);
        productState.fetchColumnsByProductCriteria(rows, true).then(columns => {
            let productSummary = new DeltaLevelSummary({rows: rows, columns: columns, level: DeltaLevel.L2, key: productCode, label: productState.product.code, groupBy: productState.selectedL2Attribute});
            if (selectedProductState.current?.product.code !== productState.product.code) {
                // if we have switched products, hide secondary summary
                setSecondarySummary(undefined);
            } else if (attributeSummary !== undefined) {
                // else make sure secondary summary is up-to-date
                loadL3Table(attributeSummary.key!, productSummary);
            }
            // set primary summary AFTER setting product state to ensure the selected attribute value renders correctly
            selectedProductState.current = productState;
            setPrimarySummary(productSummary);
            setLoadingL2View(false);
        });
    };

    /**
     * Updates the L2 Delta Ladder table after any row containing forward trades is expanded
     * @param productCode           The code for the selected product
     * @param baseRow               The key of the base row that is being expanded
     * @param existingExpandedRows  The set of existing set of rows that we are adding expanded rows to
     * @param newExpandedRows       The set of expanded rows that we are expanding with
     */
    const updateL2TableWithExpandedRows = (productCode: string, baseRow: Row, existingExpandedRows: Map<String, ExpandedRows>, newExpandedRows: Row[], expansionDepth: number) => {
        let productState = productStateRegistry.current.get(productCode)!;
        productState.fetchColumnsByProductCriteria(newExpandedRows, false, primarySummary!).then(columns => {
            const updatedExpandedRows = new ExpandedRows(baseRow, newExpandedRows, columns, expansionDepth);
            existingExpandedRows.set(baseRow.label, updatedExpandedRows);
            baseRow.isExpanded = true;
            setPrimarySummary(primarySummary!.clone());
        });

    };

    /**
     * Populates L3 Delta Ladder table, whenever an attribute column is selected in the L2 table
     * @param code              The code for the selected column in the L2 table
     * @param attributeSummary  The attribute summary data that currently applies
     */
    const loadL3Table = (code: string, productSummary: DeltaLevelSummary) => {
        let productState = selectedProductState.current!;
        productState.fetchColumnsByAttributeCriteria(code === UNCLASSIFIED_COLUMN_KEY ? unclassifiedAttributeFilterValue : code, rows, true).then(columns => {
            let secondary = new DeltaLevelSummary({
                rows: rows,
                columns: columns,
                level: DeltaLevel.L3,
                key: code,
                label: productSummary.columns.find(i => i.key === code)?.label,
                groupBy: productState.selectedL2Attribute,
                expandedRows: new Map<string, ExpandedRows>(),
                parent: productSummary
            });
            setSecondarySummary(secondary);
        });
    };

    /**
     * Updates the L3 Delta Ladder table after any row containing forward trades is expanded
     * @param code                  The value of the attribute selected in the L2 table
     * @param baseRow               The key of the base row that is being expanded
     * @param existingExpandedRows  The set of existing set of rows that we are adding expanded rows to
     * @param newExpandedRows       The set of expanded rows that we are expanding with
     */
    const updateL3TableWithExpandedRows = (code: string, baseRow: Row, existingExpandedRows: Map<String, ExpandedRows>, newExpandedRows: Row[], expansionDepth: number) => {
        let productState = selectedProductState.current!;
        productState.fetchColumnsByAttributeCriteria(code === UNCLASSIFIED_COLUMN_KEY ? unclassifiedAttributeFilterValue : code, newExpandedRows, false, secondarySummary!).then(columns => {
            const updatedExpandedRows = new ExpandedRows(baseRow, newExpandedRows, columns, expansionDepth);
            existingExpandedRows.set(baseRow.label, updatedExpandedRows);
            baseRow.isExpanded = true;
            setSecondarySummary(secondarySummary!.clone());
        });
    };

    /**
     * Opens a link to the project details page when a base attribute is clicked
     * @param code  The code for the selected base attribute
     */
    const selectL3ProductBaseAttribute = (code: string) => {
        let projectPageUrl = projectDetailsUrl
            .replace(UrlFragment.PRODUCT_ID, selectedProductState.current!.product.id)
            .replace(UrlFragment.PRODUCT_BASE, selectedProductState.current!.product.base)
            .replace(UrlFragment.L3_BASE_ATTRIBUTE_VALUE, code);
        window.open(projectPageUrl, '_blank');
    }

    /**
     * Carries out the relevant actions when a column label is clicked
     * @param key           The key of the column that was clicked
     * @param summaryLevel  The summary level that applies to the table
     */
    const selectColumn = (key: string, summaryLevel: DeltaLevel) => {
        switch (summaryLevel) {
            case DeltaLevel.L1:
                loadL2Table(key, secondarySummary);
                break;
            case DeltaLevel.L2:
                loadL3Table(key, primarySummary!);
                break;
            case DeltaLevel.L3:
                selectL3ProductBaseAttribute(key);
                break;
        }
    };

    /**
     * Actions that take place when the group-by attribute selection box is changed
     * @param event  The change event
     */
    const attributeChanged = (event: SelectChangeEvent) => {
        selectedProductState.current!.selectedL2Attribute = event.target.value;
        setSecondarySummary(undefined);
        // reload L2 table, grouped by the new attribute
        loadL2Table(selectedProductState.current!.product.code, undefined);
    };

    /**
     * Open a link to the position risk holding page when any relevant amounts cell in the table is clicked
     * @param event    The click event
     * @param summary  The current summary data
     * @param column   The column that corresponds to the cell that was clicked on
     * @param row      The row that corresponds to the cell that was clicked on
     */
    const handleAmountClick = (event: any, summary: DeltaLevelSummary, column: Column, row: Row) => {
        event.stopPropagation(); // stops the event chaining up to the row level (to handleRowClick)

        const applyOtherUrlParams = (params: string[], currentProductState: ProductState) => {
            for (const otherUrlParam of detailsViewUrlParameters.otherUrlParams) {
                if (otherUrlParam[0] === UrlFragment.PRODUCT_ID) {
                    params.push(`${otherUrlParam[1]}=${currentProductState.product.id}`);
                } else if (otherUrlParam[0] === UrlFragment.PRODUCT_BASE) {
                    params.push(`${otherUrlParam[1]}=${currentProductState.product.base}`);
                }
            }
        }

        let parameters: string[] = [];
        if (summary.level === DeltaLevel.L1) {
            if (column.columnType !== ColumnType.TOTAL) {
                applyOtherUrlParams(parameters, productStateRegistry.current.get(column.key)!);
            }
        } else {
            applyOtherUrlParams(parameters, selectedProductState.current!);
            if ((summary.level === DeltaLevel.L2 && column.columnType !== ColumnType.TOTAL) || summary.level === DeltaLevel.L3) {
                const breakdownOption = l2AttributeBreakdownOptions.find(breakdownOption => breakdownOption.attribute === selectedProductState.current?.selectedL2Attribute);
                if (breakdownOption) {
                    parameters.push(`${breakdownOption.urlParamName}=${summary.level === DeltaLevel.L2
                        ? (column.key !== UNCLASSIFIED_COLUMN_KEY ? column.key : null)
                        : (summary.key !== UNCLASSIFIED_COLUMN_KEY ? summary.key : null)
                    }`);
                }
            }
            if (summary.level === DeltaLevel.L3) {
                if (column.columnType !== ColumnType.TOTAL) {
                    const baseAttribute = selectedProductState.current!.getL3BaseAttribute();
                    parameters.push(`${baseAttribute.urlParamName}=${column.key !== UNCLASSIFIED_COLUMN_KEY ? column.key : null}`);
                }
            }
        }
        if (row.dateRange !== undefined) {
            parameters.push(`${detailsViewUrlParameters.valueDateStartUrlParam}=${row.dateRange.startDate.toISOString()}`);
            parameters.push(`${detailsViewUrlParameters.valueDateEndUrlParam}=${row.dateRange.endDate.toISOString()}`);
        }
        const parameterString = parameters.length > 0 ? '?' + parameters.join('&') : '';
        window.open(encodeURI(`${detailsViewUrlParameters.baseUrl}${parameterString}`), '_blank');
    };

    /**
     * Handle a row click event in the delta level table. Currently used to expand and contract forward trade rows.
     * @param event             The row click event
     * @param baseRow           The base row that was clicked on
     * @param summary           The current summary data
     * @param fromExpandedRows  The current set of expanded rows, if we are drilling down further within these
     */
    const handleRowClick = (event: any, baseRow: Row, summary: DeltaLevelSummary, fromExpandedRows?: ExpandedRows) => {
        event.stopPropagation();
        if (baseRow.canExpand()) {
            setExpandedRowData(baseRow, summary, fromExpandedRows);
        }
    };

    /**
     * Expand a row of forwards data into smaller time ranges when clicked
     * @param row               The base row that was clicked on, and that we want to expand
     * @param summary           The current summary data
     * @param fromExpandedRows  The current set of expanded rows, if we are drilling down further within these
     */
    const setExpandedRowData = (baseRow: Row, summary: DeltaLevelSummary, fromExpandedRows?: ExpandedRows) => {

        if (baseRow.isExpanded) {
            // Row is already expanded, so now close it
            if (fromExpandedRows) {
                fromExpandedRows.sublevelExpandedRows.delete(baseRow.label)
            }
            if (summary.level === DeltaLevel.L3) {
                if (!fromExpandedRows) {
                    secondarySummary?.expandedRows.delete(baseRow.label);
                }
                setSecondarySummary(secondarySummary!.clone());
            } else {
                if (!fromExpandedRows) {
                    primarySummary?.expandedRows.delete(baseRow.label);
                }
                setPrimarySummary(primarySummary!.clone());
            }
            baseRow.isExpanded = false;
        } else {
            // Expand the row
            let expandedRows: Row[] = [];
            if (baseRow.dateRange?.rangeUnits === RangeUnits.MONTH) {
                if (baseRow.dateRange!.rangeEnd - baseRow.dateRange!.rangeStart > 1) {
                    expandedRows = getForwardMonthlyRows(baseRow.dateRange!);
                } else {
                    expandedRows = getForwardWeeklyRows(baseRow.dateRange!);
                }
            } else if (baseRow.dateRange?.rangeUnits === RangeUnits.WEEK) {
                expandedRows = getForwardDailyRows(baseRow.dateRange!);
            } else {
                return; // Cannot drill down further once showing RangeUnits.DAY
            }
            const existingExpandedRows =
                fromExpandedRows ? fromExpandedRows.sublevelExpandedRows
                : summary.level === DeltaLevel.L3 ? secondarySummary!.expandedRows : primarySummary!.expandedRows;
            const expansionDepth = fromExpandedRows ? fromExpandedRows.expansionDepth + 1 : 1;
            if (summary.level === DeltaLevel.L1) {
                updateL1TableWithExpandedRows(baseRow, existingExpandedRows, expandedRows, expansionDepth);
            } else if (summary.level === DeltaLevel.L2) {
                updateL2TableWithExpandedRows(selectedProductState.current!.product.code, baseRow, existingExpandedRows, expandedRows, expansionDepth);
            } else if (summary.level === DeltaLevel.L3) {
                updateL3TableWithExpandedRows(summary.key, baseRow, existingExpandedRows, expandedRows, expansionDepth);
            } else {
                throw new Error("Unknown summary level");
            }
        }
    }

    function getRowFromDefinitions(rowDefinitions: RowDefinition[]): Row[] {
        let rows: Row[] = [];
        let previousForwardMonthNumber = undefined;
        for (let rowDefinition of rowDefinitions) {
            if (rowDefinition.rowType === RowType.FORWARD) {
                let dateRange = getMonthDateRange(previousForwardMonthNumber, rowDefinition.monthNumber!);
                rows.push(new Row(rows.length, rowDefinition.label, RowType.FORWARD, dateRange));
                previousForwardMonthNumber = rowDefinition.monthNumber!;
            } else {
                rows.push(new Row(rows.length, rowDefinition.label, rowDefinition.rowType));
            }
        }
        return rows;
    }

    /**
     * Get the forward data range for months within the provided start and end date range.
     * @param dateRange  The date range that we want to fetch all months for
     * @returns          An array of Row objects that contain row info including start and end dates
     */
    const getForwardMonthlyRows = (dateRange: DateRange): Row[] => {
        const rows: Row[] = [];
        if (dateRange.startDate <= dateRange.endDate) {
            let monthStart = dateRange.startDate;
            let monthEnd = dateRange.startDate;
            let monthCount = 0;
            while (true) {
                if (monthEnd > dateRange.startDate) {
                    const monthEndNumber = dateRange.rangeStart + monthCount;
                    const newDateRange = new DateRange(dateRange.rangeStart, dateRange.rangeStart, RangeUnits.MONTH, monthStart, monthEnd >= dateRange.endDate ? dateRange.endDate : monthEnd);
                    rows.push(new Row(rows.length, `${monthEndNumber}M`, RowType.FORWARD, newDateRange));
                    if (monthEnd >= dateRange.endDate) {
                        break;
                    }
                }
                monthStart = monthEnd;
                monthEnd = monthEnd.add(1, "month");
                monthCount += 1;
            }
        }
        return rows;
    }

    /**
     * Get the forward data range for individual weeks within the provided start and end date range. Weeks are calculated
     * from the current date onwards. Partial weeks that overlap with the provided start and end date range will be included.
     * @param dateRange  The date range that we want to fetch all weeks for
     * @returns          An array of Row objects that contain row info including start and end dates
     */
    const getForwardWeeklyRows = (dateRange: DateRange): Row[] => {

        const getWeeklyRowLabel = (start: dayjs.Dayjs, end: dayjs.Dayjs, index: number) => {
            return `Week ${index}${end.diff(start, 'day') >= 7 ? '' : ' (partial)'}`;
        }

        const rows: Row[] = [];
        // We add one day to be compatible with the month ranges chosen in getMonthDateRange
        const today = dayjs().startOf('day').add(1, 'days');
        if (dateRange.startDate >= today && dateRange.endDate > dateRange.startDate) {
            let weekStart = today;
            let weekEnd = today;
            let weekCount = 0;
            while (true) {
                if (weekEnd > dateRange.startDate) {
                    if (rows.length === 0) {
                        const newDateRange = new DateRange(weekCount, weekCount, RangeUnits.WEEK, dateRange.startDate, weekEnd);
                        rows.push(new Row(rows.length, getWeeklyRowLabel(dateRange.startDate, weekEnd, weekCount), RowType.FORWARD, newDateRange));
                    } else {
                        if (weekEnd >= dateRange.endDate) {
                            const newDateRange = new DateRange(weekCount, weekCount, RangeUnits.WEEK, weekStart, dateRange.endDate);
                            rows.push(new Row(rows.length, getWeeklyRowLabel(weekStart, dateRange.endDate, weekCount), RowType.FORWARD, newDateRange));
                            break;
                        } else {
                            const newDateRange = new DateRange(weekCount, weekCount, RangeUnits.WEEK, weekStart, weekEnd);
                            rows.push(new Row(rows.length, getWeeklyRowLabel(weekStart, weekEnd, weekCount), RowType.FORWARD, newDateRange));
                        }
                    }
                }
                weekStart = weekEnd;
                weekEnd = weekEnd.add(1, "week");
                weekCount += 1;
            }
        }
        return rows;
    }

    /**
     * Get the forward data range for individual days within the provided start and end date range.
     * @param dateRange  The date range that we want to fetch all days for
     * @returns          An array of Row objects that contain row info including start and end dates
     */
    const getForwardDailyRows = (dateRange: DateRange): Row[] => {

        const rows: Row[] = [];
        if (dateRange.endDate > dateRange.startDate) {
            let currentDay = dateRange.startDate;
            let dayCount = 1;
            while (currentDay < dateRange.endDate) {
                const newDateRange = new DateRange(dayCount, dayCount, RangeUnits.DAY, currentDay, currentDay.add(1, "day"));
                rows.push(new Row(rows.length, `${currentDay.format(DATE_DISPLAY_FORMAT)}`, RowType.FORWARD, newDateRange));
                currentDay = currentDay.add(1, "day");
                dayCount += 1;
            }
        }
        return rows;
    }

    return (
        <>
            {primarySummary === undefined ? <LinearProgress/> : <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        disabled={primarySummary.is(DeltaLevel.L1)}
                                        startIcon={!primarySummary.is(DeltaLevel.L1) && <CloseIcon />}
                                        onClick={() => loadL1Table()}>
                                    {primarySummary.is(DeltaLevel.L2) ? primarySummary.label : 'Product'}
                                </Button>
                                {primarySummary.is(DeltaLevel.L2) && <FormControl size='small'>
                                    <Select sx={{ fontSize: '0.875rem', marginRight: 1 }} value={selectedProductState.current?.selectedL2Attribute} onChange={attributeChanged}>
                                        {l2AttributeBreakdownOptions.filter(l2BreakdownOption => selectedProductState.current && optionConditionsMatch(selectedProductState.current.product.code, l2BreakdownOption.conditions)).map(l2BreakdownOption => (
                                            <MenuItem key={l2BreakdownOption.attribute} value={l2BreakdownOption.attribute}>{l2BreakdownOption.displayName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        onClick={() => downloadCsv(primarySummary)}>
                                    {'Export CSV'}
                                </Button>
                            </TableCell>
                            {primarySummary.columns.map(column => (
                                <TableCell key={column.key} align='right'>
                                    <Button variant={column.key === secondarySummary?.key ? 'outlined' : 'text'}
                                            onClick={() => selectColumn(column.key, primarySummary.level)}
                                            disabled={loadingL2View}
                                            sx={{
                                                justifyContent: 'right',
                                                '&:hover': {
                                                    backgroundColor: navHoverBackgroundColor
                                                }
                                            }}>
                                        {column.label}
                                    </Button>
                                </TableCell>
                            ))}
                            <TableCell align='right' sx={{ fontWeight: 'bold' }}><Box sx={{paddingRight: '8px'}}>{TOTAL_COLUMN_LABEL}</Box></TableCell>
                        </TableRow>
                    </TableHead>
                    <DeltaLadderBody
                        summary={primarySummary}
                        handleAmountClick={handleAmountClick}
                        handleRowClick={handleRowClick}
                        numberDecimalPointCharacter={numberDecimalPointCharacter}
                        numberGroupSeparatorCharacter={numberGroupSeparatorCharacter}
                        navHoverBackgroundColor={navHoverBackgroundColor}
                        nestedTableBackgroundColors={nestedTableBackgroundColors}
                        productState={selectedProductState.current}
                    />
                </Table>
            </TableContainer>}

            {secondarySummary && <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell>
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        disabled={secondarySummary.is(DeltaLevel.L1)}
                                        startIcon={!secondarySummary.is(DeltaLevel.L1) && <CloseIcon />}
                                        onClick={() => setSecondarySummary(undefined)}>
                                    {secondarySummary.label}
                                </Button>
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        onClick={() => downloadCsv(secondarySummary)}>
                                    {'Export CSV'}
                                </Button>
                            </TableCell>
                            {secondarySummary.columns.map(column => (
                                <TableCell key={column.key} align='right'>
                                    { (column.key === UNCLASSIFIED_COLUMN_KEY) ? (
                                        <Typography>
                                            {column.label}
                                        </Typography>
                                    ) : (
                                        <Button onClick={() => selectColumn(column.key, secondarySummary.level)}
                                                endIcon={<LaunchIcon />}
                                                sx={{'&:hover': {
                                                    backgroundColor: navHoverBackgroundColor,
                                                }}}>
                                            {column.label}
                                        </Button>
                                    )}
                                </TableCell>
                            ))}
                            <TableCell align='right' sx={{ fontWeight: 'bold' }}><Box sx={{paddingRight: '8px'}}>{TOTAL_COLUMN_LABEL}</Box></TableCell>
                        </TableRow>
                    </TableHead>
                    <DeltaLadderBody
                        summary={secondarySummary}
                        handleAmountClick={handleAmountClick}
                        handleRowClick={handleRowClick}
                        numberDecimalPointCharacter={numberDecimalPointCharacter}
                        numberGroupSeparatorCharacter={numberGroupSeparatorCharacter}
                        navHoverBackgroundColor={navHoverBackgroundColor}
                        nestedTableBackgroundColors={nestedTableBackgroundColors}
                        productState={selectedProductState.current}
                    />
                </Table>
            </TableContainer>}
        </>
    );
};

export {
    DeltaLadder,
    type DisplayCode,
    type BaseAttributeOption,
    type ProductInfo,
    RowType
};
