var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Download } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { DataTableAppliedFilters, DataTableColumnVisibilityDropdown, DataTableDensityToggle, DataTableMultiFilterDropdown } from '@trovio-ui-libs/ui';
import { download, generateCsv, mkConfig } from 'export-to-csv';
export var DataTableToolbar = function (_a) {
    var table = _a.table, exportFileName = _a.exportFileName;
    return (_jsxs(Box, __assign({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, { children: [_jsx(DataTableAppliedFilters, { table: table }), _jsxs(Box, __assign({ display: 'flex', alignItems: 'center', gap: 1 }, { children: [_jsx(DataTableDensityToggle, { table: table, hideLabel: true }), _jsx(DataTableColumnVisibilityDropdown, { table: table, label: "Columns" }), _jsx(DataTableMultiFilterDropdown, { table: table }), _jsxs(Button, __assign({ size: "small", onClick: function () {
                            var _a;
                            var csvConfig = mkConfig({
                                filename: exportFileName || 'export.csv',
                                fieldSeparator: ',',
                                decimalSeparator: '.',
                                quoteCharacter: '"',
                                useKeysAsHeaders: false,
                                columnHeaders: table.getVisibleFlatColumns().map(function (column) { var _a, _b; return (_b = (_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.headerLabel) !== null && _b !== void 0 ? _b : column.id; })
                            });
                            var rowData = (_a = table
                                .getPrePaginationRowModel()
                                .rows) === null || _a === void 0 ? void 0 : _a.map(function (row) {
                                var rowForCsvExport = {};
                                table.getVisibleFlatColumns().forEach(function (column) {
                                    var _a, _b;
                                    if (row.original.hasOwnProperty(column.id)) {
                                        rowForCsvExport[(_b = (_a = column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.headerLabel) !== null && _b !== void 0 ? _b : column.id] = row.original[column.id];
                                    }
                                });
                                return rowForCsvExport;
                            });
                            var csv = generateCsv(csvConfig)(rowData);
                            download(csvConfig)(csv);
                        } }, { children: [_jsx(Download, { sx: { fontSize: 16, mr: 0.5 } }), "Export"] }))] }))] })));
};
