import InventoryManagement from './InventoryManagement';
import { InventoryManagementProvider } from './state/InventoryManagementState';
import { ProductProvider } from './state/ProductState';

const ContainInventoryManagement = () => {
    return (
        <ProductProvider>
            <InventoryManagementProvider>
                <InventoryManagement />
            </InventoryManagementProvider>
        </ProductProvider>
    );
};

export default ContainInventoryManagement;
