import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LinearProgress } from '@mui/material';
import { useProjectDetailsState } from './state/ProjectDetailsState';
import { useAppNavigate } from '@commodity-desk/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { homepage } from '../../state/Variables';

const Vintages = () => {
    const projectDetailsState = useProjectDetailsState();
    const { vintages, loading } = projectDetailsState;

    // Extract productID & projectId from URL (/app/inventory-management/product/:productID/project/:projectID/vintages/:year)
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productID = splitLocation[4];
    const projectID = splitLocation[6];

    const navigate= useNavigate();
    const appNavigate = useAppNavigate({homepage, navigate});

    return (
        <>
        {loading ?
            <LinearProgress />
            :
            <TableContainer component={Paper} sx={{ minWidth: 750 }}>
                <Table sx={{ minWidth: 750 }} size='small' aria-label="vintages table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Vintages</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vintages.map((vintage: number, index: number) => (
                            <TableRow
                                hover
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    cursor: 'pointer'
                                }}
                                onDoubleClick={() =>
                                    appNavigate(
                                        `/inventory-management/product/${productID}/project/${projectID}/vintages/${vintage}`
                                    )
                                }
                            >
                                <TableCell>{vintage}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </>
    );
};

export default Vintages;
