import { carbonProjectAttributes, transactionAttributes } from "../state/AppConfig";
import { containsExpr, endsWithExpr, equalsExpr, inExpr, isAfterExpr, isBeforeExpr, isGreaterExpr, isGreaterOrEqualExpr, isLessExpr, isLessOrEqualExpr, isOnOrAfterExpr, isOnOrBeforeExpr, notEqualsExpr, rangeExpr, startsWithExpr, timeRangeExpr } from "@trovio-tech/trovio-core-api-js";
import Big from "big.js";
var dataTableFilterToCoreFilter = function (_a) {
    var item = _a.item, _b = _a.unitAmount, unitAmount = _b === void 0 ? 1 : _b, _c = _a.isDate, isDate = _c === void 0 ? false : _c, _d = _a.containsTime, containsTime = _d === void 0 ? false : _d;
    var filterValue = item.value;
    if (filterValue === '*') {
        // Filter for attributes that has any value other than undefined
        // Eg: When filtering for entries where greenPowerAccredited is either true or false and value is not undefined
        // (mainly when sorting with greenPowerAccredited, so sorted results will only include true/false entries),
        // this function should be called with filterValue === BOOLEAN_NOT_NULL and with equals operator.
        // When filtering for entries where any string or date field (eg: project state, trade date) has any values and not undefined 
        // (mainly used when sorting by the field to remove entries where value for sort field is missing),
        // this function should be called with filterValue === * and with equals operator.
        return '*';
    }
    if (isDate) {
        if (item.operator === 'range') {
            var value = filterValue === null || filterValue === void 0 ? void 0 : filterValue.split('..');
            var startDate = value[0];
            var endDate = value[1];
            if (startDate && endDate) {
                // Pass values as Date for timeRangeExpr
                return timeRangeExpr(new Date(startDate), new Date(endDate));
            }
            // Pass value as string for equalsExpr
            return equalsExpr(startDate);
        }
        // MUI datagrid seems to provide date time values as YYYY-mm-DDTHH:MM but
        // date as YYYY-mm-DD
        // https://github.com/mui/mui-x/blob/ffc58b377c91d2d991d59e34d407ca4fdde119f8/packages/grid/x-data-grid/src/colDef/gridDateOperators.ts#L7
        // 
        // JS Date seems to parse Date in local time if we supply the hours and minutes 
        // but UTC time if only the date component is supplied. Therefore, we need to add
        // the 'T00:00' part here
        var dateValue = new Date(containsTime ? filterValue : "".concat(filterValue, "T00:00"));
        if (item.operator === 'onOrAfter') {
            return isOnOrAfterExpr(dateValue);
        }
        else if (item.operator === 'after') {
            return isAfterExpr(dateValue, containsTime);
        }
        else if (item.operator === 'before') {
            return isBeforeExpr(dateValue);
        }
        else if (item.operator === 'onOrBefore') {
            return isOnOrBeforeExpr(dateValue, containsTime);
        }
        else if (item.operator === 'is') {
            var startOfDay = new Date(dateValue);
            startOfDay.setHours(0, 0, 0, 0);
            var startOfNextDay = new Date(dateValue);
            startOfNextDay.setDate(startOfNextDay.getDate() + 1);
            startOfNextDay.setHours(0, 0, 0, 0);
            return timeRangeExpr(startOfDay, startOfNextDay);
        }
        else if (item.operator === '!=') {
            return notEqualsExpr(dateValue);
        }
        throw Error("Unexpected Operator Type for Date: ".concat(item.operator));
    }
    else if (['>', '<='].includes(item.operator)) {
        return item.operator === '>' ? isGreaterExpr(Big(filterValue), Big(unitAmount)) : isLessOrEqualExpr(Big(filterValue), Big(unitAmount));
    }
    else if (item.operator === 'range') {
        // Adding unitAmount to the end value, as corten treats all ranges as end exclusive
        // Input Range filter value is in the format of 'start..end', so we need to split it to get the start and end value
        var value = filterValue.split('..');
        var startValue = Big(value[0]);
        var endValue = Big(value[1]).plus(Big(unitAmount !== null && unitAmount !== void 0 ? unitAmount : 1));
        return rangeExpr(startValue, endValue);
    }
    else if (filterValue === undefined || filterValue === '') {
        return '';
    }
    else if (item.operator === 'equals' && filterValue === '*') {
        // Do not wrap with quotes
        filterValue = '*';
    }
    else if (item.operator === 'equals' && typeof item.value === "number") {
        filterValue = equalsExpr(Big(filterValue));
    }
    else if (item.operator === 'equals') {
        filterValue = equalsExpr(filterValue);
    }
    else if (item.operator === 'contains') {
        filterValue = containsExpr(filterValue);
    }
    else if (item.operator === 'startsWith') {
        filterValue = startsWithExpr(filterValue);
    }
    else if (item.operator === 'endsWith') {
        filterValue = endsWithExpr(filterValue);
    }
    else if (item.operator === 'isAnyOf') {
        filterValue = inExpr(filterValue);
    }
    else if (item.operator === '!=' && typeof item.value === "number") {
        filterValue = notEqualsExpr(Big(filterValue));
    }
    else if (item.operator === '!=') {
        return notEqualsExpr(filterValue);
    }
    else if (item.operator === '>=') {
        filterValue = isGreaterOrEqualExpr(Big(filterValue));
    }
    else if (item.operator === '<') {
        filterValue = isLessExpr(Big(filterValue));
    }
    // If operator is not any of the above, return the filter value as is. 
    // Since all number operations are already handled, not need for Big conversion here.
    return filterValue;
};
/**
 * Function to construct the sorting component of the /history API request.
 *
 * @param {ColumnSort[]} items a list of items based on which to construct the sorting URI component
 * @param defaultSort The default sort method to use, if no custom columns are to be used in sorting
 * @param appConfigState application config state
 * @param isProductItemApiQuery A boolean indicating whether this function is being used for a product item API query.
 *                              When set, some of the keywords used to reference attributes change, as the API uses
 *                              slightly different naming.
 * @param {Map<String, FieldDefinition>} fieldDefinitionMap a map of field definitions,
 *                                                          used to determine the type of the field and the operator to apply
 *
 * @returns {string} a string that will be the sorting component of the request URI
 */
var dataTableSortToCoreSortString = function (_a) {
    var items = _a.items, defaultSort = _a.defaultSort, appConfigState = _a.appConfigState, isProductItemApiQuery = _a.isProductItemApiQuery, fieldDefinitionMap = _a.fieldDefinitionMap;
    var sortItems = [];
    var transactionSortApplied = false;
    items
        .forEach(function (item) {
        var directionChar = item.desc ? '-' : '+';
        var productItemAttributeLabel = 'productItemAttribute';
        var attributeLabel = 'transactionAttribute';
        if (isProductItemApiQuery) {
            // For the product item API, which queries product items, all transaction / trade level attributes
            // apply to the product item itself, alongside the usual product level attributes. The API requires
            // the use of the productItemAttribute label to sort on any attribute.
            attributeLabel = 'productItemAttribute';
        }
        switch (item.id) {
            case 'transactionTimestamp':
                sortItems.push("".concat(directionChar, "transaction"));
                transactionSortApplied = true;
                break;
            case 'quantity':
                if (isProductItemApiQuery) {
                    sortItems.push("".concat(directionChar, "unassignedAmount"));
                }
                else {
                    sortItems.push("".concat(directionChar, "amount"));
                }
                break;
            case 'projectState':
                // TODO: Field is named differently in Holdings.tsx - reconcile this and delete this entry afterwards
                sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(carbonProjectAttributes.projectState.key));
                break;
            case 'transactionType':
                sortItems.push("".concat(directionChar).concat(attributeLabel, ".").concat(transactionAttributes.transactionTypeInventory.key));
                break;
            default:
                var fieldDefinition = fieldDefinitionMap.get(item.id);
                if (fieldDefinition === undefined) {
                    throw new Error("Could not find field definition matching sort item ".concat(item.id));
                }
                if (fieldDefinition.attribute === undefined) {
                    throw new Error("No attribute was specified for sortable field ".concat(item.id));
                }
                if (fieldDefinition.attribute.group === 'TRADE') {
                    sortItems.push("".concat(directionChar).concat(attributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else if (fieldDefinition.attribute.group === 'PROJECT') {
                    sortItems.push("".concat(directionChar).concat(productItemAttributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else if (fieldDefinition.attribute.group === 'SHARED') {
                    sortItems.push("".concat(directionChar).concat(attributeLabel, ".").concat(fieldDefinition.attribute.key));
                }
                else {
                    throw new Error("Unknown attribute group ".concat(fieldDefinition.attribute.group, " in dataTableSortToCoreSortString"));
                }
                break;
        }
    });
    // If a default sort is specified and we are not sorting by any custom columns, then use default sort
    if (defaultSort !== undefined && sortItems.length === 0) {
        sortItems.push(defaultSort);
    }
    if (!transactionSortApplied && !isProductItemApiQuery) {
        // We want to apply a default sort of reverse chronological order if
        // - no sorting is being applied
        // - sorting on fields other than timestamp is applied (so that items
        //   with the same value are then further sorted)
        // This is only done for the history API and not for the product item API
        sortItems.push('-transaction');
    }
    return sortItems;
};
export { dataTableFilterToCoreFilter, dataTableSortToCoreSortString };
