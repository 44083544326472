/**
 * Utility function to transform the corten config so that it is overriden
 * with values from this app
 * @param {any} config the original corten config
 * @returns {any} transformed config with the overrides from this app
 */
var transformConfig = function (config, loginCallbackPath, logoutCallBackPath) {
    config.redirectUri = loginCallbackPath;
    config.postLogoutRedirectUri = logoutCallBackPath;
    return config;
};
export { transformConfig };
