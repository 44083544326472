var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
export var OidcLogin = function (_a) {
    var user = _a.user, signIn = _a.signIn, signOut = _a.signOut, loading = _a.loading, enabled = _a.enabled, currentKey = _a.currentKey, keys = _a.keys;
    var _b = useState(), currentKeyId = _b[0], setCurrentKeyId = _b[1];
    var _c = useState(), displayName = _c[0], setDisplayName = _c[1];
    function shortAccountId(id) {
        if (id) {
            return id.substring(0, 10) + "..." + id.substring(id.length - 8);
        }
        else
            return "...";
    }
    function handleCopy(id) {
        navigator.clipboard.writeText(id);
    }
    useEffect(function () {
        setCurrentKeyId(currentKey);
    }, [currentKey]);
    useEffect(function () {
        var _a;
        // Skip reading displayName if keys is undefined or empty
        if (keys) {
            setDisplayName((_a = keys[currentKey]) === null || _a === void 0 ? void 0 : _a.name);
        }
    }, [currentKey, keys]);
    return (_jsx(Box, __assign({ sx: { marginTop: 'auto', paddingTop: 2, borderTop: 'solid 1px #D3D3D3' } }, { children: enabled ? (!user ? (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '1rem' } }, { children: "Log in with a configured OIDC provider:" })), _jsxs(Button, __assign({ onClick: signIn, variant: "outlined", disabled: loading }, { children: [_jsx(Typography, __assign({ sx: { fontSize: '1em' } }, { children: "Login" })), loading && (_jsx(CircularProgress, { size: '1.5em', sx: { marginLeft: '10px' } }))] }))] })) : (_jsxs(Box, { children: [displayName && (_jsxs(Typography, __assign({ sx: { marginBottom: '1rem' } }, { children: ["You are logged in as ", displayName] }))), _jsxs(Card, __assign({ variant: 'outlined', sx: {
                        display: 'flex',
                        outlineStyle: 'double',
                        marginTop: 1,
                        marginBottom: 2
                    } }, { children: [_jsx(CardActionArea, { children: _jsx(Tooltip, __assign({ title: "Authenticated account" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ component: "div", variant: "h8" }, { children: "Account id" })), currentKeyId === ''
                                            ?
                                                _jsx(Typography, __assign({ variant: "subtitle2", color: "text.secondary", component: "div" }, { children: "Account ID unavailable" }))
                                            :
                                                _jsx(Typography, __assign({ variant: "subtitle2", color: "text.secondary", component: "div" }, { children: shortAccountId(currentKeyId) }))] }) })) }), _jsx(CardActions, { children: _jsx(Grid, __assign({ container: true }, { children: _jsx(Grid, __assign({ item: true }, { children: _jsx(Tooltip, __assign({ title: "Copy id to clipboard" }, { children: _jsx(IconButton, __assign({ size: "small", sx: { color: 'primary.main' }, onClick: function () { return handleCopy(currentKeyId); } }, { children: _jsx(FontAwesomeIcon, { icon: faCopy }) })) })) })) })) })] })), _jsx(Button, __assign({ onClick: signOut, variant: "outlined" }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1em' } }, { children: "Logout" })) }))] }))) : (_jsx("div", { children: _jsx(Typography, { children: "OIDC login disabled." }) })) })));
};
