var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { AmountFormatWrapper } from './AmountFormatWrapper';
import { UNDEFINED_CODE_INTEGER, UNDEFINED_CODE_STRING } from '../component/trade/TradeUtil';
var renderDialogField = function (fieldName, fieldValue, amountRenderProp) {
    var renderValue;
    if (amountRenderProp && fieldValue) {
        renderValue = (_jsx(AmountFormatWrapper, { amount: fieldValue, minDecimalPos: amountRenderProp.minDecimalPos, maxDecimalPos: amountRenderProp.maxDecimalPos }));
    }
    else if (fieldValue) {
        renderValue = fieldValue;
    }
    else {
        renderValue = '-';
    }
    return (_jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' } }, { children: [_jsxs(Typography, __assign({ sx: { flex: '1' } }, { children: [fieldName, ":"] })), _jsx(Typography, __assign({ sx: { flex: '1', textAlign: 'right' } }, { children: renderValue }))] })));
};
var renderFieldValue = function (fieldValue) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === '' || fieldValue === UNDEFINED_CODE_STRING || fieldValue === parseInt(UNDEFINED_CODE_INTEGER)) {
        return '-';
    }
    return fieldValue;
};
export { renderDialogField, renderFieldValue };
