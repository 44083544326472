import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography
} from '@mui/material';
import { useDecarboniseDispatch, useDecarboniseState } from './state/DecarboniseState';
import { useForm, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { DialogState, useCommoditiesState } from './state/CommoditiesState';
import { 
    AlertDialogContent, 
    AlertType,
    AmountFormatWrapper,
    ControlledTextField,
    emissionAttributes,
    fetchProductItemsWithId,
    getFieldDefinitionMap,
    inventoryAccount,
    ProductType,
    carbonProjectAttributes,
    renderDialogField,
    renderEntry,
    ResettableFormFields,
    TransactionOverview,
    useAppConfigState,
    useProductDataState,
    useProductItemFilters,
} from '@commodity-desk/common';
import { equalsExpr } from '@trovio-tech/trovio-core-api-js';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface DecarboniseFormType {
    product: string;
    projectType: string;
    project: string;
    vintage: string;
    projectState: string;
    country: string;
    // LGC
    fuelSource: string;
    creationYear: string;
    generationYear: string;
    generationState: string;
    greenPowerAccredited: string;
    // MiQC
    segment: string;
    issueYear: string,
    miqGrade: string;
}

const defaultValues: DecarboniseFormType = {
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    projectState: '',
    country: '',
    // LGC
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenPowerAccredited: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
};

const DecarboniseForm = () => {
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { cortenApi } = useCortenApiState();

    const {
        isDecarboniseFormOpen,
        dialogState,
        performDecarboniseEmissionAsset,
        isRequesting,
        fetchItemsForDecarbonisedAsset,
        txId,
        errorInfo,
        unsubscribe,
        selectedPendingEmission
    } = useDecarboniseState();
    const { setIsDecarboniseFormOpen, setDialogState, setTxId } = useDecarboniseDispatch();
    const { commodityConfig } = useCommoditiesState();
    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({cortenApi: cortenApi, excludeCertificateBasedProduct: true});

    const {
        control,
        getValues,
        resetField,
        reset,
        formState: { errors, isValid }
    } = useForm<DecarboniseFormType>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['product'], control });
    const [balanceError, setBalanceError] = useState<string>();

    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    // close form
    const handleDecarboniseFormClose = () => {
        setIsDecarboniseFormOpen(false);

        // refresh table data
        if (dialogState === DialogState.SUCCESS || dialogState === DialogState.ERROR) {
            fetchItemsForDecarbonisedAsset();
        }

        // unsubscribe from transaction monitor
        unsubscribe();

        // clear fields
        reset();
        setTxId('');
    };

    // on input field change
    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(
            resetField,
            { ...getValues(), account: inventoryAccount.id },
            field
        );
    };

    // set pre-selected fields for dialog
    useEffect(() => {
        if (isDecarboniseFormOpen) {
            let preSelectedData = getValues();

            // set the forms preselected carbon product
            if (commodityConfig.carbonProducts.length > 0) {
                preSelectedData.product = commodityConfig.carbonProducts[0].id;
            }
            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, {
                ...preSelectedData,
                account: inventoryAccount.id
            });
        }
    }, [isDecarboniseFormOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    // balance error watch for no product selected
    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product) {
            error = 'Please select a Product';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    // handle request to decarbonise asset
    const handleDecarbonise = async () => {
        let filters = [];
        // find all input field values
        if (getValues().projectType)
            filters.push({
                code: carbonProjectAttributes.projectType.key,
                value: equalsExpr(getValues().projectType)
            });
        if (getValues().project)
            filters.push({
                code: carbonProjectAttributes.projectId.key,
                value: equalsExpr(getValues().project)
            });
        if (getValues().vintage)
            filters.push({
                code: carbonProjectAttributes.vintage.key,
                value: equalsExpr(getValues().vintage)
            });
        if (getValues().projectState)
            filters.push({
                code: carbonProjectAttributes.projectState.key,
                value: equalsExpr(getValues().projectState)
            });

        // find product items that match the criteria selected in form
        const itemIds = await fetchProductItemsWithId(cortenApi, getValues().product, filters).then((items) =>
            items ? items.map((item) => item['productItemId']) : []
        );

        // make POST request
        performDecarboniseEmissionAsset(itemIds);
    };

    return (
        <>
            <Dialog
                open={isDecarboniseFormOpen}
                onClose={handleDecarboniseFormClose}
                fullWidth
                maxWidth="sm"
            >
                {dialogState === DialogState.FORM && (
                    <>
                        <DialogContent>
                            <Typography variant="h2">
                                Offset Asset {selectedPendingEmission?.id}
                            </Typography>

                            <Box sx={{ marginBottom: '2rem' }}>
                                <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
                                    Emission Details
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography>
                                        {
                                            emissionAttributes.name
                                                .display
                                        }
                                        :
                                    </Typography>
                                    <Typography>{selectedPendingEmission?.name}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography>
                                        {
                                            emissionAttributes.intensity.display
                                        }
                                        :
                                    </Typography>
                                    <Typography>{selectedPendingEmission?.intensity}</Typography>
                                </Box>
                            </Box>

                            <Typography variant="h3" sx={{ marginBottom: '1.5rem' }}>
                                Retire
                            </Typography>

                            {renderEntry(
                                'Product:',
                                <>
                                    {
                                        <ControlledTextField
                                            name="product"
                                            label="Product"
                                            options={productOptions}
                                            customOnChange={() => onOptionSelect('product')}
                                            rules={{ required: 'Product is required' }}
                                            control={control}
                                            errors={errors}
                                            reset={resetField}
                                        />
                                    }
                                </>,
                                true,
                                true
                            )}

                            {renderEntry(
                                'Project Type:',
                                <>
                                    {
                                        <ControlledTextField
                                            name="projectType"
                                            label="Project Type"
                                            options={projectTypeOptions}
                                            customOnChange={() => onOptionSelect('projectType')}
                                            control={control}
                                            errors={errors}
                                            reset={resetField}
                                            balanceDisplayMinDecimals={
                                                productsData.get(getValues().product)?.minDecimalPos
                                            }
                                            balanceDisplayMaxDecimals={
                                                productsData.get(getValues().product)?.maxDecimalPos
                                            }
                                        />
                                    }
                                </>,
                                true,
                                true
                            )}

                            {renderEntry(
                                'Project:',
                                <>
                                    {
                                        <ControlledTextField
                                            name="project"
                                            label="Project"
                                            options={projectOptions}
                                            customOnChange={() => onOptionSelect('project')}
                                            control={control}
                                            errors={errors}
                                            reset={resetField}
                                            balanceDisplayMinDecimals={
                                                productsData.get(getValues().product)?.minDecimalPos
                                            }
                                            balanceDisplayMaxDecimals={
                                                productsData.get(getValues().product)?.maxDecimalPos
                                            }
                                        />
                                    }
                                </>,
                                true,
                                true
                            )}
                            {renderEntry(
                                'Vintage:',
                                <>
                                    {
                                        <ControlledTextField
                                            name="vintage"
                                            label="Vintage"
                                            options={vintageOptions}
                                            customOnChange={() => onOptionSelect('vintage')}
                                            control={control}
                                            errors={errors}
                                            reset={resetField}
                                            balanceDisplayMinDecimals={
                                                productsData.get(getValues().product)?.minDecimalPos
                                            }
                                            balanceDisplayMaxDecimals={
                                                productsData.get(getValues().product)?.maxDecimalPos
                                            }
                                        />
                                    }
                                </>,
                                true,
                                true
                            )}
                            {appConfigState.getProduct(getValues().product)?.displayCode === ProductType.ACCU && renderEntry(
                                'State:',
                                <>
                                    {
                                        <ControlledTextField
                                            name="projectState"
                                            label="State"
                                            options={projectStateOptions}
                                            customOnChange={() => onOptionSelect('projectState')}
                                            control={control}
                                            errors={errors}
                                            reset={resetField}
                                        />
                                    }
                                </>,
                                true,
                                true
                            )}

                            {!balanceError && availableBalance === null ? (
                                <LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />
                            ) : (
                                <Typography
                                    variant="caption"
                                    color={balanceError ? 'error' : 'textSecondary'}
                                    mt={1}
                                    mb={1}
                                    sx={{ float: 'right' }}
                                >
                                    {balanceError ? (
                                        <div>{balanceError}</div>
                                    ) : (
                                        <div>
                                            Available Balance:{' '}
                                            <AmountFormatWrapper
                                                amount={availableBalance}
                                                minDecimalPos={0}
                                                maxDecimalPos={0}
                                            />
                                        </div>
                                    )}
                                </Typography>
                            )}
                        </DialogContent>

                        <DialogActions>
                            {selectedPendingEmission?.intensity !== undefined &&
                                availableBalance !== null &&
                                availableBalance < +selectedPendingEmission.intensity && (
                                    <Typography sx={{ color: 'red', marginRight: 2 }}>
                                        Insufficient Balance
                                    </Typography>
                                )}

                            <Button variant="outlined" onClick={handleDecarboniseFormClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setDialogState(DialogState.REVIEW)}
                                disabled={
                                    !isValid ||
                                    !availableBalance ||
                                    (selectedPendingEmission?.intensity !== undefined &&
                                        availableBalance < +selectedPendingEmission.intensity)
                                }
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </>
                )}

                {/* Review dialog */}
                {dialogState === DialogState.REVIEW && (
                    <>
                        <DialogContent>
                            <Typography variant="h2">Review Details Below</Typography>

                            <Box sx={{ marginBottom: '2rem' }}>
                                <Typography variant="h3" sx={{ marginBottom: '1rem' }}>
                                    Emission Asset {selectedPendingEmission?.id}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography>
                                        {
                                            emissionAttributes.name
                                                .display
                                        }
                                        :
                                    </Typography>
                                    <Typography>{selectedPendingEmission?.name}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography>
                                        {
                                            emissionAttributes.intensity.display
                                        }
                                        :
                                    </Typography>
                                    <Typography>{selectedPendingEmission?.intensity}</Typography>
                                </Box>
                            </Box>

                            <Typography variant="h3" sx={{ marginBottom: '1.5rem' }}>
                                Retire
                            </Typography>

                            {getValues().product &&
                                renderDialogField(
                                    'Product',
                                    appConfigState.getProduct(getValues().product)?.displayCode
                                )}
                            {getValues().projectType &&
                                renderDialogField('Project Type', getValues().projectType)}
                            {getValues().project &&
                                renderDialogField(
                                    'Project',
                                    projectOptions?.values.find(
                                        (opt) => opt.id === getValues().project
                                    )?.label
                                )}
                            {getValues().vintage &&
                                renderDialogField('Vintage', getValues().vintage)}
                            {getValues().projectState &&
                                renderDialogField('State', getValues().projectState)}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => setDialogState(DialogState.FORM)}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Button onClick={handleDecarbonise} variant="outlined">
                                {isRequesting ? <CircularProgress size={24} /> : 'Confirm'}
                            </Button>
                        </DialogActions>
                    </>
                )}

                {/* Error Dialog */}
                {dialogState === DialogState.ERROR && errorInfo.errorMessage && (
                    <>
                        <AlertDialogContent
                            alertType={AlertType.Error}
                            alertMessage={errorInfo.errorMessage}
                            errorCode={errorInfo.errorCode}
                            handleDialogClose={handleDecarboniseFormClose}
                        />
                    </>
                )}
                {dialogState === DialogState.ERROR && errorInfo.warningMessage && (
                    <>
                        <AlertDialogContent
                            alertType={AlertType.Warning}
                            alertMessage={errorInfo.warningMessage}
                            errorCode={null}
                            handleDialogClose={handleDecarboniseFormClose}
                        />
                    </>
                )}
            </Dialog>

            {/* Success dialog */}
            {dialogState === DialogState.SUCCESS && (
                <>
                    <TransactionOverview
                        open={isDecarboniseFormOpen}
                        onClose={handleDecarboniseFormClose}
                        title="Transaction Submitted Successfully"
                        uiElements={{
                            'transactionId': { value: txId },
                            'emissionId': { value: selectedPendingEmission?.id },
                            'emissionName': { value: selectedPendingEmission?.name },
                            'totalEmissionIntensity': { value: selectedPendingEmission?.intensity },
                            'productId': { value: getValues().product, label: 'Product Code' },
                            ...(getValues().projectType && {
                                'projectType': { value: getValues().projectType }
                            }),
                            ...(getValues().project && {
                                'projectId': {
                                    value: projectOptions?.values.find(
                                        (opt) => opt.id === getValues().project
                                    )?.label
                                }
                            }),
                            ...(getValues().vintage && { 'vintage': { value: getValues().vintage } }),
                            ...(getValues().projectState && { 'projectState': { value: getValues().projectState } })
                        }}
                        fieldDefinitionMap={fieldDefinitionMap}
                        productType={appConfigState.getProduct(getValues().product)?.displayCode as ProductType}
                    />
                </>
            )}
        </>
    );
};

export default DecarboniseForm;
