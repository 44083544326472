import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { DATE_DISPLAY_FORMAT, MONTH_YEAR_DISPLAY_FORMAT, utcToLocalFormat } from '../../utility/utcToLocalFormat';
import { getProductFieldDefinitions } from './ProductFieldDefinitions';
import { getTransactionFieldDefinitions } from './TransactionFieldDefinitions';
import { getProjectBasedProductFieldDefinitions } from './productDefinitions/ProjectBasedProductDefinitions';
import { getACCUFieldDefinitions } from './productDefinitions/ACCUProductDefinitions';
import { getVCUFieldDefinitions } from './productDefinitions/VCUProductDefinitions';
import { getLGCFieldDefinitions } from './productDefinitions/LGCProductDefinitions';
import { getMiQCFieldDefinitions } from './productDefinitions/MiQCProductDefinitions';
import DocumentLink from '../../component/DocumentLink';
// A value to display in a table of data or in the overview when no data exists in that row for a given field
var NO_DATA_INDICATOR = '-';
/**
 * All possible data types that can be assigned to a given field definition
 * Data type is the main classification for the field, and affects many things, most notably:
 * - The way the data is parsed and rendered
 * - The filter operators that are available when filtering on the column
 *
 * Details about each type, and the corresponding core10 attribute typically used are:
 * - String:        Used for standard string based fields. Used with the STRING core10 attribute type.
 * - Integer:       Used for integer numbers without any decimal component. Used with the INTEGER core10 attribute type.
 * - Decimal:       Used for numbers that have a decimal component. Used with the DECIMAL core10 attribute type.
 * - FixedOptions:  Used for string coded fields that have a small number of possible options. Used with the STRING core10 attribute type, though should be considered as an enum.
 * - Boolean:       Used for boolean fields that can be either true or false. Used with the BOOLEAN core10 attribute type.
 * - Date:          Used for date fields that do not have a timestamp component. Used with the TIMESTAMP core10 attribute type.
 * - DateYearMonth: Used for date fields that do not have a timestamp or a day component. Used with the TIMESTAMP core10 attribute type.
 * - Timestamp:     Used for date fields that have a timestamp component. Used with the TIMESTAMP core10 attribute type.
 * - Document:      Used for fields that refer to a document. Used with the DOCUMENT core10 attribute type.
 */
var FieldDataType;
(function (FieldDataType) {
    FieldDataType["String"] = "String";
    FieldDataType["Integer"] = "Integer";
    FieldDataType["Decimal"] = "Decimal";
    FieldDataType["FixedOptions"] = "FixedOptions";
    FieldDataType["Boolean"] = "Boolean";
    FieldDataType["Date"] = "Date";
    FieldDataType["DateYearMonth"] = "DateYearMonth";
    FieldDataType["Timestamp"] = "Timestamp";
    FieldDataType["Document"] = "Document";
})(FieldDataType || (FieldDataType = {}));
/**
 * Lists all possible categories that can be shown in an overview (such as in the overview dialog)
 * Each category contains a number of attributes and the values for the current row of data
 * The 'Other' category is used as a general fallback category for any attribute that does not exist in a normal category.
 */
var OverviewCategory;
(function (OverviewCategory) {
    OverviewCategory["Transaction"] = "Transaction";
    OverviewCategory["Product"] = "Product";
    OverviewCategory["Commodity"] = "Commodity";
    OverviewCategory["Other"] = "Other";
})(OverviewCategory || (OverviewCategory = {}));
/**
 * Lists all possible areas that we currently render custom JSX for fields, using the `renderJSX` function.
 */
var RenderLocation;
(function (RenderLocation) {
    // Table = 'Table',  // TODO: Add this when DataGrid is removed. Cannot be safely added now, as DataGrid does not call renderCell with the 'renderLocation' argument.
    RenderLocation["OverviewDialog"] = "OverviewDialog";
})(RenderLocation || (RenderLocation = {}));
/**
 * Get the full list of all field definitions, including all products as well as transaction based fields.
 * This list must be updated whenever a new product is added.
 * @param appConfigState   The application config state, used to retrieve application settings such as attribute keys
 * @param productsData     The product data that is currently in use, containing the number of decimal places to render for each product
 * @param filterOperators  A set of filter operators that can be used by columns of data in a table. Optional, only required when used for tables. TODO: Remove this
 * @returns An array of {@link FieldDefinition}s
 */
var getFieldDefinitions = function (_a) {
    var appConfigState = _a.appConfigState, productsData = _a.productsData, filterOperators = _a.filterOperators;
    return getProductFieldDefinitions(appConfigState, filterOperators)
        .concat(getTransactionFieldDefinitions(appConfigState, productsData, filterOperators))
        .concat(getProjectBasedProductFieldDefinitions(appConfigState))
        .concat(getACCUFieldDefinitions(appConfigState, false))
        .concat(getVCUFieldDefinitions(appConfigState, false))
        .concat(getLGCFieldDefinitions(appConfigState))
        .concat(getMiQCFieldDefinitions(appConfigState));
};
/**
 * Get all field definitions in map form, keyed by field ID.
 * @param appConfigState   The application config state, used to retrieve application settings such as attribute keys
 * @param productsData     The product data that is currently in use, containing the number of decimal places to render for each product
 * @param filterOperators  A set of filter operators that can be used by columns of data in a table. Optional, only required when used for tables. TODO: Remove this
 * @returns A Map<FieldDefinitionKey, FieldDefinition>, keyed by {@link FieldDefinitionKey} and containing the {@link FieldDefinition}
 */
var getFieldDefinitionMap = function (_a) {
    var appConfigState = _a.appConfigState, productsData = _a.productsData, filterOperators = _a.filterOperators;
    return new Map(getFieldDefinitions({ appConfigState: appConfigState, productsData: productsData, filterOperators: filterOperators }).map(function (fieldDefinition) { return [fieldDefinition.key, fieldDefinition]; }));
};
/**
 * Find a field definition from the field definition map, falling back to shared column ID if it could not be found as a standard field ID.
 * In most cases findFieldDefinitionInMapEnforced should be used instead of this method. This method here is only to
 * be used in specific cases where we have a set of string identifiers, some of which correspond to a field in the field
 * definitions, and some are custom elements that are not in the field definitions.
 * @param fieldId The Id of the field to find
 * @param fieldDefinitionMap The field definition map to search through
 * @returns A FieldDefinition, or undefined if it could not be found
 */
var findFieldDefinitionInMap = function (fieldId, fieldDefinitionMap) {
    var filterField = fieldDefinitionMap.get(fieldId);
    if (filterField === undefined) {
        // try to find based on shared column ID
        filterField = Array.from(fieldDefinitionMap.values()).find(function (fieldDefinition) { return fieldDefinition.sharedColumnId === fieldId; });
    }
    return filterField;
};
/**
 * Find a field definition from the field definition map, falling back to shared column ID if it could not be found as a standard field ID
 * Also raises an error if the field definition could not be found.
 * @param fieldId The Id of the field to find
 * @param fieldDefinitionMap The field definition map to search through
 * @returns A FieldDefinition, otherwise an error is raised if it could not be found
 */
var findFieldDefinitionInMapEnforced = function (fieldId, fieldDefinitionMap) {
    var filterField = findFieldDefinitionInMap(fieldId, fieldDefinitionMap);
    if (filterField === undefined) {
        throw new Error("Could not find field definition for ".concat(fieldId, "."));
    }
    return filterField;
};
/**
 * Default valueGetter for all FieldDefinitions. Date-like types are returned as Date, others are returned as-is
 * TODO: Once DataGrid is removed, determine whether this is needed or whether it can be removed.
 * @param fieldType The type of field we are retrieving the value for
 * @param value The raw value provided as input
 * @returns The usable value (e.g. string dates are parsed into Date objects)
 */
var defaultValueGetter = function (fieldType, value) {
    if (value !== undefined && [FieldDataType.Date, FieldDataType.DateYearMonth, FieldDataType.Timestamp].includes(fieldType)) {
        return new Date(value);
    }
    return value;
};
/**
 * Determines the way to render data for display in tables and in other areas such as on overview dialogs.
 * @param fieldDefinition The definition for the field we are rendering
 * @param value The value of the field we are rendering
 * @returns A string containing the rendered value
 */
var formatDisplayValue = function (fieldDefinition, value) {
    if (fieldDefinition.formatDisplayValue) { // Use custom formatDisplayValue function if it exists
        return fieldDefinition.formatDisplayValue(value);
    }
    else if (value === undefined || value === '') { // Blank values exist for some optional attributes (e.g. in MiQC)
        return NO_DATA_INDICATOR;
    }
    else if (fieldDefinition.dataType === undefined) { // Defaults to String format
        return value;
    }
    else if (fieldDefinition.dataType === FieldDataType.Date) {
        return utcToLocalFormat(value, DATE_DISPLAY_FORMAT);
    }
    else if (fieldDefinition.dataType === FieldDataType.DateYearMonth) {
        return utcToLocalFormat(value, MONTH_YEAR_DISPLAY_FORMAT);
    }
    else if (fieldDefinition.dataType === FieldDataType.Timestamp) {
        return utcToLocalFormat(value);
    }
    else if (fieldDefinition.dataType === FieldDataType.Boolean) {
        return "".concat(value);
    }
    return value;
};
/**
 * Determines how to format values when displayed in the DataGrid table, with custom JSX. Takes highest precedence, so will be used
 * over formatDisplayValue (used in valueFormatter) if both are specified (except for CSV exports, which does not use fieldRenderJSX).
 * @param fieldDefinition The field that we are rendering
 * @returns JSX to display in the table
 */
var renderJSXValue = function (fieldDefinition) {
    if (fieldDefinition.renderJSX) {
        return fieldDefinition.renderJSX;
    }
    else if (fieldDefinition.dataType === FieldDataType.Boolean) {
        return function (params) {
            switch (params.value) {
                case true:
                    return _jsx(FontAwesomeIcon, { icon: faCheck });
                case false:
                    return _jsx(FontAwesomeIcon, { icon: faXmark });
                default:
                    return NO_DATA_INDICATOR;
            }
        };
    }
    return undefined;
};
/**
 * Used to render a DocumentLink
 * @param params The standard parameters where the value must be the URL to the document
 * @returns JSX
 */
var renderDocumentLink = function (params) {
    return _jsx(DocumentLink, { url: params.value });
};
export { FieldDataType, OverviewCategory, RenderLocation, getFieldDefinitions, getFieldDefinitionMap, findFieldDefinitionInMap, findFieldDefinitionInMapEnforced, defaultValueGetter, renderDocumentLink, formatDisplayValue, renderJSXValue, NO_DATA_INDICATOR };
