import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fade, Paper, Popper, Tooltip } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import 'react-day-picker/dist/style.css';
import { isValidDate } from '@commodity-desk/common';
import { RangeDatePicker } from './RangeDatePicker';

type Props = {
    showTimeStamp?: boolean;
    wholeMonths?: boolean;
} & GridFilterInputValueProps;

export const DatePickerFilter = (props: Props) => {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(popoverAnchorEl);
    const { item, showTimeStamp, wholeMonths, applyValue } = props;

    const handleFilterChange = (newValue: string) => {
        applyValue({ ...item, value: newValue });
    };

    const handleClose = () => {
        setPopoverAnchorEl(null);
    };

    const TIME_FORMAT = `YYYY-MM${wholeMonths ? '' : '-DD'}${showTimeStamp ? ' LTS' : ''}`;

    const renderLabel = () => {
        if (!item?.value) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '7px 15px'
                    }}
                >
                    Select Date
                    <FontAwesomeIcon icon={faCalendar} />
                </Box>
            );
        }
        const labels = item?.value?.split('..');
        let fromDate = isValidDate(labels[0]) ? dayjs(new Date(labels[0])).format(TIME_FORMAT) : undefined;
        let toDate = isValidDate(labels[1]) ? dayjs(new Date(labels[1])).format(TIME_FORMAT) : undefined;

        return (
            <>
                <Tooltip
                    title={
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {fromDate}
                            <span>To</span>
                            {toDate}
                        </Box>
                    }
                >
                    <Box
                        sx={{
                            margin: '0 8px',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 10
                        }}
                    >
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                            <span>From : </span>
                            {fromDate}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginTop: '5px',
                                gap: '3px'
                            }}
                        >
                            <span>To : </span>
                            {toDate}
                        </Box>
                    </Box>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <Box
                onClick={(e) => setPopoverAnchorEl(open ? null : e?.currentTarget)}
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px 10px',
                    cursor: 'pointer'
                }}
            >
                {renderLabel()}
            </Box>

            <Popper
                placement="right"
                open={open}
                anchorEl={popoverAnchorEl}
                disablePortal={false}
                transition
                sx={{ zIndex: 1300 }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper elevation={24}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                                <RangeDatePicker
                                    onChange={(newDate) => {
                                        handleClose();
                                        handleFilterChange(newDate);
                                    }}
                                    onReset={() => applyValue({ ...item, value: undefined })}
                                    startDate={item?.value?.split('..')?.[0]}
                                    endDate={item?.value?.split('..')?.[1]}
                                    showTimeStamp={showTimeStamp}
                                    wholeMonths={wholeMonths}
                                />
                            </LocalizationProvider>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};
