import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { 
    AttributeCriteria,
    fetchBalances,
    fetchByCriteria,
    fetchProductAttributesWithId,
    ProductBase,
    ProductData,
    ProjectData,
    useAppConfigState
} from '@commodity-desk/common';
import { useProductDispatch, useProductState } from './ProductState';
import { projectDisplayDetails } from '../../../utility/ConfigureProjectDetails';
import { BalanceData } from '@trovio-tech/trovio-core-api-js';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface InventoryManagementStateType {
    totalBalance: BalanceData | undefined;
    productData: ProductData | undefined;
    uniqueProjectsDisplay: ProjectData[];
    findUniqueProjects: (attributeFilters: AttributeCriteria[] | undefined) => Promise<void>;
    loadingProjects: boolean;
    setLoadingProjects: Dispatch<SetStateAction<boolean>>;
}

interface InventoryManagementDispatchType {}

const InventoryManagementStateContext = createContext<InventoryManagementStateType | null>(null);
const InventoryManagementDispatchContext = createContext<InventoryManagementDispatchType | null>(
    null
);

const InventoryManagementProvider = ({ children }: { children?: ReactNode }) => {
    const appConfigState = useAppConfigState();
    const { mblSelectedProduct } = useProductState();
    const { cortenApi } = useCortenApiState();
    const { setLoading } = useProductDispatch(); // For the main products API
    const [ loadingProjects, setLoadingProjects ] = useState(false);
    const [totalBalance, setTotalBalance] = useState<BalanceData | undefined>(undefined);
    const [productData, setProductData] = useState<ProductData | undefined>(undefined);
    const [uniqueProjectsDisplay, setUniqueProjectsDisplay] = useState<ProjectData[]>([]);
    const abortController = useRef(new AbortController());

    // find balances for selected product
    const findTotalBalances = async () => {
        // fetch sum balances
        let balanceData = undefined;
        if (mblSelectedProduct.id) {
            let criteria = { productId: [mblSelectedProduct.id], sumProductItems: true };
            balanceData = await fetchBalances({
                criteria: criteria, 
                api: cortenApi,
                signal: abortController.current.signal
            })
                .then(balances => balances[0]);
        }
        setTotalBalance(balanceData);
    };

    // all available attributes for the MBL-ACCU product & product items
    const findAvailableAttributes = async () => {
        let productData = await fetchProductAttributesWithId(mblSelectedProduct.id, cortenApi, abortController.current.signal);

        if (productData && Object.keys(productData).length > 0) {
            let displayData = {
                issuerId: productData.data.issuerId,
                code: appConfigState.getProduct(productData.productId)?.displayCode ?? productData.data.code,
                name: appConfigState.getProduct(productData.productId)?.displayName ?? productData.data.name,
                minDecimalPos: productData.data.minDecimalPos,
                maxDecimalPos: productData.data.maxDecimalPos,
                currency: appConfigState.getProduct(productData.productId)!.currency,
                attributes: productData.data.attributes ?? {},
                itemAttributes: productData.data.itemAttributes ?? []
            };
            setProductData(displayData);
        } else {
            setProductData(undefined);
        }
    };

    // fetch project attributes and balances by product ID
    const findUniqueProjects = async (attributeFilters: AttributeCriteria[] | undefined = undefined) => {
        setLoadingProjects(true);
        const criteria = {
            productIds: [mblSelectedProduct.id],
            includeBalances: true,
            axes: appConfigState.getProjectAttributes(),
            attributes: attributeFilters
        }

        let projectData = await fetchByCriteria(criteria, cortenApi, abortController.current.signal);

        if (projectData && projectData.list.length > 0) {
            // map into format for UI display
            const displayFormat = projectData.list.map((project: any) => projectDisplayDetails(project, appConfigState))
            // set state
            setUniqueProjectsDisplay(displayFormat);
        } else {
            setUniqueProjectsDisplay([]);
        }

        // stop loader
        setLoading(false); // This is the overall page loader used on the initial rendering of the page
        setLoadingProjects(false); // This is the loader for the projects table only
    };

    // update when new product is selected
    useEffect(() => {
        if (mblSelectedProduct.id !== '') {
            abortController.current.abort();
            abortController.current = new AbortController();

            if (appConfigState.getProduct(mblSelectedProduct.id)?.productBase === ProductBase.Certificate) {
                // if we are dealing with certificate based product then we don't want to deal
                // with projects. Instead we want to stop the loader after the balances and the
                // product attributes are loaded
                Promise.all([
                    findTotalBalances(),
                    findAvailableAttributes()
                ]).then(() => {
                    setLoading(false);
                })
            } else {
                findTotalBalances();
                findAvailableAttributes();
                findUniqueProjects();
            }
        }
    }, [mblSelectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <InventoryManagementStateContext.Provider
            value={{
                totalBalance: totalBalance,
                productData: productData,
                uniqueProjectsDisplay: uniqueProjectsDisplay,
                findUniqueProjects: findUniqueProjects,
                loadingProjects: loadingProjects,
                setLoadingProjects: setLoadingProjects
            }}
        >
            <InventoryManagementDispatchContext.Provider value={{}}>
                {children}
            </InventoryManagementDispatchContext.Provider>
        </InventoryManagementStateContext.Provider>
    );
};

function useInventoryManagementState() {
    const context = useContext(InventoryManagementStateContext);
    if (!context) {
        throw new Error('no provider for useInventoryManagementState');
    }
    return context;
}

function useInventoryManagementDispatch() {
    const context = useContext(InventoryManagementDispatchContext);
    if (!context) {
        throw new Error('no provider for useInventoryManagementDispatch');
    }
    return context;
}

export {
    InventoryManagementProvider,
    useInventoryManagementState,
    useInventoryManagementDispatch
};
