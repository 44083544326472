import { Button, Typography } from '@mui/material';
import DecarboniseForm from './DecarboniseForm';
import UnderlyingDrawer from './UnderlyingDrawer';
import CommoditiesTable from './CommoditiesTable';
import CommodityForm from './CommodityForm';
import { useCommoditiesState } from './state/CommoditiesState';

const Commodities = () => {
    const { handleCommoditiesFormOpen } = useCommoditiesState();

    return (
        <>
            <Typography variant="h2">Commodities</Typography>

            <Button
                variant="outlined"
                onClick={handleCommoditiesFormOpen}
                sx={{ marginBottom: 5, marginRight: 2 }}
            >
                Create New Commodity
            </Button>
            {/* Form to issue a commodity */}
            <CommodityForm />

            {/* Form to decarbonise a commodity */}
            <DecarboniseForm />

            {/* Dialog to show underlying baked product items */}
            <UnderlyingDrawer />

            {/* The table to display all commodities */}
            <CommoditiesTable />
        </>
    );
};

export default Commodities;
