import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { 
    fetchProducts,
    inventoryAccount,
    ProductBase,
    useAppConfigState
} from '@commodity-desk/common';
import { useAppNavigate } from '@commodity-desk/common';
import { homepage } from '../../../state/Variables';
import { useNavigate } from 'react-router-dom';
import {  useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface ProductOption {
    name: string;
    code: string;
    id: string;
    base: ProductBase;
}

interface ProductsStateType {
    mblProductOptions: ProductOption[];
    mblSelectedProduct: ProductOption;
    fetchAndInitProducts: (currentProductID: string) => void;
    loading: boolean;
    isPathError: boolean;
}

interface ProductDispatchType {
    setMblSelectedProduct: (mblSelectedProduct: ProductOption) => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setIsPathError: Dispatch<SetStateAction<boolean>>;
}

const ProductStateContext = createContext<ProductsStateType | null>(null);
const ProductDispatchContext = createContext<ProductDispatchType | null>(null);

const ProductProvider = ({ children }: { children?: ReactNode }) => {
    const appConfigState = useAppConfigState();
    const { cortenApi } = useCortenApiState();
    const [mblProductOptions, setMblProductOptions] = useState<ProductOption[]>([]);
    const [mblSelectedProduct, setMblSelectedProduct] = useState<ProductOption>({
        name: '',
        code: '',
        id: '',
        base: ProductBase.Project
    });
    const [loading, setLoading] = useState(true);
    const [isPathError, setIsPathError] = useState(false);

    const navigate= useNavigate();
    const appNavigate = useAppNavigate({homepage, navigate});

    const fetchAndInitProducts = async (currentProductID: string) => {
        // fetch all products for specified issuer ID
        let productsList = await fetchProducts(
            cortenApi,
            inventoryAccount.id,
            appConfigState.getProducts()
        );

        // check if fetch was successful
        if (productsList && productsList.length > 0) {
            // filter on the configured list of products
            let filteredProducts = productsList.filter((p) =>
                appConfigState.getProducts().map(p => p.id).includes(p.productId)
            );

            // extract values for display
            let displayData = mapProductData(filteredProducts);

            // set to state
            setMblProductOptions(displayData);

            // set an initial product for the app, this will show in option-select dropdown
            initProductSelection(displayData, currentProductID);
        }
    };

    // create a map of relevant information for each product
    const mapProductData = (filteredProducts: any[]) => {
        let displayData: ProductOption[] = [];

        filteredProducts.map((item) =>
            displayData.push({
                name: appConfigState.getProduct(item.productId)?.displayName ?? item.data.name,
                code: appConfigState.getProduct(item.productId)?.displayCode ?? item.data.code,
                id: item.productId,
                base: appConfigState.getProduct(item.productId)?.productBase ?? ProductBase.Project,
            })
        );

        return displayData;
    };

    // set the initial product, either currently selected product from url or the 1st available product from data response
    const initProductSelection = (displayData: ProductOption[], currentProductID: string) => {
        if (displayData.length > 0) {
            if (currentProductID === '') {
                // set to first product in list if no product in url
                setMblSelectedProduct(displayData[0]);
                appNavigate(`/inventory-management/product/${displayData[0].id}`);
            } else {
                let currentProduct: ProductOption = {
                    name: '',
                    code: '',
                    id: '',
                    base: ProductBase.Project
                };
                // set to the product specified in url
                for (let product of displayData) {
                    if (product.id === currentProductID) {
                        currentProduct = product;
                    }
                }
                setMblSelectedProduct(currentProduct);
                appNavigate(`/inventory-management/product/${currentProductID}`);
            }
        }
    };

    return (
        <ProductStateContext.Provider
            value={{
                mblProductOptions: mblProductOptions,
                mblSelectedProduct: mblSelectedProduct,
                fetchAndInitProducts: fetchAndInitProducts,
                loading: loading,
                isPathError: isPathError
            }}
        >
            <ProductDispatchContext.Provider
                value={{ setMblSelectedProduct: setMblSelectedProduct, setLoading: setLoading, setIsPathError: setIsPathError }}
            >
                {children}
            </ProductDispatchContext.Provider>
        </ProductStateContext.Provider>
    );
};

function useProductState() {
    const context = useContext(ProductStateContext);
    if (!context) {
        throw new Error('no provider for useProductState');
    }
    return context;
}

function useProductDispatch() {
    const context = useContext(ProductDispatchContext);
    if (!context) {
        throw new Error('no provider for useProductDispatch');
    }
    return context;
}

export { ProductProvider, useProductState, useProductDispatch, type ProductOption };
