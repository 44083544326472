var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FieldDataType, OverviewCategory } from './FieldDefinitions';
import { renderWithCopyButton } from '../utility/CommonJsxRenderFunctions';
import { productAttributes } from '../../state/AppConfig';
/**
 * Get all product based field definitions. This includes product properties (e.g. product ID), product item properties (e.g. holding ID
 * or forward ID), and product attributes (e.g. registry ID)
 * @param appConfigState The application config state, used to retrieve settings from the config, such as attribute key
 * @param filterOperators The set of all possible filter operators that can apply.
 * @returns An array of {@link FieldDefinition}s
 */
var getProductFieldDefinitions = function (appConfigState, filterOperators) {
    return [
        {
            key: "productId",
            displayName: "Product",
            dataType: FieldDataType.FixedOptions,
            overviewCategory: OverviewCategory.Product,
            fixedOptions: appConfigState.getProducts().map(function (data) { return ({
                value: data.id,
                label: data.displayCode
            }); }),
            formatDisplayValue: function (value) { var _a, _b; return (_b = (_a = appConfigState.getProduct(value)) === null || _a === void 0 ? void 0 : _a.displayCode) !== null && _b !== void 0 ? _b : value; },
            sortable: false,
            columnMinWidth: 120,
            dataGridDefinitions: __assign({}, (filterOperators ? {
                customFilterOperators: filterOperators.singleSelectFilterOperators().filter(function (operator) { return operator.value !== 'not'; }).map(function (operator) {
                    return __assign(__assign({}, operator), { getValueAsString: function (value) {
                            var valueArray = [];
                            if (!Array.isArray(value)) {
                                valueArray = [value];
                            }
                            else {
                                valueArray = value;
                            }
                            return appConfigState.getProducts().filter(function (data) { return valueArray.includes(data.id); })
                                .map(function (data) { return data.displayCode; }).join(", ");
                        } });
                })
            } : {}))
        },
        {
            key: "registry",
            displayName: "Registry",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Product,
            attribute: productAttributes.registry,
            filterable: false,
            sortable: false,
            includeInGroupBy: false,
            columnMinWidth: 130,
        },
        {
            key: "forwardId",
            displayName: "Forward ID",
            dataType: FieldDataType.String,
            overviewCategory: OverviewCategory.Transaction,
            sortable: false,
            filterable: false,
            columnMinWidth: 150,
            renderJSX: renderWithCopyButton
        },
    ];
};
export { getProductFieldDefinitions };
