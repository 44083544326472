import { FieldDataType, getFieldDefinitions, renderJSXValue } from './FieldDefinitions';
/**
 * Get all DataTable column definitions for the [chosenProduct].
 *
 * This function will:
 * 1. Obtain all [FieldDefinition]s relevant to the [chosenProduct].
 * 2. Convert each [FieldDefinition] to [DataTableColumnProp<RowType>].
 * 3. Filter out duplicate column definitions, which may result due to multiple field definitions mapping to the same column.
 *
 * @param appConfigState   The application config state, used to retrieve application settings such as attribute keys
 * @param productsData     The product data that is currently in use, containing the number of decimal places to render for each product
 * @param filterOperators  A set of filter operators that can be used by columns of data in a table
 * @param chosenProduct    The currently active product, if any, used to filter the column definitions down to only show those relevant to that product
 * @returns An array of column definitions in DataTable compatible format
 */
var getDataTableColumnDefinitions = function (_a) {
    var appConfigState = _a.appConfigState, productsData = _a.productsData, filterOperators = _a.filterOperators, chosenProduct = _a.chosenProduct;
    return getFieldDefinitions({ appConfigState: appConfigState, productsData: productsData })
        .filter(function (fieldDefinition) { return fieldDefinition.productTypes === undefined || chosenProduct === undefined || fieldDefinition.productTypes.includes(chosenProduct); })
        .map(function (fieldDefinition) { return fieldDefinitionToDataTableColumnDefinition(fieldDefinition, filterOperators); }).filter(
    // Filter out duplicate column definitions, which can result from multiple field definitions mapping to the same column (same sharedColumnId)
    function (value, index, self) { return index === self.findIndex(function (t) { return t.key === value.key; }); });
};
/**
 * Maps a {@link FieldDefinition} to a column definition in DataTable format, used to add a column to a DataTable
 */
var fieldDefinitionToDataTableColumnDefinition = function (fieldDefinition, filterOperators) {
    var _a, _b, _c, _d, _e;
    var jsxRenderFunc = renderJSXValue(fieldDefinition);
    return {
        key: (_a = fieldDefinition.sharedColumnId) !== null && _a !== void 0 ? _a : fieldDefinition.key,
        queryKey: (_b = fieldDefinition.attribute) === null || _b === void 0 ? void 0 : _b.key,
        header: fieldDefinition.displayName,
        columnTypeProps: fieldTypeToDataTableType(fieldDefinition),
        // TODO: Add formatDisplayValue when available
        enableSorting: (_c = fieldDefinition.sortable) !== null && _c !== void 0 ? _c : true,
        filterOperators: ((_d = fieldDefinition.filterable) !== null && _d !== void 0 ? _d : true) ? (_e = fieldDefinition.customFilterOperators) !== null && _e !== void 0 ? _e : fieldFilterOperators(fieldDefinition.dataType, filterOperators) : undefined,
        minWidth: fieldDefinition.columnMinWidth,
        cell: jsxRenderFunc === undefined ? undefined : function (_a) {
            var _b;
            var row = _a.row;
            return jsxRenderFunc({
                value: row[(_b = fieldDefinition.sharedColumnId) !== null && _b !== void 0 ? _b : fieldDefinition.key],
                row: row
            });
        }
    };
};
/**
 * Determines the filter operators that should apply when adding a column to a DataTable, given the type of the field (column)
 * TODO: Remove the filterOperators argument when DataTable filter operators are defined in the common module
 * @param fieldType        The type of the field
 * @param filterOperators  The set of all possible filter operators that can apply
 * @returns An array of filter operators
 */
var fieldFilterOperators = function (fieldType, filterOperators) {
    return filterOperators.standardFilterOperators;
};
/**
 * Determines the data type for a field, in DataTable format, including relevant options as necessary
 * @param fieldType The field type to map to table data type
 * @returns A string describing the table data type
 */
var fieldTypeToDataTableType = function (fieldDefinition) {
    if ([FieldDataType.FixedOptions, FieldDataType.Boolean].includes(fieldDefinition.dataType)) {
        return {
            type: "select",
            options: fieldValueOptions(fieldDefinition)
        };
    }
    else if (fieldDefinition.dataType === FieldDataType.Decimal) {
        return {
            type: "decimal"
        };
    }
    else if (fieldDefinition.dataType === FieldDataType.Integer) {
        return {
            type: "integer"
        };
    }
    else if ([FieldDataType.Date, FieldDataType.DateYearMonth, FieldDataType.Timestamp].includes(fieldDefinition.dataType)) {
        return {
            type: "date",
            enableTimeStamp: fieldDefinition.dataType === FieldDataType.Timestamp,
            enableMonthSelection: fieldDefinition.dataType === FieldDataType.DateYearMonth
        };
    }
    return {
        type: "string"
    };
};
/**
 * Determines the select options that should apply to a column of data in a DataTable, if any. Select options provide the full possible
 * set of values that can be encountered in the data for that column. Used to show a drop down select box with specific options
 * when filtering, as opposed to an open-ended text input box
 * @param fieldDefinition The field definition to retrieve select options for
 * @returns A list of select options, containing value and label
 */
var fieldValueOptions = function (fieldDefinition) {
    if (fieldDefinition.fixedOptions) {
        return fieldDefinition.fixedOptions;
    }
    else if (fieldDefinition.dataType === FieldDataType.Boolean) {
        return [
            { value: 'true', label: 'true' },
            { value: 'false', label: 'false' }
        ];
    }
    else {
        throw new Error("No fixedOptions were specified for field ".concat(fieldDefinition.key));
    }
};
export { fieldDefinitionToDataTableColumnDefinition, getDataTableColumnDefinitions, };
