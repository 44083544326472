(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("@commodity-desk/commodity-desk-api-js"), require("@trovio-ui-libs/ui"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "@commodity-desk/commodity-desk-api-js", "@trovio-ui-libs/ui"], factory);
	else if(typeof exports === 'object')
		exports["$"] = factory(require("react"), require("react-dom"), require("@commodity-desk/commodity-desk-api-js"), require("@trovio-ui-libs/ui"));
	else
		root["$"] = factory(root["React"], root["ReactDOM"], root["@commodity-desk/commodity-desk-api-js"], root["@trovio-ui-libs/ui"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4012__, __WEBPACK_EXTERNAL_MODULE__6033__, __WEBPACK_EXTERNAL_MODULE__8466__, __WEBPACK_EXTERNAL_MODULE__7426__) => {
return 