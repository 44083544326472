/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the VintageProjectPricesResponse interface.
 */
export function instanceOfVintageProjectPricesResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "prices" in value;
    isInstance = isInstance && "productPrice" in value;
    return isInstance;
}
export function VintageProjectPricesResponseFromJSON(json) {
    return VintageProjectPricesResponseFromJSONTyped(json, false);
}
export function VintageProjectPricesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'prices': json['prices'],
        'productPrice': json['productPrice'],
    };
}
export function VintageProjectPricesResponseToJSON(value) {
    return VintageProjectPricesResponseToJSONTyped(value, false);
}
export function VintageProjectPricesResponseToJSONTyped(value, ignoreDiscriminator) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'prices': value.prices,
        'productPrice': value.productPrice,
    };
}
