var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DRAFTS_MODEL_VERSION, draftsReviver } from './FormDraftsModel';
var storageSpace = localStorage;
/**
 * Custom hook providing persistent form draft capabilities. Call {@link initialiseDrafts} before use.
 *
 * @param storageKey the unique key used to identify stored values
 * @param defaultData default form used for new draft initiation
 * @param formVersion version (discriminator) of the actual data: gives us the ability to easily
 * invalidate stored data when its interface changes
 * @param onDraftSelect callback to be invoked on every draft select (including selects that result
 * from {@link initialiseDrafts} or {@link deleteDraft}
 */
var useFormDrafts = function (storageKey, defaultData, formVersion, onDraftSelect) {
    var drafts = useRef();
    var _a = useState(), draftsMetadata = _a[0], setDraftsMetadata = _a[1];
    var initialiseDrafts = function () {
        drafts.current = loadDraftsFromStorage();
        return selectDraft(drafts.current.currentDraftId);
    };
    var selectDraft = function (id) {
        onDraftSelect();
        if (id == null) {
            var draft = buildNewDraft();
            drafts.current.list.unshift(draft); // add as first draft
            id = draft.id;
        }
        drafts.current.currentDraftId = id;
        write(drafts.current);
        return getDraft(id).data;
    };
    var deleteDraft = function (id, switchToNewTab) {
        if (switchToNewTab === void 0) { switchToNewTab = false; }
        var deletedIndex = drafts.current.list.findIndex(function (draft) { return draft.id === id; });
        drafts.current.list.splice(deletedIndex, 1);
        var length = drafts.current.list.length;
        var draftId = null;
        if (!switchToNewTab && length > 0) {
            draftId = length > deletedIndex
                ? drafts.current.list[deletedIndex].id
                : drafts.current.list[length - 1].id;
        }
        return selectDraft(draftId);
    };
    var updateDraft = function (applyUpdate, id) {
        if (id === void 0) { id = drafts.current.currentDraftId; }
        var targetDraft = getDraft(id);
        if (targetDraft) {
            applyUpdate(targetDraft.data);
            targetDraft.modifiedDate = dayjs();
            write(drafts.current);
        }
    };
    var loadDraftsFromStorage = function () {
        var storedString = storageSpace.getItem(storageKey);
        if (storedString) {
            var storedObject = JSON.parse(storedString, draftsReviver);
            if (hasCorrectVersion(storedObject)) {
                return storedObject;
            }
        }
        var defaultDraft = buildNewDraft();
        return { version: DRAFTS_MODEL_VERSION, list: [defaultDraft], currentDraftId: defaultDraft.id };
    };
    var buildNewDraft = function () {
        return { id: crypto.randomUUID(), data: __assign(__assign({}, defaultData), { version: formVersion }), modifiedDate: dayjs() };
    };
    var getDraft = function (id) {
        return drafts.current.list.find(function (draft) { return draft.id === id; });
    };
    var hasCorrectVersion = function (drafts) {
        return drafts.version === DRAFTS_MODEL_VERSION && !drafts.list.some(function (d) { return d.data.version !== formVersion; });
    };
    var write = function (source) {
        // update stored values
        storageSpace.setItem(storageKey, JSON.stringify(drafts.current));
        // rebuild metadata
        var getDraftName = function (modifiedDate) { return modifiedDate.isSame(dayjs(), 'day')
            ? modifiedDate.format('HH:mm:ss')
            : modifiedDate.format('DD MMM'); };
        setDraftsMetadata({
            drafts: source.list.map(function (draft) { return ({ id: draft.id, name: getDraftName(draft.modifiedDate) }); }),
            currentDraftId: source.currentDraftId,
        });
    };
    return {
        draftsMetadata: draftsMetadata,
        initialiseDrafts: initialiseDrafts,
        selectDraft: selectDraft,
        updateDraft: updateDraft,
        deleteDraft: deleteDraft,
        getCurrentDraftId: function () { return drafts.current.currentDraftId; },
    };
};
export { useFormDrafts };
