import { CurveApi, FrontendAppConfigApi, OptimiserScoreApi, OrderedProjectVintagesApi } from "../generated/types/index.js";
var CommodityDeskApi = /** @class */ (function () {
    function CommodityDeskApi(configuration) {
        this.curve = bindAll(new CurveApi(configuration));
        this.frontendAppConfig = bindAll(new FrontendAppConfigApi(configuration));
        this.optimiserScore = bindAll(new OptimiserScoreApi(configuration));
        this.orderedProjectVintages = bindAll(new OrderedProjectVintagesApi(configuration));
    }
    return CommodityDeskApi;
}());
export { CommodityDeskApi };
function bindAll(objectToBind) {
    for (var _i = 0, _a = Object.getOwnPropertyNames(Object.getPrototypeOf(objectToBind)); _i < _a.length; _i++) {
        var name_1 = _a[_i];
        var method = objectToBind[name_1];
        if (name_1 !== "constructor" && method instanceof Function) {
            objectToBind[name_1] = method.bind(objectToBind);
        }
    }
    return objectToBind;
}
