import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ChangeEvent, CSSProperties, MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, CircularProgress, TableCell, TextField } from "@mui/material";
import { AmountFormatWrapper } from '@commodity-desk/common';
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { TableCellProps } from "@mui/material/TableCell/TableCell";

/**
 * Pre-configured text field input, designed to capture a decimal value
 *
 * @param defaultValue Initial value
 * @param handleChange Function to call on change
 * @param textFieldProps Properties for the underlying <TextField/> element
 */
const CurveInput = (
    {
        defaultValue,
        handleChange,
        textFieldProps,
    }: {
        defaultValue: number,
        handleChange: (value: number) => void,
        textFieldProps?: TextFieldProps
    }) => {
    const [value, setValue] = useState("");
    const theme = useTheme();

    useEffect(() => {
        setValue(String(defaultValue));
    }, [defaultValue]);

    const asNumber = (value: string) => isNaN(+value) ? 0 : +value;

    return (
        <TextField
            {...textFieldProps}
            value={value}
            InputProps={{
                ...textFieldProps?.InputProps,
                sx: {
                    ...textFieldProps?.InputProps?.sx,
                    ...(asNumber(value) !== defaultValue && { color: `${theme.palette.primary.main}` }),
                    '& input': {
                        ...(textFieldProps?.InputProps?.sx as any)?.['& input'],
                        textAlign: 'right',
                    },
                },
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;
                // we don't worry about leading zeros: all valid inputs are parsed to a number on change
                if (/^[+-]?\d*\.?\d{0,2}$/.test(newValue)) {
                    setValue(newValue);
                    handleChange(asNumber(newValue));
                }
            }}
        />
    );
};

/**
 * Styled button component for the curve screen
 *
 * @param onClick On-click handler
 * @param disabled Optional disable parameter
 * @param style Optional style props for the encapsulating <Box/> component
 * @param children Child node
 */
const CurveButton = (
    {
        onClick,
        disabled,
        style,
        children,
    }: {
        onClick: MouseEventHandler<HTMLButtonElement>,
        disabled?: boolean,
        style?: CSSProperties,
        children: ReactNode,
    }) => {
    return (
        <Box height='2.321rem' alignItems='center' style={{ display: 'flex', ...style }}>
            <Button size='small' variant='outlined' onClick={onClick} disabled={disabled}>
                {children}
            </Button>
        </Box>
    );
};

/**
 * A custom component for the curve marking screen, containing Edit, Cancel and Save buttons.
 * The component has minimal logic and doesn't keep any state, relying on parent to provide it and
 * then handle click events.
 *
 * @param isEditing Drives whether the buttons are in edit mode, determining which of them are rendered
 * @param handleEditClick Edit and Cancel button click handler
 * @param isSaving When save is in progress, the Save button will show a spinner instead of the usual label
 * @param handleSaveClick Save button click handler
 * @param disabled Disables buttons, unless already in "edit" mode
 * @param style Style props for the <Box/> element, which contains the buttons
 */
const CurveEditButtonBlock = (
    {
        isEditing,
        handleEditClick,
        isSaving,
        handleSaveClick,
        disabled,
        style,
    }: {
        isEditing: boolean,
        handleEditClick: () => void,
        isSaving: boolean,
        handleSaveClick: () => void,
        disabled: boolean,
        style: CSSProperties,
    }) => {

    return (
        <Box style={{ display: 'inline-flex', ...style }}>
            <CurveButton onClick={handleEditClick} disabled={!isEditing && disabled}>
                {isEditing
                    ? <><ClearIcon sx={{ mr: '5px', ml: '-5px' }}/>CANCEL</>
                    : <><EditIcon sx={{ mr: '5px', ml: '-5px' }}/>EDIT</>
                }
            </CurveButton>
            {isEditing &&
                <CurveButton onClick={() => !isSaving && handleSaveClick()} style={{ marginLeft: '5px' }}>
                    {isSaving
                        ? <CircularProgress size={25}/>
                        : <><SaveIcon sx={{ mr: '5px', ml: '-5px' }}/>SAVE</>
                    }
                </CurveButton>
            }
        </Box>
    );
};

/**
 * Common formatter component for the prices and bases shown on curve marking screen
 *
 * @param value The value to format and display
 */
const CurveAmount = ({ value }: { value: number }) => {
    return <AmountFormatWrapper amount={value} minDecimalPos={2} maxDecimalPos={2}/>
};

/**
 * Pre-formatted cell element for curve table components
 *
 * @param height Height prop
 * @param sx SX prop
 * @param children Children prop
 * @param rest Other properties for the underlying <TableCell/> component
 */
const CurveTableCell = (
    {
        height = '45px',
        sx = { minWidth: '180px' },
        children,
        ...rest
    }: TableCellProps) => {
    return <TableCell height={height} sx={sx} {...rest}>{children}</TableCell>
};

export { CurveInput, CurveButton, CurveEditButtonBlock, CurveAmount, CurveTableCell };
