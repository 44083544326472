/* eslint-disable no-mixed-operators */
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { 
    useAuth, 
    useCortenApiState , 
    submitUnsignedTransaction
} from '@trovio-tech/trovio-core-api-jsx';
import { useForm, useWatch } from 'react-hook-form';
import { 
    AlertDialogContent, 
    AlertType,
    AmountFormatWrapper, 
    ControlledTextField,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getOrderedProjectsAndAmounts,
    getProductAttributeUiElements,
    inventoryAccount,
    marketControllerAccount,
    Option, 
    Options, 
    ProductType,
    renderDialogField,
    renderEntry,
    ResettableFormFields,
    TradeOptimiser,
    transactionAttributes,
    TransactionOverview,
    TransactionType, 
    useAppConfigState,
    useCommodityDeskApiContext,
    useProductDataState,
    useProductItemFilters,
    useTransactionMonitor
} from '@commodity-desk/common';
import { CancelEscrowedHoldingsRequest, HoldingSelector } from '@trovio-tech/trovio-core-api-js';

interface UnlockHoldingsForm extends ResettableFormFields {
    quantity: number,
    market: string,
    holdings: string,
}

const defaultValues: UnlockHoldingsForm = {
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    projectState: '',
    country: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
    // other
    quantity: 0,
    market: '',
    holdings: ''
};


const UnlockHoldings = ({
    unlockHoldingsDialogActive,
    preSelectedProductId,
    preSelectedProjectId,
    preSelectedProjectType,
    preSelectedVintage,
    onUnlockHoldingsDialogClosed,
    holdings
}: {
    unlockHoldingsDialogActive: boolean;
    preSelectedProductId?: string;
    preSelectedProjectId?: string;
    preSelectedProjectType?: string;
    preSelectedVintage?: string;
    onUnlockHoldingsDialogClosed: any;
    holdings: any[];
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm<UnlockHoldingsForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['product', 'market'], control });

    const { cortenApi, cortenAuthApi } = useCortenApiState();

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenPowerAccreditedOptions,
        segmentOptions,
        issueYearOptions,
        miqGradeOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({cortenApi: cortenApi, excludeProducts: [], useLockedBalances: true});

    const appConfigState = useAppConfigState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);
    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const user = useAuth();
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [availableBalanceOfSelectedHoldings, setAvailableBalanceOfSelectedHoldings] = useState<number | undefined>(undefined);
    const [balanceError, setBalanceError] = useState<string>();
    const [marketOptions, setMarketOptions] = useState<Option[]>([]);
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const { productsData } = useProductDataState();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const productItemIds = useRef<Promise<string[]>>();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    useEffect(() => {
        if (unlockHoldingsDialogActive) {
            let preSelectedData = getValues();
            setMarketOptions(appConfigState.getMarkets().map((market) => (
                new Option(market.name, market.name, null, !market.enabled)
            )));
            if (holdings.length > 0) {
                preSelectedData.holdings = holdings.map((holding: any) => { return holding.holdingId }).join(", ");
                setAvailableBalanceOfSelectedHoldings(
                    holdings.map((holding: any) => { return parseFloat(holding.amount) }).reduce((sumAmount: number, amount: number) => sumAmount + amount, 0)
                );
                //When multiple markets are supported, read from controllerId of selectedHoldings
                preSelectedData.market = appConfigState.getMarkets()[0].name;
            } else {
                if (preSelectedProductId !== undefined) {
                    preSelectedData.product = preSelectedProductId;
                } else {
                    preSelectedData.product = "";
                }
                if (preSelectedProjectId !== undefined) {
                    preSelectedData.project = preSelectedProjectId;
                } else {
                    preSelectedData.project = "";
                }
                if (preSelectedProjectType !== undefined) {
                    preSelectedData.projectType = preSelectedProjectType;
                } else {
                    preSelectedData.projectType = "";
                }
                if (preSelectedVintage !== undefined) {
                    preSelectedData.vintage = preSelectedVintage;
                } else {
                    preSelectedData.vintage = "";
                }
            }
            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, { ...preSelectedData, account: inventoryAccount.id });
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [unlockHoldingsDialogActive]); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (holdings.length === 0 && !form.product) {
            error = 'Please select a Product';
        }
        if (!form.market) {
            error = 'Please select a Market';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
        },
        (tx) => {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when locking holdings to market.');
            setTransactionErrorCode(tx.errorCode);
        },
        () => {
            setIsTransactionInProgress(false);
            setTransactionWarnMessage('The transaction timed out. Please check back later to confirm whether the transaction was processed.');
        }
    );

    const handleUnlockHoldingDialogClose = () => {
        unsubscribe();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        // update state in the parent view
        onUnlockHoldingsDialogClosed();
    };

    const performUnlock = async () => {
        setIsTransactionInProgress(true);
        setTransactionInReview(false);
        let requestFrom: HoldingSelector  = (holdings.length > 0) ? {
            type: "HoldingAmount",
            inputList: holdings.map((holding: any) => holding.holdingId as string),
            amount: getValues().quantity,
        } as HoldingSelector : {
            type: "ProductItemsAmount",
            productItemIds: await productItemIds.current!!,
            amount: getValues().quantity,
        } as HoldingSelector;
        
        const request: CancelEscrowedHoldingsRequest = {
            type: 'CancelEscrowedHoldingsRequest',
            fromAccountId: inventoryAccount.id,
            controllerAccountId: marketControllerAccount.id,
            from: requestFrom,
            requesterIsController: true,
            attributes: {
                [`${transactionAttributes.transactionTypeInventory.key}`]: TransactionType.UnlockFromMarket as any
            }
        };
        const result = await submitUnsignedTransaction(request, user, cortenAuthApi);
        if (result === undefined) {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage("Could not complete the transaction - the request was rejected")

        } else {
            pendingTransaction.current = result.txId;
            subscribe(result.txId);
        }
    };

    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(resetField, {...getValues(), account: inventoryAccount.id}, field);
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        if (holdings.length === 0) {
            getOrderedProjectsAndAmounts(
                { 
                    ...getValues(), 
                    product: selectedProductId,
                    tradeOptimiser: TradeOptimiser.DEFAULT 
                },
                inventoryAccount.id,
                setProjectAmounts,
                productItemIds,
                appConfigState,
                cortenApi,
                commodityDeskApi,
                handleError
            );
        }
    };

    const handleError = () => {
        setTransactionInReview(false);
        setTransactionErrorMessage("Could not connect to CortenX. Please re-try.");
    };

    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };

    const selectedProduct = appConfigState.getProduct(getValues('product') ?? '');
    const selectedProductId = getValues().product!;

    if (transactionSuccess) {
        return (
            <TransactionOverview
                open={transactionSuccess}
                onClose={handleUnlockHoldingDialogClose}
                title="Transaction Submitted successfully"
                uiElements={{
                    transactionId: { value: pendingTransaction.current! },
                    market: { value: getValues().market },
                    ...(holdings.length > 0
                        ? {
                              holdings: {
                                  value: holdings
                                      .map((holding: any) => holding.holdingId)
                                      .join(', '),
                                  label: 'Holdings'
                              }
                          }
                        : {
                              productId: {
                                  value: appConfigState.getProduct(selectedProductId)?.displayCode
                              },
                              ...getProductAttributeUiElements({
                                  data: {
                                      ...getValues(),
                                      // projectName is not part of the form object, so we need to add it here for it to show on the overview
                                      projectName: projectOptions?.values.find(
                                          (opt) => opt.id === getValues().project
                                      )?.label
                                  },
                                  fieldDefinitions: fieldDefinitions,
                                  productType: appConfigState.getProduct(selectedProductId)
                                      ?.displayCode as ProductType
                              })
                          }),

                    quantity: { value: getValues().quantity.toString() }
                }}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={
                    appConfigState.getProduct(selectedProductId)?.displayCode as ProductType
                }
            />
        );
    }

    return (
        <Dialog
            open={unlockHoldingsDialogActive}
            onClose={handleUnlockHoldingDialogClose}
            fullWidth
            maxWidth="sm"
        >

            {!transactionInReview && !transactionSuccess && !isTransactionInProgress && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Unlock from Market
                        </Typography>

                        {renderEntry('Market:', (
                            <ControlledTextField name='market' label='Market'
                                options={new Options(marketOptions, false)}
                                rules={{ required: 'Market is required' }}
                                control={control} errors={errors}
                                reset={resetField}
                            />
                        ), true, true)}
                        {holdings.length > 0 && (
                            <>
                                {renderEntry('Holdings:', (
                                    <ControlledTextField name='holdings' label='Holdings'
                                        control={control} errors={errors} disabled />
                                ), true, true)}
                            </>
                        )}
                        {holdings.length === 0 && (
                            <>
                                {renderEntry('Product:', (
                                    <ControlledTextField name='product' label='Product'
                                        options={productOptions}
                                        customOnChange={() => onOptionSelect('product')}
                                        rules={{ required: 'Product is required' }}
                                        control={control} errors={errors} reset={resetField}
                                    />
                                ), true, true)}

                                {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Project Type:', (
                                    <ControlledTextField name='projectType' label='Project Type'
                                        options={projectTypeOptions}
                                        customOnChange={() => onOptionSelect('projectType')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}

                                {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Project:', (
                                    <ControlledTextField name='project' label='Project'
                                        options={projectOptions}
                                        customOnChange={() => onOptionSelect('project')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}
                                {[ProductType.ACCU, ProductType.VCU].includes(selectedProduct?.displayCode as ProductType) && renderEntry('Vintage:', (
                                    <ControlledTextField name='vintage' label='Vintage'
                                        options={vintageOptions}
                                        customOnChange={() => onOptionSelect('vintage')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}
                                {selectedProduct?.displayCode === ProductType.ACCU && renderEntry('State:', (
                                    <ControlledTextField name='projectState' label='State'
                                        options={projectStateOptions}
                                        customOnChange={() => onOptionSelect('projectState')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}
                                {selectedProduct?.displayCode === ProductType.VCU && renderEntry('Country:', (
                                    <ControlledTextField name='country' label='Country'
                                        options={countryOptions}
                                        customOnChange={() => onOptionSelect('country')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}



                                {selectedProduct?.displayCode === ProductType.LGC &&
                                    renderEntry('Fuel Source:', (
                                        <ControlledTextField name='fuelSource' label='Fuel Source'
                                            options={fuelSourceOptions}
                                            customOnChange={() => onOptionSelect('fuelSource')}
                                            control={control} errors={errors} reset={resetField}
                                            balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                            balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                        />
                                    ), true, true)}
                                {selectedProduct?.displayCode === ProductType.LGC &&
                                    renderEntry('Creation Year:', (
                                        <ControlledTextField name='creationYear' label='Creation Year'
                                            options={creationYearOptions}
                                            customOnChange={() => onOptionSelect('creationYear')}
                                            control={control} errors={errors} reset={resetField}
                                            balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                            balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                        />
                                    ), true, true)}
                                {selectedProduct?.displayCode === ProductType.LGC &&
                                    renderEntry('Generation Year:', (
                                        <ControlledTextField name='generationYear' label='Generation Year'
                                            options={generationYearOptions}
                                            customOnChange={() => onOptionSelect('generationYear')}
                                            control={control} errors={errors} reset={resetField}
                                            balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                            balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                        />
                                    ), true, true)}
                                {selectedProduct?.displayCode === ProductType.LGC &&
                                    renderEntry('Generation State:', (
                                        <ControlledTextField name='generationState' label='Generation State'
                                            options={generationStateOptions}
                                            customOnChange={() => onOptionSelect('generationState')}
                                            control={control} errors={errors} reset={resetField}
                                            balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                            balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                        />
                                    ), true, true)}
                                {selectedProduct?.displayCode === ProductType.LGC &&
                                    renderEntry('GreenPower Accredited:', (
                                        <ControlledTextField name='greenPowerAccredited' label='GreenPower Accredited'
                                            options={greenPowerAccreditedOptions}
                                            customOnChange={() => onOptionSelect('greenPowerAccredited')}
                                            control={control} errors={errors} reset={resetField}
                                            balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                            balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                        />
                                    ), true, true)}

                                {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('Segment:', (
                                    <ControlledTextField name='segment' label='Segment'
                                        options={segmentOptions}
                                        customOnChange={() => onOptionSelect('segment')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}

                                {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('Issue Year:', (
                                    <ControlledTextField name='issueYear' label='Issue Year'
                                        options={issueYearOptions}
                                        customOnChange={() => onOptionSelect('issueYear')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}

                                {selectedProduct?.displayCode === ProductType.MiQC && renderEntry('MiQ Grade:', (
                                    <ControlledTextField name='miqGrade' label='MiQ Grade'
                                        options={miqGradeOptions}
                                        customOnChange={() => onOptionSelect('miqGrade')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productsData.get(selectedProductId)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productsData.get(selectedProductId)?.maxDecimalPos}
                                    />
                                ), true, true)}
                            </>
                        )}

                        {renderEntry('Quantity:', (
                            <>
                                <ControlledTextField name='quantity' label='Quantity' integer
                                    rules={{
                                        required: 'Quantity is required',
                                        pattern: {
                                            value: /^\d*$/,
                                            message: 'Quantity must be a whole number',
                                        },
                                        min: {
                                            value: 1,
                                            message: 'Quantity must be greater than 0',
                                        },
                                        max: {
                                            value: holdings.length > 0 ? availableBalanceOfSelectedHoldings : availableBalance,
                                            message: 'Quantity exceeds available balance',
                                        },
                                    }}
                                    control={control} errors={errors}
                                />
                                {(holdings.length === 0 && !balanceError && availableBalance == null) || (holdings.length > 0 && availableBalanceOfSelectedHoldings === undefined)
                                    ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                    : (<Typography variant='caption'
                                        color={balanceError ? 'error' : 'textSecondary'}
                                        mt={1} mb={1}
                                        sx={{ float: 'right' }}>
                                        {balanceError
                                            ? <div>{balanceError}</div>
                                            : <div>Available Balance: <AmountFormatWrapper
                                                amount={holdings.length > 0 ? availableBalanceOfSelectedHoldings : availableBalance}
                                                minDecimalPos={productsData.get(selectedProductId)?.minDecimalPos!}
                                                maxDecimalPos={productsData.get(selectedProductId)?.maxDecimalPos!}
                                            /></div>}
                                    </Typography>)
                                }
                            </>
                        ), true, true)}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleUnlockHoldingDialogClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => openReviewDialog()}
                            color="primary"
                            variant="outlined"
                            disabled={
                                !isValid || !availableBalance
                            }
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {(transactionInReview || isTransactionInProgress) && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>Review Details Below</Typography>
                        {renderDialogField('Market', getValues().market)}
                        {holdings.length > 0 && (
                            <>
                                {renderDialogField('Holdings', (
                                    holdings.map((holding: any) => { return holding.holdingId }).join(", ")

                                ))}
                            </>
                        )}
                        {getValues().product && renderDialogField('Product', appConfigState.getProduct(selectedProductId)?.displayCode)}
                        {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}
                        {getValues().project && renderDialogField(
                            'Project',
                            projectOptions?.values.find(opt => opt.id === getValues().project)?.label)
                        }
                        {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                        {getValues().projectState && renderDialogField('State', getValues().projectState)}
                        {getValues().country && renderDialogField('Country', getValues().country)}
                        {getValues().segment && renderDialogField('Segment', getValues().segment)}
                        {getValues().issueYear && renderDialogField('Issue Year', getValues().issueYear)}
                        {getValues().miqGrade && renderDialogField('MiQ Grade', getValues().miqGrade)}
                        {renderDialogField('Quantity', getValues().quantity, {
                            minDecimalPos: productsData.get(selectedProductId)?.minDecimalPos!,
                            maxDecimalPos: productsData.get(selectedProductId)?.maxDecimalPos!,
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                        <Button
                            onClick={performUnlock}
                            color='primary'
                            variant='outlined'
                            disabled={
                                (!availableBalance && holdings.length === 0) || (!availableBalanceOfSelectedHoldings && holdings.length > 0)
                            }
                        >
                            {isTransactionInProgress ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '2px',
                                }}
                                >
                                    <CircularProgress size={20} />
                                </div>
                            ) : ('Confirm')}
                        </Button>
                    </DialogActions>
                </>
            )}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onUnlockHoldingsDialogClosed}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onUnlockHoldingsDialogClosed}
                />
            )}


        </Dialog>
    );

};

export {UnlockHoldings};
