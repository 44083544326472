import { Box, Grid, Typography, Select, MenuItem } from "@mui/material";
import { GridColumnVisibilityModel, GridToolbar } from "@mui/x-data-grid";
import {
    CustomTheme,
    getTableColumnSetStorageItem, 
    getTableColumnVisibilityStorageItem
} from '@commodity-desk/common';
import { STORAGE_KEY_PREFIX } from "../state/Variables";

export enum ColumnSet {
    All_Products = 'All Products',
    ACCU = 'ACCU',
    VCU = 'VCU',
    LGC = 'LGC',
    MiQC = 'MiQC',
    Custom = 'Custom'
}

export const productBasedColumnSets = [ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC];

/**
 * Contains the definitions about which columns to include in the table, which to show by default and other properties.
 * At most one of showByDefault or showInDefaultColumnSet should be specified, and not both. If neither are provided, the column
 * will not appear by default in any view (and if all columns have this setting, all columns will be hidden by default).
 * @property key                     The column key
 * @property showByDefault           Whether the column will appear by default (before customisation).
 *                                   This is for tables without default column sets, and should not be used alongside showInDefaultColumnSet
 * @property showInDefaultColumnSet  Which column sets this column will appear in, by default (before customisation)
 *                                   Should not be used alongside showByDefault (it overrides showByDefault)
 */
export interface TableColumnDefinition {
    key: string;
    showByDefault?: boolean;
    showInDefaultColumnSet?: ColumnSet[]
}

/**
 * Retrieves the stored column set for a given table. If no column set is stored, return {@link ColumnSet.All_Products}
 * @param getTableNameForStorageKey  Function to get the table name component used in the column visibility/set storage keys
 * @returns 
 */
export const getStoredColumnSet = (getTableNameForStorageKey: any) => {
    const storageKey = getTableColumnSetStorageItem(getTableNameForStorageKey(), STORAGE_KEY_PREFIX);
    return storageKey.getItemValue() as ColumnSet ?? ColumnSet.All_Products;
}

/**
 * Determines the {@link GridColumnVisibilityModel} based on the provided array of {@link TableColumnDefinition}s and columnSet 
 * @param columnSet          The column set that currently applies
 * @param columnDefinitions  The set of column definitions, including default visibility
 * @param showOnly           If included, will only show columns that match ones in this list. Other columns are hidden.
 * @returns 
 */
export const columnDefinitionsToVisibilityModel = (columnSet: ColumnSet, columnDefinitions: TableColumnDefinition[], showOnly?: string[]) => {
    return columnDefinitions.reduce(function (map, obj) {
        if (showOnly !== undefined) {
            // If the user has stored a specific preferred set of columns, then only show those
            map[obj.key] = showOnly.includes(obj.key);
        } else if (obj.showInDefaultColumnSet) {
            // otherwise, if a column set applies, show the column if it appears by default in that column set
            map[obj.key] = obj.showInDefaultColumnSet.includes(columnSet);
        } else if (obj.showByDefault === undefined) {
            // otherwise, if no 'showByDefault' setting was specified, hide it by default
            map[obj.key] = false;
        } else {
            // otherwise, use the 'showByDefault' setting
            map[obj.key] = obj.showByDefault
        }
        return map;
    }, {} as GridColumnVisibilityModel);
}

/**
 * Returns the stored column visibility model for a given table and column set. If nothing is stored, return the default column visibility model
 * for the table and column set.
 * @param columnSet                  The column set that currently applies
 * @param columnDefinitions          The set of column definitions, including default visibility
 * @param getTableNameForStorageKey  Function to get the table name component used in the column visibility/set storage keys
 * @returns a GridColumnVisibiltyModel
 */
export const getStoredColumnVisibilityModel = (
    columnSet: ColumnSet,
    columnDefinitions: TableColumnDefinition[],
    getTableNameForStorageKey: () => string
) => {
    const defaultVisibilityModel = columnDefinitionsToVisibilityModel(columnSet, columnDefinitions);
    const storageKey = getTableColumnVisibilityStorageItem(getTableNameForStorageKey(), columnSet, STORAGE_KEY_PREFIX);
    const storedVisibleColumns = storageKey.getItemValue();
    if (storedVisibleColumns && storedVisibleColumns.match(/^[\w+,]+$/g)) {
        const showOnly = storedVisibleColumns.split(',');
        return columnDefinitionsToVisibilityModel(columnSet, columnDefinitions, showOnly);
    } else {
        storageKey.setItemValue('');
        return defaultVisibilityModel;
    }
}

/**
 * Stores the column visibility model for a given table and column set.
 * @param columnSet                  The column set that currently applies
 * @param visibilityModel            The visibility model to store
 * @param getTableNameForStorageKey  Function to get the table name component used in the column visibility/set storage keys
 */
export const storeColumnVisibilityModel = (
    columnSet: ColumnSet,
    visibilityModel: GridColumnVisibilityModel,
    getTableNameForStorageKey: () => string
) => {
    const storageItem = getTableColumnVisibilityStorageItem(getTableNameForStorageKey(), columnSet, STORAGE_KEY_PREFIX);
    storageItem.setItemValue(Object.keys(visibilityModel).filter(key => visibilityModel[key]).map((key, index) => key).join(','));
}

/**
 * React hook to provide column set visibility selector related utilities
 * @param customTheme
 * @param chosenColumnSet          React state that keeps the currently chosen column set
 * @param handleColumnSetChange    Function to handle the column set change event
 * @returns  a ExtendedToolbarWithColumnSelector
 */
export const useGridToolbarWithColumnSelector = ({
    customTheme,
    chosenColumnSet,
    handleColumnSetChange
}: {
    customTheme: CustomTheme,
    chosenColumnSet: ColumnSet,
    handleColumnSetChange: any
}) => {

    /**
     * An extended MUI Data Grid tool bar with the column visibility selector component.
     */
    const ExtendedToolbarWithColumnSelector = () => {
        return (
            <Box sx={{ display: 'flex' }}>
                <GridToolbar />
                <Box sx={{ margin: '6px 6px 0 50px', flex: 1 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Typography sx={{ color: customTheme.theme.palette.primary.main, marginRight: 2 }}>
                            Column template:
                        </Typography>
                        <Select
                            size="small"
                            id="choose-column-set"
                            value={chosenColumnSet}
                            onChange={handleColumnSetChange}
                            sx={{
                                color: customTheme.theme.palette.primary.main,
                                '& .MuiSelect-select': { padding: '5px 15px' }
                            }}
                        >
                            <MenuItem value={ColumnSet.All_Products} key={ColumnSet.All_Products}>{ColumnSet.All_Products}</MenuItem>
                            {productBasedColumnSets.map(columnSet => (
                                <MenuItem value={columnSet} key={columnSet}>{columnSet}</MenuItem>
                            ))}
                            <MenuItem value={ColumnSet.Custom} key={ColumnSet.Custom}>{ColumnSet.Custom}</MenuItem>
                        </Select>
                    </Grid>
                </Box>
            </Box>
        );
    };

    return {
        ExtendedToolbarWithColumnSelector
    };
}