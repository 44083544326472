var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
/**
 * Generic link that is displayed for Documents
 *
 * @param url The URL to the document to link to
 */
var DocumentLink = function (_a) {
    var url = _a.url;
    return (_jsx(Link, __assign({ align: "right", href: url, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(FontAwesomeIcon, { size: "lg", icon: faUpRightFromSquare }) })));
};
export default DocumentLink;
