import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { HoldingPageType, Holdings } from '../../component/Holdings';
import { TradePageType, Trades } from '../../component/Trades';
import dayjs from 'dayjs';
import { TableColumnDefinition } from '../../utility/ColumnSetSelectorUtil';
import { 
    DATE_DISPLAY_FORMAT,
    inventoryAccount,
    lgcAttributes,
    miqcAttributes,
    NullableString,
    ProductBase, 
    ProductType,
    carbonProjectAttributes,
    transactionAttributes,
    useAppConfigState,
} from '@commodity-desk/common';
import { UNCLASSIFIED_LABEL } from './deltaLadder/DeltaLadderTypes';

const TradingRiskPosition = () => {

    const appConfigState = useAppConfigState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [productId, setProductId] = useState<string>("");
    const [productBase, setProductBase] = useState<ProductBase>(ProductBase.Project);
    const [valueDateStart, setValueDateStart] = useState<string | undefined>(undefined);
    const [valueDateEnd, setValueDateEnd] = useState<string | undefined>(undefined);
    // ACCU, VCU
    const [projectType, setProjectType] = useState<NullableString | undefined>(undefined);
    const [project, setProject] = useState<NullableString | undefined>(undefined);
    const [vintage, setVintage] = useState<NullableString | undefined>(undefined);
    const [countryCode, setCountryCode] = useState<NullableString | undefined>(undefined);
    const [projectState, setProjectState] = useState<NullableString | undefined>(undefined);
    // LGC
    const [fuelSource, setFuelSource] = useState<NullableString | undefined>(undefined);
    const [generationYear, setGenerationYear] = useState<NullableString | undefined>(undefined);
    const [creationYear, setCreationYear] = useState<NullableString | undefined>(undefined);
    const [generationState, setGenerationState] = useState<NullableString | undefined>(undefined);
    const [greenPowerAccredited, setGreenPowerAccredited] = useState<NullableString | undefined>(undefined);
    const [accreditationCode, setAccreditationCode] = useState<NullableString | undefined>(undefined);
    // MiQC
    const [segment, setSegment] = useState<NullableString | undefined>(undefined);
    const [issueYear, setIssueYear] = useState<NullableString | undefined>(undefined);
    const [miqGrade, setMiqGrade] = useState<NullableString | undefined>(undefined);
    const [facility, setFacility] = useState<NullableString | undefined>(undefined);
    // Only render the page when we have finished loading parameters from the URL. Without this, the table loads twice, and flickers with incorrect displayed values.
    // In some rare scenarios the updated state on second load does not follow through to DataGrid, resulting in an incorrect totals row and an invalid pagination arrow that
    // throws an error and displays an empty page if clicked. To avoid this issue we make sure to only render the table once.
    const [loaded, setLoaded] = useState<boolean>(false);

    const displayAttributeValue = (rawValue: string | null) => {
        return rawValue === null ? UNCLASSIFIED_LABEL : rawValue;
    }

    const attributeSummaryRows = [
        { attributeName: "projectType", attributeValue: projectType, label: carbonProjectAttributes.projectType.display, setter: setProjectType },
        { attributeName: "vintage", attributeValue: vintage, label: carbonProjectAttributes.vintage.display, setter: setVintage },
        { attributeName: "projectState", attributeValue: projectState, label: carbonProjectAttributes.projectState.display, setter: setProjectState },
        { attributeName: "countryCode", attributeValue: countryCode, label: carbonProjectAttributes.country.display, setter: setCountryCode },
        { attributeName: "project", attributeValue: project, label: carbonProjectAttributes.projectId.display, setter: setProject },
        { attributeName: "fuelSource", attributeValue: fuelSource, label: lgcAttributes.fuelSource.display, setter: setFuelSource },
        { attributeName: "generationYear", attributeValue: generationYear, label: lgcAttributes.generationYear.display, setter: setGenerationYear },
        { attributeName: "creationYear", attributeValue: creationYear, label: lgcAttributes.creationYear.display, setter: setCreationYear },
        { attributeName: "generationState", attributeValue: generationState, label: lgcAttributes.generationState.display, setter: setGenerationState },
        { attributeName: "greenPowerAccredited", attributeValue: greenPowerAccredited, label: lgcAttributes.greenPowerAccredited.display, setter: setGreenPowerAccredited },
        { attributeName: "accreditationCode", attributeValue: accreditationCode, label: lgcAttributes.accreditationCode.display, setter: setAccreditationCode },
        { attributeName: "segment", attributeValue: segment, label: miqcAttributes.segment.display, setter: setSegment },
        { attributeName: "issueYear", attributeValue: issueYear, label: miqcAttributes.issueYear.display, setter: setIssueYear },
        { attributeName: "miqGrade", attributeValue: miqGrade, label: miqcAttributes.miqGrade.display, setter: setMiqGrade },
        { attributeName: "facility", attributeValue: facility, label: miqcAttributes.facility.display, setter: setFacility },
    ]

    useEffect(() => {

        attributeSummaryRows.forEach((item) => {
            const paramValue = searchParams.get(item.attributeName);
            // Check if paramValue is 'null' as a string and set the state to null
            const value = paramValue === 'null' ? null : paramValue || undefined;
            item.setter(value);
        });

        // Following will not contain null
        setProductId(searchParams.get('product') || "");
        setProductBase(ProductBase[(searchParams.get('productBase') || ProductBase.Project) as keyof typeof ProductBase]);
        setValueDateStart(searchParams.get('valueDateStart') || undefined);
        setValueDateEnd(searchParams.get('valueDateEnd') || undefined);
        setLoaded(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getColumnDefinitions = (): TableColumnDefinition[] => {
        const definitions: TableColumnDefinition[] = [];

        definitions.push(
            {key: "valueDate", showByDefault: true},
            {key: "tradeDate", showByDefault: false},
            {key: "forwardId", showByDefault: true},
            {key: "tradeId", showByDefault: true},
            {key: "quantity", showByDefault: true},
            {key: "productId", showByDefault: true}
        )

        switch (appConfigState.getProduct(productId)?.productBase) {
            case ProductBase.Project:
                definitions.push(
                    {key: "projectType", showByDefault: true},
                    {key: "projectId", showByDefault: false},
                    {key: "projectName", showByDefault: false},
                    {key: "vintage", showByDefault: true},
                    appConfigState.getProduct(productId)?.displayCode === ProductType.ACCU
                        ? {key: "projectState", showByDefault: true}
                        : { key: 'country', showByDefault: true }
                )
                break;
            case ProductBase.Certificate:
                switch (appConfigState.getProduct(productId)?.displayCode) {
                    case ProductType.LGC:
                        definitions.push(
                            {key: "accreditationCode", showByDefault: true},
                            {key: "fuelSource", showByDefault: true},
                            {key: "creationYear", showByDefault: true},
                            {key: "generationYear", showByDefault: true},
                            {key: "generationState", showByDefault: true},
                            {key: "greenPowerAccredited", showByDefault: true}
                        );
                        break;
                    case ProductType.MiQC:
                        definitions.push(
                            {key: "facility", showByDefault: true},
                            {key: "segment", showByDefault: true},
                            {key: "issueMonth", showByDefault: true},
                            {key: "issueYear", showByDefault: false},
                            {key: "countryOfOperation", showByDefault: true},
                            {key: "certificateRegion", showByDefault: false},
                            {key: "operatorName", showByDefault: true},
                            {key: "miqMethaneIntensity", showByDefault: true},
                            {key: "miqGrade", showByDefault: true},
                            {key: "miqGradeStatus", showByDefault: true},
                            {key: "miqAuditorName", showByDefault: false},
                            {key: "eo100Grade", showByDefault: true},
                        );
                        break;
                }
                break;
        }

        definitions.push(
            {key: "currency", showByDefault: true},
            {key: "price", showByDefault: true},
            {key: "counterParty", showByDefault: true},
            {key: "trader", showByDefault: false},
            {key: "salesPerson", showByDefault: false},
            {key: "salesCredits", showByDefault: false},
            {key: "brokerName", showByDefault: false},
            {key: "brokerage", showByDefault: false}
        )

        return definitions;
    }
        
    return (
        <>
            {loaded && ( <>
                <Typography variant='h2'>{valueDateStart !== undefined && valueDateEnd !== undefined ? "Risk Forward Trades" : "Risk Position Holdings"}</Typography>
                <TableContainer component={Paper} sx={{ maxWidth: 450, marginBottom: 4 }}>
                    <Table sx={{ maxWidth: 450 }} size='small' aria-label="position summary table">
                        <TableBody>
                            {productId && (<TableRow hover>
                                <TableCell>Product Name</TableCell>
                                <TableCell>
                                    {appConfigState.getProduct(productId)?.displayName}
                                </TableCell>
                            </TableRow>)}

                            {attributeSummaryRows.map((item, index) => {
                                return (<>
                                    {item.attributeValue !== undefined && (
                                        <TableRow hover key={index}>
                                            <TableCell>{item.label}</TableCell>
                                            <TableCell>
                                                {displayAttributeValue(item.attributeValue)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>)
                            })}
                            {valueDateStart && (<TableRow hover>
                                <TableCell>Value Date Start</TableCell>
                                <TableCell>
                                {dayjs(valueDateStart).format(DATE_DISPLAY_FORMAT)}
                                </TableCell>
                            </TableRow>)}
                            {valueDateEnd && (<TableRow hover>
                                <TableCell>Value Date End</TableCell>
                                <TableCell>
                                    {dayjs(valueDateEnd).add(-1, 'day').format(DATE_DISPLAY_FORMAT)}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(valueDateStart && valueDateEnd) ? (
                    <Trades
                        pageType={TradePageType.Forwards}
                        columnDefinitions={getColumnDefinitions()}
                        defaultOrdering={`productItemAttribute.${transactionAttributes.valueDate.key}`}
                        pageFilters={{
                            product: productId,
                            projectType: projectType,
                            projectId: project,
                            vintage: vintage,
                            country: countryCode,
                            projectState: projectState,
                            fuelSource: fuelSource,
                            generationYear: generationYear,
                            creationYear: creationYear,
                            generationState: generationState,
                            greenPowerAccredited: greenPowerAccredited,
                            accreditationCode: accreditationCode,
                            segment: segment,
                            issueYear: issueYear,
                            miqGrade: miqGrade,
                            facility: facility,
                            valueDateStart: valueDateStart,
                            valueDateEnd: valueDateEnd,
                        }}
                    />
                ) : (
                    <Holdings
                        pageType={
                            productBase === ProductBase.Project ? HoldingPageType.RISK
                            : HoldingPageType.INVENTORY_CERTIFICATES
                        }
                        columnDefinitions={
                            [
                                {key: 'holdingId', showByDefault: true},
                                {key: 'amount', showByDefault: true},
                                {key: 'product', showByDefault: true},
                                ...(productBase === ProductBase.Project ? [
                                    {key: 'projectId', showByDefault: true},
                                    {key: 'vintage', showByDefault: true},
                                    {key: 'serialNumRange', showByDefault: true},
                                ]
                                : appConfigState.getProduct(productId)?.displayCode === ProductType.LGC ? [
                                    {key: 'accreditationCode', showByDefault: true},
                                    {key: 'fuelSource', showByDefault: true},
                                    {key: 'creationYear', showByDefault: true},
                                    {key: 'generationYear', showByDefault: true},
                                    {key: 'generationState', showByDefault: true},
                                    {key: 'serialNumRange', showByDefault: true},
                                    {key: 'greenPowerAccredited', showByDefault: true},
                                ] : appConfigState.getProduct(productId)?.displayCode === ProductType.MiQC ? [
                                    {key: "facility", showByDefault: true},
                                    {key: "segment", showByDefault: true},
                                    {key: "issueMonth", showByDefault: true},
                                    {key: "issueYear", showByDefault: false},
                                    {key: "countryOfOperation", showByDefault: true},
                                    {key: "certificateRegion", showByDefault: false},
                                    {key: "operatorName", showByDefault: true},
                                    {key: "miqMethaneIntensity", showByDefault: true},
                                    {key: "miqGrade", showByDefault: true},
                                    {key: "miqGradeStatus", showByDefault: true},
                                    {key: "miqAuditorName", showByDefault: false},
                                    {key: "eo100Grade", showByDefault: true},
                                ] : []),
                                {key: 'state', showByDefault: true},
                                {key: 'info', showByDefault: true}
                            ]
                        }
                        pageFilters = {{
                            accountId: valueDateStart && valueDateEnd ? '' : inventoryAccount.id,
                            product: productId,
                            projectType: projectType as (string | undefined),
                            project: project as (string | undefined),
                            vintage: vintage as (string | undefined),
                            countryCode: countryCode as (string | undefined),
                            projectState: projectState as (string | undefined),
                            accreditationCode: accreditationCode as (string | undefined),
                            fuelSource: fuelSource as (string | undefined),
                            generationYear: generationYear as (string | undefined),
                            creationYear: creationYear as (string | undefined),
                            generationState: generationState as (string | undefined),
                            greenPowerAccredited: greenPowerAccredited as (string | undefined),
                            segment: segment as (string | undefined),
                            issueYear: issueYear as (string | undefined),
                            miqGrade: miqGrade as (string | undefined),
                            facility: facility as (string | undefined),
                        }}
                        selectedHoldings={[]}
                    />
                )}
            </>)}
        </>
    );
};

export default TradingRiskPosition;
