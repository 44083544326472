import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { ConfirmDialog, DialogState } from '../../component/ConfirmDialog';
import { 
    submitUnsignedTransaction, 
    useAuth,
    useCortenApiState 
} from '@trovio-tech/trovio-core-api-jsx';
import { useLockedHoldings } from './state/LockedHoldingState';
import { useProductState } from './state/ProductState';
import {
    ActionType,
    AlertDialogContent,
    AlertType,
    AmountFormatWrapper,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getProductAttributeUiElements,
    marketControllerAccount,
    miqcAttributes,
    MONTH_YEAR_DISPLAY_FORMAT,
    OverviewCategory,
    ProductBase,
    ProductData,
    ProductType,
    carbonProjectAttributes,
    productAttributes,
    renderDialogField,
    TransactionOverview,
    TransactionType,
    transactionAttributes,
    useAppConfigState,
    useProductDataState,
    useTransactionMonitor,
    utcToLocalFormat,
} from '@commodity-desk/common';
import { 
    CancelEscrowRequest, 
    EscrowTransactionData, 
    GetTransactionDetailRequest, 
    ListBalanceHistoryRequest, 
    PagedListResponseBalanceHistoryData, 
    TransactionData 
} from '@trovio-tech/trovio-core-api-js';
import { DataTable, DataTableColumnProps } from '@trovio-ui-libs/ui';


interface LockedHoldingBalanceDetail {
    projectType?: string;
    projectId?: string;
    projectName?: string;
    vintage?: string;
    projectState?: string;
    country?: string;
    facility?: string;
    segment?: string;
    issueMonth?: string;
    issueYear?: string;
    countryOfOperation?: string;
    certificateRegion?: string;
    operatorName?: string;
    miqMethaneIntensity?: string;
    miqGrade?: string;
    miqGradeStatus?: string;
    miqAuditorName?: string;
    eo100Grade?: string;
    quantity: number;
}

interface LockedHoldingDetail {
    balances: LockedHoldingBalanceDetail[];
    transactionTimestamp: string;
    transactionHash: string;
    lockId: string;
    controller: string;
}

const LockedHoldings = () => {
    const user = useAuth();
    const appConfigState = useAppConfigState();
    const { cortenApi, cortenAuthApi } = useCortenApiState();
    const { productsData } = useProductDataState();
    const { mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [detailsLoaded, setDetailsLoaded] = useState(false);
    const [selectedEscrowDetails, setSelectedEscrowDetails] = useState<LockedHoldingDetail | null>(null);
    const [detailsLoadingError, setDetailsLoadingError] = useState(false);
    const { isLoading, data, total, onSuccess, getNextPageToken, pagination, onPaginationChange } = useLockedHoldings();

    const fontBoldStyle = { fontWeight: 'bold' };

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    // States for Confirm dialog
    const [dialogState, setDialogState] = useState<{
        currentSelectedItem: EscrowTransactionData | undefined;
        currentState: DialogState | undefined;
        isError?: boolean;
        errorCode?: any;
        isLoading: boolean;
    }>({
        currentSelectedItem: undefined,
        currentState: 'REVIEW',
        isLoading: false
    });

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            // On Success
            setDialogState((prev) => ({
                ...prev,
                currentState: 'SUCCESS',
                isLoading: false
            }));
        },
        (tx) =>
            // On Error
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                errorCode: tx?.errorCode,
                isLoading: false
            })),
        () =>
            // On TimeOut
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                errorCode: 'TimeOut',
                isLoading: false
            }))
    );

    // Handle  Unlock holding
    const handleUnlock = async (
        fromAccountId: string,
        controllerAccountId: string,
        escrowId: string
    ) => {
        try {
            setDialogState((prev) => ({
                ...prev,
                isLoading: true
            }));
            const request: CancelEscrowRequest = {
                type: "CancelEscrowRequest",
                fromAccountId: fromAccountId,
                controllerAccountId: controllerAccountId,
                escrowId: escrowId,
                requesterIsController: false
            };
            const result = await submitUnsignedTransaction(request, user, cortenAuthApi);
            if (!result) {
                // If Error, set dialog state to error state
                setDialogState((prev) => ({
                    ...prev,
                    currentState: 'ERROR',
                    isError: true,
                    isLoading: false
                }));
            }
            subscribe(result.txId);
        } catch (error: any) {
            // Catch error and update dialog state
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                isError: true,
                errorCode: error?.code || 500,
                isLoading: false
            }));
        }
    };

    // On Dialog Close, handle onSuccess func on Locked holding state also reset unique products in useInventoryManagementState hooks and context
    const handleDialogClose = async () => {
        unsubscribe();
        onSuccess(dialogState.currentSelectedItem?.escrowId!);
        fetchAndInitProducts(mblSelectedProduct.id);
        setDialogState((prev) => ({
            ...prev,
            currentSelectedItem: undefined,
            currentState: undefined
        }));
    };

    const handleOpenHoldingDetails = (escrowId: string, productId: string) => {
        setDetailsDialogOpen(true);
        setDetailsLoaded(false);
        loadHoldingDetails(escrowId, productId);
    }

    const handleDialogOpenError = () => {
        setDetailsDialogOpen(false);
        setDetailsLoaded(false);
        setSelectedEscrowDetails(null);
        setDetailsLoadingError(true);
    }

    /**
     * Fetch amounts escrowed with each attribute set in a given escrow transaction
     * @param escrowId ID of escrow transaction in block:index form
     */
    const loadHoldingDetails = async (escrowId: string, productId: string) => {
        // Get the transaction hash of the transaction
        const getTransactionDetailsRequest: GetTransactionDetailRequest = {
            blockAndIndex: escrowId
        }
        let response: TransactionData = await cortenApi.account.getTransactionDetail(getTransactionDetailsRequest);
        if (!response) {
            handleDialogOpenError()
        }
        const escrowTxHash = response.txId;

        const lockedHoldingDetail: LockedHoldingDetail = {
            balances: [],
            transactionHash: escrowTxHash,
            lockId: escrowId,
            transactionTimestamp: response.timestamp.toISOString(),
            controller: marketControllerAccount.display
        }
        let product = appConfigState.getProduct(productId);
        let listBalanceHistoryRequest: ListBalanceHistoryRequest = {
            transactionId: [escrowTxHash],
            transactionAttribute: {
                [`${transactionAttributes.transactionTypeInventory.key}`]: TransactionType.LockToMarket
            },
            productItemAttribute: {},
            groupBy: product?.productBase === ProductBase.Project ?[
                `productItemAttribute.${carbonProjectAttributes.projectId.key}`,
                `productItemAttribute.${carbonProjectAttributes.projectName.key}`,
                `productItemAttribute.${carbonProjectAttributes.vintage.key}`,
                `productItemAttribute.${carbonProjectAttributes.country.key}`,
                `productItemAttribute.${carbonProjectAttributes.projectState.key}`,
                `productItemAttribute.${carbonProjectAttributes.projectType.key}`,
                "transaction"
            ] : [
                `productItemAttribute.${miqcAttributes.facility.key}`,
                `productItemAttribute.${miqcAttributes.segment.key}`,
                `productItemAttribute.${miqcAttributes.issueMonth.key}`,
                `productItemAttribute.${miqcAttributes.countryOfOperation.key}`,
                `productItemAttribute.${miqcAttributes.certificateRegion.key}`,
                `productItemAttribute.${miqcAttributes.operatorName.key}`,
                `productItemAttribute.${miqcAttributes.miqMethaneIntensity.key}`,
                `productItemAttribute.${miqcAttributes.miqGrade.key}`,
                `productItemAttribute.${miqcAttributes.miqGradeStatus.key}`,
                `productItemAttribute.${miqcAttributes.miqAuditorName.key}`,
                `productItemAttribute.${miqcAttributes.eo100Grade.key}`,
                "transaction"
            ],
            pageWithCount: true,
            pageLimit: 200
        }

        let nextPage = undefined;
        do {
            if (nextPage) {
                listBalanceHistoryRequest.pageFrom = nextPage;
            }
            let historyData: PagedListResponseBalanceHistoryData = await cortenApi.account.listBalanceHistory(listBalanceHistoryRequest);
            if (!historyData) {
                handleDialogOpenError()
            }
            historyData.list.forEach((data: any) => {
                lockedHoldingDetail.balances.push(
                    {
                        ...(product?.productBase === ProductBase.Project && {
                            projectType: data.productItemAttributes[carbonProjectAttributes.projectType.key],
                            projectId: data.productItemAttributes[carbonProjectAttributes.projectId.key],
                            projectName: data.productItemAttributes[carbonProjectAttributes.projectName.key],
                            vintage: data.productItemAttributes[carbonProjectAttributes.vintage.key],
                            country: data.productItemAttributes[carbonProjectAttributes.country.key],
                            projectState: data.productItemAttributes[carbonProjectAttributes.projectState.key]
                        }),
                        ...(product?.displayCode === ProductType.MiQC && {
                            facility: data.productItemAttributes[miqcAttributes.facility.key],
                            segment: data.productItemAttributes[miqcAttributes.segment.key],
                            issueMonth: data.productItemAttributes[miqcAttributes.issueMonth.key],
                            issueYear: data.productItemAttributes[miqcAttributes.issueYear.key],
                            countryOfOperation: data.productItemAttributes[miqcAttributes.countryOfOperation.key],
                            certificateRegion: data.productItemAttributes[miqcAttributes.certificateRegion.key],
                            operatorName: data.productItemAttributes[miqcAttributes.operatorName.key],
                            miqMethaneIntensity: data.productItemAttributes[miqcAttributes.miqMethaneIntensity.key],
                            miqGrade: data.productItemAttributes[miqcAttributes.miqGrade.key],
                            miqGradeStatus: data.productItemAttributes[miqcAttributes.miqGradeStatus.key],
                            miqAuditorName: data.productItemAttributes[miqcAttributes.miqAuditorName.key],
                            eo100Grade: data.productItemAttributes[miqcAttributes.eo100Grade.key]
                        }),
                        quantity: data.balances.escrowAmount
                    }
                )
            });
            nextPage = historyData.nextPage;
        } while (nextPage);
        setSelectedEscrowDetails(lockedHoldingDetail);
        setDetailsLoaded(true);
    }

    const handleDetailsDialogClose = () => {
        setDetailsDialogOpen(false);
        setDetailsLoaded(false);
        setSelectedEscrowDetails(null);
        setDetailsLoadingError(false);
    }

    const columns: DataTableColumnProps<EscrowTransactionData>[] = [
        {
            key: 'escrowId',
            header: 'ID',
            minWidth: 150
        },
        {
            key: 'productId',
            header: 'Product',
            cell: ({value}) => appConfigState.getProduct(value)?.displayCode ?? value,
            minWidth: 300
        },
        {
            key: 'remainingAmount',
            header: 'Quantity',
            cell: ({value}) => {
                return (
                    <AmountFormatWrapper
                        amount={Number(value)}
                        minDecimalPos={productsData.get(mblSelectedProduct.id)?.minDecimalPos!}
                        maxDecimalPos={productsData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                    />
                );
            },
            minWidth: 300
        },
        {
            key: 'controllerAccountId',
            header: 'Market',
            cell: (data) => {
                return <>{marketControllerAccount.display}</>;
            },
            minWidth: 200,
            
        },
        {
            key: 'actions',
            header: 'Actions',
            minWidth: 200,
            cell: ({row}) => {
                return (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={(event: any) => {
                            event.stopPropagation();
                            setDialogState((prev) => ({
                                ...prev,
                                currentSelectedItem: row,
                                currentState: 'REVIEW'
                            }));
                        }}
                    >
                        Unlock
                    </Button>
                );
            }
        }
    ];

    return (
        <>
            <DataTable
                loading={isLoading}
                columns={columns}
                data={data}
                paginationPageSizes={[pagination.pageSize]}
                onRowClick={row => handleOpenHoldingDetails(row.escrowId, row.productId)}
                paginationMode="server"
                paginationNextPage={getNextPageToken(pagination.pageIndex)}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                totalCount={total}
                hidePaginationPageSize
            />

            {detailsDialogOpen && (
                <TransactionOverview
                    open={detailsDialogOpen}
                    title="Locked Holdings Details"
                    onClose={handleDetailsDialogClose}
                    actions={
                        detailsLoaded === false ? [{
                            actionType: ActionType.LoadingBar
                        }] : []
                    }
                    productType={appConfigState.getProduct(mblSelectedProduct.id)?.displayCode as ProductType}
                    uiElements={(detailsLoaded === true && selectedEscrowDetails) ?
                        {
                            'transactionTimestamp': { value: selectedEscrowDetails?.transactionTimestamp! },
                            'lockId': { value: selectedEscrowDetails?.lockId },
                            'transactionId': { value: selectedEscrowDetails?.transactionHash },
                            'transactionType': { value: TransactionType.LockToMarket },
                            'market': { value: selectedEscrowDetails.controller },
                            ...selectedEscrowDetails.balances.length! === 1 ? {
                                'quantity': { value: selectedEscrowDetails.balances[0].quantity },
                            } : {},
                            'registry': {
                                value: productsData.get(mblSelectedProduct.id)?.attributes[productAttributes.registry.key] || ''
                            },
                            'productId': { value: mblSelectedProduct.id, label: 'Product Code' },
                            ...selectedEscrowDetails?.balances.length! > 1 ? {
                                ...(appConfigState.getProduct(mblSelectedProduct.id)?.productBase === ProductBase.Project && {
                                    'projectTable': {
                                        category: OverviewCategory.Product,
                                        hideLabel: true,
                                        value: (
                                            <>
                                                {(
                                                    <>
                                                        <Table
                                                            size="small"
                                                            sx={{
                                                                border: 1,
                                                                borderColor: 'divider'
                                                            }}
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Project Type
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Project
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Vintage
                                                                    </TableCell>
                                                                    {appConfigState.getProduct(mblSelectedProduct.id)?.displayCode === ProductType.ACCU && (
                                                                        <TableCell sx={fontBoldStyle}>
                                                                            State
                                                                        </TableCell>
                                                                    )}
                                                                    {appConfigState.getProduct(mblSelectedProduct.id)?.displayCode === ProductType.VCU && (
                                                                        <TableCell sx={fontBoldStyle}>
                                                                            Country
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Quantity
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {selectedEscrowDetails.balances.map(
                                                                    (escrowDetail, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail.projectType ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail.projectName ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail.vintage ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            {appConfigState.getProduct(mblSelectedProduct.id)?.displayCode === ProductType.ACCU && (
                                                                                <TableCell>
                                                                                    <Typography variant="body2">
                                                                                        {escrowDetail.projectState ?? '-'}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            )}
                                                                            {appConfigState.getProduct(mblSelectedProduct.id)?.displayCode === ProductType.VCU && (
                                                                                <TableCell>
                                                                                    <Typography variant="body2">
                                                                                        {escrowDetail.country ?? '-'}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell align='right'>
                                                                                <AmountFormatWrapper
                                                                                    amount={escrowDetail.quantity}
                                                                                    minDecimalPos={productsData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                                                                    maxDecimalPos={productsData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                )}
                                            </>
                                        )
                                    }
                                }),
                                ...(appConfigState.getProduct(mblSelectedProduct.id)?.displayCode === ProductType.MiQC && {
                                    'certificateTableLGC': {
                                        category: OverviewCategory.Product,
                                        hideLabel: true,
                                        value: (
                                            <>
                                                {(
                                                    <>
                                                        <Table
                                                            size="small"
                                                            sx={{
                                                                border: 1,
                                                                borderColor: 'divider'
                                                            }}
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Facility
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Segment
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Issue Month
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Country of Operation
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Certificate Region
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Operator Name
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        MiQ Methane Intensity
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        MiQ Grade
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        MiQ Grade Status
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        MiQ Auditor Name
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        EO100 Grade
                                                                    </TableCell>
                                                                    <TableCell sx={fontBoldStyle}>
                                                                        Quantity
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {selectedEscrowDetails.balances.map(
                                                                    (escrowDetail, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.facility ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.segment ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.issueMonth ? utcToLocalFormat(new Date(escrowDetail?.issueMonth).toDateString(), MONTH_YEAR_DISPLAY_FORMAT) : '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.countryOfOperation ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.certificateRegion ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.operatorName ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.miqMethaneIntensity ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.miqGrade ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.miqGradeStatus ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.miqAuditorName ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {escrowDetail?.eo100Grade ?? '-'}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align='right'>
                                                                                <AmountFormatWrapper
                                                                                    amount={escrowDetail.quantity}
                                                                                    minDecimalPos={productsData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                                                                    maxDecimalPos={productsData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                )}
                                            </>
                                        )
                                    }
                                }),
                                'quantity': {
                                    value: selectedEscrowDetails.balances.reduce((sum, element) => sum + Number(element.quantity), 0),
                                    label: 'Total Quantity'
                                }
                            } : {
                                ...getProductAttributeUiElements({
                                    data: selectedEscrowDetails.balances[0],
                                    fieldDefinitions: fieldDefinitions,
                                    productType: appConfigState.getProduct(mblSelectedProduct.id)?.displayCode as ProductType
                                }),
                            }
                        } : {}
                    }
                    fieldDefinitionMap={fieldDefinitionMap}
                />)}
            {detailsLoadingError && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={"Error while loading details, please re-try"}
                    handleDialogClose={handleDetailsDialogClose}
                    errorCode={null}
                />
            )}
            {/* Dialog  to handle confirm UI */}
            <ConfirmDialog
                isOpen={dialogState.currentSelectedItem ? true : false}
                isConfirmLoading={dialogState.isLoading}
                currentState={dialogState.currentState}
                handleAfterSuccess={() => handleDialogClose()}
                onCancel={() =>
                    setDialogState((prev) => ({
                        ...prev,
                        currentSelectedItem: undefined,
                        currentState: undefined
                    }))
                }
                onConfirm={() => {
                    if (dialogState.currentSelectedItem) {
                        const { accountId, controllerAccountId, escrowId } =
                            dialogState.currentSelectedItem;
                        handleUnlock(accountId, controllerAccountId, escrowId);
                    }
                }}
                render={(currentState) => {
                    // This  code conditionally renders different UI based on the value of the currentState.
                    // If currentState is 'REVIEW', it renders a UI for Review
                    if (currentState === 'REVIEW') {
                        return (
                            <>
                                <Typography variant="h2">Review Details Below</Typography>
                                {dialogState?.currentSelectedItem && (
                                    <>
                                        <HoldingPopUpContents
                                            id={dialogState.currentSelectedItem?.escrowId}
                                            productId={dialogState?.currentSelectedItem?.productId!}
                                            quantity={
                                                dialogState.currentSelectedItem?.remainingAmount.toString()
                                            }
                                            productData={productsData}
                                        />
                                    </>
                                )}
                            </>
                        );
                    }
                    // If currentState is 'SUCCESS', it renders a UI for Success
                    if (currentState === 'SUCCESS') {
                        return (
                            <>
                                <Typography variant="h2">
                                    Transaction submitted successfully
                                </Typography>
                                {dialogState?.currentSelectedItem && (
                                    <>
                                        <HoldingPopUpContents
                                            id={dialogState.currentSelectedItem?.escrowId}
                                            productId={dialogState?.currentSelectedItem?.productId!}
                                            quantity={
                                                dialogState.currentSelectedItem?.remainingAmount.toString()
                                            }
                                            productData={productsData}
                                        />
                                    </>
                                )}
                            </>
                        );
                    }
                    // If currentState is 'ERROR', it renders a UI for Error
                    if (currentState === 'ERROR') {
                        return (
                            <>
                                <Typography variant="h2">Transaction failed</Typography>
                                <Typography variant="h4">{dialogState?.errorCode}</Typography>
                                <Typography>Please try again later!!</Typography>
                            </>
                        );
                    }

                    return null;
                }}
            />
        </>
    );
};

export default LockedHoldings;

const HoldingPopUpContents = ({
    id,
    productId,
    quantity,
    productData
}: {
    id: string;
    productId: string;
    quantity: string;
    productData: Map<String, ProductData>
}) => {
    const appConfigState = useAppConfigState();

    return (
        <>
            {renderDialogField('ID', id)}
            {renderDialogField('Product', appConfigState.getProduct(productId!)?.displayCode)}
            {renderDialogField(
                'Quantity',
                quantity,
                {
                    minDecimalPos: productData.get(productId!)?.minDecimalPos!,
                    maxDecimalPos: productData.get(productId!)?.maxDecimalPos!,
                }
            )}
        </>
    );
};
