import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Link, Typography } from '@mui/material';
import { useUnderlyingDispatch, useUnderlyingState } from './state/UnderlyingState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { renderDialogField } from '@commodity-desk/common';

const UnderlyingHoldingDialog = () => {
    const { isUnderlyingHoldingDialogOpen, holdingData, loadingHoldings } = useUnderlyingState();
    const { setIsUnderlyingHoldingDialogOpen } = useUnderlyingDispatch();

    return (
        <Dialog
            open={isUnderlyingHoldingDialogOpen}
            onClose={() => setIsUnderlyingHoldingDialogOpen(false)}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent>
                <>
                    {loadingHoldings ? (
                        <LinearProgress />
                    ) : (
                        <>
                            {/* Title */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row'
                                }}
                            >
                                <Typography variant="h2">
                                    <FontAwesomeIcon icon={faBoxes} />
                                </Typography>
                                <Typography variant="h2" sx={{ padding: '0px 8px' }}>
                                    {holdingData.holdingState} Holding
                                </Typography>
                                <Typography variant="h2">{holdingData.holdingId}</Typography>
                            </Box>

                            {renderDialogField('Held By', holdingData.accountId)}
                            {renderDialogField('Amount', holdingData.amount, {
                                minDecimalPos: holdingData.minDecimalPos,
                                maxDecimalPos: holdingData.maxDecimalPos
                            })}
                            {renderDialogField('Product', holdingData.productCode)}

                            {/* Display for Emission holding */}
                            {holdingData.isEmission ? (
                                <>
                                    <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                        Emission details
                                    </Typography>

                                    {renderDialogField('Name', holdingData.emissionName)}
                                    {renderDialogField('Intensity', holdingData.emissionIntensity)}

                                    {holdingData.emissionDocument && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography>Document</Typography>
                                            <Link
                                                align="right"
                                                href={holdingData.emissionDocument}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faUpRightFromSquare}
                                                    style={{ paddingLeft: '2rem' }}
                                                />
                                            </Link>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <>
                                    {/* Display for carbon product holding */}
                                    <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                        Project details
                                    </Typography>

                                    {renderDialogField('Project ID', holdingData.projectId)}
                                    {holdingData.projectName && (
                                        <>{renderDialogField('Name', holdingData.projectName)}</>
                                    )}
                                    {holdingData.projectType && (
                                        <>{renderDialogField('Type', holdingData.projectType)}</>
                                    )}
                                    {holdingData.projectCountry && (
                                        <>
                                            {renderDialogField(
                                                'Country',
                                                holdingData.projectCountry
                                            )}
                                        </>
                                    )}
                                    {holdingData.projectState && (
                                        <>{renderDialogField('State', holdingData.projectState)}</>
                                    )}
                                    {holdingData.projectUri && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            <Typography>URI</Typography>
                                            <Link
                                                align="right"
                                                href={holdingData.projectUri}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faUpRightFromSquare}
                                                    style={{ paddingLeft: '2rem' }}
                                                />
                                            </Link>
                                        </Box>
                                    )}
                                    {holdingData.projectVintage && (
                                        <>
                                            {renderDialogField(
                                                'Vintage',
                                                holdingData.projectVintage
                                            )}
                                        </>
                                    )}
                                    {holdingData.serialNumRange && (
                                        <>
                                            {renderDialogField(
                                                'Serial Numbers',
                                                holdingData.serialNumRange
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={() => setIsUnderlyingHoldingDialogOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnderlyingHoldingDialog;
