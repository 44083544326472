// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/PPPangramSans-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
    text-decoration: none;
}

table a:hover {
    text-decoration: underline;
}
.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    word-wrap: break-word !important;
    line-height: 2;
}

@font-face {
    font-family: 'PPPangramSans';
    src: local('PPPangramSans'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}

.rdp-button:hover {
    background-color: #f8834d !important
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,4BAA4B;IAC5B,mFAAgF;AACpF;;AAEA;IACI;AACJ","sourcesContent":["a {\n    text-decoration: none;\n}\n\ntable a:hover {\n    text-decoration: underline;\n}\n.MuiDataGrid-root .MuiDataGrid-cell {\n    white-space: normal !important;\n    word-wrap: break-word !important;\n}\n\n.MuiDataGrid-columnHeaderTitle {\n    white-space: normal !important;\n    word-wrap: break-word !important;\n    line-height: 2;\n}\n\n@font-face {\n    font-family: 'PPPangramSans';\n    src: local('PPPangramSans'), url(fonts/PPPangramSans-Medium.woff) format('woff');\n}\n\n.rdp-button:hover {\n    background-color: #f8834d !important\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
