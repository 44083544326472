var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * This file holds the main UI layout for the main viewport and collapsable side menu
 */
import { Box, Drawer, LinearProgress } from '@mui/material';
import SideDrawer from './SideDrawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import { ToastContainer } from "react-toastify";
import { DrawerHeader, Main, drawerWidth, navBarHeight } from '../../state/Layout';
var MainView = function (_a) {
    var user = _a.user, signIn = _a.signIn, signOut = _a.signOut, loading = _a.loading, enabled = _a.enabled, isTokenExpired = _a.isTokenExpired, currentKey = _a.currentKey, keys = _a.keys, layoutState = _a.layoutState, theme = _a.theme, children = _a.children;
    return (_jsxs(Box, __assign({ sx: { display: 'flex', marginTop: navBarHeight } }, { children: [_jsxs(Drawer, __assign({ sx: {
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box'
                    }
                }, variant: "persistent", anchor: "left", open: layoutState.open }, { children: [_jsx(DrawerHeader, { children: _jsx(IconButton, __assign({ onClick: layoutState.handleDrawerClose }, { children: theme.direction === 'ltr' ? _jsx(ChevronLeftIcon, {}) : _jsx(ChevronRightIcon, {}) })) }), _jsx(Divider, {}), _jsx(SideDrawer, { user: user, signIn: signIn, signOut: signOut, loading: loading, enabled: enabled, currentKey: currentKey, keys: keys })] })), _jsxs(Main, __assign({ open: layoutState.open }, { children: [(!user || isTokenExpired(user)) && (
                    /* Show a Linear Progress Bar while the user is being signed in or redirected to login page */
                    _jsx(Box, __assign({ sx: { width: '100%' } }, { children: _jsx(LinearProgress, {}) }))), children] })), _jsx(ToastContainer, { position: 'bottom-center', autoClose: 3000, newestOnTop: true, hideProgressBar: true, theme: 'dark', style: { width: 'auto', maxWidth: '600px' } })] })));
};
export default MainView;
