import { Typography } from '@mui/material';
import { DeltaLadder } from './deltaLadder/DeltaLadder';
import { RowType, UrlFragment } from './deltaLadder/DeltaLadderTypes';
import { homepage } from '../../state/Variables';
import {
    CORTEN_ATTRIBUTE_MISSING_EXPRESSION,
    getDefaultLocaleConfig,
    lgcAttributes,
    miqcAttributes,
    ProductType,
    carbonProjectAttributes,
    transactionAttributes,
    useAppConfigState,
    useProductDataState,
    useLayoutState,
} from '@commodity-desk/common';

const TradingRisk = () => {
    const appConfigState = useAppConfigState();
    const {customTheme} = useLayoutState();
    const { productsData } = useProductDataState();
    const localeConfig = getDefaultLocaleConfig();

    return (
        <>
            <Typography variant='h2'>Delta Ladder</Typography>

            <DeltaLadder
                rowDefinitions={[
                    { label: 'Physical', rowType: RowType.PHYSICAL },
                    { label: '1M', rowType: RowType.FORWARD, monthNumber: 1 },
                    { label: '2M', rowType: RowType.FORWARD, monthNumber: 2 },
                    { label: '3M', rowType: RowType.FORWARD, monthNumber: 3 },
                    { label: '6M', rowType: RowType.FORWARD, monthNumber: 6 },
                    { label: '9M', rowType: RowType.FORWARD, monthNumber: 9 },
                    { label: '12M', rowType: RowType.FORWARD, monthNumber: 12 },
                    { label: '18M', rowType: RowType.FORWARD, monthNumber: 18 },
                    { label: '24M', rowType: RowType.FORWARD, monthNumber: 24 },
                    { label: '36M', rowType: RowType.FORWARD, monthNumber: 36 },
                    { label: 'Net Delta', rowType: RowType.NET_DELTA },
                ]}
                projectionAttribute={transactionAttributes.valueDate.key}
                unclassifiedAttributeFilterValue={CORTEN_ATTRIBUTE_MISSING_EXPRESSION}
                products={appConfigState.getProducts().map((product) => {
                    return {
                        id: product.id,
                        code: product.displayCode,
                        base: product.productBase,
                        minDecimalPlaces: productsData.get(product.id)?.minDecimalPos ?? 0,
                        maxDecimalPlaces: productsData.get(product.id)?.maxDecimalPos ?? 0
                    }
                })}
                l2AttributeBreakdownOptions={[
                    {
                        displayName: "PROJECT TYPE",
                        attribute: carbonProjectAttributes.projectType.key,
                        isDefault: true,
                        conditions: [{l1AttributeValue: [ProductType.ACCU, ProductType.VCU]}],
                        urlParamName: 'projectType'
                    },
                    {
                        displayName: "VINTAGE",
                        attribute: carbonProjectAttributes.vintage.key,
                        conditions: [{l1AttributeValue: [ProductType.ACCU, ProductType.VCU]}],
                        urlParamName: 'vintage'
                    },
                    {
                        displayName: "STATE",
                        attribute: carbonProjectAttributes.projectState.key,
                        conditions: [{l1AttributeValue: [ProductType.ACCU]}],
                        urlParamName: 'projectState'
                    },
                    {
                        displayName: "COUNTRY CODE",
                        attribute: carbonProjectAttributes.country.key,
                        conditions: [{l1AttributeValue: [ProductType.VCU]}],
                        urlParamName: 'countryCode'
                    },
                    {
                        displayName: "FUEL SOURCE",
                        attribute: lgcAttributes.fuelSource.key,
                        isDefault: true,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'fuelSource'
                    },
                    {
                        displayName: "CREATION YEAR",
                        attribute: lgcAttributes.creationYear.key,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'creationYear'
                    },
                    {
                        displayName: "GENERATION YEAR",
                        attribute: lgcAttributes.generationYear.key,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'generationYear'
                    },
                    {
                        displayName: "GENERATION STATE",
                        attribute: lgcAttributes.generationState.key,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'generationState'
                    },
                    {
                        displayName: "GREENPOWER ACCREDITED",
                        attribute: lgcAttributes.greenPowerAccredited.key,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'greenPowerAccredited'
                    },
                    {
                        displayName: "SEGMENT",
                        attribute: miqcAttributes.segment.key,
                        isDefault: true,
                        conditions: [{l1AttributeValue: [ProductType.MiQC]}],
                        urlParamName: 'segment'
                    },
                    {
                        displayName: "ISSUE YEAR",
                        attribute: miqcAttributes.issueYear.key,
                        conditions: [{l1AttributeValue: [ProductType.MiQC]}],
                        urlParamName: 'issueYear'
                    },
                    {
                        displayName: "MIQ GRADE",
                        attribute: miqcAttributes.miqGrade.key,
                        conditions: [{l1AttributeValue: [ProductType.MiQC]}],
                        urlParamName: 'miqGrade'
                    },
                ]}
                l3BaseAttributes={[
                    {
                        attributeKey: carbonProjectAttributes.projectId.key,
                        conditions: [{l1AttributeValue: [ProductType.ACCU, ProductType.VCU]}],
                        urlParamName: 'project'
                    },
                    {
                        attributeKey: lgcAttributes.accreditationCode.key,
                        conditions: [{l1AttributeValue: [ProductType.LGC]}],
                        urlParamName: 'accreditationCode'
                    },
                    {
                        attributeKey: miqcAttributes.facility.key,
                        conditions: [{l1AttributeValue: [ProductType.MiQC]}],
                        urlParamName: 'facility'
                    }
                ]}
                detailsViewUrlParameters={
                    {
                        baseUrl: `${homepage}/risk/position`,
                        valueDateStartUrlParam: 'valueDateStart',
                        valueDateEndUrlParam: 'valueDateEnd',
                        otherUrlParams: [
                            [UrlFragment.PRODUCT_ID, 'product'],
                            [UrlFragment.PRODUCT_BASE, 'productBase']
                        ]
                        // Other parameters not listed here are instead taken from urlParamName as listed above.
                    }
                }
                projectDetailsUrl={`${homepage}/inventory-management/product/${UrlFragment.PRODUCT_ID}/${UrlFragment.PRODUCT_BASE}/${UrlFragment.L3_BASE_ATTRIBUTE_VALUE}`}
                numberDecimalPointCharacter={localeConfig.amountFormatDecimalPoint}
                numberGroupSeparatorCharacter={localeConfig.amountFormatGroupSeparator}
                navHoverBackgroundColor={customTheme.customProps.navHoverBackgroundColor}
                nestedTableBackgroundColors={customTheme.customProps.nestedTableBackgroundColors}
            />
        </>
    );
}

export default TradingRisk;
