import { TextField } from '@mui/material';
import { GridTypeFilterInputValueProps, useGridRootProps } from '@mui/x-data-grid';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { isValidInteger } from '@commodity-desk/common';


/**
 * Custom integer input filters for columns that  accept integer values, such as Quantity and Vintage.
 * @param GridTypeFilterInputValueProps
 * @returns {TextField} Input component which only accepts integer
 */
const IntegerInput = (props: GridTypeFilterInputValueProps) => {
    const { item, applyValue, focusElementRef } = props;
    const [value, setValue] = useState(item?.value);
    const rootProps = useGridRootProps();
    const inputRef = useRef<string | undefined>(undefined);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target?.value);
        inputRef.current = e.target?.value;
    };

    useEffect(() => {
        /**
         * When the filter panel is opened and closed with the same value, it will not trigger applyValue, which prevents unnecessary API calls.
         */
        if (inputRef.current === undefined) {
            return;
        }
        /**
         * Debounce for TextField
         */
        let timer: NodeJS.Timeout | null = null;
        timer = setTimeout(() => {
            if (isValidInteger(value) || value === '') {
                const newItem = { ...item, value };
                applyValue(newItem);
            }
        }, 700);

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    // This is necessary when we click the reset button on the filter panel.
    // It will also apply an empty value if the field is reset.
    useEffect(() => {
        setValue(item.value);
    }, [item.value]);

    return (
        <TextField
            value={value || ''}
            type="number"
            inputProps={{ step: 1 }}
            id="integer-input"
            label="Value"
            variant="standard"
            onChange={handleChange}
            inputRef={focusElementRef}
            InputLabelProps={{
                shrink: true
            }}
            helperText={value && !isValidInteger(value) && 'Please enter a valid integer'}
            {...rootProps.slotProps?.baseTextField}
        />
    );
};

export default IntegerInput;
