import { Box, LinearProgress, Typography } from '@mui/material';
import { sectionSpace } from '@commodity-desk/common';
import Error from '../../component/Error';
import { HoldingsSummary } from './HoldingsSummary';
import ProductAttributes from './ProductAttributes';
import ProductSelector from './ProductSelector';
import Projects from './Projects';
import { useInventoryManagementState } from './state/InventoryManagementState';
import { useProductState } from './state/ProductState';
import { LockedHoldingsProvider } from './state/LockedHoldingState';
import { 
    ProductBase,
    useProductDataState
} from '@commodity-desk/common';
import Certificates from './Certificates';

const InventoryManagement = () => {
    const { totalBalance } = useInventoryManagementState();
    const { loading, isPathError, mblSelectedProduct } = useProductState();
    const { productsData } = useProductDataState();

    return (
        <>
            <Typography variant="h2">Inventory Management</Typography>

            {isPathError ? (
                <Error message="Error - Product ID not recognised" />
            ) : (
                <>
                    <ProductSelector />

                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <>
                            <ProductAttributes />

                            {totalBalance && Object.keys(totalBalance).length > 0 ? (
                                <HoldingsSummary
                                    issuerAmount={totalBalance?.issuerAmount}
                                    assignedAmount={totalBalance?.assignedAmount}
                                    unassignedAmount={totalBalance?.unassignedAmount}
                                    escrowAmount={totalBalance?.escrowAmount}
                                    title="Holding Summary"
                                    minDecimalPos={productsData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                    maxDecimalPos={productsData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                />
                            ) : (
                                <Box sx={{ marginBottom: sectionSpace }}>
                                    <Typography sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                                        Holding Summary
                                    </Typography>
                                    <Typography>No holding summary to display</Typography>
                                </Box>
                            )}
                            <LockedHoldingsProvider>
                                {mblSelectedProduct.base === ProductBase.Project && <Projects />}
                                {mblSelectedProduct.base === ProductBase.Certificate &&
                                    <Certificates product={mblSelectedProduct}/>
                                }
                            </LockedHoldingsProvider>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InventoryManagement;
