import { equalsExpr, TrovioCoreApi } from '@trovio-tech/trovio-core-api-js';
import { BaseAttributeOption, Column, ColumnType, DeltaLadderOptionCondition, ProductInfo, Row, RowType, DeltaLevelSummary, UNCLASSIFIED_LABEL, UNCLASSIFIED_COLUMN_KEY } from './DeltaLadderTypes'

// TODO: Remove references below
import { fetchByCriteria, ProductCriteria } from '@commodity-desk/common';

/**
 * {@link ProductState} maintains last selected attribute per product, as well as code <-> ID mapping.
 * Since this is all we need to interact with the Corten API, {@link ProductState} also holds the fetch logic.
 * 
 * @property product                           The {@link ProductInfo} about the current product that has it's state tracked by this class
 * @property selectedL2Attribute               The attribute that was selected in the L2 attribute drop-down. Used as a group-by field in data queries.
 * @property projectionAttribute               The attribute to be used to project the data down the rows of the table. Must be a timestamp type attribute.
 * @property unclassifiedAttributeFilterValue  The value used to search for unclassified attributes. This is a Corten expression (usually !*)
 * @property l3BaseAttributes                  The L3 base attributes that apply to this product. Usually there is only one L3 base attribute (e.g. project ID)
 */
class ProductState {
    product: ProductInfo;
    selectedL2Attribute: string;
    projectionAttribute: string;
    unclassifiedAttributeFilterValue: string;
    l3BaseAttributes: BaseAttributeOption[];
    fetchForwardData: (api: TrovioCoreApi, criteria: ProductCriteria, rows: Row[], dateAttribute: string)  => Promise<any>[];
    optionConditionsMatch: (productCode: string, conditions?: DeltaLadderOptionCondition[]) => boolean;
    cortenApi: TrovioCoreApi;

    constructor({
        product,
        selectedL2Attribute,
        projectionAttribute,
        unclassifiedAttributeFilterValue,
        l3BaseAttributes,
        fetchForwardData,
        optionConditionsMatch,
        cortenApi
    } : {
        product: ProductInfo,
        selectedL2Attribute: string,
        projectionAttribute: string,
        unclassifiedAttributeFilterValue: string,
        l3BaseAttributes: BaseAttributeOption[]
        fetchForwardData: (api: TrovioCoreApi, criteria: ProductCriteria, rows: Row[], dateAttribute: string)  => Promise<any>[],
        optionConditionsMatch: (productCode: string, conditions?: DeltaLadderOptionCondition[]) => boolean,
        cortenApi: TrovioCoreApi
    }) {
        this.product = product;
        this.selectedL2Attribute = selectedL2Attribute;
        this.projectionAttribute = projectionAttribute;
        this.unclassifiedAttributeFilterValue = unclassifiedAttributeFilterValue;
        this.l3BaseAttributes = l3BaseAttributes;
        this.fetchForwardData = fetchForwardData;
        this.optionConditionsMatch = optionConditionsMatch;
        this.cortenApi = cortenApi;
    }

    baseProductCriteria = (isUnassigned: boolean): ProductCriteria => {
        return {
            productIds: [this.product.id],
            includeBalances: true,
            axes: [this.selectedL2Attribute],
            attributes: [],
            isUnassigned: isUnassigned
        };
    };

    /**
     * Transform a set of balance data from core10 into a single array of numbers, representing the forward balances for each row in the table
     *
     * Each element the [forwardResponse] array contains forward balances for a specific row. This function takes all
     * forward responses and returns the forward balances for a desired column (defined by the groupBy attribute).
     *
     * @param forwardResponses     The core10 response from the /queryBalance API for this set of data. Contains one promise result per row in 
     *                             the table. Each promise result is associated with a particular time range (from a particular table row).
     *                             The number of forward responses in this array is equal to the number of forward rows in the table.
     *                             Each promise result contains multiple pieces of data, grouped by the chosen attribute (e.g. project, state etc)
     *                             The size of each promise result is less than or equal to the total number of columns in the table. This 
     *                             array of forward responses therefore contains the full grid matrix of forward data, over all rows and 
     *                             columns in the table.
     * @param rows                 The set of rows that apply here, used to split up the requests to retrieve forward data (one request
     *                             is made for each date range associated with a given row in the table)
     * @param attributeKey         The key of the attribute that we are grouping by for this set of data. E.g. Project type or project ID
     * @param attributeValue       The value of the attribute that we want to extract from this set of data. E.g. AGRICULTURE or EOP0001
     * @param l3Breakdown          A boolean indicating whether we are parsing data for the lowest level of data (the L3 project breakdown table)
     * @param isUnclassifiedColumn Indicates whether we are deriving forward balances for an unclassified column
     * @returns                    An array of numbers representing the forward balances for each row in the table, for the attributeValue that we requested
     */
    private getForwardBalances = ({
        forwardResponses,
        rows,
        attributeKey,
        attributeValue,
        l3Breakdown,
        isUnclassifiedColumn
    }: {
        forwardResponses: any[],
        rows: Row[],
        attributeKey: string,
        attributeValue: string | number | undefined,
        l3Breakdown?: boolean,
        isUnclassifiedColumn?: boolean
    }): number[] => {
        const matchVariables = [attributeValue];

        // If we are deriving forward balances for unclassified column then also
        // match against undefined values as they could be missing from the response
        if (isUnclassifiedColumn || attributeValue === UNCLASSIFIED_COLUMN_KEY) {
            matchVariables.push(undefined);
        }

        const findMatch = (entry: any): boolean => {
            if (l3Breakdown) {
                const l3BaseAttribute = this.getL3BaseAttribute();
                return matchVariables.includes(entry.attributes[l3BaseAttribute.attributeKey]);
            } else {
                return matchVariables.includes(entry.attributes[attributeKey]);
            }
        }

        let idx = 0;
        let forwardBalances: number[] = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (let row of rows.filter(r => r.rowType === RowType.FORWARD)) {
            let balance = 0;
            for (let entry of forwardResponses[idx].list) {
                if (findMatch(entry)) {
                    balance += +entry.balances.unassignedAmount;
                }
            }
            forwardBalances.push(balance);
            idx += 1;
        }
        return forwardBalances;
    };

    /**
     * Get an array of Column data from the relevant fetch requests for physical and forward data
     * @param rows                 The rows that currently apply
     * @param physicalBalances     The set of physical balances that apply to this table (if relevant)
     * @param forwardResponses     The set of forward responses, each response containing a set of forward balance data
     * @param existingSummary      If provided, the existing summary information including specified columns and attribute key
     *                             will be used as a basis. If not, a new set of columns will be generated from the response data
     * @param isL3Breakdown        A flag determining whether this is the L3 breakdown view or not
     * @param isUnclassifiedData   A flag determining whether this data is obtained from clicking on an unclassified column in the 
     *                             L2 table when loading the data here for the L3 table.
     */
    private getColumns = ({
        rows,
        physicalBalances,
        forwardResponses,
        existingSummary,
        isL3Breakdown,
        isUnclassifiedData
    } : {
        rows: Row[],
        physicalBalances?: any,
        forwardResponses: any[],
        existingSummary?: DeltaLevelSummary,
        isL3Breakdown: boolean,
        isUnclassifiedData?: boolean
    }): Column[] => {
        let columns: any[] = [];
        let l3BaseGroupingAttribute = '';
        if (isL3Breakdown) {
            l3BaseGroupingAttribute = this.getL3BaseAttribute().attributeKey;
        }
        if (existingSummary) {
            // We are appending data to an existing table. All data we add here must match the existing columns exactly
            for (let existingColumn of existingSummary.columns) {
                if (existingColumn.columnType === ColumnType.UNCLASSIFIED) {
                    this.addUnclassifiedColumn(rows, columns, forwardResponses, isL3Breakdown, isL3Breakdown ? l3BaseGroupingAttribute : '', true);
                } else {
                    const forwardBalance = this.getForwardBalances({
                        forwardResponses: forwardResponses,
                        rows: rows,
                        attributeKey: isL3Breakdown ? l3BaseGroupingAttribute : existingSummary.groupBy!,
                        attributeValue: existingColumn.key,
                        l3Breakdown: isL3Breakdown
                    });
                    columns.push(Column.fromBalances(existingColumn.key, ColumnType.STANDARD, rows, forwardBalance));
                }
            }
        } else {
            // We are creating a new table, and constructing the columns based on the data we see in the physical and forward responses.
            const attribute = isL3Breakdown ? l3BaseGroupingAttribute : this.selectedL2Attribute;
            columns = physicalBalances.list.map(
                (item: any) => Column.fromBalances(
                    item.attributes[attribute],
                    ColumnType.STANDARD,
                    rows,
                    this.getForwardBalances({
                        forwardResponses: forwardResponses,
                        rows: rows,
                        attributeKey: attribute,
                        attributeValue: item.attributes[attribute],
                        l3Breakdown: isL3Breakdown
                    }),
                    parseInt(item.balances.issuerAmount) + parseInt(item.balances.escrowAmount)
                )
            );

            // If we've drilled into the Unclassified group then we need to determine the project columns, since there is
            // no physical to base this on. We instead obtain columns from the forward response data.
            if (isL3Breakdown && isUnclassifiedData) {
                this.addColumnsForUnclassifiedL3Table(rows, columns, forwardResponses, l3BaseGroupingAttribute);
            }

            this.addUnclassifiedColumn(rows, columns, forwardResponses, isL3Breakdown, isL3Breakdown ? l3BaseGroupingAttribute : '', false);
        }
        return columns;
    };

    /**
     * Logic to add columns that exist in the forward responses only, and not in the physical response.
     *
     * Applies mostly when clicking on the 'Unclassified' column in the L2 summary. For example, you can group by
     * Vintage, which can be unclassified but can contain product items that do have a Project Type and Project ID.
     * 
     * TODO: Test whether this scenario can ever happen if physical is empty but we somehow still have some outstanding
     * forwards. In this scenario, we need to make the logic in this function here apply to all levels of the table, not
     * just the L3 table when clicking on an 'Unclassified' column.
     *
     * @param rows              The rows that apply to this table
     * @param existingColumns   The existing set of columns that we want to add to
     * @param forwardResponses  The response data for forward trades, that we will use to find any columns missing from existing columns
     * @param attributeKey      The key of the project ID attribute (from app config)
     */
    private addColumnsForUnclassifiedL3Table = (rows: Row[], existingColumns: Column[], forwardResponses: any[], projectIdKeyAttribute: string) => {
        // add individual projects if they don't already exist, for the project breakdown view only
        let projects: string[] = [];
        for (let response of forwardResponses) {
            for (let item of response.list) {
                const projectId = item.attributes?.[projectIdKeyAttribute];
                if (
                    item.attributes?.[this.selectedL2Attribute] === undefined
                    && projectId !== undefined
                    && !existingColumns.map(c => c.key).includes(projectId) && !projects.includes(projectId)
                ) {
                    projects.push(projectId);
                }
            }
        }
        for (let projectId of projects) {
            existingColumns.push(Column.fromBalances(
                projectId,
                ColumnType.STANDARD,
                rows,
                this.getForwardBalances({
                    forwardResponses: forwardResponses,
                    rows: rows,
                    attributeKey: projectIdKeyAttribute,
                    attributeValue: projectId,
                    l3Breakdown: true
                }),
                0,
                projectId
            ));
        }
    }

    /**
     * Fetch data for L2, for a specific product (chosen from L1)
     * @returns An array of column data
     */
    fetchColumnsByProductCriteria = (rows: Row[], includePhysical: boolean, existingSummary?: DeltaLevelSummary) => {
        let promises: Promise<any>[] = [];
        if (includePhysical) {
            promises = promises.concat(fetchByCriteria(this.baseProductCriteria(false), this.cortenApi));
        }
        promises = promises.concat(this.fetchForwardData(
            this.cortenApi,
            this.baseProductCriteria(true),
            rows,
            this.projectionAttribute
        ));
        return Promise.all(promises).then((promiseResponse) => {
            let forwardsStartFromIdx = 0;
            let physicalBalances = undefined;
            if (includePhysical) {
                physicalBalances = promiseResponse[0];
                forwardsStartFromIdx += 1;
            }
            const forwardResponses = promiseResponse.slice(forwardsStartFromIdx);
            return this.getColumns({
                rows: rows,
                physicalBalances: physicalBalances,
                forwardResponses: forwardResponses,
                existingSummary: existingSummary,
                isL3Breakdown: false
            });
        });
    };

    /**
     * Fetch data for L3 (by attribute)
     * @param attributeValue  The value of the attribute we are fetching data for
     * @returns               An array of column data
     */
    fetchColumnsByAttributeCriteria = (attributeValue: string, rows: Row[], includePhysical: boolean, existingSummary?: DeltaLevelSummary) => {
        const l3BaseGroupingAttribute = this.getL3BaseAttribute().attributeKey;

        let promises: Promise<any>[] = [];
        if (includePhysical) {
            let physicalCriteria = this.baseProductCriteria(false);
            physicalCriteria.axes!.push(l3BaseGroupingAttribute);
            physicalCriteria.attributes!.push({ code: this.selectedL2Attribute, value: this.getFilterValueForAttribute(attributeValue) });
            promises = promises.concat(fetchByCriteria(physicalCriteria, this.cortenApi));
        }

        let forwardCriteria = this.baseProductCriteria(true);
        forwardCriteria.axes!.push(l3BaseGroupingAttribute);
        forwardCriteria.attributes!.push({ code: this.selectedL2Attribute, value: this.getFilterValueForAttribute(attributeValue) });
        promises = promises.concat(this.fetchForwardData(
            this.cortenApi,
            forwardCriteria,
            rows,
            this.projectionAttribute
        ));

        return Promise.all(promises).then((promiseResponse) => {
            let forwardsStartFromIdx = 0;
            let physicalBalances = undefined;
            if (includePhysical) {
                physicalBalances = promiseResponse[0];
                forwardsStartFromIdx += 1;
            }
            const forwardResponses = promiseResponse.slice(forwardsStartFromIdx);
            return this.getColumns({
                rows: rows,
                physicalBalances: physicalBalances,
                forwardResponses: forwardResponses,
                existingSummary: existingSummary,
                isL3Breakdown: true,
                isUnclassifiedData: attributeValue.includes(this.unclassifiedAttributeFilterValue)
            });
        });
    };

    /**
     * Logic to add the 'Unclassified' column, if applicable, on any view, including the L2 view or L3
     * @param rows                   The rows that apply to this table
     * @param existingColumns        The existing set of columns that we want to add to
     * @param forwardResponses       The response data for forward trades
     * @param l3Breakdown            A boolean indicating whether this is the L3 view which contains the breakdown by project
     * @param projectIdKeyAttribute  The key of the project ID attribute (from app config), used for L3 breakdown only
     * @param forwardExpansion       Whether we are adding a column from a forward expansion operation (where no physical exists)
     */
    private addUnclassifiedColumn = (
        rows: Row[],
        existingColumns: Column[],
        forwardResponses: any[],
        l3Breakdown: boolean,
        projectIdKeyAttribute: string,
        forwardExpansion: boolean
    ) => {
        const searchAttribute = l3Breakdown ? projectIdKeyAttribute: this.selectedL2Attribute;
        if (forwardExpansion || forwardResponses.some(response => response.list.some((item: any) => item.attributes[searchAttribute] === undefined))) {
            existingColumns.push(Column.fromBalances(
                UNCLASSIFIED_COLUMN_KEY,
                ColumnType.UNCLASSIFIED,
                rows,
                this.getForwardBalances({
                    forwardResponses: forwardResponses,
                    rows: rows,
                    attributeKey: this.selectedL2Attribute,
                    attributeValue: undefined,
                    l3Breakdown: l3Breakdown,
                    isUnclassifiedColumn: true
                }),
                forwardExpansion ? undefined : 0, // physical balance is zero for non forward-expansion tables
                UNCLASSIFIED_LABEL
            ));
        }
    }

    getL3BaseAttribute = () => {
        for (const l3BaseAttribute of this.l3BaseAttributes) {
            if (this.optionConditionsMatch(this.product.code, l3BaseAttribute.conditions)) {
                return l3BaseAttribute;
            }
        }
        throw new Error("Base attribute was not defined");
    }

    getFilterValueForAttribute = (attributeValue: any): string => {
        if (attributeValue && !attributeValue.includes(this.unclassifiedAttributeFilterValue)) {
            return equalsExpr(attributeValue);
        }
        return this.unclassifiedAttributeFilterValue;
    }
}

export { ProductState };