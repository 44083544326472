import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { isValidInteger } from '@commodity-desk/common';
import { InputRangeType } from './InputRangeFilter';

/**
 * InputRangePicker component, which is used in InputRangeFilter component.
 * In terms of UI it has two input fields "start" and "end" to define the range.
 * @param startInput - start value of the range
 * @param endInput - end value of the range
 * @param type - type of input range filter either Integer or Decimal
 * @param onApply - callback function to apply the range
 * @returns {InputRangePicker} Filter component with two input fields to define the range
 */
const InputRangePicker = ({
    startInput,
    endInput,
    type,
    onApply
}: {
    startInput: string;
    endInput: string;
    type?: InputRangeType;
    onApply: (val: string) => void;
}) => {
    const [start, setStart] = useState(startInput);
    const [end, setEnd] = useState(endInput);
    const isInteger: boolean = type === 'Integer';

    const isStartGreaterThanEnd = Number(start) > Number(end);
    let isDisabled = !start || !end || isStartGreaterThanEnd;

    // Todo
    // If future we can use Zod or other validation library to handle all sort of validation
    // through out the application (Needs all engineers inputs as well)

    let startHelperText = isStartGreaterThanEnd
        ? 'Start value cannot be greater than the end value.'
        : '';
    let endHelperText = isStartGreaterThanEnd
        ? 'End value cannot be less than the start value.'
        : '';

    if (start && isInteger && !isValidInteger(start)) {
        startHelperText = 'Invalid integer';
        isDisabled = true;
    }
    if (end && isInteger && !isValidInteger(end)) {
        endHelperText = 'Invalid integer';
        isDisabled = true;
    }

    const handleApply = () => {
        if (start && end) {
            onApply(`${start}..${end}`);
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
            <TextField
                value={start || ''}
                type="number"
                id="integer-input"
                label="Start"
                variant="standard"
                onChange={(e) => setStart(e.target.value)}
                InputLabelProps={{
                    shrink: true
                }}
                helperText={startHelperText}
            />
            <TextField
                value={end || ''}
                type="number"
                id="integer-input"
                label="End"
                variant="standard"
                onChange={(e) => setEnd(e.target.value)}
                InputLabelProps={{
                    shrink: true
                }}
                helperText={endHelperText}
            />
            <Button onClick={handleApply} disabled={isDisabled}>
                Apply
            </Button>
        </Box>
    );
};

export { InputRangePicker };
