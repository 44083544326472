var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, Fragment, useContext, useEffect, useState } from 'react';
var NotificationContext = createContext({});
/**
 * Subscribe to the topic for the current access key and sets notifications as state.
 * Downstream components can get the 'current notification' from the state using `useNotification()`
 * @param children
 * @returns {JSX.Element}
 */
var NotificationProvider = function (_a) {
    var children = _a.children, currentKey = _a.currentKey, useSubscription = _a.useSubscription;
    var _b = useState({}), notification = _b[0], setNotification = _b[1];
    var topic = currentKey ? "/topic/account.".concat(currentKey) : undefined;
    var clearNotification = function () { return setNotification({}); };
    return (_jsxs(NotificationContext.Provider, __assign({ value: { notification: notification, clearNotification: clearNotification } }, { children: [topic && _jsx(NotificationSubscription, { topic: topic, onNotification: setNotification, useSubscription: useSubscription }), children] })));
};
var NotificationSubscription = function (_a) {
    var topic = _a.topic, onNotification = _a.onNotification, useSubscription = _a.useSubscription;
    useEffect(function () {
        console.log("Subscribing topic: ".concat(topic));
    }, [topic]);
    useSubscription(topic, function (rawMessage) {
        // Handle response data in binary message body
        var message = rawMessage.isBinaryBody
            ? JSON.parse(new TextDecoder().decode(rawMessage.binaryBody))
            : rawMessage.body;
        onNotification(message);
    });
    return _jsx(Fragment, {});
};
function useNotification() {
    var context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification without provider');
    }
    return context;
}
export { useNotification, NotificationProvider };
