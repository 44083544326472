import { Box, Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { 
    appThemes,
    isValidDate
} from '@commodity-desk/common';

export const RangeDatePicker = ({
    onChange,
    onReset,
    startDate,
    endDate,
    showTimeStamp,
    wholeMonths
}: {
    onChange: (date: string) => void;
    onReset: () => void;
    startDate: string;
    endDate: string;
    showTimeStamp?: boolean;
    wholeMonths?: boolean;
}) => {
    const [start, setStartDate] = useState<string | undefined>(startDate || undefined);
    const [end, setEndDate] = useState<string | undefined>(endDate || undefined);

    // Default start and end dates
    const defaultSelected: DateRange = {
        from: start && isValidDate(start) ? new Date(start) : undefined,
        to: end && isValidDate(end) ? new Date(end) : undefined
    };

    const handleStartDate = (date: Dayjs | Date | null) => {
        if (date) {
            setStartDate(dayjs(date).toISOString());
        }
    };

    const handleEndDate = (date: Dayjs | Date | null) => {
        if (date) {
            setEndDate(dayjs(date).toISOString());
        }
    };

    return (
        <Box sx={{ padding: '10px 0px' }}>
            <DayPicker
                mode="range"
                defaultMonth={defaultSelected?.from ? defaultSelected?.from : new Date()}
                selected={defaultSelected}
                footer={null}
                onSelect={(range) => {
                    if (range?.from) {
                        const newDate = new Date(range?.from);
                        handleStartDate(newDate);
                        // Initially When start day is selected, set end date to same day till 11:59:59 PM
                        if (!range?.to) {
                            newDate.setHours(23, 59, 59);
                            handleEndDate(newDate);
                        }
                    }
                    if (range?.to) {
                        const newDate = new Date(range?.to);
                        newDate.setHours(23, 59, 59);
                        handleEndDate(newDate);
                    }
                }}
                modifiersStyles={{
                    selected: {
                        backgroundColor: appThemes['trovio-dark'].theme.palette.primary.main,
                        color: appThemes['trovio-dark'].theme.palette.background.default
                    }
                }}
            />

            {!!showTimeStamp && (
                <>
                    {/* Start Timestamp */}
                    {!!start && (
                        <Box sx={{ padding: '2px 30px', display: 'flex', flexDirection: 'column' }}>
                            <label>
                                From : {dayjs(defaultSelected?.from).format('YYYY-MM-DD')}
                            </label>
                            <TimePicker
                                views={['hours', 'minutes', 'seconds']}
                                value={dayjs(defaultSelected?.from) || null}
                                slotProps={{
                                    textField: {
                                        size: 'small'
                                    }
                                }}
                                onAccept={(val) => {
                                    handleStartDate(val);
                                }}
                                onChange={(val) => {
                                    handleStartDate(val);
                                }}
                            />
                        </Box>
                    )}

                    {/* End Timestamp */}
                    {!!end && (
                        <Box
                            sx={{ padding: '10px 30px', display: 'flex', flexDirection: 'column' }}
                        >
                            <label>To : {dayjs(defaultSelected?.to).format('YYYY-MM-DD')}</label>
                            <TimePicker
                                views={['hours', 'minutes', 'seconds']}
                                value={dayjs(defaultSelected?.to) || null}
                                slotProps={{
                                    textField: {
                                        size: 'small'
                                    }
                                }}
                                onChange={(val) => {
                                    handleEndDate(val);
                                }}
                                onAccept={(val) => {
                                    handleEndDate(val);
                                }}
                            />
                        </Box>
                    )}
                </>
            )}

            <Box sx={{ padding: '10px 30px', display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                <Button
                    disabled={!defaultSelected?.from}
                    color="secondary"
                    onClick={() => {
                        setStartDate(undefined);
                        setEndDate(undefined);
                        onReset();
                    }}
                >
                    Reset
                </Button>
                <Button
                    disabled={!defaultSelected?.from}
                    onClick={() => {
                        let startString = start;
                        let endString = end;
                        if (wholeMonths) {
                            // Change the day to the start of the month if we are filtering on a field that contains dates with month precision only (no days)
                            startString = startString ? dayjs(startString).startOf("month").toISOString() : undefined;
                            endString = endString ? dayjs(endString).endOf("month").toISOString() : undefined;
                        }
                        if (startString && endString) {
                            onChange(`${startString}..${endString}`);
                            return;
                        }
                        onChange(`${startString}`);
                    }}
                >
                    Apply
                </Button>
            </Box>
        </Box>
    );
};
