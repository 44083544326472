import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Typography, } from '@mui/material';
import { PhysicalTradeForm } from './PhysicalTrade';
import { submitUnsignedTransaction, useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
    TransferRequestOutput, 
    TransactionRequest,
    ProductItemsAmount,
    TransferRequest
} from '@trovio-tech/trovio-core-api-js';
import { 
    AlertDialogContent, 
    AlertType,
    DATE_DISPLAY_FORMAT,
    getFieldDefinitionMap,
    getFieldDefinitions,
    getOrderedProjectsAndAmounts,
    getProductAttributeUiElements,
    inventoryAccount,
    ProductType,
    renderDialogField,
    renderOrderedProjectVintageAmounts,
    TradeDirection,
    TradeOptimiser,
    TradeOptimiserAmountType,
    transactionAttributes,
    TransactionOverview,
    TransactionType,
    useAppConfigState,
    useCommodityDeskApiContext,
    useProductDataState,
    useTransactionMonitor,
} from '@commodity-desk/common';

enum DialogState {
    REVIEW,
    SUCCESS,
    ERROR,
}

const PhysicalTradeSubmitDialog = ({ open, onClose, form, projectName}: {
    open: boolean,
    onClose: (resetForm: boolean) => void,
    form: PhysicalTradeForm,
    projectName: string | undefined,
}) => {
    const [dialogState, setDialogState] = useState<DialogState | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [transactionErrorCode, setTransactionErrorCode] = useState<string | null>(null);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [projectAmounts, setProjectAmounts] = useState<any[]>();

    const productItemIds = useRef<Promise<string[]>>();
    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { cortenApi, cortenAuthApi } = useCortenApiState();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData});

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => setDialogState(DialogState.SUCCESS),
        (tx) => onTransactionError(tx.errorCode),
        () => onTransactionError('TIMEOUT'),
    );

    useEffect(() => {
        if (open) {
            setDialogState(DialogState.REVIEW);
            getOrderedProjectsAndAmounts(
                form,
                getAccountIds(form).from,
                setProjectAmounts,
                productItemIds,
                appConfigState,
                cortenApi,
                commodityDeskApi,
                handleError
            );

        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleError = () => {
        setDialogState(DialogState.ERROR);
    };

    const handleDialogClose = () => {
        unsubscribe();
        setDialogState(null);
        setSubmitting(false);
        setProjectAmounts(undefined);
        setTransactionId(null);
        setTimeout(() => setTransactionErrorCode(null), 1_000); // wait for animation to finish
        // if the transaction succeeded - reset trade form
        onClose(DialogState.SUCCESS === dialogState);
    };

    const confirmAndSendRequest = async () => {
        setSubmitting(true);
        const result = await submitUnsignedTransaction(await buildUnsignedRequest(form), user, cortenAuthApi);
        if (!result) {
            onTransactionError();

        } else {
            setTransactionId(result.txId);
            subscribe(result.txId);
        }
    };


    const onTransactionError = (code: string | null = null) => {
        setTransactionErrorCode(code);
        setDialogState(DialogState.ERROR);
    };

    const getValueDate = (): any => {
        let forwardDate = dayjs();
        let forwardDateOption = '';
        if (form.valueDateOption === undefined) {
            return {
                value: undefined,
                display: ''
            }
        } else if (form.valueDateOption === 'Custom') {
            forwardDate = form.customValueDate;
        } else {
            forwardDate = forwardDate.add(parseInt(form.valueDateOption), 'day');
            forwardDateOption = ` (T + ${form.valueDateOption} day${form.valueDateOption === '1' ? '' : 's'})`
        }
        return {
            value: forwardDate,
            display: `${dayjs(forwardDate.toDate()).format(DATE_DISPLAY_FORMAT)}${forwardDateOption}`
        }
    };

    const buildUnsignedRequest = async (data: PhysicalTradeForm): Promise<TransactionRequest> => {
        let requestFrom: ProductItemsAmount = {
            type: "ProductItemsAmount",
            productItemIds: await productItemIds.current!,
            amount: data.quantity
        }
        let transferRequestOutput: TransferRequestOutput = {
            from: requestFrom,
            toAccountId: getAccountIds(form).to
        }
        const request: TransferRequest = {
            type: "TransferRequest",
            fromAccountId: getAccountIds(form).from,
            nonce: new Date().getTime(),
            outputList: [
                transferRequestOutput
            ],
            attributes: {
                // TODO MBL-201: keep time component
                [`${transactionAttributes.tradeDate.key}`]: data.tradeDate.startOf('day').toDate().getTime() as any,
                [`${transactionAttributes.trader.key}`]: data.trader,
                [`${transactionAttributes.price.key}`]: data.price,
                [`${transactionAttributes.currency.key}`]: data.currency.toString(),
                [`${transactionAttributes.valueDate.key}`]: getValueDate().value.startOf('day').toDate().getTime(),
                [`${transactionAttributes.transactionTypeInventory.key}`]:
                    data.tradeDirection === TradeDirection.BUY ?
                        TransactionType.PhysicalBuy :
                        TransactionType.PhysicalSell,
                [`${transactionAttributes.transactionTypeClient.key}`]:
                    data.tradeDirection === TradeDirection.BUY ?
                        TransactionType.PhysicalSell :
                        TransactionType.PhysicalBuy
            }
        };

        if (data.tradeId) {
            request.attributes![`${transactionAttributes.tradeId.key}`] = data.tradeId as any;
        }
        if (data.salesPerson) {
            request.attributes![`${transactionAttributes.salesPerson.key}`] = data.salesPerson as any;
        }
        if (data.salesCredits) {
            request.attributes![`${transactionAttributes.salesCredits}`] = data.salesCredits as any;
        }
        if (data.broker) {
            request.attributes![`${transactionAttributes.brokerName.key}`] = data.broker as any;
        }
        if (data.brokerageFee) {
            request.attributes![`${transactionAttributes.brokerage}`] = data.brokerageFee as any;
        }

        return request;
    };

    const getAccountIds = (form: PhysicalTradeForm): {from: string, to: string} => {
        let issuerAccount = inventoryAccount.id;
        return {
            from: form.tradeDirection === TradeDirection.SELL ? issuerAccount : form.counterparty,
            to: form.tradeDirection === TradeDirection.SELL ? form.counterparty : issuerAccount,
        }
    };

    if (DialogState.SUCCESS === dialogState) {
        return (
            <TransactionOverview
                open={DialogState.SUCCESS === dialogState}
                onClose={handleDialogClose}
                title="Transaction Submitted Successfully"
                uiElements={{
                    ...(form.tradeId && { tradeId: { value: form.tradeId } }),
                    transactionId: { value: transactionId! },
                    tradeDate: { value: form.tradeDate.toISOString() },
                    trader: { value: form.trader },
                    tradeDirection: { value: form.tradeDirection },
                    counterParty: { value: appConfigState.getClientForAddress(form.counterparty) },
                    productId: { value: form.product, label: 'Product Code' },
                    ...getProductAttributeUiElements({
                        data: {
                            ...form,
                            projectName: projectName
                        },
                        fieldDefinitions: fieldDefinitions,
                        productType: appConfigState.getProduct(form.product)
                            ?.displayCode as ProductType
                    }),
                    quantity: {
                        value: form.quantity.toString()
                    },
                    valueDate: { value: getValueDate().display },
                    currency: { value: form.currency },
                    price: { value: form.price },
                    ...(form.salesCredits && { salesCredits: { value: form.salesCredits } }),
                    ...(form.salesPerson && { salesPerson: { value: form.salesPerson } }),
                    ...(form.brokerageFee && { brokerage: { value: form.brokerageFee } }),
                    ...(form.broker && { brokerName: { value: form.broker } })
                }}
                relatedProperties={new Map([['counterPartyToolTipText', form.counterparty]])}
                fieldDefinitionMap={fieldDefinitionMap}
                productType={appConfigState.getProduct(form.product)?.displayCode as ProductType}
            />
        );
    }

    return (
        <Container>
            <Dialog open={DialogState.REVIEW === dialogState} onClose={handleDialogClose} fullWidth maxWidth='sm'>
                <DialogContent>
                    <Typography variant='h2'>Review Details Below</Typography>
                    {form.tradeId && renderDialogField('Trade ID', form.tradeId)}
                    {renderDialogField('Trade Date', dayjs(form.tradeDate).format(DATE_DISPLAY_FORMAT))}
                    {renderDialogField('Trader', form.trader)}
                    {renderDialogField('Trade Direction', form.tradeDirection)}
                    {renderDialogField('Counterparty', appConfigState.getClientForAddress(form.counterparty))}
                    {renderDialogField('Product', appConfigState.getProduct(form.product)?.displayCode)}
                    {form.projectType && renderDialogField('Project Type', form.projectType)}
                    {form.project && renderDialogField('Project', projectName)}
                    {form.vintage && renderDialogField('Vintage', form.vintage)}
                    {form.projectState && renderDialogField('State', form.projectState)}
                    {form.country && renderDialogField('Country', form.country)}
                    {form.fuelSource && renderDialogField('Fuel Source', form.fuelSource)}
                    {form.creationYear && renderDialogField('Creation Year', form.creationYear)}
                    {form.generationYear && renderDialogField('Generation Year', form.generationYear)}
                    {form.generationState && renderDialogField('Generation State', form.generationState)}
                    {form.greenPowerAccredited && renderDialogField('GreenPower Accredited', form.greenPowerAccredited)}
                    {form.segment && renderDialogField('Segment', form.segment)}
                    {form.issueYear && renderDialogField('Issue Year', form.issueYear)}
                    {form.miqGrade && renderDialogField('MiQ Grade', form.miqGrade)}
                    {renderDialogField('Quantity', form.quantity, {
                        minDecimalPos: productsData.get(form.product)?.minDecimalPos!,
                        maxDecimalPos: productsData.get(form.product)?.maxDecimalPos!,
                    })}
                    {renderDialogField('Value Date', getValueDate().display)}
                    {renderDialogField('Currency', form.currency)}
                    {renderDialogField('Price', form.price, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesCredits && renderDialogField('Sales Credits', form.salesCredits, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesPerson && renderDialogField('Sales Person', form.salesPerson)}
                    {form.brokerageFee && renderDialogField('Brokerage Fee', form.brokerageFee, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.broker && renderDialogField('Broker Name', form.broker)}
                    {form.tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(form.product)!, TradeOptimiserAmountType.Price)}
                    {form.tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productsData.get(form.product)!, TradeOptimiserAmountType.Score)}
                    {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(form.tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < form.quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary' variant='outlined'>Cancel</Button>
                    <Button onClick={confirmAndSendRequest}
                            color='primary'
                            variant='outlined'
                            disabled={
                                // eslint-disable-next-line no-mixed-operators
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(form.tradeOptimiser) &&
                                // eslint-disable-next-line no-mixed-operators
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < form.quantity)
                            }>
                        {submitting ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '2px',
                            }}>
                                <CircularProgress size={20} />
                            </div>
                        ) : ('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={DialogState.ERROR === dialogState} onClose={handleDialogClose}>
                {transactionErrorCode !== 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Error}
                        alertMessage='An error occurred while submitting the form.'
                        errorCode={transactionErrorCode}
                        handleDialogClose={handleDialogClose}
                    />
                )}

                {transactionErrorCode === 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Warning}
                        alertMessage='The transaction timed out. Please check back later to confirm whether the transaction was
                    processed.'
                        errorCode={null}
                        handleDialogClose={handleDialogClose}
                    />
                )}
            </Dialog>
        </Container>
    );
};

export { PhysicalTradeSubmitDialog };
