import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CurrentState, carbonProjectAttributes } from '@commodity-desk/common';
import { useProjectDetailsState } from './ProjectDetailsState';

interface ProjectVintageDetailsStateType {
    handleVintageDetailsView: () => void
    loading: boolean
}

const ProjectVintageDetailsStateContext = createContext<ProjectVintageDetailsStateType | null>(
    null
);

const ProjectVintageDetailsProvider = ({ children }: { children?: ReactNode }) => {
    const [loading, setLoading] = useState(true);

    const { initState, selectedIds, fetchRegistryId, fetchProductItems, fetchProjectBalanceAndDetails } = useProjectDetailsState();

    // runs only when we have the productItemData response
    useEffect(() => {
        if (initState.state === CurrentState.READY && selectedIds.current.vintage !== '') {
            // run other functions that depend on productItemData
            init();
        }
    }, [initState]); // eslint-disable-line react-hooks/exhaustive-deps

    // called from vintage details View
    const handleVintageDetailsView = () => {
        fetchRegistryId();
        fetchProductItems();
    }

    /**
     * run all functions that depend on productItemData response
     */
    const init = async () => {
        // fetch sum holdings for selected project, set project details display data
        let attributes = [
            { code: carbonProjectAttributes.projectId.key, value: selectedIds.current.projectID },
            { code: carbonProjectAttributes.vintage.key, value: selectedIds.current.vintage }
        ]
        await fetchProjectBalanceAndDetails(attributes)

        // stop page loader
        setLoading(false)
    };

    return (
        <ProjectVintageDetailsStateContext.Provider
            value={{
                handleVintageDetailsView: handleVintageDetailsView,
                loading: loading
            }}
        >
            {children}
        </ProjectVintageDetailsStateContext.Provider>
    );
};

function useProjectVintageDetailsState() {
    const context = useContext(ProjectVintageDetailsStateContext);
    if (!context) {
        throw new Error('no provider for useProjectVintageDetailsState');
    }
    return context;
}

export { ProjectVintageDetailsProvider, useProjectVintageDetailsState };
