var projectKeyNames = {
    clientAmountAssigned: 'Client Holdings',
    price: 'Price (AUD)',
    product: 'Product',
    marketHoldings: 'Market Holdings (Locked)'
};
//We should never change these values
var TransactionType;
(function (TransactionType) {
    TransactionType["PhysicalSell"] = "Physical Sell";
    TransactionType["PhysicalBuy"] = "Physical Buy";
    TransactionType["ForwardSell"] = "Forward Sell";
    TransactionType["ForwardBuy"] = "Forward Buy";
    TransactionType["ForwardSellDeliver"] = "Forward Sell Deliver";
    TransactionType["ForwardBuyDeliver"] = "Forward Buy Deliver";
    TransactionType["InventoryRetirement"] = "Inventory Retirement";
    TransactionType["ClientRetirement"] = "Client Retirement";
    TransactionType["LockToMarket"] = "Lock to Market";
    TransactionType["UnlockFromMarket"] = "Unlock from Market";
})(TransactionType || (TransactionType = {}));
var isTrade = function (t) { return [
    TransactionType.PhysicalBuy, TransactionType.PhysicalSell, TransactionType.ForwardBuy, TransactionType.ForwardSell, TransactionType.ForwardSellDeliver, TransactionType.ForwardBuyDeliver
].some(function (a) { return a === t; }); };
var isRetirement = function (t) { return [
    TransactionType.InventoryRetirement, TransactionType.ClientRetirement
].some(function (a) { return a === t; }); };
// Emission & Decarbonisation
var EMISSION_UNIT_AMOUNT = '1';
var EMISSION_ASSET_AMOUNT = '1';
var COGNITO_GROUPS_KEY = "cognito:groups";
export { projectKeyNames, TransactionType, isTrade, isRetirement, EMISSION_ASSET_AMOUNT, EMISSION_UNIT_AMOUNT, COGNITO_GROUPS_KEY };
