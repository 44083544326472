import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { CurveAmount, CurveEditButtonBlock, CurveInput, CurveTableCell } from "./CurveComponents";
import { useTheme } from "@mui/material/styles";
import MapUtils from "./MapUtils";

/**
 * Editable table component for the curve marking screen
 *
 * @param initialRows Initial table values
 * @param handleSave Handle save action
 * @param handleEditing Handle the change to and from editing mode
 * @param disableEditing Disables ability to enter editing mode, however if the table is being edited - has no effect
 * @param rowNameResolver Optional parameter, enabling translation of row titles
 * @param productPrice Base product price, providing will cause each row to have prices shown underneath it
 * @param pricesRowNameResolver Optional parameter, enabling translation of price row titles
 * @param dialogMode Causes the table to open and stay in editing mode
 */
const CurveMarkingTable = (
    {
        initialRows,
        handleSave,
        handleEditing,
        disableEditing = false,
        rowNameResolver = rowKey => rowKey,
        productPrice = undefined,
        pricesRowNameResolver = title => `${title} Price`,
        dialogMode = false,
    }: {
        initialRows: Map<string, Map<string, number>>,
        handleSave: (rows: Map<string, Map<string, number>>) => Promise<boolean>,
        handleEditing: (isEditing: boolean) => void,
        disableEditing?: boolean,
        rowNameResolver?: (rowKey: string) => string,
        productPrice?: number,
        pricesRowNameResolver?: (title: string) => string,
        dialogMode?: boolean,
    }) => {
    const theme = useTheme();

    const [isEditing, setEditing] = useState<boolean>(dialogMode);
    const [isSaving, setSaving] = useState<boolean>(false);
    const [updatedRows, setUpdatedRows] = useState(new Map<string, Map<string, number>>());

    useEffect(() => {
        setUpdatedRows(MapUtils.deepCopy(initialRows));
    }, [initialRows]);

    useEffect(() => {
        handleEditing(isEditing);
    }, [isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCurveChange = (row: string, column: string, value: number) => {
        setUpdatedRows(prevState => {
            const nextState = MapUtils.deepCopy(prevState);
            nextState.get(row)!.set(column, value);
            return nextState;
        })
    };

    const handleEditClick = () => {
        setUpdatedRows(MapUtils.deepCopy(initialRows));
        setEditing(prevState => !prevState);
    };

    const handleSaveClick = () => {
        setSaving(true);
        handleSave(updatedRows).then(success => {
            if (success) {
                setEditing(false);
            }
            if (!dialogMode || !success) {
                setSaving(false);
            }
        });
    };

    return (
        <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: '50px' }}>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <CurveTableCell>
                            <CurveEditButtonBlock
                                isEditing={dialogMode || isEditing}
                                handleEditClick={handleEditClick}
                                isSaving={isSaving}
                                handleSaveClick={handleSaveClick}
                                disabled={disableEditing}
                                style={{ marginRight: '-30px' }}
                            />
                        </CurveTableCell>
                        {MapUtils.innerKeys(updatedRows).map(header =>
                            <CurveTableCell key={header} align='right'>{header}</CurveTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {MapUtils.entries(updatedRows).map(([rowKey, rowValue]) =>
                        <Fragment key={rowKey}>
                            <TableRow key={rowKey}>
                                <CurveTableCell key={rowKey}>{rowNameResolver(rowKey)}</CurveTableCell>
                                {MapUtils.entries(rowValue).map(([columnKey, columnValue]) => (
                                    <CurveTableCell key={columnKey} align='right'>
                                        {dialogMode || isEditing
                                            // NB: On edit cancel, we must update defaultValue to reset PriceInput's state.
                                            // Currently, this is done through toggling isEditing which causes a re-render.
                                            ? <CurveInput
                                                defaultValue={initialRows.get(rowKey)?.get(columnKey)!}
                                                handleChange={value => handleCurveChange(rowKey, columnKey, value)}
                                                textFieldProps={{
                                                    disabled: isSaving,
                                                    InputProps: {
                                                        sx: {
                                                            width: '100px',
                                                            height: '32px',
                                                            mr: '-14px',
                                                        },
                                                    },
                                                }}
                                            />
                                            : <CurveAmount value={columnValue} />
                                        }
                                    </CurveTableCell>
                                ))}
                            </TableRow>
                            {productPrice != null &&
                                <TableRow
                                    key={`${rowKey}-prices`}
                                    style={{ backgroundColor: theme.palette.background.paper }}
                                >
                                    <CurveTableCell
                                        key={rowKey}>{pricesRowNameResolver(rowNameResolver(rowKey))}</CurveTableCell>
                                    {MapUtils.entries(updatedRows.get(rowKey)!).map(([columnKey, columnValue]) => {
                                        const initialPrice = initialRows.get(rowKey)!.get(columnKey)! + productPrice;
                                        const updatedPrice = columnValue + productPrice;
                                        const priceChanged = initialPrice !== updatedPrice;
                                        return (
                                            <CurveTableCell key={columnKey} align='right'>
                                                <Tooltip title={priceChanged && `from ${initialPrice} to ${updatedPrice}`}>
                                                    <Typography{...priceChanged && { color: 'primary' }}>
                                                        <CurveAmount value={updatedPrice} />
                                                    </Typography>
                                                </Tooltip>
                                            </CurveTableCell>
                                        )
                                    })}
                                </TableRow>
                            }
                        </Fragment>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export { CurveMarkingTable };
