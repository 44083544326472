var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useWatch, } from 'react-hook-form';
import { IconButton, ListItemText, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Clear } from '@mui/icons-material';
import { Option } from '../../state/ProductItemFilters';
import { useLayoutState } from '../../state/Layout';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
var TradeDirection;
(function (TradeDirection) {
    TradeDirection["SELL"] = "SELL";
    TradeDirection["BUY"] = "BUY";
})(TradeDirection || (TradeDirection = {}));
var Currency;
(function (Currency) {
    Currency["AUD"] = "AUD";
    Currency["USD"] = "USD";
})(Currency || (Currency = {}));
// Some attributes aren't used by forward trades. However, we still need to save these attributes with a placeholder
// value instead of leaving it missing, otherwise we will not be able to filter by these values later (required for
// the position holdings views, among others). If / when core10 is updated to allow filtering on missing attributes
// we can consider removing these placeholder values.
var UNDEFINED_CODE_STRING = 'Undefined'; // Stores the value taken by unused string-type attributes in forward trades
var UNDEFINED_CODE_INTEGER = '-1'; // Stores the value taken by unused integer-type attributes in forward trades
var CORTEN_ATTRIBUTE_MISSING_EXPRESSION = '!*';
var containsUndefinedPlaceholder = function (rawValue) {
    return [UNDEFINED_CODE_STRING, UNDEFINED_CODE_INTEGER, CORTEN_ATTRIBUTE_MISSING_EXPRESSION].some(function (u) { return rawValue.includes(u); });
};
/**
 * generic MUI field component properly wrapped by an RHF controller
 * @param options List of {@link Option} objects for select fields with balance included where applicable.
 * @param balanceDisplayMinDecimals Minimum decimals to use when showing balance in option select fields.
 * @param balanceDisplayMaxDecimals Maximum decimals to use when showing balance in option select fields.
 * @returns
 */
var ControlledTextField = function (_a) {
    var name = _a.name, label = _a.label, _b = _a.type, type = _b === void 0 ? null : _b, _c = _a.integer, integer = _c === void 0 ? false : _c, _d = _a.options, options = _d === void 0 ? null : _d, rules = _a.rules, _e = _a.disabled, disabled = _e === void 0 ? false : _e, control = _a.control, errors = _a.errors, reset = _a.reset, _f = _a.customOnChange, customOnChange = _f === void 0 ? function () { } : _f, _g = _a.balanceDisplayMinDecimals, balanceDisplayMinDecimals = _g === void 0 ? 0 : _g, _h = _a.balanceDisplayMaxDecimals, balanceDisplayMaxDecimals = _h === void 0 ? 0 : _h, _j = _a.emphasizeUsableFields, emphasizeUsableFields = _j === void 0 ? false : _j, _k = _a.ignoreOptionBalances, ignoreOptionBalances = _k === void 0 ? false : _k;
    var theme = useLayoutState();
    var textFieldProps = function (onChange, field) {
        var _a;
        return (__assign(__assign(__assign({ onChange: function (e) {
                onChange(e);
                customOnChange();
            }, id: name, label: label, type: integer ? 'number' : type, select: !!options, error: !!errors[name], helperText: (_a = errors[name]) === null || _a === void 0 ? void 0 : _a.message, required: !!(rules === null || rules === void 0 ? void 0 : rules.required), disabled: disabled || (options === null || options === void 0 ? void 0 : options.disabled) || (options === null || options === void 0 ? void 0 : options.values.length) === 0, size: 'small', fullWidth: true }, (type === 'number' && { inputProps: { step: 'any' } })), (options && {
            SelectProps: __assign(__assign(__assign({ role: 'select' }, (emphasizeUsableFields && !disabled && { sx: { backgroundColor: theme.customTheme.customProps.inputFieldBackground } })), { 
                // override value that is displayed for the selected item
                renderValue: (function (selected) {
                    var _a;
                    var option = options === null || options === void 0 ? void 0 : options.values.find(function (opt) { return opt.id === selected; });
                    // Use field label until the option has loaded, after which use the option label
                    return (_a = option === null || option === void 0 ? void 0 : option.label) !== null && _a !== void 0 ? _a : label;
                }) }), (reset && field.value && !disabled && {
                endAdornment: (_jsx(IconButton, __assign({ sx: { marginRight: -1.5 }, size: 'small', onClick: function () {
                        reset(name);
                        customOnChange();
                    } }, { children: _jsx(Clear, { color: 'secondary', sx: { fontSize: '20px' } }) }))),
                IconComponent: function () { return null; },
            }))
        })), (integer && {
            onKeyDown: function (e) {
                // Most of the "filtering" is done by typing the input element as "number", but since
                // we don't need scientific notation, nor singed or decimal values, here we add further
                // restrictions to the input.
                // As of right now this seems to be the only way to make an input ONLY accept numbers.
                if (['e', 'E', '-', '+', '.'].includes(e.key))
                    e.preventDefault();
            },
        })));
    };
    return (_jsx(Controller, { control: control, name: name, rules: rules, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, field = __rest(_b, ["onChange"]);
            return _jsx(TextField, __assign({}, field, textFieldProps(onChange, field), { children: options && options.values.map(function (option) { return (_jsxs(MenuItem, __assign({ disabled: Option.isDisabled(option, !ignoreOptionBalances), value: option.id }, { children: [_jsx(ListItemText, { children: option.label }), !ignoreOptionBalances && option.balance &&
                            _jsx(ListItemText, __assign({ primaryTypographyProps: {
                                    color: 'textSecondary',
                                    typography: 'caption',
                                }, style: { textAlign: 'right' } }, { children: _jsx(AmountFormatWrapper, { amount: option.balance, minDecimalPos: balanceDisplayMinDecimals, maxDecimalPos: balanceDisplayMaxDecimals }) }))] }), option.id)); }) }));
        } }));
};
var useRequired = function (field, dependsOn, control, clearErrors) {
    var _a = useState(false), isRequired = _a[0], setRequired = _a[1];
    var dependsOnWatch = useWatch({ name: dependsOn, control: control });
    useEffect(function () {
        // the field is required when the one it depends on has a value specified
        var required = ![null, ''].includes(dependsOnWatch);
        // clear error messages, in case we are transitioning from "required" to "not required"
        if (!required)
            clearErrors(field);
        setRequired(required);
    }, [dependsOnWatch]); // eslint-disable-line react-hooks/exhaustive-deps
    return isRequired;
};
export { useRequired, ControlledTextField, TradeDirection, Currency, UNDEFINED_CODE_STRING, UNDEFINED_CODE_INTEGER, containsUndefinedPlaceholder, CORTEN_ATTRIBUTE_MISSING_EXPRESSION };
