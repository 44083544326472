var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
 * This file is for UI layout state
 */
import { createContext, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@emotion/react';
var drawerWidth = '300px';
// This is the set minHeight of MUI nav bar 'dense' variant.
var navBarHeight = '50px';
/**
 * Open and close collapsable side menu ***************************************
 */
var Main = styled('main', { shouldForwardProp: function (prop) { return prop !== 'open'; } })(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign({ flexGrow: 1, padding: theme.spacing(3), minHeight: "calc(100vh - ".concat(navBarHeight, ")"), transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }), marginLeft: "-".concat(drawerWidth), overflow: 'hidden' }, (open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })));
});
var AppBar = styled(MuiAppBar, {
    shouldForwardProp: function (prop) { return prop !== 'open'; }
})(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign({ transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }) }, (open && {
        width: "calc(100% - ".concat(drawerWidth, ")"),
        marginLeft: "".concat(drawerWidth),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })));
});
var DrawerHeader = styled('div')(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(0, 1) }, theme.mixins.toolbar), { justifyContent: 'flex-end', height: navBarHeight, 
        // this is an override
        '@media (min-width: 600px)': {
            minHeight: navBarHeight
        } }));
});
var LayoutStateContext = createContext(null);
var LayoutProvider = function (_a) {
    var theme = _a.theme, user = _a.user, isTokenExpired = _a.isTokenExpired, children = _a.children;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(theme), customTheme = _c[0], setCustomTheme = _c[1];
    useEffect(function () {
        if ((!user || isTokenExpired(user)) && !open) {
            handleDrawerOpen();
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () {
        setCustomTheme(theme);
    }, [theme]);
    var handleDrawerOpen = function () {
        setOpen(true);
    };
    var handleDrawerClose = function () {
        setOpen(false);
    };
    return (_jsx(ThemeProvider, __assign({ theme: customTheme.theme }, { children: _jsx(LayoutStateContext.Provider, __assign({ value: {
                open: open,
                handleDrawerOpen: handleDrawerOpen,
                handleDrawerClose: handleDrawerClose,
                customTheme: customTheme
            } }, { children: children })) })));
};
function useLayoutState() {
    var context = useContext(LayoutStateContext);
    if (!context) {
        throw new Error('no provider for useLayoutState');
    }
    return context;
}
export { LayoutProvider, useLayoutState, drawerWidth, navBarHeight, Main, DrawerHeader, AppBar };
