var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
 * This file holds all items in the collapsable side menu
 */
import { Box } from '@mui/material';
import { OidcLogin } from '../OidcLogin';
var SideDrawer = function (_a) {
    var user = _a.user, signIn = _a.signIn, signOut = _a.signOut, loading = _a.loading, enabled = _a.enabled, currentKey = _a.currentKey, keys = _a.keys;
    return (_jsx(Box, __assign({ sx: {
            padding: 3,
            display: 'flex',
            flexGrow: 1
        } }, { children: _jsx(OidcLogin, { user: user, signIn: signIn, signOut: signOut, loading: loading, enabled: enabled, currentKey: currentKey, keys: keys }) })));
};
export default SideDrawer;
